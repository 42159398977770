import { BlockCaravanInstance } from "./BlockCaravanBlock";
import { SlaterGordon, getFunnelVariables } from "@claimsgate/core";
import { reject } from "@/helpers/vue";
import { getFirebaseBackend } from "@/authUtils";

export const methods = {
  displayHelpModal,
  checkCaravanType,
  notMyVehicle,
  submitVehicleConfirmation,
  submitKeeperSelect,
};

export function displayHelpModal(state: BlockCaravanInstance) {
  state.$bvModal.show("helpModal");
}

/** When the user selects not my vehicle */
export function notMyVehicle(state: BlockCaravanInstance) {
  resetToSearchVehicleView(state);
}

export function checkCaravanType(state: BlockCaravanInstance) {
  try {
    state.vin = state.vin.replace(/[-\s]/g, "").substring(0, 17);

    if (!/^[A-HJ-NPR-Z0-9]+$/.test(state.vin)) {
      throw new Error(state.uiMessages.vinContainsInvalidCharacters);
    }

    const vehicle = {
      vin: state.vin,
      make: decodeManufacturer(state.vin.substring(7, 9), state),
      yearOfManufacture: decodeYear(state.vin[9], state),
      serialNumber: state.vin.substring(11),
    };

    state.vehicle = vehicle;
    state.uiToggles.showVehicleConfirmation = true;
  } catch (error) {
    console.log("[blockCaravan.vue - checkCaravanType]", error);
    state.vinInputInvalidFeedback = error.message;
    state.vinInputState = false;
    tryCatchHandler(state, error, error.message);
  }
}

export function resetToSearchVehicleView(state: BlockCaravanInstance) {
  // Clear fetched vehicle
  state.vehicle = null;

  // Clear previous answers
  state.vin = "";
  state.ownershipDate = "";
  state.BlockInputs.vehicleConfirmationSingleSelect.answer = "";
  state.BlockInputs.keeperSelectSingleSelect.answer = "";

  // Clear previous block states
  state.vinInputState = null;
  state.BlockInputs.ownershipDatePicker.state = null;
  state.BlockInputs.vehicleConfirmationSingleSelect.state = null;
  state.BlockInputs.keeperSelectSingleSelect.state = null;

  // Clear previous block invalid feedbacks
  state.vinInputInvalidFeedback = "";
  state.BlockInputs.keeperSelectSingleSelect.invalidFeedback = "";
  state.BlockInputs.ownershipDatePicker.invalidFeedback = "";
  state.BlockInputs.vehicleConfirmationSingleSelect.invalidFeedback = "";

  // Display the vehicle select
  state.uiToggles.isKeeperSelectVisible = false;
  state.uiToggles.showVehicleConfirmation = false;
  state.uiToggles.showOwnershipDateQuestion = false;
  state.$nextTick(() => {
    state.uiToggles.isVehicleSearchVisible = true;
  });

  // Reset the HTML text
  state.BlockInputs.confirmVehicleText.html = "";

  // Reset button loaders
  state.BlockInputs.submitVehicleConfirmationButton.isProcessing = false;
  state.BlockInputs.searchVinButton.isProcessing = false;
  state.BlockInputs.submitKeeperSelectButton.isProcessing = false;
}

/** Handles the logic when the user submits the view for confirming if the retured vehicle is correct */
export async function submitVehicleConfirmation(state: BlockCaravanInstance) {
  try {
    if (state.BlockInputs.vehicleConfirmationSingleSelect.answer === "No") {
      state.uiToggles.showOwnershipDateQuestion = true;
      await state.$nextTick();
      state.uiToggles.showVehicleConfirmation = false;

      state.BlockInputs.vehicleConfirmationSingleSelect.answer = "";
      return;
    }

    state.BlockInputs.confirmVehicleText.html = `<div><div><h3><b>Vehicle Found</b></h3><h4>We found your vehicle, <b>${state.vehicle.make?.toUpperCase()}</b> <b>${
      state.vehicle.yearOfManufacture
    }</b>.</h4></div></div>`;

    state.uiToggles.isVehicleSearchVisible = false;

    setupKeeperChangeSingleSelect(state);
    await state.$nextTick();
    state.uiToggles.showVehicleConfirmation = false;
    await state.$nextTick();
    state.uiToggles.isKeeperSelectVisible = true;
  } catch (error) {
    tryCatchHandler(state, error);
  }
}

function setupKeeperChangeSingleSelect(state: BlockCaravanInstance) {
  state.BlockInputs.keeperSelectSingleSelect.options = ["Other"];
}

export async function submitKeeperSelect(state: BlockCaravanInstance) {
  try {
    console.log("OWNERSHIP DATE", state.ownershipDate);
    if (!state.ownershipDate) {
      state.BlockInputs.ownershipDatePicker.state = false;
      state.BlockInputs.ownershipDatePicker.invalidFeedback = "Please enter a valid date of purchase";
      return;
    }
    if (
      SlaterGordon.Funnels.MISSOLD_CAR_FINANCE_FUNNEL_ID === state.funnelId ||
      state.funnelId === "umkfPtVEbYgMUP5R4DMR"
    ) {
      const { ownershipDate } = state;
      console.log(ownershipDate, new Date(ownershipDate) > new Date("2021-01-01"));
      if (new Date(ownershipDate) > new Date("2021-01-01")) {
        // if date is greater than January 1st 2021, reject with error
        state.BlockInputs.ownershipDatePicker.state = false;
        state.BlockInputs.ownershipDatePicker.invalidFeedback =
          "The vehicle must have been purchased before January 1st 2021";
        return;
      }
    }
    state.BlockInputs.submitKeeperSelectButton.isProcessing = true;
    state.BlockInputs.keeperSelectSingleSelect.state = null;

    await saveVehicle(state);
  } catch (error) {
    tryCatchHandler(state, error);
  }
}

async function saveVehicle(state: BlockCaravanInstance) {
  const [funnelVariables] = await getFunnelVariables(getFirebaseBackend().firestore(), state.funnelId);

  console.log(state.vehicle);

  const vehicle: (typeof state)["vehicle"] = {
    ...state.vehicle,
    keeperStartDate: state.ownershipDate,
  };

  const hashedVehicle = await state.variableService.hashData(vehicle, funnelVariables);

  for (const [key, value] of Object.entries(hashedVehicle)) {
    state.claimDataService.setArtefact(key, value);
  }
  console.log("Save Vehicle");

  state.$store.dispatch("events/fire", { name: state.eventValues.next });
}

function decodeManufacturer(wmi: string, state: BlockCaravanInstance): string {
  const manufacturers: { [key: string]: string } = {
    AB: "ABI",
    AD: "Adria",
    AV: "Avondale",
    BE: "Bessacarr",
    BU: "Buccaneer",
    BY: "Bailey",
    CL: "Carlight",
    CM: "Coachman",
    CP: "Compass",
    CS: "Cosalt",
    CU: "Sprite",
    EL: "Elddis",
    EX: "Explorer",
    FL: "Fleetwood",
    LU: "Lunar",
    SW: "Swift",
  };

  if (manufacturers[wmi]) {
    return manufacturers[wmi];
  }

  state.vinInputInvalidFeedback = state.uiMessages.vinNotMatched;
  state.vinInputState = false;
  reject(state, state.uiMessages.vinNotMatched);
  throw new Error("Invalid VIN manufacturer");
}

function decodeYear(yearChar: string, state: BlockCaravanInstance): number {
  // Example logic for years 1980-2009; adjust as needed
  const years: { [key: string]: number } = {
    N: 1992,
    P: 1993,
    R: 1994,
    S: 1995,
    T: 1996,
    V: 1997,
    W: 1998,
    X: 1999,
    Y: 2000,
    "1": 2001,
    "2": 2002,
    "3": 2003,
    "4": 2004,
    "5": 2005,
    "6": 2006,
    "7": 2007,
    "8": 2008,
    "9": 2009,
    A: 2010,
    B: 2011,
    C: 2012,
    D: 2013,
    E: 2014,
    F: 2015,
    G: 2016,
    H: 2017,
    I: 2018,
    J: 2019,
    K: 2020,
    L: 2021,
    M: 2022,
  };

  if (years[yearChar]) {
    return years[yearChar];
  }
  state.vinInputInvalidFeedback = state.uiMessages.vinNotMatched;
  state.vinInputState = false;
  reject(state, state.uiMessages.vinNotMatched);
  throw new Error("Invalid VIN year");
}

// Create a generic try catch handler which will log the error and reset the view to the search vehicle view
function tryCatchHandler(state: BlockCaravanInstance, error: Error, uiErrorMessage?: string) {
  console.error(error);
  resetToSearchVehicleView(state);

  state.vinInputState = false;
  state.vinInputInvalidFeedback = uiErrorMessage || state.uiMessages.vinNotMatched;
  reject(state, uiErrorMessage || state.uiMessages.vinNotMatched);
}
