<script lang="ts">
const CODE_LENGTH: number = 6;

/*eslint-disable*/
enum ViewKind {
  VerifyCode = 0,
  Options = 1,
}

enum VerifyUserAuthRequests {
  SendCode = 1,
  VerifyCode = 2,
}

enum CodeKind {
  Sms = "Sms",
  Email = "Email",
}

enum CodeKindText {
  Sms = "Text Message",
  Email = "Email",
}

enum CodeKindValues {
  Sms = "phoneNumber",
  Email = "email",
}

namespace CodeKind {
  export function toString(code: CodeKind) {
    return CodeKindText[code];
  }

  export function value(code: CodeKind) {
    return CodeKindValues[code];
  }

  export function fromValue(value: CodeKindValues) {
    return Object.keys(CodeKindValues).find((key) => CodeKindValues[key] === value) as CodeKind;
  }
}

/*eslint-enable*/
import Vue, { PropOptions, VueConstructor } from "vue";
import { authMethods, formComputed } from "@/state/helpers";
import { parsePhoneNumber } from "libphonenumber-js";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
// Wrappers
import ModalBodyWrapper from "@/components/shared/wrappers/modalBodyWrapper.vue";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";

/** Interface to define additional component properties **/
interface RecoverAccount extends Vue {
  $refs: {
    codeInput: Array<HTMLInputElement>;
  };
}

export default (Vue as VueConstructor<Vue & RecoverAccount>).extend({
  props: {
    /** If claimId is passed as prop, will try and copy claim automatically on login */
    claimId: { type: String, required: false } as PropOptions<string>,
    /** Used to lookup a userRecord and send a code via a medium */
    userIdentifier: { type: Object, required: true } as PropOptions<{
      email?: string;
      phoneNumber?: string;
      uid?: string;
    }>,
    /** Optionally add in a unique modal identifier to the <b-modal id=> */
    uniqueModalId: { type: String, required: false } as PropOptions<string>,
    verifyOnly: { type: Boolean, required: false, default: false } as PropOptions<boolean>,
    // TODO: Look into adding medium as a prop here?
  },
  components: {
    ModalBodyWrapper,
  },
  data() {
    return {
      /** Holds the entered digit for each input box **/
      codeArray: new Array(CODE_LENGTH) as Array<number>,
      viewSelected: ViewKind.VerifyCode,
      ViewKind,
      CodeKind,
      /** How the code will be sent **/
      medium: "email" as CodeKindValues,
      /** The value used to verify a code **/
      authValue: null,
      /** Upon successful code verify, will contain a customToken **/
      authToken: null,
      sentTo: null,
      /** The different UI states **/
      uiToggles: {
        isError: false,
        isProcessing: false,
        isSendingCode: false,
      },
      /** The different UI messages **/
      uiMessages: {
        codeInvalid: "Code invalid, please try again",
        codeBadlyFormatted: "Code too short, please try again.",
      },
      error: "",
      focusedCodeIndex: 0,
      supportedMediums: new Array(0) as Array<CodeKindValues>,
      ready: false,
      infoModalService: new InfoModalService(this.$infoModal),
    };
  },

  methods: {
    ...authMethods,

    hideHandler(): void {
      this.uiToggles.isError = false;
      this.clearCode();
    },

    showHandler(): void {
      this.$nextTick(() => {
        if (this.$refs.codeInput && this.$refs.codeInput[this.firstEmptyCodeIndex])
          this.$refs.codeInput[this.firstEmptyCodeIndex].focus();
      });
    },

    /** Resets the entered code **/
    clearCode() {
      this.codeArray = new Array(CODE_LENGTH);
    },
    /** Selects the view to display **/
    selectView(view: ViewKind): void {
      this.viewSelected = view;
    },
    /** Sets the code array while paying attention to CODE_LENGTH **/
    setCode(code: string) {
      // ! for-of doesn't work here please do not change - Jesse
      for (let i = 0; i < code.length; i++) {
        if (isNaN(parseInt(code[i]))) return; // exit function early if not numeric string (maybe remove spaces first?)
      }

      // start pasting the code from the focused element
      const codeLength = this.clamp(code.length, 0, CODE_LENGTH - this.focusedCodeIndex);
      let lastCodeIndex = 0;

      // sets the digits into the apropriate indices
      [...code.substring(0, codeLength)].forEach((codeChar, codeIndex) => {
        this.$set(this.codeArray, codeIndex + this.focusedCodeIndex, parseInt(codeChar));
        lastCodeIndex = codeIndex;
      });

      // focus next input
      if (this.$refs.codeInput[lastCodeIndex + 1]) this.$refs.codeInput[lastCodeIndex + 1].focus();
      // assume we are done and unfocus last input
      else this.$refs.codeInput[this.focusedCodeIndex].blur();
    },

    /**
     * Handles the focus event when a code input is selected and ensures that i.e. digit 6 can not be entered before digit 1.
     */
    handleFocus(codeIndex: number): void {
      // If the code is empty
      this.focusedCodeIndex = codeIndex;
      if (this.code.length === 0) {
        this.$refs.codeInput[0].focus();
        // If the user is trying to select an input which is not the next digit in the code
        // i.e. User wants to enter digit 6 but they are on digit 2
      } else if (codeIndex > this.firstEmptyCodeIndex && this.$refs.codeInput[this.firstEmptyCodeIndex]) {
        this.$refs.codeInput[this.firstEmptyCodeIndex].focus();
      }
    },

    /** Clamps a number to be within min/max range */
    clamp(num: number, min: number, max: number) {
      return Math.min(Math.max(num, min), max);
    },

    /** Handles the paste event when a code input is selected and will only allow digits */
    handlePaste(paste: any) {
      try {
        // we want our custom handling to be the only handling
        paste.preventDefault();

        // ? Supported by most modern browsers
        const code = paste.clipboardData.getData("text/plain");

        this.setCode(code);
      } catch (error) {
        console.trace(error);
      }
    },

    /** Handles the native keydown event for when a code input receives a key press, handles next previous and replace */
    handleKeydown(event: any, codeIndex: number) {
      if (!event || event.ctrlKey || event.metaKey) return;

      console.log("[handleKeydown]", event, this.codeArray);

      // preview default event handler from running
      event.preventDefault();

      const { key, keyCode } = event;

      // emulate backspace
      if (keyCode == 8) {
        // focus previous input if next is blank
        if (this.codeArray[codeIndex + 1] == undefined && this.$refs.codeInput[codeIndex - 1])
          this.$refs.codeInput[codeIndex - 1].focus();

        // focus previous if current is blank and emulate backspace on it
        if (this.codeArray[codeIndex] == undefined && this.$refs.codeInput[codeIndex - 1]) {
          this.$set(this.codeArray, codeIndex - 1, undefined);
          this.$refs.codeInput[codeIndex - 1].focus();
        }

        this.$set(this.codeArray, codeIndex, undefined);

        return;
      }

      const input = parseInt(key);
      if (isNaN(input)) return; // is digit?

      // replace code value
      this.$set(this.codeArray, codeIndex, input);

      // focus next input
      if (this.$refs.codeInput[codeIndex + 1] && this.$refs.codeInput[this.firstEmptyCodeIndex])
        this.$refs.codeInput[this.firstEmptyCodeIndex].focus();
      // assume we are done and unfocus last input
      else this.$refs.codeInput[codeIndex].blur();
    },

    /** Requests that a code be sent via the specified medium of the userRecord found from looking up a user via uid/email/phoneNumber */
    async requestCode(): Promise<void> {
      try {
        this.$emit("ready");
        this.uiToggles.isProcessing = true;
        this.uiToggles.isSendingCode = true;
        this.uiToggles.isError = false;
        this.error = "";
        // ? Implement logic for re-sending the code via a different medium
        // ^ see medium

        const userRecordLookupData = this.userIdentifier;

        const sendCodeRequest: any = {
          requestType: VerifyUserAuthRequests.SendCode,
          medium: this.medium,
          ...userRecordLookupData,
        };
        console.log("verifyOnly is", this.verifyOnly);
        if (this.verifyOnly === true) {
          sendCodeRequest.verifyOnly = this.verifyOnly;
        }

        const { data: sendCodeResponse } = await onCallGateway<"verifyUserAuth">({
          functionName: "verifyUserAuth",
          data: sendCodeRequest,
        });
        const { error, message } = sendCodeResponse;

        if (error) {
          throw new Error(message);
        } else {
          this.ready = true;

          this.authValue = Object.values(sendCodeResponse.auth)[0];
          this.supportedMediums = sendCodeResponse.supportedMediums;
          this.sentTo = sendCodeResponse.sentTo;

          // TODO: Decide if this needs to be moved
          if (this.viewSelected != ViewKind.VerifyCode) {
            this.viewSelected = ViewKind.VerifyCode;
            // focus the correct input when the DOM is rendered next
            this.$nextTick(() => {
              if (this.$refs.codeInput && this.$refs.codeInput[this.firstEmptyCodeIndex])
                this.$refs.codeInput[this.firstEmptyCodeIndex].focus();
            });
          } else {
            if (this.$refs.codeInput && this.$refs.codeInput[0]) this.$refs.codeInput[0].focus();
          }
        }
        this.uiToggles.isProcessing = false;
        this.uiToggles.isSendingCode = false;
      } catch (exception) {
        console.error(exception);
        this.$bvModal.hide(`RecoverAccount${this.uniqueModalId}`);
        await this.infoModalService.fire("error");
      }
    },

    /** Tries to validate a code, requires the value of the key used to lookup the userRecord.
     *  Sets this.authToken to a customToken on success
     */
    async submitCode(): Promise<void> {
      this.uiToggles.isError = false;
      this.uiToggles.isProcessing = true;

      // If the entered code is badly formatted
      if (this.code.length != CODE_LENGTH) {
        this.uiToggles.isError = true;
        this.uiToggles.isProcessing = false;
        this.error = this.uiMessages.codeBadlyFormatted;

        return;
      }

      // If the code has not been requested yet
      if (!this.authValue) {
        return;
      }

      const verifyCodeData: any = { value: this.authValue, code: this.code };
      if (this.claimId) {
        verifyCodeData.claimId = this.claimId; // by providing the claim id here, we copy the user's claim to the account we will be logging in to
      }

      const verifyCodeRequest = { requestType: VerifyUserAuthRequests.VerifyCode, ...verifyCodeData };

      const { data: verifyCodeResponse } = await onCallGateway<"verifyUserAuth">({
        functionName: "verifyUserAuth",
        data: verifyCodeRequest,
      });

      const { error, message } = verifyCodeResponse;

      if (error) {
        this.uiToggles.isError = true;
        this.error = this.uiMessages.codeInvalid;
        this.clearCode();
        this.uiToggles.isProcessing = false;
        // Focus the first input so the user can easily enter the next code
        this.$refs.codeInput[0].focus();
        window.console.error(message);
      } else {
        // Success
        if (this.verifyOnly === true) {
          this.$emit("authSuccessful");
          return;
        }
        //this.authToken = ;
        await this.customTokenLogin({ token: verifyCodeResponse.customToken });
        // ! We need to emit a success event to indicate the calling component
        // ! can now handle a redirect operation.

        /* 
        emitting this to email.vue (contact details block) just results in an eventual call to window.location.reload(); via verifyauth.vue:32
        so instead if we know that we want the response is indicating that we need to be redirected to a different claim, we can assume this
        can only happen from contact details block having invoked the copy claim functionallity during a login therefore we can just handle
        the redirect here in that case
         */

        if (verifyCodeResponse.claimId) {
          // TODO: make this modal look better
          await this.infoModalService.fire("warning", {
            title: "You have already registered a claim",
            text: "It looks like you already have an active claim. Click resume to go to your existing claim.",
            dismissButtonText: "Understood",
            onConfirm: () => {
              // TODO: bugfix for query params staying in tact after this next line
              window.location.pathname = `/track/${verifyCodeResponse.claimId}`;
              //this.$router.push({ name: "Track", params: { claimId: this.redirectToClaimId } }).then();
            },
          });
        } else {
          this.$emit("authSuccessful");
        }
        this.uiToggles.isProcessing = false;
      }
    },
  },
  watch: {
    /** Populate the code into our data correctly autofilled by iOS and Android */
    codeArray: function () {
      // ! IOS and Android does some strange method of filling in values through autofill
      if (this.codeArray[0]?.toString()?.length === 6) {
        const _codeArray = [...this.codeArray[0].toString()].map((element) => parseInt(element));
        this.codeArray = _codeArray;
        this.$nextTick(() => {
          this.$refs.codeInput[0].value = _codeArray[0].toString();
        });
        //this.setCode(this.codeArray[0].toString());
      }
    },
    /** Auto requests a code on change */
    userIdentifier: {
      async handler(newIdentifier, oldIdentifier) {
        try {
          console.log(">>> Watcher triggered in RecoverAccount", { newIdentifier, oldIdentifier });
          const identifierNotDefined =
            !this.userIdentifier || (!this.userIdentifier?.phoneNumber && !this.userIdentifier?.email);

          // const identifierNotChanged =
          //   newIdentifier?.email && oldIdentifier?.email && newIdentifier?.email === oldIdentifier?.email;

          if (identifierNotDefined) {
            this.$store.dispatch("form/setLoader", { name: "next", value: false });
            return;
          }
          this.sentTo = Object.values(this.userIdentifier)?.[0];
          this.medium = Object.keys(this.userIdentifier)?.[0] as CodeKindValues;
          this.supportedMediums = [this.medium];

          // If a phone number has been given then ensure it is E.164 compilant
          if (this.userIdentifier?.phoneNumber) {
            this.userIdentifier.phoneNumber = parsePhoneNumber(
              this.userIdentifier.phoneNumber,
              "GB"
            )?.number?.toString();
          }
          await this.requestCode();
          //this.$store.dispatch("form/setLoader", { name: "next", value: false });
        } catch (exception) {
          console.error(exception);
          this.$store.dispatch("form/setLoader", { name: "next", value: true });
          return;
        }
      },
      immediate: true,
    },
    /** Auto submits a code once a length requirement is met */
    code: {
      handler: function (code: string) {
        // If the code does not match the code length
        if (code.length < CODE_LENGTH || code.length > CODE_LENGTH) return;

        // ! Implement the logic to submit and validate the code

        this.submitCode();
      },
    },
  },
  computed: {
    /** Calculates the given code from the array **/
    code(): string {
      return this.codeArray.join("");
    },

    /** Calculates index of the first empty/undefined item */
    firstEmptyCodeIndex(): number {
      return this.codeArray.findIndex((code) => code === undefined);
    },

    /** Calculates the array of selectable code types **/
    codeTypes(): Array<{ text: string; value: string }> {
      const codes: Array<{ text: string; value: string; disabled?: boolean }> = [
        { text: "Please select a type", value: null, disabled: true },
      ];

      this.supportedMediums.forEach((medium) => {
        const kind = CodeKind.fromValue(medium);
        console.log("[debug]", medium, CodeKind.fromValue(medium), CodeKindValues);
        codes.push({ text: CodeKind.toString(kind), value: CodeKind.value(kind) });
      });

      //codes.push({ text: CodeKind.toString(CodeKind.Email), value: CodeKind.value(CodeKind.Email) });
      //codes.push({ text: CodeKind.toString(CodeKind.Sms), value: CodeKind.value(CodeKind.Sms) });

      return codes;
    },
    ...formComputed,
  },
  mounted() {
    this.uiToggles.isProcessing = false;
  },
});
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
<template>
  <div>
    <b-modal
      :id="uniqueModalId ? 'RecoverAccount' + uniqueModalId : 'RecoverAccount'"
      centered
      size="md"
      hide-footer
      @shown="showHandler"
      @hide="hideHandler"
    >
      <template #modal-header="{ close }">
        <ModalBodyWrapper :useTheme="true">
          <b-row>
            <b-col cols="10" sm="11" class="overflow-hidden">
              <template v-if="viewSelected === ViewKind.VerifyCode">
                <div style="display: block; float: left">
                  <template v-if="verifyOnly">
                    <h4>Validate your email address</h4>
                    <p class="mb-0">Please enter the 6 digit code we've sent to: {{ sentTo }}</p>
                  </template>
                  <template v-else>
                    <h4>Account Recovery</h4>
                    <p class="mb-0">Enter the code sent to: {{ sentTo }}</p>
                  </template>
                </div>
              </template>
              <template v-else-if="viewSelected === ViewKind.Options">
                <div style="display: block">
                  <h4>Choose another way to recover your account</h4>
                  <p class="mb-0">Make sure you have your notifications turned on</p>
                </div>
              </template>
            </b-col>
            <b-col cols="2" sm="1">
              <b-button size="lg" variant="link" @click="close()" class="float-right d-inline-block p-0 pr-1">
                <i class="fas fa-times mx-1" />
              </b-button>
            </b-col>
          </b-row>
        </ModalBodyWrapper>
      </template>
      <ModalBodyWrapper :useTheme="true">
        <template v-if="viewSelected === ViewKind.VerifyCode">
          <b-overlay :show="uiToggles.isProcessing" spinner-small variant="white">
            <div class="d-flex flex-row">
              <b-form-input
                style="height: 50px; text-align: center; font-size: 1rem"
                class="codeInput mr-2"
                type="number"
                no-wheel
                :id="'codeInput' + codeIndex"
                ref="codeInput"
                @focus="handleFocus(codeIndex)"
                @paste="handlePaste"
                @keydown.native="handleKeydown($event, codeIndex)"
                size="md"
                inputmode="numeric"
                v-for="(codeElement, codeIndex) in codeArray"
                v-model="codeArray[codeIndex]"
                :key="`codeInput${codeIndex}`"
                pattern="[0-9]*"
                :disabled="uiToggles.isProcessing"
                autocomplete="no"
              />
            </div>
          </b-overlay>

          <p class="my-2 text-grey-700">You may need to check your spam folder.</p>
          <p class="my-0">
            Haven't received a code?
            <a href="javascript:void(0)" @click="selectView(ViewKind.Options)"><u>More options</u></a>
          </p>

          <span v-if="uiToggles.isError">
            <p class="mb-0 text-danger">
              {{ error }}
            </p>
          </span>

          <div class="d-none mt-4 mb-0">
            <b-button variant="primary text-white " @click="submitCode" size="lg" block>
              <template v-if="uiToggles.isProcessing">
                <b-spinner class="spinner-border-sm m-1" role="status"></b-spinner>
              </template>
              <template v-else> Submit </template>
            </b-button>
          </div>
        </template>

        <template v-if="viewSelected === ViewKind.Options">
          <h5 class="mb-1">Code Type</h5>
          <b-input-group class="mb-1">
            <b-form-select size="lg" v-model="medium" :options="codeTypes" />
          </b-input-group>

          <div class="mt-4">
            <b-button variant="primary text-white" @click="requestCode" size="lg">
              <template v-if="uiToggles.isSendingCode">
                <b-spinner class="spinner-border-sm m-1" role="status"></b-spinner>
              </template>
              <template v-else> Send code </template>
            </b-button>
          </div>
        </template>
      </ModalBodyWrapper>
    </b-modal>
  </div>
</template>
