import { BlockEmailInstance } from "../BlockEmail";
import { AsyncHelper, ClaimsGateErrors } from "@claimsgate/core";
import { StandardAsyncResult } from "@claimsgate/core-types";
import firebase from "firebase/compat/app";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";

export async function validateWithMailgun(
  state: BlockEmailInstance
): Promise<StandardAsyncResult<boolean, never, ClaimsGateErrors.ApiError>> {
  try {
    const beforeValidateEmail = performance.now();

    const { data: result } = await onCallGateway<"validateEmail">({
      functionName: "validateEmail",
      data: { email: state.providedEmail, claimId: state.claimId, funnelId: state.funnelId, pageId: state.pageId },
    });

    const afterValidateEmail = performance.now();
    console.warn(`Validate Email Function: ${afterValidateEmail - beforeValidateEmail}`);
    const validationAssesment = result?.data;

    if (validationAssesment?.isValidEmail) {
      delete validationAssesment?.reason;
      validationAssesment.emailValidationResult.updatedAt = firebase.firestore.FieldValue.serverTimestamp() as any;

      state.userDataService.setManyArtefacts(validationAssesment);
      return AsyncHelper.onCompleted(true);
    } else {
      state.BlockInputs.email.state = false;
      state.BlockInputs.email.invalidFeedback = state.uiMessages.emailInvalid;
      state.BlockInputs.emailConfirm.state = false;
      state.BlockInputs.emailConfirm.invalidFeedback = state.uiMessages.emailInvalid;

      return AsyncHelper.onCompleted(false);
    }
  } catch (exception) {
    console.error("Mailgun validation threw", exception);
    return AsyncHelper.onException(ClaimsGateErrors.ApiError);
  }
}
