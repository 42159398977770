import { MutatorFunction } from "@/types";
import { Page, Block } from "@claimsgate/core-types";

import { PageUtility } from "@/helpers/ClaimsGate/pages/PageUtility";
import { UserBlockValidatorsService } from "./UserBlockValidatorsService";
import { reject } from "@/helpers/vue";
import { FormState } from "@/components/claimant/form/BlockFormInstance";
export class BaseValidationService {
  userBlockValidatorsService = new UserBlockValidatorsService();

  async runBaseValidations(page: Page, formState: FormState) {
    let isValid = true;

    const mutator: MutatorFunction<Promise<void>> = async (block: Block) => {
      const { answer, state, isVisible } = block;
      let { required } = block;

      // Small workaround as natinal isurance doesn't have the required prop
      if (block.type === "BlockNationalInsurance") {
        required = true;
      }
      const blockTBlocks = ["BlockDataArray", "BlockCurrency", "BlockSimplePhone", "BlockSimpleEmail", "BlockNestedMultiSelect", "BlockComplexQuestion"];
      if (blockTBlocks.includes(block.type)) {
        return;
      }

      console.log("[baseValidations] Running for block!", required, answer, isVisible, state, block.type);
      if (state !== undefined) {
        if (
          required &&
          isVisible !== false &&
          (answer === undefined || !answer || (Array.isArray(answer) && answer.length === 0))
        ) {
          block.state = false;
          isValid = false;
          reject(formState, `${"The field " + block.storeAs.field ?? "This field"} is required`);
          // If the input block is required and an answer has been given
          // and the block is visible
        } else if (required && answer && isVisible !== false) {
          block.state = true;
        } else {
          block.state = null;
        }
      }
    };

    await PageUtility.traverseBlocks(mutator, page, "cols");

    return isValid;
  }
}
