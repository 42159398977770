import { Stewarts, getClaimsByFunnelId } from "@claimsgate/core";
import { FormState } from "../BlockFormInstance";
import fuzzball from "fuzzball";

export async function handleFunnelSpecificLogicOnSubmit(state: FormState): Promise<{ cancelSubmit: boolean }> {
  const STEWARTS_CV19_PAGES = {
    businessDetails: "rlpBL1FEoVG5L0e53XjO",
  } as const;
  const STEWARTS_CV19_VARIABLES = {
    companyName: "1Wmk6ijUvSVTKNARAx5s",
  } as const;

  if (state.funnelId === Stewarts.COVID_19_FUNNEL_ID) {
    if (state.page.id !== STEWARTS_CV19_PAGES.businessDetails) {
      return { cancelSubmit: false };
    }

    if (state.claimDataService.getArtefact("claimStatus") === "testing") {
      return { cancelSubmit: false };
    }

    const companyName = state.claimDataService.getArtefact(STEWARTS_CV19_VARIABLES.companyName);

    const [claims, error] = await getClaimsByFunnelId(state.db, state.userId, state.funnelId);

    if (error) {
      return { cancelSubmit: true };
    }

    // Filter active claims with defined company name
    const activeClaims = claims.filter(
      (claim) =>
        ["active", "testing"].includes(claim.claimStatus) && claim[STEWARTS_CV19_VARIABLES.companyName] !== undefined
    );

    // Perform fuzzy string comparison
    for (const claim of activeClaims) {
      const score = fuzzball.ratio(companyName, claim[STEWARTS_CV19_VARIABLES.companyName]);

      if (score > 70) {
        // Show modal when a duplicate is found
        state.infoModalService.fire("warning", {
          title: "You have already registered a claim for this company",
          text: "It looks like you already have an active claim for this company. Click resume to go to your existing claim.",
          cancelButtonText: "Cancel",
          confirmButtonText: "Resume",
          onConfirm: () => {
            state.claimDataService.setArtefact("wasDuplicate", true);
            state.claimDataService.setArtefact("dateDuplicateIdentified", new Date());
            state.claimDataService.setArtefact("claimStatus", "paused");
            state.claimDataService.update().then(() => {
              state.$router.push({ name: "Track", params: { claimId: claim.documentId } });
            });
          },
          onHide: () => {
            console.log("Closed");
          },
        });

        return { cancelSubmit: true };
      }
    }

    return { cancelSubmit: false };
  }

  return { cancelSubmit: false };
}
