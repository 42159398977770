import { DataArrayInstance } from "./DataArrayInstance";
export const mappedComputed = {};
export const computed = {
  componentList,
  buttonText,
  addText,
  validationClasses,
};

function componentList(state: DataArrayInstance): object {
  return window.vm.$options.components;
}

function buttonText(state: DataArrayInstance): string {
  return `Add ${state.entityName}`;
}
function addText(state: DataArrayInstance): string {
  const entityStartsWithVowel = ["a", "e", "i", "o", "u"].includes(state.entityName[0].toLowerCase());
  const entityAllCaps = state.entityName === state.entityName.toUpperCase();

  if (entityAllCaps) {
    return `Add a ${state.entityName}`;
  }

  return `Add ${entityStartsWithVowel ? "an" : "a"} ${state.entityName}`;
}
function validationClasses(state: DataArrayInstance): string {
  if (state.isValid === true) {
    return "border-success border-thick";
  }
  if (state.isValid === false) {
    return "border-danger border-thick";
  }
  return "";
}
