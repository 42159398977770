import { BuilderUtility } from "@/helpers/ClaimsGate/builder/BuilderUtility";
import { navigationComputed } from "@/state/helpers";
import { BlockConsentInstance } from "./BlockConsent";

export function mappedComputed() {
  return {
    ...(navigationComputed as Record<keyof typeof navigationComputed, any>),
  };
}

export const computed = {
  cgPrivacyPolicyLinkText,
  workspaceTermsAndConditionsLinkParsed,
};

function cgPrivacyPolicyLinkText(state: BlockConsentInstance): string {
  if (state.workspacePrivacyPolicyLink.length > 0) {
    return "Claims Gate Privacy Policy";
  } else {
    return "Privacy Policy";
  }
}

function workspaceTermsAndConditionsLinkParsed(state: BlockConsentInstance): string {
  return BuilderUtility.parseUrl(state.workspaceTermsAndConditionsLink);
}
