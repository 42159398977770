//import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
//import { Props } from "@/types";
//const _blockPhoneInputProps = inputProps({ answerType: "string" });
//delete _blockPhoneInputProps.label;
//delete _blockPhoneInputProps.info;
//delete _blockPhoneInputProps.condition;
//const blockPhoneInputProps: Props.BlockPhone = _blockPhoneInputProps;
import { Props } from "@/types";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
const blockPhoneInputProps = inputProps({
  answerType: "none",
  extraProps: ["padding"],
  excludeDefaults: true,
}) as Props.BlockPhone;

export function getProps() {
  return {
    ...blockPhoneInputProps,
    parentIsProcessing: { type: Boolean, default: false },
    allowInternationalNumbers: { type: Boolean, default: false },
    requireUkMobileNumber: { type: Boolean, default: false },
  };
}
