<script>
//@ts-check
// eslint-disable-next-line no-unused-vars
import { Props } from "@/types";
import BlockPadding from "@/components/shared/blocks/padding.vue";
export default {
  name: "BlockDraw",

  components: { BlockPadding },

  props: {
    /** @type {Props.Label} */
    label: {
      type: String,
      required: false,
      allowVariable: false,
      description: "The label on the input",
      default: "Signature",
    },
    /** @type {Props.Str} */
    height: {
      type: String,
      required: false,
      allowVariable: false,
      default: "auto",
      valid: [
        "auto",
        "50",
        "100",
        "150",
        "200",
        "250",
        "300",
        "350",
        "400",
        "450",
        "500",
        "600",
        "700",
        "800",
        "900",
        "1000",
      ],
      description: "Padding in pixels",
    },
    /** @type {Props.InvalidFeedback} */
    invalidFeedback: {
      type: String,
      required: false,
      allowVariable: false,
      default: "Please sign your signature in the box above",
      description: "The feedback displayed when an input is invalid",
    },
    /** @type {Props.State} */
    state: {
      type: Boolean,
      required: false,
      allowVariable: false,
      default: null,
      description:
        "Controls the validation state appearance of the component. `true` for valid, `false` for invalid, or `null` for no validation state",
    },
    /** @type {Props.Disabled} */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      description: "Disable the input so the user cannot edit the value",
    },
    /** @type {Props.Text} */
    text: {
      type: String,
      required: false,
      allowVariable: false,
      description: "The help text surrounding the input",
    },
    /** @type {Props.Answer} */
    answer: {
      type: String,
      required: false,
      allowVariable: false,
      default: undefined,
      description: "The text in the input",
    },
    /** @type {Props.Bool} */
    required: {
      type: Boolean,
      required: false,
      allowVariable: false,
      default: false,
      description: "Whether the input is required",
    },
    /** @type {Props.Bool} */
    lockPad: {
      type: Boolean,
      default: false,
    },
    /** @type { Props.Str} */
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    },
  },
  computed: {
    /** @type { () => string } @this { {uiToggles : {padIsLocked : boolean}}} */
    backgroundColor: function () {
      return this.uiToggles.padIsLocked ? "rgb(136,136,136)" : "rgba(0,0,0,0)";
    },
  },
  data() {
    return {
      /** @type { { isValidating : boolean, hasNoSignature: boolean, padIsLocked : boolean }} */
      uiToggles: {
        isValidating: false,
        hasNoSignature: false,
        padIsLocked: false,
      },
      /** @type { String } */
      mountedDrawHeight: undefined,
      /** @type { String } */
      mountedDrawWidth: undefined,
    };
  },
  methods: {
    /** Triggers when the user finishes drawing oin the signature pad */
    async onEnd() {
      // @ts-ignore
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$emit("update:answer", undefined);
      } else {
        this.$emit("update:answer", data);
        this.$emit("update:state", null);
      }
    },

    /** Triggers when the user begins drawing on the signature pad */
    async onBegin() {
      console.log(">> fired on begin");
      await this.$nextTick();
      await this.$nextTick();
      // @ts-ignore
      this.$refs.signatureCard.focus();
    },
    clearSignature() {
      // @ts-ignore
      this.$refs.signaturePad.clearSignature();
      this.$emit("update:answer", undefined);
    },
  },
  async mounted() {
    // @ts-ignore
    this.mountedDrawHeight = this.$refs.drawCard.clientHeight.toString() + "px";
    // @ts-ignore
    this.mountedDrawWidth = this.$refs.drawCard.clientWidth.toString() + "px";
    console.log(this.mountedDrawHeight, this.mountedDrawWidth);
  },
  watch: {
    /**
     * Because the signaturePad is v-if mountedDrawHeight, cannot set the draw pad signature until the draw pad has been rendered
     */
    async mountedDrawHeight() {
      // mountedDrawheight intializes as undefined
      if (this.mountedDrawHeight) {
        if (this.answer) {
          // Wait for the draw pad to be rendered
          await this.$nextTick();
          // @ts-ignore
          this.$refs.signaturePad.fromDataURL(this.answer);
          // @ts-ignore
          this.$refs.signaturePad.lockSignaturePad();
          this.uiToggles.padIsLocked = true;
        }
      }
    },
    disabled: {
      handler() {
        if (this.disabled) {
          // @ts-ignore
          //this.$refs?.signaturePad?.lockSignaturePad();
          //this.uiToggles.padIsLocked = true;
        } else {
          // @ts-ignore
          this.$refs.signaturePad.openSignaturePad();
          this.uiToggles.padIsLocked = false;
        }
      },
    },
  },
};
</script>
<style scoped>
.deselected {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: #ced4da !important;
  transition: 0.25s;
}
.stateError {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: #f14646 !important;
  transition: 0.25s;
}
.stateTrue {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: #3bc392 !important;
  transition: 0.25s;
}
.disabled {
  background-color: #dcdee3 !important;
}
#signature-container {
  width: 343px;
  /* height: 140px; */
}

div .card-header {
  padding: 0px !important;
}

/** This style ensures when the card holding the signature pad is focused, it has a border in the primary colour */
#signatureCard:focus {
  border-radius: 0.25rem !important;
  border-width: 2px !important;
  border-color: var(--primary) !important;
}
.card > .card-body {
  padding-top: 0rem !important;
  padding-bottom: 1rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
</style>
<template>
  <div>
    <BlockPadding :padding="padding" />
    <h5 v-if="label" class="mb-0">
      <label class="mb-1" for="input-live">{{ label }}</label>
    </h5>
    <b-card
      ref="drawCard"
      no-body
      :title="label"
      :class="{ disabled: uiToggles.padIsLocked, stateError: state === false, stateTrue: state }"
      class="mb-0 deselected"
    >
      <b-aspect aspect="3">
        <VueSignaturePad
          :ref="'signaturePad'"
          :options="{ onEnd, backgroundColor: backgroundColor }"
          :height="mountedDrawHeight"
          width="100%"
          v-if="mountedDrawHeight && mountedDrawWidth"
        />
      </b-aspect>
      <b-card-body class="pt-0 pb-3">
        <hr class="mt-0 mb-2" />
        <p class="mb-0" v-if="!uiToggles.padIsLocked">
          <a href onClick="return false;" @click="clearSignature">Clear</a>
        </p>
      </b-card-body>
    </b-card>
    <template v-if="!state">
      <b-form-invalid-feedback :state="state" id="input-live-feedback">
        {{ invalidFeedback }}
      </b-form-invalid-feedback>
    </template>
    <h5 class="mb-1 font-weight-light">
      <b-form-text id="input-live-help">
        {{ text }}
      </b-form-text>
    </h5>
  </div>
</template>
