import Vue from "vue";
import { methods } from "./methods";
import { getProps } from "./props";
import { getData } from "./data";

import { BlockInstanceProps, Props } from "@/types";
import { mappedComputed, computed } from "./computed";
import { MotorSpecsVehicleStore } from "../motorSpecs/types";
export { methods, getProps, getData, computed };
export type Props = ReturnType<typeof getProps>;
export type Methods = keyof typeof methods;
export type Data = ReturnType<typeof getData>;

export type Computed = { [K in keyof typeof computed]: ReturnType<typeof computed[K]> } & ReturnType<
  typeof mappedComputed
>;

export interface BlockVehicleSplitTestProps {
  padding: Props.Str;
  returnMercedesRecallData: Props.Bool;
  stores: Props.CGPropExtras<Array<{ name: MotorSpecsVehicleStore; type: string }>>;
}

/**
 * Passing Computed as a generic prevents TS from throwing circular reference error when
 * computed functions use a state object with `BlockVehicleSplitTestInstance` as its type
 */
export type BlockVehicleSplitTestInstance = Vue & Data & Methods & Computed & BlockVehicleSplitTestProps;
