import { getFirebaseBackend } from "@/authUtils";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
import { BlockEmailInstance } from "../BlockEmail";

export async function saveEmail(state: BlockEmailInstance) {
  //if this throws an exception, the next() method in form will catch and handle
  if (!state.uiToggles.isEmailDefined || state.verifyEmailAttempted) {
    try {
      const before = performance.now();
      const { data: token } = await onCallGateway<"generateCustomToken">({ functionName: "generateCustomToken" });
      const after = performance.now();
      console.warn(`generateCustomToken function: ${after - before}`);
      await getFirebaseBackend().updateEmail(state.providedEmail);
      await getFirebaseBackend().customTokenLogin(token);
    } catch (e) {
      console.error(e);
    }

    // Setting the block as complete so when form.next() comes back to validate,
    // the block will be skipped
    state.blockDisabled = true;
    state.uiToggles.isEmailDefined = true;
    state.user.email = state.providedEmail;
    state.user.isVerifiedEmail = true;

    state.userDataService.setArtefact("email", state.providedEmail);
    if (state.verifyEmailAttempted) {
      state.$store.dispatch("events/fire", { name: state.eventValues.next });

      state.$bvModal.hide("RecoverAccountVerify");
      return true;
    }
  }

  if (state.verifyEmailAttempted) {
    // The veirfy modal has completed so we need to 'click' continue for the user
    state.user.isVerifiedEmail = true;
    state.$store.dispatch("events/fire", { name: state.eventValues.next });
  }

  state.$bvModal.hide("RecoverAccountVerify");
  return true;
}
