import Vue from "vue";

import { getProps } from "./props";
import { getData } from "./data";
import { computed, mappedComputed } from "./computed";
import { BlockCompanyStore } from "./types";
import { methods } from "./methods";
export { methods, getProps, getData, computed, mappedComputed };

// Defined this here as wanted to use local type
export interface BlockCompanyProps {
  padding: string;
  stores: Array<Record<BlockCompanyStore, string>>;
  condition: Record<string, any>;
  isVisible: boolean;
  label: string;
}

export type Data = ReturnType<typeof getData>;
export type Computed = { [K in keyof typeof computed]: ReturnType<(typeof computed)[K]> } & ReturnType<
  typeof mappedComputed
>;

export type BlockCompanyInstance = Vue & Data & Computed & BlockCompanyProps;
