import { DateService } from "@/helpers/ClaimsGate/DateService";
class ComputeUtils {
  /**
   *
   * @param {*} paramsObject
   * @returns
   */

  static async parseParams(object) {
    let parsedParams = {};
    for (const [key, value] of Object.entries(object)) {
      if (key === "variable") {
        parsedParams[key] = await this.loadVariable(value);
      } else if (key === "value") {
        parsedParams[key] = value;
      }
    }
    return parsedParams;
  }

  static toQueryString(params) {
    // If query parameters have been defined in an object
    // Convert them from {k: v} to ?k=v
    // Create endpoint with the parsed parameter
    if (params && Object.keys(params).length > 0) {
      const queryString = Object.keys(params)
        .map(index => params[index].key + "=" + params[index].value)
        .join("&");
      return queryString;
    }

    return undefined;
  }

  /**
   * Flattens a deeply nested object into a flattened object
   * Values of arrays remain unflattened
   */
  static flatten(objectOrArray, prefix = "") {
    const dateService = new DateService();
    const nestElement = (prev, value, key) => {
      if (value && typeof value === "object") {
        return { ...prev, ...this.flatten(value, `${prefix}${key}.`) };
      } else {
        return { ...prev, ...{ [`${prefix}${key}`]: value } };
      }
    };

    if (Array.isArray(objectOrArray)) {
      if (objectOrArray.length > 0) {
        const arrayElement = objectOrArray[0];
        // Build out a set of denormalised arrays for objects which return an array of objects
        // i.e. if objectOrArray is [{d: 1, y: 2}]
        // We will build an array which states: objectOrArray.d[1, 2, 3];
        if (typeof arrayElement === "object" && !Array.isArray(arrayElement)) {
          let out = {};
          out = { [prefix]: JSON.stringify(objectOrArray) };
          Object.keys(arrayElement).forEach(arrayElementKey => {
            out[`${prefix}${arrayElementKey}`] = objectOrArray.map(arrayElement => {
              if (dateService.parseDate(arrayElement[arrayElementKey]) instanceof Date) {
                return dateService.parseDate(arrayElement[arrayElementKey]).toISOString();
              }
              return arrayElement[arrayElementKey];
            });
          });

          return out;
        }
        if (typeof arrayElement === "string") {
          return { [prefix]: JSON.stringify(objectOrArray) };
        }
      }
    } else {
      return Object.keys(objectOrArray).reduce(
        (prev, element) => nestElement(prev, objectOrArray[element], element),
        {}
      );
    }
  }
}

export { ComputeUtils };
