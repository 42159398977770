import Vue from "vue";
import { methods } from "./methods";
import { getProps } from "./props";
import { getData } from "./data";

import { BlockInstanceProps, Props } from "@/types";
import { computed, mappedComputed } from "./computed";
import { FinanceAgreementDataStore } from "./types";
export { methods, getProps, getData, computed };
export type Props = ReturnType<typeof getProps>;
export type Methods = keyof typeof methods;
export type Data = ReturnType<typeof getData>;
export type Computed = { [K in keyof typeof computed]: ReturnType<typeof computed[K]> } & ReturnType<
  typeof mappedComputed
>;

export interface BlockFinanceAgreementProps {
  padding: Props.Str;
  invalidFeedback: Props.Str;
  stagingEnvironment: Props.Bool;
  stores: Props.CGPropExtras<Array<{ name: FinanceAgreementDataStore; type: string }>>;
}
/**
 * Passing Computed as a generic prevents TS from throwing circular reference error when
 * computed functions use a state object with `BlockFinanceAgreementInstance` as its type
 */
export type BlockFinanceAgreementInstance = Vue &
  Data &
  Methods &
  Computed &
  BlockInstanceProps<BlockFinanceAgreementProps>;
