import { getFirebaseBackend } from "@/authUtils";
import { createClaimDataService } from "@/helpers/vue";
import { ClaimsGateFunctionReturnedError } from "@claimsgate/core";
import { AddressListEntity, ClaimsGateGetAddressResponse } from "@claimsgate/core-types";
import isNil from "lodash.isnil";
import omitBy from "lodash.omitby";
import { BlockClaimAddressInstance } from "./BlockClaimAddress";
import { BlockClaimAddressPartialStores, BlockClaimAddressStores } from "./types";

export const methods = {
  mounted,
  searchAddress,
  computes,
  validations,
};

/** Runs the validations when the Form submit mechanism is triggered */
export async function validations(state: BlockClaimAddressInstance): Promise<boolean> {
  if (state.forceInternationalAddress) {
    if (!validateAddressFields(state)) return false;
  } else {
    if (!validatePostcode(state)) return false;
    if (!validateAddressSearched(state)) return false;
    if (!validateAddressSelected(state)) return false;
  }

  return true;
}

function validateAddressSearched(state: BlockClaimAddressInstance): boolean {
  if (!state.BlockInputs.addressSelect.options || state.BlockInputs.addressSelect.options.length === 0) {
    state.BlockInputs.postcode.state = false;
    state.BlockInputs.postcode.invalidFeedback = state.uiMessages.addressNotSearched;
    return false;
  }
  return true;
}
/** Runs the computes when the Form submit mechanism is triggered */
export async function computes(state: BlockClaimAddressInstance): Promise<boolean> {
  let addressStores: BlockClaimAddressStores = { AddressLine1: "", City: "", Postcode: "", Country: "" };

  if (state.forceInternationalAddress) {
    addressStores = {
      AddressLine1: state.BlockInputs.addressLine1.answer,
      City: state.BlockInputs.town.answer,
      Postcode: state.BlockInputs.postcode.answer,
      Country: "Ireland",
    };
  } else {
    const address = findSelectedAddress(state);

    if (!address) {
      state.BlockInputs.addressSelect.invalidFeedback = state.uiMessages.addressNotSelected;
      state.BlockInputs.addressSelect.state;
      return false;
    }

    addressStores = {
      AddressLine1: `${address.line_1} ${address.line_2}`,
      City: address.town,
      Postcode: address.postcode,
    };
  }

  // Prefix the address with the prefix
  const prefixedAddressStores: Record<string, string> = {};
  for (const [key, value] of Object.entries(addressStores)) {
    prefixedAddressStores[`${state.prefix}${key}`] = value;
  }

  const compactedAddressStores = omitBy(prefixedAddressStores, isNil) as BlockClaimAddressPartialStores;
  await saveAddress(state, compactedAddressStores);

  Object.keys(state.BlockInputs).forEach((key) => {
    state.BlockInputs[key].state = true;
  });
  return true;
}

export function findSelectedAddress(state: BlockClaimAddressInstance) {
  const addressSelected = state.BlockInputs.addressSelect.answer;
  // Find the matching address from the list of addresses

  if (!state.addresses) {
    return null;
  }
  const address = state.addresses.find((address) => convertAddressToDisplayName(address) === addressSelected);

  return address;
}

export function validateAddressSelected(state: BlockClaimAddressInstance): boolean {
  state.BlockInputs.addressSelect.invalidFeedback = "";
  state.BlockInputs.addressSelect.state = null;

  if (!state.BlockInputs.addressSelect.answer || state.BlockInputs.addressSelect.answer.length === 0) {
    state.BlockInputs.addressSelect.invalidFeedback = state.uiMessages.addressNotSelected;
    state.BlockInputs.addressSelect.state = false;
    return false;
  }

  return true;
}

/** Validates the entered postcode */
export function validatePostcode(state: BlockClaimAddressInstance): boolean {
  state.BlockInputs.postcode.invalidFeedback = "";
  state.BlockInputs.postcode.state = null;

  if (!state.BlockInputs.postcode.answer || state.BlockInputs.postcode.answer.length === 0) {
    state.BlockInputs.postcode.invalidFeedback = state.uiMessages.addressPostcodeMissing;
    state.BlockInputs.postcode.state = false;
    return false;
  }

  if (state.BlockInputs.postcode.answer.length < 5) {
    state.BlockInputs.postcode.invalidFeedback = state.uiMessages.postcodeTooShort;
    state.BlockInputs.postcode.state = false;
    return false;
  }

  return true;
}

export function validateInternationalAddress(state: BlockClaimAddressInstance): boolean {
  if (state.internationalPlace.addressLine1?.length === 0 && state.internationalPlace.city?.length === 0) {
    state.BlockInputs.internationalPlace.invalidFeedback = state.uiMessages.addressNotSelected;
    state.BlockInputs.internationalPlace.state = false;
    return false;
  }

  if (state.internationalPlace.addressLine1?.length === 0 || state.internationalPlace.city?.length === 0) {
    state.BlockInputs.internationalPlace.invalidFeedback = state.uiMessages.addressOrCityMissing;
    state.BlockInputs.internationalPlace.state = false;

    console.log("invalid", state.internationalPlace.invalidFeedback);
    return false;
  }

  state.internationalPlace.invalidFeedback = "";
  state.internationalPlace.state = null;

  return true;
}

export async function searchAddress(state: BlockClaimAddressInstance) {
  state.uiToggles.loaders.isSearchingForAddress = true;
  state.uiToggles.show.addressSelect = false;

  state.BlockInputs.postcode.invalidFeedback = "";
  state.BlockInputs.postcode.state = null;
  state.BlockInputs.addressSelect.state = null;
  state.BlockInputs.addressSelect.invalidFeedback = "";
  state.BlockInputs.addressSelect.options = [];
  state.BlockInputs.addressSelect.answer = "";

  // Validate the postcode
  if (!validatePostcode(state)) {
    state.uiToggles.loaders.isSearchingForAddress = false;
    return;
  }

  const postcode = state.BlockInputs.postcode.answer;
  const claimId = state.claimId;
  const { data: workspaceId } = await state.funnelsService.getFunnelAuthor(state.funnelId);

  const { data: response } = await searchAddressFromT2a(postcode, claimId, state.funnelId, state.pageId, workspaceId);

  // If we were unable to find the list of addresses then display an error
  if (isClaimsGateFunctionReturnedError(response)) {
    state.BlockInputs.postcode.invalidFeedback = state.uiMessages.addressNotFound;
    state.BlockInputs.postcode.state = false;
    state.uiToggles.loaders.isSearchingForAddress = false;

    return;
  }

  // Set the list of returned addresses into a dropdown
  const addresses = response;

  // If we were able to find the list of addresses then store it in the state
  state.addresses = addresses;

  // Setup the UI to show the dropdown
  state.BlockInputs.addressSelect.options = addresses.map((address) => {
    return convertAddressToDisplayName(address);
  });

  // Hide the address search input
  state.uiToggles.loaders.isSearchingForAddress = false;

  // Show the dropdown
  state.uiToggles.show.addressSelect = true;
}

function convertAddressToDisplayName(address: AddressListEntity): string {
  // ! here
  return `${address.addr_single_line}`;
}

/** Type guard to check if a given value is a ClaimsGateFunctionReturnedError */
function isClaimsGateFunctionReturnedError(
  response: ClaimsGateGetAddressResponse | ClaimsGateFunctionReturnedError
): response is ClaimsGateFunctionReturnedError {
  return !Array.isArray(response);
}

/** Fetches a list of addresses given the postcode from the T2A backend */
async function searchAddressFromT2a(
  postcode: string,
  claimId: string,
  funnelId: string,
  pageId: string,
  workspaceId: string
): Promise<{ data: ClaimsGateGetAddressResponse | ClaimsGateFunctionReturnedError }> {
  const searchAddress = getFirebaseBackend().firebaseFunctions().httpsCallable("searchAddress");

  return searchAddress({
    postcode,
    claimId,
    funnelId,
    pageId,
    workspaceId,
  });
}

export async function mounted(state: BlockClaimAddressInstance) {
  createClaimDataService<BlockClaimAddressInstance>(state);
}

/** Saves the vehicle stored in the state to the claim data service */
export async function saveAddress(
  state: BlockClaimAddressInstance,
  address: BlockClaimAddressPartialStores
): Promise<boolean> {
  // ! look over this code
  const { data: funnelVariables } = await state.funnelsService.getFunnelVariables(state.funnelId);
  const hashedVehicle = await state.variablesService.hashData(address, funnelVariables);

  for (const [key, value] of Object.entries(hashedVehicle)) {
    state.claimDataService.setArtefact(key, value);
  }

  return true;
}

export function validateAddressFields(state: BlockClaimAddressInstance): boolean {
  // Reset all states and feedback
  state.BlockInputs.postcode.invalidFeedback = "";
  state.BlockInputs.town.invalidFeedback = "";
  state.BlockInputs.addressLine1.invalidFeedback = "";

  // Reset states
  state.BlockInputs.town.state = null;
  state.BlockInputs.postcode.state = null;
  state.BlockInputs.addressLine1.state = null;

  let isValid = true;

  // Address Line 1 validation
  if (!state.BlockInputs.addressLine1.answer || state.BlockInputs.addressLine1.answer.length === 0) {
    state.BlockInputs.addressLine1.state = false;
    state.BlockInputs.addressLine1.invalidFeedback = "Please enter your address";
    isValid = false;
  } else if (state.BlockInputs.addressLine1.answer.length < 3) {
    state.BlockInputs.addressLine1.state = false;
    state.BlockInputs.addressLine1.invalidFeedback = "Please enter a valid address";
    isValid = false;
  } else {
    state.BlockInputs.addressLine1.state = true;
  }

  // Town/City validation
  if (!state.BlockInputs.town.answer || state.BlockInputs.town.answer.length === 0) {
    state.BlockInputs.town.state = false;
    state.BlockInputs.town.invalidFeedback = "Please enter your city";
    isValid = false;
  } else if (state.BlockInputs.town.answer.length < 3) {
    state.BlockInputs.town.state = false;
    state.BlockInputs.town.invalidFeedback = "Please enter a valid city";
    isValid = false;
  } else {
    state.BlockInputs.town.state = true;
  }

  // Postcode validation
  if (!state.BlockInputs.postcode.answer || state.BlockInputs.postcode.answer.length === 0) {
    state.BlockInputs.postcode.state = false;
    state.BlockInputs.postcode.invalidFeedback = "Please enter your postcode";
    isValid = false;
  } else if (state.BlockInputs.postcode.answer.length < 6) {
    state.BlockInputs.postcode.state = false;
    state.BlockInputs.postcode.invalidFeedback =
      "Please enter a valid Irish Eircode, which is intended to be 7 characters long";
    isValid = false;
  } else {
    state.BlockInputs.postcode.state = true;
  }

  return isValid;
}
