import { BlockConsentInstance } from "./BlockConsent";
import { mounted } from "./mounted";
import { Consent } from "@claimsgate/core-types";
export const methods = {
  checkConsents,
  mounted,
  validations,
  computes,
};

async function validations(state: BlockConsentInstance): Promise<boolean> {
  if (blockIsComplete(state)) return true;

  setConsentState(state);

  if (!checkConsents(state)) {
    setConsentState(state);
    return false;
  }

  disableConsents(state);

  return true;
}
async function computes(state: BlockConsentInstance): Promise<boolean> {
  if (blockIsComplete(state)) {
    setConsentState(state);
    return true;
  }

  // update user consents
  const userConsents = updateConsentsArray(state, state.user.consents ?? []);

  state.userDataService.setManyArtefacts({
    privacyConsent: state.checkboxes.cgPrivacy.answer,
    privacyConsentDate: state.FieldValue.serverTimestamp(),
  });
  console.log("Updating consents to", userConsents);
  state.userDataService.setArtefact("consents", userConsents);

  setConsentState(state);

  // update user communication preferences
  let userCommunicationPreferences = state.userDataService.getArtefact("communicationPReferences");
  if (!userCommunicationPreferences) {
    userCommunicationPreferences = { email: true, sms: true, phone: true };
  }

  state.userDataService.setArtefact("communicationPreferences", userCommunicationPreferences);

  await state.userDataService.update();

  return true;
}

function blockIsComplete(state: BlockConsentInstance) {
  const result: Array<boolean> = [];
  if (state.workspacePrivacyPolicyLink?.length > 0) {
    result.push(state.existingConsentsForThisWorkspace?.latestPrivacyConsent?.status === "accepted");
  }

  if (state.workspaceTermsAndConditionsLink?.length > 0) {
    result.push(state.existingConsentsForThisWorkspace?.latestTsAndCsConsent?.status === "accepted");
  }

  result.push(state.user.privacyConsent === "accepted");
  console.log("blockIsComplete results", result);
  return result.every((consent) => consent);
}

/**
 * Creates new Consent objects for workpsace PrivacyPolict and TermsAndConditions
 */
function updateConsentsArray(state: BlockConsentInstance, consents: Array<Consent>): Array<Consent> {
  console.log(
    "existingWTp",
    state.existingConsentsForThisWorkspace?.latestPrivacyConsent?.status,
    state.workspacePrivacyPolicyLink
  );
  if (
    state.workspacePrivacyPolicyLink?.length > 0 &&
    !(state.existingConsentsForThisWorkspace?.latestPrivacyConsent?.status === "accepted")
  ) {
    const workspacePrivacyConsent: Consent = {
      workspaceId: state.navigationWorkspaceId,
      date: new Date(),
      type: "PrivacyPolicy",
      status: state.checkboxes.workspacePrivacy.answer,
    };

    consents.push(workspacePrivacyConsent);
  }
  // Not displaying TsAndCs so can't add it to the consents array
  //if (
  //  state.workspaceTermsAndConditionsLink?.length > 0 &&
  //  !(state.existingConsentsForThisWorkspace?.latestTsAndCsConsent?.status === "accepted")
  //) {
  //  const workspaceTsAndCsConsent: Consent = {
  //    workspaceId: state.navigationWorkspaceId,
  //    date: new Date(),
  //    type: "TermsAndConditions",
  //    status: "accepted",
  //  };

  //  consents.push(workspaceTsAndCsConsent);
  //}
  return consents;
}

/**
 * Asserts that checkboxes.cgPrivacy  is checked, and if a workspsacePrivacyLink is provided
 * it asserts that the checkboxes.workspacePrivacy is checked
 * @param state
 * @returns
 */
function checkConsents(state: BlockConsentInstance) {
  state.checkboxes.cgPrivacy.state = state.checkboxes.cgPrivacy.answer === "accepted";
  const allAccepted = [state.checkboxes.cgPrivacy.state];

  if (state.smsCheckBox) {
    state.checkboxes.sms.state = state.checkboxes.sms.answer === "accepted";
    allAccepted.push(state.checkboxes.sms.state);
  }

  if (state.workspacePrivacyPolicyLink?.length > 0) {
    state.checkboxes.workspacePrivacy.state = state.checkboxes.workspacePrivacy.answer === "accepted";
    allAccepted.push(state.checkboxes.workspacePrivacy.state);
  }
  console.log("Accepted consents", allAccepted, state);
  return allAccepted.every((state) => state);
}

function disableConsents(state: BlockConsentInstance) {
  state.checkboxes.cgPrivacy.disabled = true;
  state.checkboxes.workspacePrivacy.disabled = true;
  state.checkboxes.sms.disabled = true;
}

/**
 * If the boxes have been ticked their state will be set to true, else false
 * @param state
 */
function setConsentState(state: BlockConsentInstance) {
  state.checkboxes.cgPrivacy.state = state.checkboxes.cgPrivacy.answer === "accepted";
  state.checkboxes.workspacePrivacy.state = state.checkboxes.workspacePrivacy.answer === "accepted";
  state.checkboxes.sms.state = state.checkboxes.sms.answer === "accepted";
}
