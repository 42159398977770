import { Props } from "@/types";
import { BlockKycProps } from "./BlockKycInstance";
import { blockYotiPropsForParent } from "../../utility/yoti/props";
import { identificationFlow } from "@claimsgate/core";

const blockKycProps: Record<keyof BlockKycProps, any> = {
  ...blockYotiPropsForParent,
  //! Make this a computed or data point that is calculated based on new props
  identificationFlow: {
    type: String,
    description: "Determine what methods will be used to identify the user",
    default: identificationFlow.electoralRoll,
    valid: [
      identificationFlow.none,
      identificationFlow.electoralRoll,
      identificationFlow.electoralRollWithYotiFallback,
      identificationFlow.electoralRollWithYotiIdAndAddressFallback,
      identificationFlow.electoralRollWithYotiIdAndAmlFallback,
      identificationFlow.yotiAddress,
      identificationFlow.yotiIdAndAddress,
      identificationFlow.yotiId,
      identificationFlow.yotiIdAndAml,
    ] as Array<identificationFlow>,
  } as Props.Str,
  faceMatch: {
    type: Boolean,
    default: true,
    description: "Check that the user is a match for the provided ID using facial recognition",
    required: true,
  } as Props.Bool,
  padding: {
    type: String,
    required: false,
    default: "10",
    description: "The padding above the block",
  } as Props.CGPropExtras<string>,
  allowOtherTitle: {
    type: Boolean,
    default: true,
    description: "Allow user to enter a custom title",
  } as Props.Bool,
  title: {
    type: Boolean,
    required: false,
    default: true,
    description: "If true, the block allows the user to enter their title",
  },
  middleName: {
    type: Boolean,
    required: false,
    default: true,
    description: "If true, the block allows the user to enter a middle name",
  } as Props.Bool,
  collectDateOfBirth: {
    type: Boolean,
    required: false,
    default: true,
    description: "If true, the block allows the user to enter a date of birth",
  } as Props.Bool,
  isBuilder: {
    type: Boolean,
    default: false,
    description: `Lets the block know if it is in builder, so it doesn't launch a session`,
  } as Props.Bool,
  minAge: {
    type: String,
    description: "If the requireAgeCheck is true, this is the minimum age required of the user",
    default: "18",
  } as Props.CGPropExtras<string>,
  maxAge: {
    type: String,
    description: "If the requireAgeCheck is true, this is the maximum age required of the user",
    default: "100",
  } as Props.CGPropExtras<string>,
  requireAgeCheck: {
    type: Boolean,
    description: "If true, the user must be between the min and max age",
    default: true,
  } as Props.CGPropExtras<boolean>,
  allowInternationalClients: {
    type: Boolean,
    description: "If true, the user can be from any country",
    default: false,
  } as Props.CGPropExtras<boolean>,
  allowManualUploadFallback: {
    type: Boolean,
    description: "If true, the user can manually upload a file",
    default: false,
  } as Props.CGPropExtras<boolean>,
};

export function getProps() {
  return {
    ...blockKycProps,
  };
}
