import { YotiClientError, YotiVerificationSession } from "@claimsgate/core-types";
import { BlockYotiInstance } from "../BlockYotiInstance";

/**
 * Adds the given error to the current `yotiVerificationSession` and saves it to the user
 */
export async function saveSessionError(state: BlockYotiInstance, clientError: YotiClientError): Promise<void> {
  let { yotiVerificationSessions } = state.userData;
  const { currentYotiSessionId } = state.userData;

  if (!currentYotiSessionId) {
    console.error("Unable to save yoti session error", { user: state.userData, claimId: state.claimId });
    return;
  }

  if (!yotiVerificationSessions) {
    yotiVerificationSessions = [];
  }

  let thisSessionsTrailIndex = 0;
  let thisSessionsTrail = yotiVerificationSessions.find((session, index) => {
    if (session.sessionId === currentYotiSessionId) {
      thisSessionsTrailIndex = index;
      return true;
    }
    return false;
  });

  //It should be deinfed by now, but just in case it doesn't exist
  if (!thisSessionsTrail) {
    thisSessionsTrail = {
      sessionId: currentYotiSessionId,
      clientErrors: [],
      sessionStartDate: new Date(),
      supplementaryDocCheck: {},
    };
  }

  thisSessionsTrail.clientErrors.push(clientError);

  // Replace with the updated session trail
  yotiVerificationSessions.splice(thisSessionsTrailIndex, 1, thisSessionsTrail);

  // Save the updated session trail on the db
  state.userDataService.setManyArtefacts({ yotiVerificationSessions });

  state.userDataService.update();
}
