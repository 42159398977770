import { getFirebaseBackend } from "@/authUtils";
import { Variable } from "@claimsgate/core-types";
import * as Sentry from "@sentry/vue";
import { captureMessage } from "@sentry/vue";
import { FormState } from "./BlockFormInstance";

/** Fires a proactive Sentry log including the VRM called 'MotorSpecsApiError' fingerprinted by our release number */
export async function fireMotorSpecsNotQualifiedEvent(state: FormState) {
  // Fetch the VRM from the claim data service
  const { data: funnelVariables } = await state.funnelsService.getFunnelVariables(state.funnelId);

  const vehicleRegistrationVariable = funnelVariables.find((variable: Variable) => {
    return variable.field === "vehicleRegistration";
  });

  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("funnelId", state.funnelId);

    if (vehicleRegistrationVariable) {
      const vehicleRegistration = state.claimDataService.getArtefact(vehicleRegistrationVariable.id);
      scope.setTag("vrm", vehicleRegistration);
    }

    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("MotorSpecsNotQualified");
  });
}

export function fireMotorSpecsQualifiedEvent(state: FormState) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("funnelId", state.funnelId);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("MotorSpecsQualified");
  });
}

/** Fires a proactive Sentry log including the VRM called 'CarwebNotQualfiied' fingerprinted by our release number */
export async function fireCarwebNotQualifiedEvent(state: FormState) {
  // Fetch the VRM from the claim data service
  const { data: funnelVariables } = await state.funnelsService.getFunnelVariables(state.funnelId);

  const vehicleRegistrationVariable = funnelVariables.find((variable: Variable) => {
    return variable.field === "vehicleRegistration";
  });

  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("funnelId", state.funnelId);

    if (vehicleRegistrationVariable) {
      const vehicleRegistration = state.claimDataService.getArtefact(vehicleRegistrationVariable.id);
      scope.setTag("vrm", vehicleRegistration);
    }

    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("CarwebNotQualified");
  });
}

export function fireCarwebQualifiedEvent(state: FormState) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("funnelId", state.funnelId);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("CarwebQualified");
  });
}
