// import { form } from "./helpers/form";

import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import { Variable } from "@claimsgate/core-types";
import { PropOptions } from "vue";
const props: {
  padding: PropOptions<string>;
  // invalidFeedback: PropOptions<string>;
  // state: PropOptions<boolean>;
  label: PropOptions<string>;
  state: PropOptions<boolean>;
} = inputProps({
  excludeDefaults: true,
  answerType: "array",
  extraProps: ["padding", "label", "state"],
});

export function getProps() {
  return {
    answer: {
      type: [Array, String],
      required: true,
    } as PropOptions<Array<any> | String>,
    entityName: {
      type: String,
      required: true,
    },
    storeAs: {
      type: [Object, String],
      required: true,
    } as PropOptions<Variable>,
    ...props,
    // form: {
    //   type: [Array, String],
    //   required: false,
    //   default: () => [],
    // } as PropOptions<typeof form>,
  };
}
