import { getFirebaseBackend } from "@/authUtils";
import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
import { CarwebBlockProps, blocks } from "./blocks";
import { FunnelsService, Milberg } from "@claimsgate/core";
import { CarwebPartialVehicleStore } from "./types";

export function getData(state: Vue) {
  return {
    vrm: "",
    ownershipDate: "",
    vrmInputState: null as boolean | null,
    Milberg,
    userId: null as string,
    claimId: state.$route.params.claimId,
    pageId: state.$route.params.pageId,
    funnelId: state.$route.params.funnelId,
    funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
    variablesService: new VariableService(getFirebaseBackend().firestore()),
    claimDataService: null as ClaimDataService | null,
    userService: getUserHelper(),
    uiToggles: {
      process: {
        isAutoSearchingWithoutOwnershipDate: false,
      },
      isVehicleSearchVisible: true,
      isKeeperSelectVisible: false,
      isVehicleHistorySearchVisible: false,
      isSearchingByQueryParamaters: false,
      isLoading: false,
    },
    vehicleHistoryMap: [] as Array<{ text: string; vin: string }>,

    BlockInputs: {
      ...UserVariableBlocksService.getButtons(blocks.buttons as any),
      ...blocks.inputs,
      ...blocks.texts,
    } as CarwebBlockProps,
    vehicle: null as CarwebPartialVehicleStore,
    vrmInputInvalidFeedback: "",
    uiMessages: {
      vehicleDoesNotQualify: "Unfortunately, your vehicle does not qualify to to make a claim.",
      milbergVehicleNotOwnedIn2017: "To register, you must have owned the vehicle between 2017 and 2019.",
      enterReg: "Please enter a valid registration number",
      noVehicleFound: "Unfortunately, we could not find your vehicle. Please try entering the VRM again.",
      doubleCheckOwnershipDate: "Please double check you owned the vehicle during this date",

      errorFindingVehicle:
        "There was an error finding the data for your vehicle, please try again later or use a different registration number.",
      noVehicleSelected: "Please select the vehicle you owned",
      noKeeperSelected: "Please select the date you acquired the vehicle",
      noOwnershipDateSelected: "Please select a date in which you owned the vehicle",
      ownershipDateInFuture: "Please select a date which has already taken place",
      noVehicleConfirmationSelected: "Please confirm whether the above vehicle is your vehicle.",
    },
  };
}
