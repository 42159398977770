/** The props accepted by the Carweb block */
export interface CarwebProps {
  recallData: boolean;
  padding: string;
  api: "CarWeb";
}

/** The props accepted by the Motorspecs block */
export interface MotorspecsProps {
  returnRecallData: boolean;
  requireConfirmation: false;
  padding: string;
}

/** Each of the BlockTextProps used in this component */
export interface BlockVehicleProps {
  carweb: Partial<CarwebProps>;
  motorspecs: Partial<MotorspecsProps>;
}

const vehicles: BlockVehicleProps = {
  motorspecs: { requireConfirmation: false },
  carweb: { api: "CarWeb" },
};

/** Each of the blocks used on the BlockMotorSpecs component */
export const blocks = { vehicles };
