import { BlockContactDetailsInstance } from "./blockContactDetails";

export async function validations(state: BlockContactDetailsInstance) {
  try {
    state.isProcessing = true;

    const validationResults = await Promise.all([
      state.$refs.BlockEmail.validations(),
      state.$refs.BlockPhone.validations(),
      state.collectName ? state.$refs.BlockSimpleName.validations() : true,
    ]);

    // If BlockConsent.computes does not exist or is not a function
    // then we assume that the consent is not required.
    if (state.$refs.BlockConsent && typeof state.$refs.BlockConsent.validations === "function") {
      validationResults.push(await state.$refs.BlockConsent.validations());
    } else {
      console.error("BlockConsents was undefined or not a function?", state.$refs);
    }

    if (validationResults.some((result) => result === false)) {
      state.isProcessing = false;
      return false;
    }

    return true;
  } catch (exception) {
    state.isProcessing = false;
    throw exception;
  }
}
