import { Awaited } from "@/types/utils";

import { isClaimsGateFunctionReturnedError, reject } from "@/helpers/vue";
import { ClaimsGateErrors, ClaimsGateFunctionReturnedError, Milberg, Quantum } from "@claimsgate/core";
import { AutoGuruGetVehicleHistoryVrmResponse, CarwebGetVehicleResponse } from "@claimsgate/core-types";
import isNil from "lodash.isnil";
import omitBy from "lodash.omitby";
import { DateTime } from "luxon";

import { BlockCarwebInstance } from "./BlockCarweb";
import { fireCarwebApiErrorEvent } from "./sentryV2";
import { CarwebPartialVehicleStore } from "./types";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
export const methods = {
  searchVehicle,
  submitVehicleHistorySelect,
  setValidationInvalidFeedback,
  submitKeeperSelect,
  notMyVehicle,
  displayHelpModal,
};

export function displayHelpModal(state: BlockCarwebInstance) {
  state.$bvModal.show("helpModal");
}

/** Handles the logic when the user selects 'notMyVehicle' */
export function notMyVehicle(state: BlockCarwebInstance) {
  resetToSearchVehicleView(state);
}
/** Handles the logic for submitting the Keeper Select view */
async function submitKeeperSelect(state: BlockCarwebInstance) {
  state.BlockInputs.submitKeeperSelectButton.isProcessing = true;
  state.BlockInputs.keeperSelectSingleSelect.state = null;

  // Validate the answer selected
  const answer = state.BlockInputs.keeperSelectSingleSelect.answer;
  if (!answer || answer.length === 0) {
    state.BlockInputs.keeperSelectSingleSelect.state = false;
    state.BlockInputs.keeperSelectSingleSelect.invalidFeedback = state.uiMessages.noKeeperSelected;
    reject(state, state.uiMessages.noKeeperSelected);
    state.BlockInputs.submitKeeperSelectButton.isProcessing = false;
    return;
  }

  // Find the matching keeper change element which has a matching start date
  const selectedKeeperIndex = state.vehicle.keeperChangeHistory.findIndex((keeperChange) => {
    return DateTime.fromJSDate(new Date(keeperChange.StartDateOfKeeper)).toISO() === answer;
  });

  const nextKeeperIndex = selectedKeeperIndex - 1; // ! Yes this is correct (minus 1)

  state.vehicle.keeperStartDate = answer;

  // If there was a keeper after the selected keeper then fetch the start date to set as the endKeeperDate
  if (state.vehicle.keeperChangeHistory[nextKeeperIndex]) {
    state.vehicle.keeperEndDate = DateTime.fromJSDate(
      new Date(state.vehicle.keeperChangeHistory[nextKeeperIndex].StartDateOfKeeper)
    ).toISO();
  } else {
    // If there was no keeper after the selected then the selected must be the current
    state.vehicle.isCurrentKeeper = true;
  }

  await saveVehicle(state);
}

/** Sets invalid feedback which has been fed from a page level validation onto the block */
export function setValidationInvalidFeedback(state: BlockCarwebInstance, invalidFeedback: string) {
  resetToSearchVehicleView(state);
  state.vrmInputState = false;
  state.vrmInputInvalidFeedback = invalidFeedback;
  reject(state, invalidFeedback);
}

/** Resets all of the known states to their defaults in preparation for displaying the search vehicle view */
export function resetToSearchVehicleView(state: BlockCarwebInstance) {
  // Clear fetched vehicle
  state.vehicle = null;

  // Clear previous answers
  state.vrm = "";

  state.BlockInputs.keeperSelectSingleSelect.answer = "";
  state.BlockInputs.vehicleHistorySingleSelect.answer = "";
  // Clear previous block states
  state.vrmInputState = null;

  state.BlockInputs.vehicleHistorySingleSelect.state = null;
  state.BlockInputs.keeperSelectSingleSelect.state = null;

  // Clear previous block invalid feedbacks
  state.vrmInputInvalidFeedback = "";
  state.BlockInputs.keeperSelectSingleSelect.invalidFeedback = "";
  state.BlockInputs.vehicleHistorySingleSelect.invalidFeedback = "";
  state.BlockInputs.keeperSelectSingleSelect.options = [];
  state.BlockInputs.vehicleHistorySingleSelect.options = [];

  // Reset vehicle history map
  state.vehicleHistoryMap = [];

  // Display the vehicle select
  state.uiToggles.isKeeperSelectVisible = false;
  state.uiToggles.isVehicleHistorySearchVisible = false;

  state.$nextTick(() => {
    state.uiToggles.isVehicleSearchVisible = true;
  });

  // Reset the HTML text
  state.BlockInputs.confirmVehicleText.html = "";

  // Reset button loaders
  state.BlockInputs.submitVehicleConfirmationButton.isProcessing = false;
  state.BlockInputs.searchRegButton.isProcessing = false;
  state.BlockInputs.submitVehicleHistorySelectButton.isProcessing = false;
  state.BlockInputs.submitKeeperSelectButton.isProcessing = false;
}

async function submitVehicleHistorySelect(state: BlockCarwebInstance) {
  try {
    state.BlockInputs.submitVehicleHistorySelectButton.isProcessing = true;
    state.BlockInputs.vehicleHistorySingleSelect.state = null;

    // Validate the answer selected
    const answer = state.BlockInputs.vehicleHistorySingleSelect.answer;
    if (!answer || answer.length === 0) {
      state.BlockInputs.vehicleHistorySingleSelect.state = false;
      state.BlockInputs.vehicleHistorySingleSelect.invalidFeedback = "Please select an option";
      reject(state, "Please select an option");
      state.BlockInputs.submitVehicleHistorySelectButton.isProcessing = false;
      return;
    }

    // Find the matching VIN from the Vehicle History Map
    const vin = state.vehicleHistoryMap.find((vhm) => vhm.text === answer)?.vin;

    // Search for the vehicle using the provided VIN
    const carwebResponse = await getVehicleFromCarweb(state, vin);
    if (!(await parseCarwebResponse(carwebResponse, state))) return;

    // Display the keeper select question
    state.uiToggles.isVehicleSearchVisible = false;
    state.uiToggles.isVehicleHistorySearchVisible = false;
    state.uiToggles.isKeeperSelectVisible = true;

    state.BlockInputs.submitKeeperSelectButton.isProcessing = false;
    state.BlockInputs.keeperSelectSingleSelect.state = null;
  } catch (exception) {
    tryCatchHandler(state, exception);
  }
}
/** Runs the logic to search for a vehicle which is entered into the VRM input box */
export async function searchVehicle(state: BlockCarwebInstance) {
  try {
    resetSearchVehicleUiToggles(state);

    // Breaks if we were unable to validate the entered VRM
    if (!validateVrm(state)) return;

    // Remove any spaces from the VRM
    const vrm = state.vrm.replace(/\s/g, "");

    const carwebResponse = await getVehicleFromCarweb(state, vrm);
    if (!(await parseCarwebResponse(carwebResponse, state))) return;

    state.BlockInputs.searchRegButton.isProcessing = false;
    state.vrmInputState = null;
  } catch (exception) {
    tryCatchHandler(state, exception);

    state.uiToggles.isSearchingByQueryParamaters = false;
  }
}

/** Resets the states of inputs and processing state when on the search vehicle view */
function resetSearchVehicleUiToggles(state: BlockCarwebInstance) {
  state.BlockInputs.searchRegButton.isProcessing = true;
  state.vrmInputState = null;
}

/** Returns a boolean indcating if the entered vrm is valid */
function validateVrm(state: BlockCarwebInstance): boolean {
  if (state.vrm.length === 0) {
    state.vrmInputState = false;
    state.vrmInputInvalidFeedback = state.uiMessages.enterReg;
    reject(state, state.uiMessages.enterReg);
    state.BlockInputs.searchRegButton.isProcessing = false;
    return false;
  }

  return true;
}

/** Fetches a given vehicle by vid from the Carweb backend */
async function getVehicleFromCarweb(state: BlockCarwebInstance, vid: string) {
  return onCallGateway<"getVehicleFromCarwebV2">({
    functionName: "getVehicleFromCarwebV2",
    data: {
      vid,
      options: {
        returnMercedesRecallData: state.returnMercedesRecallData,
        returnEmissionsAnalyticsData: state.returnEmissionsAnalyticsData,
      },
      claimId: state.claimId,
      funnelId: state.funnelId,
      pageId: state.pageId,
    },
  });
}
async function parseCarwebResponse(
  carwebResponse: Awaited<ReturnType<typeof getVehicleFromCarweb>>,
  state: BlockCarwebInstance
) {
  if (isClaimsGateFunctionReturnedError(carwebResponse)) {
    const isCarwebVehicleDoesNotExistError =
      carwebResponse.data.error.id === new ClaimsGateErrors.CarwebVehicleDoesNotExist().id ||
      carwebResponse.data.error.id === "CarwebVehicleDoesNotExist";

    if (isCarwebVehicleDoesNotExistError) {
      state.vrmInputState = false;
      state.vrmInputInvalidFeedback = state.uiMessages.noVehicleFound;
      reject(state, state.uiMessages.noVehicleFound);
      state.BlockInputs.searchRegButton.isProcessing = false;
    } else {
      state.vrmInputState = false;
      state.vrmInputInvalidFeedback = state.uiMessages.errorFindingVehicle;
      state.BlockInputs.searchRegButton.isProcessing = false;

      reject(state, state.uiMessages.errorFindingVehicle);
      // FIRE API ERROR
      fireCarwebApiErrorEvent(state);
    }

    return false;
  } else if (isCarwebVehicleResponse(carwebResponse)) {
    const vehicle: CarwebPartialVehicleStore = {
      emCode: carwebResponse.data.emCode,
      make: carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.DVLA_Make,
      model:
        typeof carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.DVLA_Model === "string"
          ? carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.DVLA_Model
          : carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.Combined_Model,
      version: carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.Combined_Model,
      colour: carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.ColourCurrent,
      vin: carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.Combined_VIN,
      vehicleRegistration: carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.VRM_Curr,
      fuelType: carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.Combined_FuelType,
      numberOfCylinders: parseFloat(carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.NumberOfCylinders),
      engineCapacity: parseFloat(carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.EngineCapacity),
      engineSize: parseFloat(carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.Nom_CC),
      dateFirstRegistered: carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.DateFirstRegistered,
      bhp: parseFloat(carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.MaximumPowerBHP),
      torqueNm: parseFloat(carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.MaximumTorqueNM),
      engineNumber: carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.EngineNumber,
      engineModelCode: carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.EngineModelCode,
      engineLocation: carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.EngineLocation,
      euroStatus: carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.EuroStatus,
      numberOfDoors: parseFloat(carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.NumberOfDoors),
      series: carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.ModelSeries,
      maximumPowerInKw: parseFloat(carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.MaximumPowerInKW),
      vinLast5: carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.Combined_VIN.substring(
        carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.Combined_VIN.length - 5
      ),
      yearOfManufacture: carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.DVLAYearOfManufacture,
      keeperChangeHistory: carwebResponse.data.GetVehicles.DataArea.Vehicles.Vehicle.KeeperChangeHistory.KeeperChange,
      mercedesEmissionsRecallMandatoryStatus:
        carwebResponse.data.mercedesRecallComputedFields?.mercedesEmissionsRecallMandatoryStatus,
      mercedesEmissionsRecallEligibleForExclusion:
        carwebResponse.data.mercedesRecallComputedFields?.mercedesEmissionsRecallEligibleForExclusion,
      mercedesEmissionsRecallAccomplished:
        carwebResponse.data.mercedesRecallComputedFields?.mercedesEmissionsRecallAccomplished,
      recalls: carwebResponse.data.recalls,
      vehicleDataSource: "Carweb",
    };

    const compactedVehicle: CarwebPartialVehicleStore = omitBy(vehicle, isNil);

    // Using a vue setter to prevenet reactivity issues
    state.$set(state, "vehicle", compactedVehicle);

    state.BlockInputs.confirmVehicleText.html = `<div><div><h2>Vehicle Found</h2><h4>We found your vehicle, <b>${state.vehicle.make?.toUpperCase()}</b>, <b>${state.vehicle.model?.toUpperCase()}</b>, <b>${
      state.vehicle.yearOfManufacture
    }</b>, <b>${state.vehicle.fuelType}</b>.</h4></div></div>`;

    if (!(await setupKeeperSelectQuestion(state))) {
      return false;
    }

    state.uiToggles.isVehicleSearchVisible = false;
    await state.$nextTick();
    state.uiToggles.isVehicleHistorySearchVisible = false;
    await state.$nextTick();
    state.uiToggles.isKeeperSelectVisible = true;

    return true;
  } else if (isAutoGuruVehicleHistoryResponse(carwebResponse)) {
    // Populate the single select for the Vehicle History question

    const vehicleHistoryMap = carwebResponse.data.vehicles.map((vehicle) => {
      return {
        text: `${vehicle.dvlavehicle.manufacturer} ${vehicle.dvlavehicle.model}`,
        vin: vehicle.dvlavehicle.vin,
      };
    });

    state.vehicleHistoryMap = vehicleHistoryMap;
    state.BlockInputs.vehicleHistorySingleSelect.options = vehicleHistoryMap.map((vehicle) => vehicle.text);

    // Display the Vehicle History question
    state.uiToggles.isVehicleHistorySearchVisible = true;
    state.uiToggles.isVehicleSearchVisible = false;

    return true;
  }

  return false;
}

/** Type guard to check if a returned function response is a Claims Gate function returned error */
export function isAutoGuruVehicleHistoryResponse<T>(response: {
  data?: ClaimsGateFunctionReturnedError | CarwebGetVehicleResponse | AutoGuruGetVehicleHistoryVrmResponse;
}): response is { data: AutoGuruGetVehicleHistoryVrmResponse } {
  return (response.data as AutoGuruGetVehicleHistoryVrmResponse).vehicles !== undefined;
}

/** Type guard to check if a returned function response is a Claims Gate function returned error */
export function isCarwebVehicleResponse<T>(response: {
  data?: ClaimsGateFunctionReturnedError | CarwebGetVehicleResponse | AutoGuruGetVehicleHistoryVrmResponse;
}): response is { data: CarwebGetVehicleResponse } {
  return (response.data as CarwebGetVehicleResponse).GetVehicles !== undefined;
}

/** Saves the vehicle stored in the state to the claim data service */
export async function saveVehicle(state: BlockCarwebInstance): Promise<boolean> {
  const { data: funnelVariables } = await state.funnelsService.getFunnelVariables(state.funnelId);
  const hashedVehicle = await state.variablesService.hashData(state.vehicle, funnelVariables);

  for (const [key, value] of Object.entries(hashedVehicle)) {
    state.claimDataService.setArtefact(key, value);
  }

  const quantumFunnels = [
    Quantum.Funnels.DRUMMOND_MILLER_DIESEL_EMISSIONS_ID,
    Quantum.Funnels.LEFEVRES_DIESEL_EMISSIONS_ID,
    Quantum.Funnels.SLATER_GORDON_DIESEL_EMISSIONS_ID,
    Quantum.Funnels.THOMPSONS_SOLICITORS_DIESEL_EMISSIONS_ID,
    Quantum.Funnels.JONES_WHYTE_DIESEL_EMISSIONS_ID,
    "wPaZGhYhezQcEvrTdyEB",
  ];
  const slaterGordonFunnelId = Quantum.Funnels.SLATER_GORDON_DIESEL_EMISSIONS_ID;

  if (quantumFunnels.includes(state.funnelId)) {
    if (state.funnelId === slaterGordonFunnelId || state.funnelId === "wPaZGhYhezQcEvrTdyEB") {
      setupDefenderAndContactEmail(state, true);
    } else {
      setupDefenderAndContactEmail(state, false);
    }
  }

  if (state.funnelId === Milberg.Funnels.VAUXHALL_DIESEL_EMISSIONS_FUNNEL_ID) {
    // If keeper end date is defined and keeper end date is greater than the 31st of Decemeber 2016
    if (
      state.vehicle.keeperEndDate &&
      DateTime.fromJSDate(new Date(state.vehicle.keeperEndDate)) <= DateTime.fromJSDate(new Date("2017-01-01"))
    ) {
      setValidationInvalidFeedback(state, state.uiMessages.milbergVehicleNotOwnedIn2017);

      return false;
    }

    // If keeper end date is defined and keeper end date is greater than 31st December 2019
    // and the keeper start date is less than 1st January 2017
    if (DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)) >= DateTime.fromJSDate(new Date("2019-12-31"))) {
      setValidationInvalidFeedback(state, state.uiMessages.milbergVehicleNotOwnedIn2017);

      return false;
    }
  }

  await state.$store.dispatch("events/fire", { name: state.eventValues.next });

  return true;
}

/** Runs logic to set up the keeper select question */
async function setupKeeperSelectQuestion(state: BlockCarwebInstance): Promise<boolean> {
  // Populate the single select for the keeper select question

  if (state.vehicle.keeperChangeHistory && Array.isArray(state.vehicle.keeperChangeHistory)) {
    state.BlockInputs.keeperSelectSingleSelect.options = state.vehicle.keeperChangeHistory.map((keeperChange) => {
      return DateTime.fromJSDate(new Date(keeperChange.StartDateOfKeeper)).toISO();
    });
    return true;
  } else {
    // If there is no keeper change history then proceed with saving the vehicle?
    await saveVehicle(state);
    return false;
  }
}
function tryCatchHandler(state: BlockCarwebInstance, exception: any) {
  console.error(exception);

  resetToSearchVehicleView(state);

  state.$nextTick(() => {
    state.vrmInputState = false;
    state.vrmInputInvalidFeedback = state.uiMessages.errorFindingVehicle;
    reject(state, state.uiMessages.errorFindingVehicle);
  });

  // ! FIRE CARWEB INVALID EVENT
  fireCarwebApiErrorEvent(state);
}

/** Sets up the defender and contact email for Quantum funnels */
function setupDefenderAndContactEmail(state: BlockCarwebInstance, setContactEmail: boolean) {
  // If make contains Citroen
  if (state.vehicle.make.toLowerCase().includes("citroen")) {
    state.claimDataService.setArtefact("defender", "Citroen");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "citroenscotlandclaims@slatergordon.co.uk");
    }
  }

  // If make contains Fiat
  if (state.vehicle.make.toLowerCase().includes("fiat")) {
    state.claimDataService.setArtefact("defender", "Fiat");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "fiatscotlandclaims@slatergordon.co.uk");
    }
  }

  // If make contains Jaguar Land Rover
  if (
    state.vehicle.make.toLowerCase().includes("jaguar") ||
    state.vehicle.make.toLowerCase().includes("land rover") ||
    state.vehicle.make.toLowerCase().includes("landrover") ||
    state.vehicle.make.toLowerCase().includes("rover")
  ) {
    state.claimDataService.setArtefact("defender", "Jaguar Land Rover");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "jaguarscotlandclaims@slatergordon.co.uk");
    }
  }

  // If make contains Nissan
  if (state.vehicle.make.toLowerCase().includes("nissan")) {
    state.claimDataService.setArtefact("defender", "Nissan");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "nissanscotlandclaims@slatergordon.co.uk");
    }
  }

  // If make contains Peugot
  if (state.vehicle.make.toLowerCase().includes("peugeot")) {
    state.claimDataService.setArtefact("defender", "Peugeot");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "peugeotscotlandclaims@slatergordon.co.uk");
    }
  }

  // If make contains Renault
  if (state.vehicle.make.toLowerCase().includes("renault")) {
    state.claimDataService.setArtefact("defender", "Renault");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "renaultscotlandclaims@slatergordon.co.uk");
    }
  }

  // If make contains Vauxhall
  if (state.vehicle.make.toLowerCase().includes("vauxhall")) {
    state.claimDataService.setArtefact("defender", "Vauxhall");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "vauxhallscotlandclaims@slatergordon.co.uk");
    }
  }

  // If make contains Volvo
  if (state.vehicle.make.toLowerCase().includes("volvo")) {
    state.claimDataService.setArtefact("defender", "Volvo");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "volvoscotlandclaims@slatergordon.co.uk");
    }
  }
}
