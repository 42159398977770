<script lang="ts">
import Vue from "vue";
import { getData } from "./data";
import { getProps } from "./props";
import { computed } from "./computed";
import * as methods from "./methods";
import { Fragment } from "vue-frag";
import BlockDatePicker from "@/components/shared/blocks/datepicker.vue";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import BlockTextArea from "@/components/shared/blocks/text/textarea.vue";
//import BlockPadding from "@/components/shared/blocks/padding.vue";
import { themesComputed } from "@/state/helpers";

export default Vue.extend({
  name: "BlockNestedMultiSelect",
  components: {
    Fragment,
    BlockDatePicker,
    BlockInputWrapper,
    BlockTextArea,
    //BlockPadding,
  },
  props: {
    ...getProps(),
  },
  data() {
    return {
      ...getData(this),
    };
  },
  computed: {
    ...themesComputed,
    ...computed, // ! we can't unpack computed, they have to be defined the same way as methods
  },
  methods: {
    async validations(): Promise<boolean> {
      return methods.validations(this);
    },
    async computes(): Promise<any> {
      console.log("nested computes");
      return this.answers;
    },
    isSelected(option: string): boolean {
      return methods.isSelected(this, option);
    },
    getIndex(option: string): number {
      return methods.getIndex(this, option);
    },
    organiseSelected(): string[] {
      return methods.organiseSelected(this);
    },
    setUpData(): void {
      methods.setUpData(this);
    },
    toggleSelected(option: string): void {
      methods.toggleSelected(this, option);
    },
    parseExistingAnswer(): void {
      methods.parseExistingAnswer(this);
    },
  },
  mounted() {
    this.setUpData();
    this.parseExistingAnswer();
  },
  watch: {
    selected: function () {
      this.selectedOrganised = this.organiseSelected();
    },
  },
});
</script>
<style scoped>
.selected {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: var(--primary) !important;
  transition: 0.25s;
}

.deselected {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: #ced4da !important;
  transition: 0.25s;
}

.invalid-border {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: #ff0404 !important;
  transition: 0.25s;
}

.valid-border {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: #129e0a !important;
  transition: 0.25s;
}
</style>
<template>
  <Fragment>
    <BlockInputWrapper v-bind="{ info, padding, label, state, invalidFeedback }">
      <b-form-group class="mb-0">
        <b-form-checkbox-group v-model="selected" id="checkbox-group-0" name="checkbox-group-0" :ref="'b-multiselect'">
          <b-row class="justify-content-center no-gutters">
            <template>
              <template v-for="option in options">
                <b-col cols="12" class="mb-0" :key="option">
                  <b-card
                    no-body
                    class="border mb-0 p-1"
                    :class="{
                      selected: isSelected(option),
                      deselected: !isSelected(option),
                      'invalid-border': state === false,
                      'valid-border': state === true,
                    }"
                    @click.prevent="toggleSelected(option)"
                  >
                    <div
                      :class="`custom-checkbox b-custom-control-${theme.size} ${
                        theme.size === 'lg' ? 'p-3 pl-4' : theme.size === 'md' ? 'p-2 pl-3' : 'px-1'
                      }`"
                    >
                      <b-form-checkbox class="mr-2 d-inline-block" :value="option"></b-form-checkbox>
                      <span class="font-weight-normal m-0 d-inline-block">
                        {{ option }}
                      </span>
                    </div>
                  </b-card>
                </b-col>
              </template>
            </template>
          </b-row>
        </b-form-checkbox-group>
      </b-form-group>
    </BlockInputWrapper>
    <!--<BlockPadding :padding="padding" />-->
    <template v-for="(option, index) in selectedOrganised">
      <b-col cols="12" class="px-0" :key="`${option}-${index}`">
        <template v-if="isSelected(option) && option === 'Other'">
          <BlockTextArea
            v-bind="{
              info: '',
              padding: padding,
              label: `Please provide as much detail as you can about other event(s) you watched or listened to (eg the type of event, the approximate date it was held and which OneCoin representatives attended.`,
              invalidFeedback: 'Cannot be empty',
              required: true,
              textRows: `5`,
              state: otherState,
              answer: otherText,
              disabled: false,
              placeholder: `Please add detail here.`,
            }"
          >
          </BlockTextArea>
        </template>

        <BlockDatePicker
          v-if="isSelected(option) && option !== 'None' && option !== 'Other'"
          :answer.sync="dates[getIndex(option)]"
          v-bind="{
            state: datePickerStates[getIndex(option)],
            label: `Please select an approximate date for when you watched or listened to the ${option} event`,
            info: '',
            invalidFeedback: 'Please select a date',
            displayDays: true,
            yearsStart: 2014,
            yearsEnd: 2024,
            disabled: false,
            required: true,
            padding: padding,
          }"
        >
        </BlockDatePicker>
      </b-col>
    </template>
  </Fragment>
</template>
