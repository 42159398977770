export default {
  email: {
    label: "عنوان البريد الإلكتروني",
    invalidFeedback: "هذا الحقل مطلوب",
  },

  phone: {
    label: "رقم الهاتف",
  },

  contactDetails: {
    mdrPrivacyPolicyText:
      'بالضغط على "متابعة" يعني أنني سعيد بالتواصل مع Mishcon de Reya LLP في ما يتعلق بالمطالبة الخاصة بك.',

    "Do you agree to the": "هل توافق على",
    "Privacy Policy": "سياسة الخصوصية",
  },

  invalidFeedback: {
    "Please enter a valid email address": "الرجاء إدخال عنوان بريد إلكتروني صالح",
    "Please enter a valid phone number": "الرجاء إدخال رقم هاتف صالح",
    "First name must be at least 2 characters": "يجب أن يكون الاسم الأول على الأقل 2 أحرف",
    "Last name must be at least 2 characters": "يجب أن يكون الاسم الأخير على الأقل 2 أحرف",
    "Please select a title": "الرجاء اختيار عنوان",
    "You must be at least 18 years old to use continue": "يجب أن يكون عمرك 18 عامًا على الأقل للاستمرار",
  },

  button: {
    continue: "متابعة",
    previous: "السابق",
  },

  address: {
    title: "اللقب",
    firstName: "الاسم الأول",
    middleName: "الاسم الأوسط",
    lastName: "الاسم الأخير",
    dateOfBirth: "تاريخ الميلاد",
    postcode: "الرمز البريدي",
    livesInUk: "Do you live in the UK?",
    enterAddress: "Enter Your Address",
  },

  singleSelect: {
    Yes: "نعم",
    No: "لا",
    "Exact figure": "رقم دقيق",
    Approximation: "رقم تقريبي",
  },

  placeholder: {
    "Select an item": "حدد عنصرًا",
  },

  dropdown: {
    "No, I am not aware that Mishcon de Reya LLP has acted or is currently acting for or against me in a legal matter":
      "لا، أنا لست على علم بأن Mishcon de Reya LLP قد تصرفت أو تتصرف حالياً لصالحي أو ضدي في قضية قانونية.",

    "Yes, Mishcon de Reya LLP has acted and/or is currently acting against or for me in legal matter(s)":
      "نعم، Mishcon de Reya LLP قد تصرفت و/أو تتصرف حالياً ضدي أو لصالحي في قضايا قانونية.",
    "I am not sure": "أنا غير متأكد.",
    Title: "اللقب",
    Mr: "السيد",
    Mrs: "السيدة",
    Ms: "الأستاذة",
    Miss: "الآنسة",
    Mx: "السيد/السيدة",
    Dr: "طبيب",
    Professor: "أستاذ جامعي",
    "Prefer not to say": "فضل ألا أقول",
    Other: "غير ذلك",
    Shareholder: "مساهم",
    Director: "مدير",
    Employee: "موظف",
  },

  currency: {
    enterValue: "الرجاء إدخال قيمة.",
  },

  yotiManualIdUpload: {
    selectDocumentType: "يرجى تحديد نوع المستند الذي ترغب في رفعه",
    thisFieldIsRequired: "هذا الحقل مطلوب",
    uploadIdentityDocument: "يرجى رفع نسخة من وثيقة الهوية الخاصة بك",
    uploadMustBeClear: "يجب عليك التأكد من أن الوثيقة واضحة ومقروءة في الملف المرفوع",
    errorUploading: "حدث خطأ أثناء رفع ملفاتك، يرجى المحاولة مرة أخرى.",
  },

  yoti: {
    clickManualUpload: "انقر هنا لرفع وثائق الهوية يدويًا إذا كنت تواجه مشاكل في استخدام خدمة الهوية Yoti",
    backToYoti: "العودة للتحقق بواسطة Yoti",
    verificationInProgress: "جاري التحقق من الهوية",
    inProgressInfo:
      "جاري التحقق من هويتك حاليًا. يمكن أن يستغرق هذا العملية بضع دقائق. نحن نقدر صبرك. إذا واجهت أي مشاكل، فلا تتردد في الاتصال بنا.",
    faqs: {
      troubleCamera: "هل تواجه مشكلة مع الكاميرا؟",
      iphoneUsersTitle: "مستخدمي الآيفون",
      iphoneSafariInstructions: "بالنسبة لسفاري: اذهب إلى الإعدادات > سفاري > الكاميرا واختر 'السماح'.",
      iphoneOtherBrowsersInstructions:
        "بالنسبة للمتصفحات الأخرى: اذهب إلى الإعدادات > [المتصفح الخاص بك] > الكاميرا واختر 'السماح'.",

      androidUsersTitle: "مستخدمي الأندرويد",
      androidPopupInstructions: "يجب أن تظهر نافذة منبثقة تطلب الأذونات عندما تحاول استخدام الكاميرا.",
      androidManualInstructions:
        "إذا لم يحدث ذلك، اذهب إلى الإعدادات > التطبيقات > [المتصفح الخاص بك] > الأذونات وقم بتمكين الكاميرا.",

      desktopUsersTitle: "مستخدمي الكمبيوتر المكتبي / الكمبيوتر المحمول",
      chromeInstructions: "جوجل كروم: ستظهر أيقونة الكاميرا في شريط العنوان. انقر عليها واختر 'السماح'.",
      firefoxInstructions: "موزيلا فايرفوكس: ستظهر نافذة منبثقة بالقرب من شريط العنوان. انقر على 'السماح'.",
      edgeInstructions: "مايكروسوفت إيدج: ستظهر نافذة منبثقة في أسفل المتصفح. انقر على 'السماح'.",
      safariInstructions: "سفاري: ستظهر مطالبة تطلب إذنك. انقر على 'السماح'.",
      introTextDocs: "يقبل Yoti الوثائق التالية:",
      whatDocuments: "ما هي الوثائق التعريفية المقبولة؟",
      passportItem: "جواز السفر الحالي (مقبول من أكثر من 200 دولة - انقر هنا لمراجعة قائمة الدول)",
      drivingLicenseItem: "رخصة القيادة المصورة (مقبولة من 92 دولة - انقر هنا لمراجعة قائمة الدول)",
      nationalIdItem: "بطاقة الهوية الوطنية (مقبولة من 66 دولة - انقر هنا لمراجعة قائمة الدول)",
      residencePermitItem: "تصريح الإقامة البريطاني مع الصورة",
      additionalInfo: "قد لا يتم قبول بعض الإصدارات من هذه الوثائق لأنها لا تفي بمتطلبات الأمان لدينا",
      howToTakePhoto: "كيف ألتقط صورة جيدة لهويتي؟",
      title: "هل تحتاج إلى مساعدة في التحقق من الهوية؟",
      introTextTakePhoto:
        "يرجى التأكد من أن الصورة والتفاصيل الشخصية على وثائقك تواجه للأعلى. سيتم التقاط الصورة تلقائيًا. حسب بلد الإصدار، قد تحتاج إلى التقاط صورة للجزء الأمامي والخلفي من هويتك. لالتقاط صورة لهويتك، تأكد من أن:",
      visibleCorners: "جميع الأركان الأربعة لهويتك مرئية في الصورة",
      noFingerCovering: "أصابعك لا تغطي صورتك أو المعلومات المكتوبة عليها",
      documentClarity: "الوثيقة واضحة وفي التركيز",
      adequateLighting: "الإضاءة ليست مظلمة جدًا",
      clearImage: "تأكد من التقاط صورة واضحة بدون أي وهج - للحصول على أفضل النتائج، استخدم هاتفك الذكي.",
      avoidGlare: "تجنب أي وهج أو انعكاس",
      fullDocumentImage: "يجب أن يكون الوثيقة كاملة في الصورة.",
      contact: "إذا كنت تواجه مشكلة في إكمال التحقق من الهوية، يرجى الاتصال بفريقنا عن طريق",
      clickHere: "النقر هنا",
      struggleToConfirm: "هل تواجه صعوبة في تأكيد هويتك؟",
    },
  },

  multiFile: {
    addFile: "إضافة ملف",
  },

  // undecided if we will move forward with these
  countries: {
    "United Kingdom": "المملكة المتحدة",
    "United States": "الولايات المتحدة",
    Canada: "كندا",
    Australia: "أستراليا",
    Germany: "ألمانيا",
    France: "فرنسا",
    Italy: "إيطاليا",
    Spain: "إسبانيا",
    Netherlands: "هولندا",
    Brazil: "البرازيل",
    Mexico: "المكسيك",
    "South Africa": "نوب أفريقيا",
    Sweden: "السويد",
    Norway: "النرويج",
    Denmark: "الدنمارك",
    Belgium: "بلجيكا",
    Switzerland: "سويسرا",
    Ireland: "أيرلندا",
    "New Zealand": "نيوزيلندا",
    Afghanistan: "أفغانستان",
    Albania: "ألبانيا",
    Algeria: "الجزائر",
    "American Samoa": "ساموا الأمريكية",
    Andorra: "أندورا",
    Angola: "أنغولا",
    Anguilla: "أنجويلا",
    "Antigua and Barbuda": "أنتيغوا وبربودا",
    Argentina: "الأرجنتين",
    Armenia: "أرمينيا",
    Aruba: "أروبا",
    Austria: "النمسا",
    Azerbaijan: "أذربيجان",
    Bahamas: "زر البهاما",
    Bahrain: "البحرين",
    Bangladesh: "بنجلاديش",
    Barbados: "بربادوس",
    Belarus: "بيلاروسيا",
    Belize: "بليز",
    Benin: "بنين",
    Bermuda: "برمودا",
    Bhutan: "بوتان",
    Bolivia: "بوليفيا",
    "Bosnia and Herzegovina": "البوسنة والهرسك",
    Botswana: "بوتسوانا",
    "British Indian Ocean Territory": "إقليم المحيط البريطاني الهندي",
    "British Virgin Islands": "زر العذراء البريطانية",
    Brunei: "بروناي",
    Bulgaria: "بلغاريا",
    "Burkina Faso": "بوركينا فاسو",
    Burundi: "بوروندي",
    Cambodia: "كمبوديا",
    Cameroon: "الكاميرون",
    "Cape Verde": "الرأس الأخضر",
    "Caribbean Netherlands": "هولندا الكاريبية",
    "Cayman Islands": "زر كايمان",
    "Central African Republic": "مهورية أفريقيا الوسطى",
    Chad: "تشاد",
    Chile: "تشيلي",
    "Christmas Island": "زيرة كريسماس",
    "Cocos Islands": "زر كوكوس",
    Colombia: "كولومبيا",
    Comoros: "زر القمر",
    "Congo (DRC)": "الكونغو (جمهورية الكونغو الديمقراطية)",
    "Congo (Republic)": "الكونغو (الجمهورية)",
    "Cook Islands": "زر كوك",
    "Costa Rica": "كوستاريكا",
    "Côte d'Ivoire": "كوت ديفوار",
    Croatia: "كرواتيا",
    Cuba: "كوبا",
    Curaçao: "كوراساو",
    Cyprus: "قبرص",
    "Czech Republic": "مهورية التشيك",
    Djibouti: "جيبوتي",
    Dominica: "دومينيكا",
    "Dominican Republic": "مهورية الدومينيكان",
    Ecuador: "الأكوادور",
    Egypt: "مصر",
    "El Salvador": "السلفادور",
    "Equatorial Guinea": "غينيا الاستوائية",
    Eritrea: "إريتريا",
    Estonia: "إستونيا",
    Ethiopia: "إثيوبيا",
    "Falkland Islands": "زر فوكلاند",
    "Faroe Islands": "زر فاروس",
    Fiji: "فيجي",
    Finland: "فنلندا",
    "French Guiana": "غويانا الفرنسية",
    "French Polynesia": "زر بولينيزيا الفرنسية",
    Gabon: "الجابون",
    Gambia: "غامبيا",
    Georgia: "ورجيا",
    Greece: "اليونان",
    Greenland: "رينلاند",
    Grenada: "غرينادا",
    Guadeloupe: "زر جوادلوب",
    Guam: "غوام",
    Guatemala: "غواتيمالا",
    Guernsey: "غيرنسي",
    Guinea: "غينيا",
    "Guinea-Bissau": "غينيا بيساو",
    Guyana: "غيانا",
    Haiti: "هايتي",
    Honduras: "هندوراس",
    "Hong Kong": "هونج كونج",
    Hungary: "المجر",
    Iceland: "أيسلندا",
    India: "الهند",
    Indonesia: "إندونيسيا",
    Iran: "إيران",
    Iraq: "العراق",
    "Isle of Man": "زيرة مان",
    Israel: "إسرائيل",
    Jamaica: "امايكا",
    Japan: "اليابان",
    Jersey: "يرسي",
    Jordan: "الأردن",
    Kazakhstan: "كازاخستان",
    Kenya: "كينيا",
    Kiribati: "كيريباتي",
    Kosovo: "كوسوفو",
    Kuwait: "الكويت",
    Kyrgyzstan: "قيرغيزستان",
    Laos: "لاوس",
    Latvia: "لاتفيا",
    Lebanon: "لبنان",
    Lesotho: "ليسوتو",
    Liberia: "ليبيريا",
    Libya: "ليبيا",
    Liechtenstein: "ليختنشتاين",
    Lithuania: "ليتوانيا",
    Luxembourg: "لوكسمبورغ",
    Macau: "ماكاو",
    Macedonia: "مقدونيا",
    Madagascar: "مدغشقر",
    Malawi: "مالاوي",
    Malaysia: "ماليزيا",
    Maldives: "زر المالديف",
    Mali: "مالي",
    Malta: "مالطا",
    "Marshall Islands": "زر مارشال",
    Martinique: "مارتينيك",
    Mauritania: "موريتانيا",
    Mauritius: "موريشيوس",
    Mayotte: "مايوت",
    Micronesia: "ميكرونيسيا",
    Moldova: "مولدوفا",
    Monaco: "موناكو",
    Mongolia: "منغوليا",
    Montenegro: "الجبل الأسود",
    Montserrat: "مونتسيرات",
    Morocco: "المغرب",
    Mozambique: "موزمبيق",
    Myanmar: "ميانمار",
    Namibia: "ناميبيا",
    Nauru: "ناورو",
    Nepal: "نيبال",
    "New Caledonia": "كاليدونيا الجديدة",
    Nicaragua: "نيكاراغوا",
    Niger: "النيجر",
    Nigeria: "نيجيريا",
    Niue: "نيوي",
    "Norfolk Islands": "زر نورفولك",
    "North Korea": "كوريا الشمالية",
    "Northern Mariana Islands": "زر مريانا الشمالية",
    Oman: "سلطنة عمان",
    Pakistan: "باكستان",
    Palau: "بالاو",
    Palestine: "فلسطين",
    Panama: "بنما",
    "Papua New Guinea": "بابوا غينيا الجديدة",
    Paraguay: "باراجواي",
    Peru: "بيرو",
    Philippines: "الفلبين",
    Poland: "بولندا",
    Portugal: "البرتغال",
    "Puerto Rico": "بورتوريكو",
    Qatar: "دولة قطر",
    Réunion: "زيرة لا ريونيون",
    Romania: "رومانيا",
    Russia: "روسيا",
    Rwanda: "رواندا",
    "Saint Barthélemy": "سانت بارتيليمي",
    "Saint Helena": "سانت هيلينا",
    "Saint Kitts and Nevis": "سانت كيتس ونيفس",
    "Saint Lucia": "سانت لوسيا",
    "Saint Martin": "سانت مارتن",
    "Saint Pierre and Miquelon": "سان بيير وميكلون",
    "Saint Vincent and the Grenadines": "سانت فينسنت والغرينادين",
    Samoa: "ساموا",
    "San Marino": "سان مارينو",
    "São Tomé and Príncipe": "ساو تومي وبرينسيبي",
    "Saudi Arabia": "المملكة العربية السعودية",
    Senegal: "السنغال",
    Serbia: "صربيا",
    Seychelles: "سيشيل",
    "Sierra Leone": "سيراليون",
    Singapore: "سنغافورة",
    "Sint Maarten": "سينت مارتن",
    Slovakia: "سلوفاكيا",
    Slovenia: "سلوفينيا",
    "Solomon Islands": "زر سليمان",
    Somalia: "الصومال",
    "South Korea": "كوريا الجنوبية",
    "South Sudan": "نوب السودان",
    "Sri Lanka": "سيريلانكا",
    Sudan: "السودان",
    Suriname: "سورينام",
    "Svalbard and Jan Mayen": "سفالبارد ويان ماين",
    Swaziland: "سوازيلاند",
    Syria: "سوريا",
    Taiwan: "تايوان",
    Tajikistan: "طاجيكستان",
    Tanzania: "تنزانيا",
    Thailand: "تايلاند",
    "Timor-Leste": "تيمور الشرقية",
    Togo: "توغو",
    Tokelau: "توكيلاو",
    Tonga: "تونغا",
    "Trinidad and Tobago": "ترينيداد وتوباغو",
    Tunisia: "تونس",
    Turkey: "تركيا",
    Turkmenistan: "تركمانستان",
    "Turks and Caicos Islands": "زر توركس وكايكوس",
    Tuvalu: "توفالو",
    "U.S. Virgin Islands": "زر العذراء الأمريكية",
    Uganda: "أوغندا",
    Ukraine: "أوكرانيا",
    "United Arab Emirates": "الإمارات العربية المتحدة",
    Uruguay: "أوروغواي",
    Uzbekistan: "أوزبكستان",
    Vanuatu: "فانواتو",
    "Vatican City": "مدينة الفاتيكان",
    Venezuela: "فنزويلا",
    Vietnam: "فيتنام",
    "Wallis and Futuna": "واليس وفوتونا",
    "Western Sahara": "الصحراء الغربية",
    Yemen: "اليمن",
    Zambia: "زامبيا",
    Zimbabwe: "زيمبابوي",
    "Åland Islands": "زر أولاند",
  },
};
