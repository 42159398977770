import { Props } from "@/types";
import { AltVehicleTypes, AltVehicleStore } from "./types";

export function getProps() {
  return {
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    } as Props.CGPropExtras<string>,
    condition: {
      type: Object,
      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },

    invalidFeedback: {
      type: String,
      required: false,
      allowVariable: false,
    } as Props.CGPropExtras<string>,

    vehicleType: {
      type: String,
      required: false,
      allowVariable: false,
      valid: ["boat", "plane", "general"],
    } as Props.CGPropExtras<AltVehicleTypes>,

    stores: {
      type: Array,
      required: false,
      default: (): Array<{ name: string; type: string }> => [
        { name: "make", type: "string" },
        { name: "model", type: "string" },
        { name: "win", type: "string" },
        { name: "tailNumber", type: "string" },
        { name: "keeperStartDate", type: "string" },
      ],
      description: "The variables stored by this block",
      allowVariable: false,
    } as Props.CGPropExtras<Array<{ name: string; type: string }>>,
  };
}
