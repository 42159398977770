<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { methods, getProps, getData, BlockMotorSpecsInstance } from "./BlockMotorSpecs";
import { Fragment } from "vue-frag";

// Components

import BlockButton from "@/components/shared/blocks/button.vue";

import BlockDatePicker from "@/components/shared/blocks/datepicker.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";

import { computed, mappedComputed } from "./computed";
import { mounted } from "./mounted";

export default (Vue as VueConstructor<Vue>).extend({
  name: "BlockMotorSpecs",
  components: {
    Fragment,
    BlockButton,
    BlockDatePicker,
    BlockSingleSelect,
    BlockPadding,
  },
  props: { ...getProps() },

  computed: {
    ...mappedComputed(),
    aComputed(): string {
      return computed.aComputed(this as unknown as BlockMotorSpecsInstance);
    },
    isAddVehicleDisabled(): boolean {
      return this.vehicles.some((vehicle) => vehicle.vrm.length > 7);
    },
  },
  data() {
    return { ...getData(this) };
  },

  watch: {
    padding: {
      handler(padding): void {
        for (const blockInput of Object.values(this.BlockInputs)) {
          blockInput.padding = padding;
        }
      },
      immediate: true,
    },
    invalidFeedback: {
      handler(invalidFeedback): void {
        this.setInvalidationFeedback(invalidFeedback);
      },
    },

    "BlockInputs.currentOwnerSingleSelect.answer": function (newValue) {
      // If the prop to allow manual keeper entry is set to false, this logic should not run.
      if (!this.allowManualKeeperEntry) {
        return;
      }

      if (newValue === "Yes") {
        this.$nextTick(() => {
          this.uiToggles.showKeeperStartQuestion = true;
        });
        this.$nextTick(() => {
          this.uiToggles.showKeeperEndQuestion = false;
        });
      } else if (newValue === "No") {
        this.$nextTick(() => {
          this.uiToggles.showKeeperStartQuestion = true;
        });

        this.$nextTick(() => {
          this.uiToggles.showKeeperEndQuestion = true;
        });
      }
    },

    "BlockInputs.keeperSelectSingleSelect.answer": function (newValue) {
      if (!this.allowManualKeeperEntry) {
        return;
      }

      if (newValue !== "Other") {
        this.$nextTick(() => {
          this.uiToggles.showKeeperStartQuestion = false;
          this.uiToggles.showKeeperEndQuestion = false;

          this.BlockInputs.keeperStartDatePicker.answer = "" as any;
          this.BlockInputs.keeperEndDatePicker.answer = "" as any;
          this.BlockInputs.currentOwnerSingleSelect.answer = null;

          this.BlockInputs.keeperStartDatePicker.invalidFeedback = null;
          this.BlockInputs.keeperEndDatePicker.invalidFeedback = null;
          this.BlockInputs.currentOwnerSingleSelect.invalidFeedback = null;

          this.BlockInputs.keeperStartDatePicker.state = null;
          this.BlockInputs.keeperEndDatePicker.state = null;
          this.BlockInputs.currentOwnerSingleSelect.state = null;
        });
      }
    },
  },

  methods: {
    displayHelpModal() {
      methods.displayHelpModal(this as unknown as BlockMotorSpecsInstance);
    },
    notMyVehicle() {
      methods.notMyVehicle(this as unknown as BlockMotorSpecsInstance);
    },

    setInvalidationFeedback(invalidFeedback: string): void {
      methods.setValidationInvalidFeedback(this as unknown as BlockMotorSpecsInstance, invalidFeedback);
    },
    async searchVehicle(): Promise<void> {
      await methods.searchVehicle(this as unknown as BlockMotorSpecsInstance);
    },

    async submitVehicleConfirmation(): Promise<void> {
      await methods.submitVehicleConfirmation(this as unknown as BlockMotorSpecsInstance);
    },

    async submitKeeperSelect(): Promise<void> {
      await methods.submitKeeperSelect(this as unknown as BlockMotorSpecsInstance);
    },

    async submitOwnershipDate(): Promise<void> {
      await methods.submitOwnershipDate(this as unknown as BlockMotorSpecsInstance);
    },

    async submitAdditionalVehiclePage(): Promise<void> {
      await methods.submitAdditionalVehiclePage(this as unknown as BlockMotorSpecsInstance);
    },

    addVehicle(): void {
      //adding a new vehicle array
      if (this.vehicles.length < 10) {
        // Limit the number of vehicles to 10
        this.vehicles.push({ vrm: "", ownershipDate: "", state: null, invalidFeedback: "" });
      }
    },

    removeVehicle(index: number): void {
      this.vehicles.splice(index, 1);
    },

    validateExistingVehicles(): boolean {
      return methods.validateExistingVehicles(this as unknown as BlockMotorSpecsInstance);
    },

    handleAddVehicle(): void {
      if (this.validateExistingVehicles()) {
        this.showError = true;
      } else {
        this.addVehicle();
      }
    },
  },

  async mounted(): Promise<void> {
    await mounted(this as unknown as BlockMotorSpecsInstance);
  },
});
</script>

<style scoped>
.error {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #e13f45 !important;
  transition: 0.25s;
}
.selected {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #3452e1 !important;
  transition: 0.25s;
}
.deselected {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #eff2f7 !important;
  transition: 0.25s;
}

.input-group > .input-group-prepend {
  flex: 0 0 15%;
}
.input-group .input-group-text {
  width: 100%;
}
/* Reg Input styling */
.reg-input-selected {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  border-right-width: 0px !important;
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #ced4da !important;
  transition: 0.25s;
}
.reg-input-selected:focus {
  border-color: var(--primary) !important;
}
.input-group-prepend:focus {
  border-color: var(--primary) !important;
}

.remove-reg {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  border-right-width: 0px !important;
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #ced4da !important;
  transition: 0.25s;
}
.btn-glow,
.btn-glow:active,
.btn-glow:focus,
.btn-glow:hover {
  box-shadow: 0px 0px 30px -10px #3452e1 !important;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
  /* Recent browsers */
  text-transform: none;
}
.disabled {
  background-color: #e9ecef !important;
}

.is-invalid {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: var(--danger) !important;
  transition: 0.25s;
}

.form-control {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.prepend-border {
  border: 2px solid var(--primary) !important;
}
</style>
<template>
  <Fragment>
    <BlockPadding :padding="padding" />

    <template v-if="uiToggles.isSearchingByQueryParameters">
      <div class="d-flex justify-content-center align-items-center flex-column" style="padding-top: 50px">
        <div>
          <b-icon class="fa fa-smog" animation="cylon" font-scale="3.75" :style="[{ color: theme.primaryColour }]" />
          <b-icon class="fa fa-car-side" animation="cylon" font-scale="4" :style="[{ color: theme.primaryColour }]" />
        </div>
        <h3 :style="[theme.textColourStyles]" class="mt-2">
          <span :style="[{ color: theme.primaryColour }]">Searching for your vehicle</span>
        </h3>
      </div>
    </template>

    <!-- Initial search -->
    <template v-if="uiToggles.isVehicleSearchVisible && !uiToggles.isSearchingByQueryParameters">
      <h5 class="mb-0 font-weight-semibold text-grey-900">Vehicle registration number</h5>
      <p class="mb-1 text-grey-700">Please enter your vehicle registration number</p>

      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text class="justify-content-center reg-input-selected">
            <img :src="require('@/assets/images/GB.png')" style="width: 30px" alt="GB" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          class="border reg-input-selected"
          size="lg"
          maxLength="20"
          v-model="vrm"
          :state="vrmInputState"
          placeholder="Example: AB12 CDE"
          @update="vrmInputState = null"
          data-cy="motorspecs-input"
        ></b-form-input>
      </b-input-group>

      <template v-if="!vrmInputState">
        <b-form-invalid-feedback :state="vrmInputState" id="input-live-feedback" data-cy="motorspecs-input-error">
          {{ vrmInputInvalidFeedback }}
        </b-form-invalid-feedback>
      </template>

      <h5 class="mt-1 text-sm" role="button" @click="displayHelpModal">
        <span :style="theme.textColourStyles">For help finding your vehicle, click here</span>
      </h5>

      <b-modal id="helpModal" title="Find Your Vehicle" size="md" centered hide-footer>
        <template #modal-header="{ close }">
          <div style="display: block">
            <h4>Find Your Vehicle</h4>
          </div>

          <div style="float: right">
            <b-button size="lg" variant="link" @click="close()" class="p-0 pr-1">
              <i class="fas fa-times mx-1" />
            </b-button>
          </div>
        </template>

        <h5>Current Registrations</h5>
        <p>
          Make sure to search for the Registration Number that is on the vehicle today. If you are not the current
          owner, note that the current owner may have changed the Registration Number.
        </p>

        <h5>Current Mistakes</h5>
        <p>
          If you vehicle uses a standard registration plate, it should be in the format LLNN LLL. L - letter, N -
          number. Common mistakes can be swapping 0 for O, or I for 1.
        </p>

        <h5>Ownership Date</h5>
        <p>
          Please select any date on which you owned the vehicle or where the registered owner of the vehicle. Selecting
          the correct ownership date will help us find the correct vehicle associated with your registration number.
        </p>
      </b-modal>

      <BlockButton
        @clicked="searchVehicle"
        v-bind="BlockInputs.searchRegButton"
        v-bind.sync="BlockInputs.searchRegButton"
        data-cy="motorspecs-search-button"
      />
    </template>

    <template v-if="uiToggles.showVehicleConfirmation">
      <b-card class="mb-0" no-body style="box-shadow: 0 0rem 0.25rem rgb(0 0 0 / 15%)">
        <b-card-body style="padding-bottom: 0.25rem !important">
          <h4 class="text-grey-700 font-weight-semibold">Vehicle Found</h4>
          <h4 class="text-grey-900">
            We found your vehicle, <b>{{ vehicle?.make }}</b
            >, <b>{{ vehicle?.model }}</b
            >, <b>{{ vehicle?.yearOfManufacture }}</b
            >, <b>{{ vehicle?.colour }}</b
            >, <b>{{ vehicle?.fuelType }}</b>
          </h4>

          <h5 class="font-weight-semibold" role="button" @click="notMyVehicle">
            <span :style="theme.textColourStyles">Click here to change your vehicle</span>
          </h5>
        </b-card-body>
      </b-card>

      <BlockPadding :padding="padding" />
      <h5 class="mb-0 font-weight-semibold text-grey-900">Vehicle Confirmation</h5>
      <p class="mb-1 text-grey-700">Please confirm the vehicle details shown above are correct</p>
      <BlockSingleSelect
        v-bind="BlockInputs.vehicleConfirmationSingleSelect"
        v-bind.sync="BlockInputs.vehicleConfirmationSingleSelect"
        padding="0"
        data-cy="motorspecs-vehicle-confirmation-select"
      />
      <BlockButton
        @clicked="submitVehicleConfirmation"
        v-bind="BlockInputs.submitVehicleConfirmationButton"
        v-bind.sync="BlockInputs.submitVehicleConfirmationButton"
        data-cy="motorspecs-vehicle-confirmation-button"
      />
    </template>

    <template v-if="uiToggles.showOwnershipDateQuestion">
      <BlockPadding :padding="padding" />
      <h5 class="mb-0 font-weight-semibold text-grey-900">Ownership Date</h5>
      <p class="mb-0 text-grey-700">Please select any date on which you were the owner of the vehicle.</p>
      <BlockDatePicker
        v-bind="BlockInputs.ownershipDatePicker"
        :answer.sync="ownershipDate"
        v-bind.sync="BlockInputs.ownershipDatePicker"
        padding="0"
        data-cy="motorspecs-ownership-date-picker"
      />

      <BlockButton
        @clicked="submitOwnershipDate"
        v-bind="BlockInputs.submitOwnershipDateButton"
        v-bind.sync="BlockInputs.submitOwnershipDateButton"
        data-cy="motorspecs-ownership-date-button"
      />
    </template>

    <!-- Vehicle Found Section -->
    <template v-if="uiToggles.isKeeperSelectVisible">
      <b-card class="mb-0" no-body style="box-shadow: 0 0rem 0.25rem rgb(0 0 0 / 15%)">
        <b-card-body style="padding-bottom: 0.25rem !important">
          <h4 class="text-grey-700 font-weight-semibold">Vehicle Found</h4>
          <h4 class="text-grey-900">
            We found your vehicle, <b>{{ vehicle?.make }}</b
            >, <b>{{ vehicle?.model }}</b
            >, <b>{{ vehicle?.yearOfManufacture }}</b
            >, <b>{{ vehicle?.colour }}</b
            >, <b>{{ vehicle?.fuelType }}</b>
          </h4>
          <h5 class="text-primary-700 font-weight-semibold" role="button" @click="notMyVehicle">
            <span>Change your vehicle? Click here</span>
          </h5>
        </b-card-body>
      </b-card>

      <BlockPadding :padding="padding" />

      <h5 class="mb-0 font-weight-semibold text-grey-900">Purchase Date</h5>
      <p class="mb-1 text-grey-700">Please select the date when you purchased the vehicle</p>

      <BlockSingleSelect
        v-bind="BlockInputs.keeperSelectSingleSelect"
        v-bind.sync="BlockInputs.keeperSelectSingleSelect"
        padding="0"
        data-cy="motorspecs-keeper-select"
      />

      <template v-if="BlockInputs.keeperSelectSingleSelect.answer === 'Other'">
        <BlockPadding :padding="padding" />
        <h5 class="mb-0 font-weight-semibold text-grey-900">Vehicle Ownership</h5>
        <p class="mb-1 text-grey-700">Are you still the current owner of the vehicle?</p>
        <BlockSingleSelect
          v-bind="BlockInputs.currentOwnerSingleSelect"
          v-bind.sync="BlockInputs.currentOwnerSingleSelect"
          padding="0"
          data-cy="motorspecs-current-owner-select"
        />

        <template v-if="uiToggles.showKeeperStartQuestion">
          <BlockPadding padding="20" />
          <h5 class="mb-0 font-weight-semibold text-grey-900">Keeper Start Date</h5>
          <p class="mb-1 text-grey-700">Please select the date when you became the keeper of the vehicle</p>
          <BlockDatePicker
            v-bind="BlockInputs.keeperStartDatePicker"
            v-bind.sync="BlockInputs.keeperStartDatePicker"
            padding="0"
            data-cy="motorspecs-keeper-start-date-picker"
          />
        </template>

        <template v-if="uiToggles.showKeeperEndQuestion">
          <BlockPadding padding="20" />
          <h5 class="mb-0 font-weight-semibold text-grey-900">Keeper End Date</h5>
          <p class="mb-1 text-grey-700">Please select the date when you ceased being the keeper of the vehicle</p>
          <BlockDatePicker
            v-bind="BlockInputs.keeperEndDatePicker"
            v-bind.sync="BlockInputs.keeperEndDatePicker"
            padding="0"
            data-cy="motorspecs-keeper-end-date-picker"
          />
        </template>
      </template>

      <BlockButton
        @clicked="submitKeeperSelect"
        v-bind="BlockInputs.submitKeeperSelectButton"
        v-bind.sync="BlockInputs.submitKeeperSelectButton"
        data-cy="motorspecs-keeper-select-button"
      />
    </template>

    <template v-if="uiToggles.showAdditionalVehicleSection">
      <!-- New section for additional vehicles question -->

      <template>
        <div class="additional-vehicles-section">
          <h5 class="mb-0 font-weight-semibold text-grey-900">Additional Vehicles</h5>
          <p class="mb-1 text-grey-700">Do you have any additional vehicles which you would like to claim for?</p>
          <div class="d-flex justify-content-center">
            <BlockSingleSelect
              v-bind="BlockInputs.addAdditionalVehiclesSingleSelect"
              v-bind.sync="BlockInputs.addAdditionalVehiclesSingleSelect"
              class="w-100"
              padding="0"
            />
          </div>
        </div>
      </template>

      <!-- for more vehicle entries-->
      <template v-if="BlockInputs.addAdditionalVehiclesSingleSelect.answer === 'Yes'">
        <BlockPadding :padding="padding" />

        <template v-for="(vehicle, index) in vehicles">
          <div class="w-100" :key="index">
            <BlockPadding padding="15" />

            <h5 class="text-grey-900 font-weight-semibold mb-0 mt-1">Vehicle {{ index + 1 }}</h5>
            <p class="mb-1 text-grey-700">Please enter your vehicle registration number</p>

            <div class="d-flex">
              <b-input-group class="w-100">
                <b-input-group-prepend>
                  <b-input-group-text class="justify-content-center reg-input-selected">
                    <img :src="require('@/assets/images/GB.png')" style="width: 30px" alt="GB" />
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  class="border reg-input-selected"
                  size="lg"
                  maxLength="20"
                  v-model="vehicle.vrm"
                  :state="vehicle.state"
                  @update="vehicle.state = null"
                  placeholder="Example: AB12 CDE"
                ></b-form-input>
              </b-input-group>
              <b-button
                size="lg"
                class="mb-0 float-right text-md p-0"
                style="background: transparent; border: none"
                @click="removeVehicle(index)"
                v-if="vehicles.length > 1"
                ><b-icon icon="x" scale="2" class="mx-3 text-grey-600 text-hover-grey-900"></b-icon>
              </b-button>
            </div>

            <b-form-invalid-feedback
              :state="vehicle.state"
              v-if="vehicle.state === false && vehicle.invalidFeedback.length > 0"
            >
              <p class="mb-0 text-danger">
                {{ vehicle.invalidFeedback }}
              </p>
            </b-form-invalid-feedback>

            <!-- render error here for each vrm that has no value, or if the trimmed string is greater than 7 -->
          </div>
        </template>

        <div class="d-flex justify-content-end mt-3">
          <b-button
            size="lg"
            @click="handleAddVehicle"
            :disabled="uiToggles.isSubmitting"
            class="d-flex align-items-center"
            variant="outline-light text-dark"
          >
            <font-awesome-icon icon="plus" class="mr-2" scale="1.5"></font-awesome-icon>
            <span :style="theme.fontStyles">Add Vehicle</span>
          </b-button>
        </div>
      </template>

      <BlockButton
        @clicked="submitAdditionalVehiclePage"
        v-bind="BlockInputs.submitAdditionalVehiclePageButton"
        v-bind.sync="BlockInputs.submitAdditionalVehiclePageButton"
        :disabled="uiToggles.isSubmitting"
        :isProcessing="loaders.next"
        data-cy="motorspecs-submit-additional-vehicle-page-button"
      />
    </template>
  </Fragment>
</template>
