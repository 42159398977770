export default {
  signInToAccess: "के साथ अपने खाते तक पहुंचने के लिए साइन इन करें",
  signInMethod: "साइन-इन का तरीका",
  selectSignInMethod: "साइन-इन का तरीका चुनें",
  continue: "जारी रखें",
  welcomeBack: "आपका पुनः स्वागत है!",
  emailPlaceholder: "ईमेल",
  emailLabel: "ईमेल",
  phonePlaceholder: "फोन",
  phoneLabel: "फोन",
  enterYourPassword: "कृपया अपना पासवर्ड दर्ज करें",
  enterPasswordLink: "इससे जुड़ा हुआ पासवर्ड दर्ज करें",
  back: "पीछे जाएं",
  passwordPlaceholder: "पासवर्ड",
  passwordLabel: "पासवर्ड",
  signIn: "साइन इन करें",
  showPassword: "पासवर्ड दिखाएं",
  forgotPassword: "पासवर्ड भूल गए?",
  createPasswordHeading: "अपना पासवर्ड बनाएं",
  createPasswordSupporting: "कृपया के साथ अपने खाते के लिए एक नया पासवर्ड बनाएं",
  forgotPasswordHeading: "अपना पासवर्ड बनाएं",
  confirmLabel: "पुष्टि करें",
  confirmPlaceholder: "पुष्टि करें",
  incorrectPassword: "गलत पासवर्ड।  कृपया पुन: प्रयास करें।",
  otpModalHeading: "कोड की पुष्टि करें",
  otpModalSupporting: "इस पर भेजा गया कोड दर्ज करें:",
  otpModalInfo: "अपना स्पैम फोल्डर भी देख लें।",
  otpModalCodeInvalid: "कोड गलत है।",
};
