var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('b-button',{staticClass:"border-1 border border-grey-300 bg-white p-3 shadow-sm",on:{"mouseover":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false}}},[_c('font-awesome-icon',{class:[
        'align-self-start mt-1 font-weight-semibold',
        _vm.isSelected ? 'text-grey-700' : 'text-grey-400',
        _vm.hovered ? 'text-grey-700' : 'text-grey-400',
      ],attrs:{"icon":_vm.sgIcons[_vm.iconType],"size":"xl"}})],1),_c('span',{class:[
      'text-sm',
      _vm.isSelected
        ? 'text-grey-700 font-weight-semibold mt-2 text-md'
        : 'text-grey-400 font-weight-semibold mt-2 text-md',
      _vm.hovered ? 'text-grey-700 font-weight-semibold mt-2 text-md' : 'text-grey-400 font-weight-semibold mt-2 text-md',
    ]},[_vm._v(_vm._s(_vm.iconName))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }