import { eventComputed, formComputed, navigationComputed, themesComputed } from "@/state/helpers";
import { identificationFlow as idFlows } from "@claimsgate/core";
import { BlockKycInstance } from "./BlockKycInstance";

export function mappedComputed() {
  return {
    ...(navigationComputed as Record<keyof typeof navigationComputed, any>),
    ...(themesComputed as Record<keyof typeof themesComputed, any>),
    ...(formComputed as Record<keyof typeof formComputed, any>),
    ...(eventComputed as Record<keyof typeof eventComputed, any>),
  };
}

export const computed = { idFlowAllowsElectoralRoll, idFlowAllowsYoti };

/**
 * Assert wether the selected identificationFlow prop allows the use of electoral roll
 */
function idFlowAllowsElectoralRoll(state: BlockKycInstance, use?: boolean): boolean {
  return (
    state.identificationFlow === idFlows.electoralRoll ||
    state.identificationFlow === idFlows.electoralRollWithYotiFallback ||
    state.identificationFlow === idFlows.electoralRollWithYotiIdAndAddressFallback ||
    state.identificationFlow === idFlows.electoralRollWithYotiIdAndAmlFallback
  );
}

function idFlowAllowsYoti(state: BlockKycInstance, use?: boolean): boolean {
  return (
    state.identificationFlow === idFlows.electoralRollWithYotiFallback ||
    state.identificationFlow === idFlows.electoralRollWithYotiIdAndAddressFallback ||
    state.identificationFlow === idFlows.electoralRollWithYotiIdAndAmlFallback ||
    state.identificationFlow === idFlows.yotiId ||
    state.identificationFlow === idFlows.yotiIdAndAddress ||
    state.identificationFlow === idFlows.yotiIdAndAml
  );
}
