var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BlockInputWrapper',_vm._b({},'BlockInputWrapper',{ info: _vm.info, padding: _vm.padding, label: _vm.label, invalidFeedback: _vm.invalidFeedback, state: _vm.state },false),[_c('b-form-group',{staticClass:"mb-0",scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-checkbox-group',{ref:'b-multiselect',attrs:{"id":"checkbox-group-0","aria-describedby":ariaDescribedby,"name":"flavour-0","required":_vm.required,"state":_vm.state},on:{"change":function($event){return _vm.$emit('update:state', null)}},model:{value:(_vm.newAnswer),callback:function ($$v) {_vm.newAnswer=$$v},expression:"newAnswer"}},[_c('b-row',{staticClass:"justify-content-center no-gutters"},[_vm._l((_vm.options),function(option,index){return [_c('b-col',{key:option.id,staticClass:"mb-0",attrs:{"cols":"12"}},[_c('b-card',{ref:'q' + index,refInFor:true,staticClass:"border mb-0 p-1",class:{
                selected: _vm.isSelected(index),
                deselected: !_vm.isSelected(index),
                'invalid-border': _vm.state === false,
              },attrs:{"no-body":""},on:{"click":function($event){$event.preventDefault();return _vm.select(index)}}},[_c('div',{class:`custom-checkbox b-custom-control-${_vm.theme.size} d-flex flex-row ${
                  _vm.theme.size === 'lg' ? 'p-3 pl-4' : _vm.theme.size === 'md' ? 'p-2 pl-3' : 'px-1'
                }`},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-checkbox',{staticClass:"mr-2",attrs:{"aria-describedby":ariaDescribedby,"value":option}})],1),_c('span',{staticClass:"font-weight-normal m-0 d-flex align-items-center",style:(_vm.theme.textColourStyles)},[_vm._v(" "+_vm._s(_vm.$te("blocks.multiSelect." + option.replace(/\.$/, "")) ? _vm.$t("blocks.multiSelect." + option.replace(/\.$/, "")) : option)+" ")])])])],1)]})],2)],1),(_vm.other)?[_c('b-form-checkbox-group',{ref:'b-multiselect-2',attrs:{"id":"checkbox-group-1","aria-describedby":ariaDescribedby,"name":"flavour-1","required":_vm.required,"state":_vm.state},on:{"change":function($event){return _vm.$emit('update:state', null)}},model:{value:(_vm.otherVModel),callback:function ($$v) {_vm.otherVModel=$$v},expression:"otherVModel"}},[_c('b-row',{staticClass:"justify-content-center no-gutters"},[_c('b-col',{key:'other-col',staticClass:"mb-0",attrs:{"cols":"12"}},[_c('b-card',{ref:'q' + (_vm.options.length - 1),staticClass:"border mb-0 p-1",class:{
                selected: _vm.otherSelected,
                deselected: !_vm.otherSelected,
                'invalid-border': _vm.state === false,
              },attrs:{"no-body":""},on:{"click":function($event){$event.preventDefault();return _vm.selectOther()}}},[_c('div',{class:`custom-checkbox b-custom-control-${_vm.theme.size} ${
                  _vm.theme.size === 'lg' ? 'p-3 pl-4' : _vm.theme.size === 'md' ? 'p-2 pl-3' : 'px-1'
                }`},[_c('b-form-checkbox',{staticClass:"mr-2",staticStyle:{"display":"inline"},attrs:{"aria-describedby":ariaDescribedby,"value":_vm.otherVModel[0]}}),_c('span',{staticClass:"font-weight-normal m-0",staticStyle:{"display":"inline-block"},style:(_vm.theme.textColourStyles)},[_vm._v(" Other ")])],1)])],1)],1)],1)]:_vm._e()]}}])}),(_vm.otherSelected)?_c('b-input-group',{ref:'other',staticClass:"pt-1"},[_c('b-form-input',{class:_vm.disabled ? 'disabled' : '',attrs:{"id":"input","type":"text","placeholder":"Your answer","size":_vm.theme.size,"required":_vm.required,"state":_vm.state,"disabled":_vm.disabled,"aria-describedby":"input-live-feedback input-live-help","trim":""},on:{"change":function($event){_vm.$emit('update:state', null);
        _vm.handleOtherAnswer();}},model:{value:(_vm.otherAnswer),callback:function ($$v) {_vm.otherAnswer=$$v},expression:"otherAnswer"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }