<script lang="ts">
//@ts-check
// Types
// eslint-disable-next-line no-unused-vars
import { Props } from "@/types";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import BlockInput from "@/components/shared/blocks/input.vue";
//import BlockProgress from "@/components/shared/blocks/progress.vue";
import { formComputed, themesComputed } from "@/state/helpers";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import Vue from "vue";
import { Fragment } from "vue-frag";
import { FileMeta } from "@claimsgate/core-types";
import { getFirebaseBackend } from "@/authUtils";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
const inputPropsValue: Props.BlockFileInput = inputProps({
  answerType: "string",
  extraProps: ["placeholder"],
  placeholder: "Click to upload",
});
export default Vue.extend({
  name: "BlockFileInput",
  components: { BlockInputWrapper /* BlockProgress */, Fragment: Fragment as any, BlockInput },
  props: {
    ...inputPropsValue,
    multiple: {
      type: Boolean,
      required: true,
      default: false,
      description: "When true, will allow multiple files to be selected",
      allowVariable: false,
    } as Props.Bool,
    answer: {
      required: false,
      allowVariable: false,
      description: "The file object",
    } as Props.CGPropExtras<any>,
  },
  data() {
    return {
      file: null,
      fileFocused: false,
      uiMessages: {
        uploadToBig: "File upload size is too large, must be less than 30MB",
      },
      previousFile: undefined as FileMeta,
      userService: getUserHelper(),
    };
  },
  watch: {
    /**
     * Updates the image if the user has uploaded a new image
     */
    file: {
      async handler(newFile, oldFile) {
        // If the user has uploaded a different image

        console.log("Selected new, old", newFile, oldFile);
        if (newFile !== oldFile) {
          if (newFile) {
            const maxUploadMB = 30;
            if (Array.isArray(newFile)) {
              // Process a multiple file upload
              const parsedFiles = (newFile as Array<File>).map((file) => {
                // Check the size of each file doesn't exceeed the maximum
                const mb = file.size / 1024 / 1024;
                if (mb > maxUploadMB) {
                  this.$emit("update:state", false);
                  this.$emit("update:invalidFeedback", this.uiMessages.uploadToBig);
                  this.reset();
                  return null;
                } else {
                  // Set a progress marker for each file
                  console.log("[FILE] set progress for ", file.name);
                  this.$store.dispatch("form/setFileUploadProgress", { fileName: file?.name, progress: 0 });
                  return file;
                }
              });
              if (parsedFiles.length === newFile.length && newFile.length > 0) {
                // All files are valid, upload them
                this.$emit("update:answer", { ...this.answer, file: newFile });
              } else {
                this.$emit("update:answer", null);
              }
            } else {
              const mb = newFile.size / 1024 / 1024;
              if (mb > maxUploadMB) {
                this.$emit("update:state", false);
                this.$emit("update:invalidFeedback", this.uiMessages.uploadToBig);
                this.reset();
                return;
              }
              this.$store.dispatch("form/setFileUploadProgress", { fileName: newFile?.name, progress: 0 });
              if (!this.answer.store) {
                this.$emit("update:answer", { file: this.file });
              } else {
                this.$emit("update:answer", { file: this.file, store: this.answer.store });
              }
            }
          } else {
            // this.imageSrc = null;
          }
        }
      },
    },
  },
  methods: {
    clearImage() {
      // this.image = null;
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    reset() {
      // @ts-ignore
      this.$refs["file-input"].reset();
    },
    changeFiles() {
      this.previousFile = undefined;
      this.$emit("update:answer", "");
      this.$emit("update:state", null);
      this.$emit("update:invalidFeedback", null);
    },
  },
  async mounted() {
    const userId = this.userService.getUserId();
    if (this.answer?.store?.id) {
      console.log("FILE ANSWER IS", this.answer.store);
      this.previousFile = { name: "Loading..." } as FileMeta;
      const fileMeta = (
        await getFirebaseBackend()
          .firestore()
          .collection("users")
          .doc(userId)
          .collection("files")
          .doc(this.answer.store.id)
          .get()
      ).data() as FileMeta;
      this.previousFile = fileMeta;
    }
  },
  beforeDestroy() {
    // Remove all of the file refs on destroy
    // @ts-ignore
    this.$refs["file-input"].reset();
  },
  computed: {
    ...themesComputed,
    ...formComputed,
    /**@this { { answer : {file: File}, fileUploadProgress}} @type { () => {fileName: string, progress: number} } */
    fileUploadProgressForCurrentFileName(): { fileName: string; progress: number } {
      // Passing the current file name to the getter retrieves the relevant object containing current progress
      if (this.answer?.file?.name) {
        return this.fileUploadProgress(this.answer?.file?.name) ?? {};
      } else {
        return { progress: 0, fileName: "" };
      }
    },
    /** @type { () => string} @this { { answer : {file: File}, fileUploadProgress}}*/
    fileUploadProgressPercent(): string {
      const integer = this.fileUploadProgress(this.answer?.file?.name)?.progress;
      const percentString = `${(Math.round(integer * 100) / 100).toString()} %`;
      return percentString;
    },
    showOverlay(): boolean {
      const singleFileUploading = !!(
        this.answer.file &&
        this.answer.file.name &&
        this.fileUploadProgressForCurrentFileName &&
        this.fileUploadProgressForCurrentFileName.progress
      );

      const multipleFilesUploading = !!(
        this.answer.file &&
        Array.isArray(this.answer.file) &&
        this.answer.file.length > 0 &&
        this.answer.file.some((file: File) => {
          console.log("[FILE]multipleFilesUploading", this.fileUploadProgress(file.name));

          return this.fileUploadProgress(file.name)?.progress;
        })
      );

      return singleFileUploading || multipleFilesUploading;
    },
  },
});
</script>
<style>
/** Custom file input padding causes y-overflow, hiding it
Credit: https://blog.hubspot.com/website/hide-scrollbar-css
*/
label.custom-file-label {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

label.custom-file-label::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.custom-file-label {
  border: 2px solid #ced4da !important;
}
.custom-file-input:focus ~ label.custom-file-label {
  border: 2px solid var(--primary) !important;
}
.custom-file-input.is-invalid ~ .custom-file-label {
  box-shadow: none !important;
  border-color: #f14646 !important;
}
.custom-file-label.is-valid:focus ~ label.custom-file-label {
  box-shadow: none !important;
  border: 2px solid #23bd85 !important;
}
.custom-file-input.is-valid ~ label.custom-file-label {
  box-shadow: none !important;
  border: 2px solid #23bd85 !important;
}
</style>
<template>
  <Fragment>
    <template v-if="previousFile">
      <BlockInputWrapper v-bind="{ info, padding, label, invalidFeedback, state }">
        <BlockInput v-bind="{ disabled: true, answer: previousFile.name, inputType: 'text' }" />
        <div>
          <b-button size="md" variant="link" class="mt-1 text-secondary" @click="changeFiles()">
            Click here to change file
          </b-button>
        </div>
      </BlockInputWrapper>
    </template>
    <BlockInputWrapper
      v-else
      :info="info"
      :padding="padding"
      :label="label"
      :invalidFeedback="invalidFeedback"
      :state="state"
    >
      <b-overlay
        :show="
          !!(
            answer.file &&
            answer.file.name &&
            fileUploadProgressForCurrentFileName &&
            fileUploadProgressForCurrentFileName.progress
          )
        "
      >
        <b-form-file
          v-model="file"
          :placeholder="placeholder"
          :size="theme.size"
          :state="state"
          :accept="null"
          :multiple="multiple"
          :ref="'file-input'"
          :disabled="disabled"
          @change="$emit('update:state', null)"
        >
        </b-form-file>
        <template #overlay>
          <div class="text-center">
            <b-spinner class="spinner-border-sm m-1"></b-spinner>
            {{ fileUploadProgressPercent }}
          </div>
        </template>
      </b-overlay>
    </BlockInputWrapper>
  </Fragment>
</template>
