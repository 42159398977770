import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { BlockProps } from "@/types";

/** Each of the BlockTextProps used in this component */
interface BlockTextProps {
  confirmVehicleText?: Partial<BlockProps.HTML>;
}

/** Each of the BlockButtonProps used in this component */
interface BlockButtonProps {
  submit?: Partial<BlockProps.Button>;
}

/** Each of the BlockInputProps used in this component */
interface BlockInputProps {
  generalMake: Partial<BlockProps.Input<string>>;
  generalModel: Partial<BlockProps.Input<string>>;
  boatWin: Partial<BlockProps.Input<string>> & { mask: string };
  planeNumber: Partial<BlockProps.Input<string>>;
  ownershipDatePicker: Partial<BlockProps.DatePicker>;
}

export type AltVehicleBlockProps = BlockButtonProps & BlockInputProps & BlockTextProps;

const texts: BlockTextProps = {
  confirmVehicleText: { html: "<a onclick='test'>Not my vehicle?</a>", height: "auto" },
};

const buttons: BlockButtonProps = {
  submit: { submitButtonDefault: false, text: "Continue", block: false },
};

const inputs: BlockInputProps = {
  generalMake: UserVariableBlocksService.genBlockInputProps({
    answer: "" as any,
    label: "",
    placeholder: "",
    required: true,
    padding: "0",
  }),
  generalModel: UserVariableBlocksService.genBlockInputProps({
    answer: "" as any,
    label: "",
    placeholder: "",
    required: false,
    padding: "0",
  }),
  boatWin: {
    ...UserVariableBlocksService.genBlockInputProps({
      answer: "" as any,
      label: "",
      placeholder: "",
      required: true,
      padding: "0",
    }),
    mask: "AA-AAA-A####-A-#-##",
  },
  planeNumber: UserVariableBlocksService.genBlockInputProps({
    answer: "" as any,
    label: "",
    placeholder: "",
    required: true,
    padding: "0",
  }),
  ownershipDatePicker: UserVariableBlocksService.genBlockDatePickerProps({
    displayDays: true,
    yearsStart: 2000,
    yearsEnd: new Date().getFullYear(),
    answer: "" as any,
    padding: "0",
    label: "",
  }),
};

export const blocks = { ...texts, ...buttons, ...inputs };
