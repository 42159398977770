import { getFirebaseBackend } from "@/authUtils";
import * as Sentry from "@sentry/vue";
import { captureMessage } from "@sentry/vue";
import { BlockCarwebInstance } from "./BlockCarweb";

/** Fires a proactive Sentry log including the VRM called 'CarwebApiError' fingerprinted by our release number */
export function fireCarwebApiErrorEvent(state: BlockCarwebInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", state.vrm);
    scope.setTag("funnelId", state.funnelId);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("CarwebApiError");
  });
}

/** Fires a proactive Sentry log including the VRM called 'CarwebVehicleNotFound' release number */
export function fireCarwebVehicleNotFoundEvent(state: BlockCarwebInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", state.vrm);
    scope.setTag("funnelId", state.funnelId);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("CarwebVehicleNotFound");
  });
}

/** Fires a proactive Sentry log including the VRM called 'CarwebVehicleFound' release number */
export function fireCarwebVehicleFoundEvent(state: BlockCarwebInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", state.vrm);
    scope.setTag("funnelId", state.funnelId);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("CarwebVehicleFound");
  });
}

/** Fires a proactive Sentry log including the VRM called 'CarwebVrmEntered' release number */
export function fireCarwebVrmEntered(state: BlockCarwebInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", state.vrm);
    scope.setTag("funnelId", state.funnelId);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("CarwebVrmEntered");
  });
}

/** Fires a proactive Sentry log including the VRM called 'CarwebVehicleNotFound' release number */
export function fireCarwebNotQualifiedEvent(state: BlockCarwebInstance) {
  const vrm = tryParseJson(state.vrm);
  const make = tryParseJson(state.vehicle.make);
  const fuelType = tryParseJson(state.vehicle.fuelType);
  const dateFirstRegistered = tryParseJson(state.vehicle.dateFirstRegistered);
  const emCode = tryParseJson(state.vehicle.emCode);

  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("funnelId", state.funnelId);
    scope.setTag("vrm", vrm);
    scope.setTag("make", make);
    scope.setTag("fuelType", fuelType);
    scope.setTag("dateFirstRegistered", dateFirstRegistered);
    scope.setTag("emCode", emCode);

    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("CarwebVehicleNotQualified");
  });
}

function tryParseJson<T>(json: T): T | string {
  try {
    return JSON.parse(JSON.stringify(json));
  } catch (e) {
    return "";
  }
}
