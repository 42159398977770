<script>
//@ts-check
// eslint-disable-next-line no-unused-vars
import { Props } from "@/types";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import { themesComputed } from "@/state/helpers";
export default {
  name: "BlockButton",
  components: { BlockPadding },
  props: {
    /**@type {Props.Text} */
    text: {
      type: String,
      required: false,
      allowVariable: false,
      default: "Continue",
      description: "The text to display on the submit button",
    },
    /** @type {Props.Bool} */
    block: {
      type: Boolean,
      required: false,
      default: false,
      allowVariable: false,
    },
    /** @type {Props.Align} */
    align: {
      type: String,
      required: false,
      allowVariable: false,
      default: "center",
      valid: ["left", "center", "right"],
      description: "The alignment of text to display",
    },
    /** @type {Props.Bool} */
    showPrevious: {
      type: Boolean,
      required: false,
      allowVariable: false,
      default: false,
      description: "While true a previous button linking to the previous page is displayed",
    },
    /** @type {Props.Bool} */
    disabled: {
      type: Boolean,
      required: false,
      allowVariable: false,
      default: false,
      description: "While true the submit and previous buttons are disabled",
    },
    /** @type {Props.Bool} */
    isProcessing: {
      type: Boolean,
      required: false,
      allowVariable: false,
      default: false,
      description: "While true the submit button is in a loading state",
    },
    /** @type {Props.Bool} */
    isDisabled: {
      type: Boolean,
      required: false,
      allowVariable: false,
      default: false,
      description: "While true the submit button is in a disabled state",
    },
    /** @type {Props.Bool} */
    isPreviousProcessing: {
      type: Boolean,
      required: false,
      allowVariable: false,
      default: false,
      description: "While true the previous button is in a loading state",
    },
    /** @type {Props.Bool} */
    submitButtonDefault: {
      type: Boolean,
      required: false,
      default: true,
    },
    /** @type { Props.Str} */
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    },
    /** @type { Props.Str} */
    variant: {
      type: String,
      default: "primary",
      required: false,
    },
    condition: {
      type: Object,
      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },
  },
  computed: {
    ...themesComputed,

    hidePrevious() {
      const pageMap = {
        hi: "YZcGMy64aPSWY6qGFtp4",
        ar: "P8WdlLvJKAUYj8nzjuIy",
        ms: "gIcIK5s2P6du0VopdD2D",
        ml: "KAAyqihebV7eFlJfXZNv",
        legalPackIdentity: "YB7loT5T5PpjWImxD2fw",
      };

      const sgPageMap = {
        mainQuestionnaire: "CM2Pkhl2ZdWGBEVaRVdN",
        dropoutAcquistion: "xiDDY6CCQGwzl7QY6Eq7",
        dropoutCustomisation: "Rs39guLYXdQUNXtORhbN",
      };

      //@ts-ignore
      if (Object.values(pageMap).includes(this.$route.params.pageId)) {
        return true;
      }

      //@ts-ignore
      if (Object.values(sgPageMap).includes(this.$route.params.pageId)) {
        return true;
      }

      return false;
    },
  },
  data() {
    return {
      isRTL: false,
      localIsProcessing: false,
    };
  },
  watch: {
    isProcessing: {
      immediate: true,
      handler: function (x) {
        this.localIsProcessing = x;
      },
    },
    selected: {
      handler: function (x) {
        window.console.log("Change to a button!", x);
      },
    },
    language: {
      handler(newLanguage) {
        if (newLanguage === "ar") {
          this.isRTL = true;
        } else {
          this.isRTL = false;
        }
        console.log("newLang", newLanguage, this.isRTL);
      },
      immediate: true,
    },
  },
};
</script>
<style>
.button-selected,
.button-selected:active,
.button-selected:focus,
.button-selected:hover {
  box-shadow: 0px 0px 30px -10px #3452e1 !important;
}

.btn-gray,
.btn-gray:active,
.btn-gray:focus,
.btn-gray:hover {
  background-color: #dee2e6;
}
.hover-shift-left:hover {
  transition: all 0.4s;
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transform: translateX(-5px);
}
.hover-shift-left {
  transition: all 0.4s;
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0s;
}
</style>
<template>
  <div>
    <BlockPadding :padding="padding" />
    <b-row class="no-gutters">
      <b-col :class="'text-' + align">
        <template v-if="!showPrevious">
          <b-button
            :size="theme.size"
            :block="block"
            :squared="theme.squared"
            :class="{
              'text-white': !localIsProcessing,
              'text-primary': localIsProcessing,
              'border-thick': localIsProcessing,
            }"
            :disabled="localIsProcessing || isDisabled"
            :variant="variant"
            :style="theme.primaryStyles"
            @click="submitButtonDefault ? $emit('submit', $event) : $emit('clicked', true)"
            :ref="'b-button'"
            data-cy="button-submit-button"
          >
            <template v-if="localIsProcessing">
              <b-spinner class="spinner-border-sm m-1" role="status" data-cy="button-submit-spinner"></b-spinner>
            </template>
            <template v-else>
              {{ text }}
            </template>
          </b-button>
          <slot name="errors"></slot>
        </template>
        <template v-else>
          <div v-if="!hidePrevious">
            <h6
              class="mt-2 font-weight-light text-primary hover-shift-left font-size-15"
              :style="isPreviousProcessing ? 'cursor: not-allowed' : ''"
              :ref="'show-previous'"
            >
              <template v-if="isPreviousProcessing">
                <b-spinner class="spinner-border-sm m-1" role="status" data-cy="button-previous-spinner"></b-spinner>
              </template>

              <a v-else href="javascript: void(0);" @click="$emit('previous', $event)">
                <i
                  style="vertical-align: middle"
                  :class="'mt-0 font-size-15 ' + (isRTL ? 'ml-1' : 'mr-1') + ' fa-solid fa-chevron-left'"
                  data-cy="button-previous-button"
                ></i>
                <span style="vertical-align: middle">{{
                  $te("blocks.button.previous") ? $t("blocks.button.previous") : "Previous"
                }}</span>
              </a>
            </h6>
          </div>
        </template>
      </b-col>
    </b-row>
  </div>
</template>
