import { onStagingEnvironment } from "@/helpers/ClaimsGate/RedirectHelper";

export function calculatePerformAtDate(delay: number | string, useVariables: boolean): Date {
  const performAt = new Date();
  let messageDelay = delay ?? 1;

  // Staging Environment check
  if (onStagingEnvironment()) messageDelay = 0;

  // Sometimes messageDelay will be stored as string in DB
  if (typeof messageDelay === "string") messageDelay = parseInt(messageDelay);

  // Agreements will need some time to be generated
  if (useVariables) {
    performAt.setMinutes(performAt.getMinutes() + 5);
  }

  performAt.setDate(performAt.getDate() + messageDelay);

  return performAt;
}
