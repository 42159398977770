import Vue from "vue";
import { form } from "./helpers/form";
import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";

export function getData(state: Vue) {
  return {
    answers: [],
    title: "Questionnaire",
    subTitle: "Please complete the following information",
    isProcessing: false,
    currentEntityIndex: 0,
    currentSectionIndex: 0,
    uiToggles: {
      isProcessing: false,
      isSaveError: false,
    },
    form: form,
    entityValidationStates: [],
    //state: null,
    invalidFeedback: "",
    claimId: state.$route.params.claimId,
    userId: null,
    claimDataService: undefined as ClaimDataService,
  };
}
