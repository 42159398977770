import { getFirebaseBackend } from "@/authUtils";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";

import axios from "axios";
import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { ComputeUtils } from "./ComputeUtil";
import { WebhookUtility } from "../builder/modules/WebhookMixin";
import { BuilderUtility } from "@/helpers/ClaimsGate/builder/BuilderUtility";

/*eslint-disable*/

/**
 * ComputeList is a mixin class which maintains each of the available compute methods in Claims Gate
 */
class ComputeList {
  /**
   *
   * @param {*} pageId
   * @param {*} claimId
   * @param {*} context
   */
  async get(page, claimId, { url, params, headers, body, stores }) {
    return await this.doRequest("GET", page, claimId, { url, params, headers, body, stores });
  }

  /**
   *
   * @param {*} pageId
   * @param {*} claimId
   * @param {*} context
   */
  async post(pageId, claimId, { url, params, headers, body, stores }) {
    return await this.doRequest("POST", pageId, claimId, { url, params, headers, body, stores });
  }

  /**
   *
   * @param {*} pageId
   * @param {*} claimId
   * @param {*} context
   */
  async put(pageId, claimId, { url, params, headers, body, stores }) {
    return await this.doRequest("PUT", pageId, claimId, { url, params, headers, body, stores });
  }

  /**
   *
   * @param {*} pageId
   * @param {*} claimId
   * @param {*} context
   */
  async patch(pageId, claimId, { url, params, headers, body, stores }) {
    return await this.doRequest("PATCH", pageId, claimId, { url, params, headers, body, stores });
  }

  /**
   *
   * @param {*} pageId
   * @param {*} claimId
   * @param {*} context
   */
  async delete(pageId, claimId, { url, params, headers, body, stores }) {
    return await this.doRequest("DELETE", pageId, claimId, { url, params, headers, body, stores });
  }

  /**
   *
   * @param {*} requestType
   * @param {*} pageId
   * @param {*} claimId
   * @param {*} context
   * @returns
   */
  async doRequest(requestType, page, claimId, context) {
    try {
      window.console.log("Running do request!");
      const { url, params, headers, body, stores } = context;
      // combine params source objects

      // parse params
      let endpoint = url;

      if (Object.keys(params) && Object.keys(params).length > 0) {
        endpoint = endpoint + "?" + ComputeUtils.toQueryString(params);
      }

      window.console.log("endpoint is", endpoint);

      // Ensure endpoint inludes protocol.
      endpoint = BuilderUtility.parseUrl(endpoint);
      window.console.log("endpoint is..", endpoint);

      const request = {
        method: requestType,
        url: endpoint
      };

      // Parse body
      window.console.log("Body is: ", body);
      if (body && Object.keys(body).length > 0) {
        request.data = WebhookUtility.parseArgumentsToJson(body);
      }

      // Parse headers
      if (headers && Object.keys(headers).length > 0) {
        request.headers = WebhookUtility.parseArgumentsToJson(headers);
      }

      let response = undefined;

      try {
        response = await axios(request);
      } catch (error) {
        response = error.response;
      }

      if (!response) {
        // is it valid to consider if response is undefined ?
        // if so either throw exception or return { exception: exception }
        return { exception: "Invalid response" };
      }
      // flatten the response
      const flattenedResponse = ComputeUtils.flatten(response.data);
      window.console.table(flattenedResponse);

      // are we storing data from response
      if (stores) {
        await this.storeResponse(claimId, flattenedResponse, stores);
      }
      return { result: true };
    } catch (exception) {
      return { exception: exception };
    }
  }

  /**
   *
   * @param {*} claimId
   * @param {*} flattenedResponse
   * @param {*} stores
   */
  async storeResponse(claimId, flattenedResponse, stores) {
    const claimDataService = ClaimDataService.getInstance(claimId);
    await claimDataService.refresh();
    // Extract and store fields

    stores.map(async store => {
      // Get the name of flattened field from object shaped as
      // {field: 'prop1.depth2.depth3', as: 'VehicleColour}

      window.console.log("store is..", store);
      window.console.log("flattened response is...", flattenedResponse);
      const fieldName = store["field"];

      const storeAs = store.as;
      const { id, type } = storeAs;

      window.console.log("Type is: ", type);
      if (flattenedResponse[fieldName] !== undefined && flattenedResponse[fieldName] !== null) {
        try {
          if (type === "date") {
            window.console.log("Setting value as a date", flattenedResponse[fieldName], id);
            claimDataService.setArtefact(id, new Date(flattenedResponse[fieldName]));
          } else {
            claimDataService.setArtefact(id, JSON.parse(flattenedResponse[fieldName]));
          }
        } catch (e) {
          if (type !== "date") {
            claimDataService.setArtefact(id, flattenedResponse[fieldName]);
          }
        }
      }
    });

    return true;
  }

  /**
   * Locates the value for a given field in Claims Gate's blocks
   * @param {*} blocks
   * @param {*} field
   * @returns
   */
  findFieldInBlocks(blocks, field) {
    // Locate the value for the given field
    var fieldBlock = blocks.find(block => {
      window.console.log(block, field, block === field);
      return block.storeAs === field;
    });

    const { answer } = fieldBlock;
    return answer;
  }

  /**
   * Creates an instance of the claim on the user
   * @param {*} page
   * @param {*} claimId
   * @param {*} param2
   * @returns
   */
  async createClaim(page, claimId, { claimType }) {
    try {
      // Create a new claim on the requesting user's account
      const userHelper = getUserHelper();

      if (!claimType) {
        return { error: "No claim type passed" }; // Displays on UI
      }
      await userHelper.createClaim(claimType);

      // Return the identifier for the claim as a compute result
      return { result: claimType.toUpperCase() };
    } catch (exception) {
      return { exception: exception }; // Major problem we need to stop everything
    }
  }

  async createClaimV2(page, claimId) {
    const db = getFirebaseBackend().firestore();
    const userHelper = getUserHelper();

    const userRef = db.collection("users").doc(userHelper.getUserId());
    const claimRef = userRef.collection("claims").doc();

    await claimRef.set({ lastUpdated: new Date() });

    return { result: claimRef.id };
  }
}

export { ComputeList };
