<script lang="ts">
import Vue from "vue";
import { themesComputed } from "@/state/helpers";

export default Vue.extend({
  name: "BlockLabel",
  props: {
    label: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...themesComputed
  }
});
</script>
<template>
  <h5 v-if="label && label.length > 0" class="mb-0 text-left" :style="theme.textColourStyles">
    {{ label }}
  </h5>
</template>
