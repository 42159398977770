/*eslint-disable*/
import { Conditional } from "@claimsgate/core-types";
import { ConditionalList } from "./ConditionalList";

export class ConditionalProcessor {
  conditionService;

  constructor() {
    this.conditionService = ConditionalList.getInstance();
  }

  /**
   * Executes a conditional
   * @param {object} conditional - the conditional to execute
   */
  async execute(conditional: Conditional) {
    if (!conditional) {
      return undefined;
    }

    window.console.log("[conditionalExec] exeucting a conditional", conditional);
    // Group conditional is a collection of
    // multiple conditionals
    if (conditional.type === "group") {
      // Loop through conditionals in group and
      // execute each (should we store results of each ?)
      let groupResult = true;
      if (window.console) window.console.log("groupResult is", groupResult);
      for (const groupConditional of conditional.conditionals) {
        let { result } = await this.executeConditional(groupConditional);
        window.console.log("[conditionalExec] Executing a group conditional", groupConditional, result);
        if (!result) {
          groupResult = false;
        }
      }

      if (groupResult) {
        return { result: true, next: conditional.next, error: conditional.error };
      } else {
        return { result: false, error: conditional.error };
      }
    } else {
      // Else execute single conditional
      const { result } = await this.executeConditional(conditional);
      let out: { result: boolean; error?: any; exception?: any; next?: any } = { result: result };

      // If the conditional failed and has an error
      // return the error

      if (window.console) window.console.log("Conditional is:", conditional, "Out is..", out);
      if (result && conditional.error) {
        out.error = conditional.error;
      }

      // If the conditional passed and it has a next route
      // return the next route
      if (result && conditional.next) {
        out.next = conditional.next;
      }

      // Return out
      return out;
    }
  }
  /**
   * Executes a single conditional
   * @param {object} conditional - the conditional to execute
   * @returns  - The execution result
   *
   */
  async executeConditional(conditional: Conditional): Promise<{ result?: boolean; exception?: string; next?: any }> {
    try {
      let { type, value, values, actual, next } = conditional;
      if (values) {
        value = values as any;
      }
      let expected = value;

      // Ensure the conditional type exists
      if (!this.conditionService[type]) {
        return { result: false, exception: "Conditional not found" };
      }

      let result;
      let exception;

      if (Array.isArray(expected) && type !== "exists" && type !== "doesNotExist") {
        ({ result, exception } = this.executeExpectedArray(expected, type, actual));
      } else {
        ({ result, exception } = this.conditionService[type](expected, actual));
      }

      window.console.log("[conditionalExec]", conditional, result, exception);

      // Execute conditional

      // If condition passes then return the
      // next value associated with the conditional
      if (result) {
        return { result: result, next: next };
      } else {
        return { result: result, exception: exception };
      }
    } catch (exception) {
      window.console.error(exception);
      return { exception: exception };
    }
  }

  /**
   * Executes the evalutation for each item in the `expected` array
   * @param expected
   * @param type conditional type to be executed
   * @param  actual
   */
  executeExpectedArray(expected: Array<string>, type: any, actual: any) {
    const eachResults = [];
    const eachExceptions = [];
    window.console.log("[execExpected]", expected, type, actual);
    expected.forEach((value) => {
      window.console.log("[execExpected]", type, value, actual);
      let { result: eachResult, exception: eachException } = this.conditionService[type](value, actual);

      window.console.log("[execExpected]", eachResult, eachException);
      eachExceptions.push(eachException);
      eachResults.push(eachResult);
    });

    console.log("EACH RESULTS ARE ", eachResults);
    let result;
    let exception;
    // Look for one true result if contains or equals,
    // one false result if not contains/equal
    if (type === "contains" || type === "equals") {
      result = eachResults.some((result) => result);
      exception = eachExceptions.some((exception) => exception);
    } else {
      result = !eachResults.some((result) => !result);
      exception = !eachExceptions.some((exception) => !exception);
    }
    console.log("[conditionalExec]: Execute expected array result is: ", result, exception);
    return { result, exception };
  }
}

let _conditionalProcessor = null;
/**
 * Returns the compute processor singleton
 */
const getConditionalProcessor = () => {
  if (!_conditionalProcessor) {
    _conditionalProcessor = new ConditionalProcessor();
  }
  return _conditionalProcessor;
};

export { getConditionalProcessor };
