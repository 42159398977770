<script lang="ts">
import Vue from "vue";

import BlockIcon from "@/components/shared/blocks/iconBlock/icon.vue";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";

import { getProps } from "./props";

export default Vue.extend({
  name: "BlockIconGroup",
  components: {
    BlockIcon,
    BlockInputWrapper,
  },
  props: {
    ...getProps(),
  },
  data() {
    return {
      selectedIndex: null,
    };
  },

  methods: {
    selectIcon(index) {
      this.selectedIndex = index;
      this.$emit("update:answer", this.icons[index].iconName);

      console.log("selectIcon: ", this.icons[index]);

      this.$nextTick(() => {
        const selectedElement = this.$el.querySelector(".selected-icon");
        if (selectedElement) {
          selectedElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
        }
      });
    },
  },

  mounted() {
    if (this.answer) {
      this.selectedIndex = this.icons.findIndex((icon) => icon.iconName === this.answer);

      if (this.selectedIndex === -1) {
        this.selectedIndex = 0;
      }
      this.selectIcon(this.selectedIndex);
    } else {
      this.selectedIndex = 0;
      this.selectIcon(this.selectedIndex);
    }
  },
});
</script>

<style>
.custom-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.custom-scrollbar:hover::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scrollbar:hover::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #e0e0e0;
}
</style>

<template>
  <BlockInputWrapper
    v-bind="{
      info: info ? info : '',
      label: label ? label : '',
      padding,
    }"
  >
    <template>
      <div class="my-4 d-flex justify-content-center">
        <div class="d-flex flex-row gap-3 overflow-auto custom-scrollbar h-auto">
          <div
            v-for="(icon, index) in icons"
            :key="index"
            :class="['flex-shrink-0 text-center', { 'selected-icon': selectedIndex === index }]"
            style="width: 100px"
            @click="selectIcon(index)"
          >
            <BlockIcon :iconName="icon.iconName" :iconType="icon.iconType" :isSelected="selectedIndex === index" />
          </div>
        </div>
      </div>
    </template>
  </BlockInputWrapper>
</template>
