import { BlockPhoneInstance } from "./blockPhone";

export const methods = {
  handleAccountRecovered,
};

/** Handles the event triggered by the RecoverAccount component when the account has been successfully recovered */
function handleAccountRecovered(state: BlockPhoneInstance) {
  // Push to the same route with an added query parameter 'accountRecovered'
  // await state.$router.push({ query: { accountRecovered: "yes", ...state.$route.query } });
  window.location.reload();
}
