import { FormRoute, MutatorFunction, StandardAsyncResult } from "@/types";
import { Block, Container, Page, Variable } from "@claimsgate/core-types";
import { Dictionary } from "vue-router/types/router";
import { PageUtility } from "..";
import { UserBlockValidatorsService } from "../validations";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
import { ThemesObjectService } from "../../themes";
import { AsyncHelper } from "../../AsyncHelper";
import { ClaimsGateErrors } from "@claimsgate/core";

export class ParserService {
  variableService = new VariableService();

  /** Parses and hashes the given route's query parameters */
  async parseQueryParameters(route: FormRoute): Promise<StandardAsyncResult<object, ClaimsGateErrors.DataMissing>> {
    const fieldsToExclude = ["wref"];

    // Create a dictionary of the given query parameters
    const stores: Dictionary<string | string[]> = { currentFunnelId: route.params?.funnelId };

    for (const [key, value] of Object.entries(route.query)) {
      if (!fieldsToExclude.includes(key)) stores[key] = value;
    }

    // Hash the dictionary using the current funnel's variables
    const hashedStores: object = await this.variableService.hashData(stores);

    // If we were unable to hash any of the given variables then return an error
    if (Object.keys(hashedStores)?.length === 1) return AsyncHelper.onError(ClaimsGateErrors.DataMissing);

    return AsyncHelper.onCompleted(hashedStores);
  }

  /**
   * Loops through each of the blocks and attempts to replace
   * any instances of variable types ({type: string, field: string, id: string, group: string})
   * with the loaded in variable from Firestore
   *
   *
   * Examples:
   * {
   *  type: 'BlockText'
   *  value: '{type: string, field: 'VehicleColour', id: string, group: "claim"}
   * }
   * =>
   * {
   *  type: "BlockText",
   *  value: "Red"
   * }
   */
  prepareBlocks(page: Page, claimId: string) {
    const mutator: MutatorFunction<void> = (block: Block) => {
      // Reset block
      if (block.storeAs) {
        window.console.log("defining block.storeAs for", block);
        block.answer = "";
      }

      if (block.type === "BlockEmail" || block.type === "BlockPhone" || block.type === "BlockContactDetails") {
        window.vm.$delete(block, "answer");
        window.vm.$delete(block, "storeAs");
        window.vm.$delete(block, "required");
      }
      if (!block?.condition || Object.keys(block.condition?.actual ?? {})?.length === 0) {
        block.isVisible = true;
      } else {
        block.isVisible = null;
      }
      if (block.state !== undefined) {
        if (Object(block.state) !== block.state) {
          // State is a primitive boolean
          block.state = null;
        } else {
          // state is a key value object
          Object.keys(block.state).forEach((field) => {
            block.state[field] = null;
          });
        }
      }
      window.console.log("Block is now: ", block);

      for (const [key, value] of Object.entries(block)) {
        // If the value we are looking at is an array
        if (Array.isArray(value)) {
          // Loop over each element in the array
          value.forEach(async (element, elementIndex) => {
            // If the element in the array is an object, then we may
            // need to load in a variable from Firestore
            if (typeof element === "object" && element !== "storeAs") {
              // For each {k,v} in the array of objects
              for (const [arrObjectKey, arrObjectValue] of Object.entries(element)) {
                if (typeof arrObjectValue === "object" && arrObjectKey !== "storeAs") {
                  const { field, type, id, group } = arrObjectValue as Variable;
                  if (type && field && id) {
                    if (group === "user") {
                      block[key][elementIndex][arrObjectKey] = PageUtility.loadVariable(page, field, claimId);
                    } else {
                      block[key][elementIndex][arrObjectKey] = PageUtility.loadVariable(page, id, claimId);
                    }
                  }
                }
              }
            }
          });
          // If the value we are looking at is an object then
          // we may need to load in a variable from Firestore
        } else if (value && typeof value === "object") {
          const { type, field, id, group, property } = value;

          // If the value we are looking at is an instance of a variable
          // then try to load in the variable
          if (type && field && id) {
            // If the variable has been defined in the storeAd
            if (key === "storeAs") {
              let loadedVariable: string;

              // User variables are loaded in by their field names.
              // User blocks with a 'storeAs' property
              if (group === "user") {
                loadedVariable = this.loadUserBlockAnswer(page, claimId, block, value);
              } else {
                // If the loaded in variable is being loaded into a repeatable container,
                // then the variable needs to be placed in the first itertation
                if (block.isRepeatableChild) {
                  const iteration = 0;
                  loadedVariable = PageUtility.loadVariable(page, id, claimId, property.field, iteration);

                  // If the variable is a standard claim variable, we load it in by ID
                } else {
                  loadedVariable = PageUtility.loadVariable(page, id, claimId);
                }
              }

              // If we were successfully able to load in a variable, then we will set it
              // on the block's answer prop
              block.answer = loadedVariable ?? "";
            } else {
              // If the variable we need to load in is not part of the storeAs
              // prop then just load it in as normal
              if (group === "user") {
                block[key] = PageUtility.loadVariable(page, field, claimId);
              } else {
                block[key] = PageUtility.loadVariable(page, id, claimId);
              }
            }
          }
        }
      }
    };

    PageUtility.traverseBlocksSync(mutator, page);

    return true;
  }

  /**
   * Calculates the answer for a user block given its storeAs property
   * @returns {unknown} The loaded answer
   */
  private loadUserBlockAnswer(page: Page, claimId: string, block: Block, variabLe: Variable) {
    const { field } = variabLe;
    // If the block we are looking at is an instance of a user block
    // then we need to use a more advanced loading mechanism.
    const blockNamesToAnswerOverrides = {
      BlockEmail: (email: string) => {
        return { email: email };
      },
    };

    if (UserBlockValidatorsService.isUserBlock(block.type)) {
      // If the block requires a special conversion from the value in it's storeAs
      // property to it's value in its answer
      if (blockNamesToAnswerOverrides[block.type]) {
        // Return the answer in it's parsed format
        return blockNamesToAnswerOverrides[block.type](PageUtility.loadVariable(page, field, claimId));
      } else {
        // Otherwise just return the answer as a whole
        return PageUtility.loadVariable(page, field, claimId);
      }
    } else {
      return PageUtility.loadVariable(page, field, claimId);
    }
  }
}
