import { Props } from "@/types";
import { BlockClaimAddressProps } from "./BlockClaimAddress";
import { BlockClaimAddressStore } from "./types";

const BlockClaimAddressProps: Record<keyof BlockClaimAddressProps, any> = {
  padding: {
    type: String,
    required: false,
    default: "10",
    description: "The padding above the block",
  } as Props.CGPropExtras<string>,
  prefix: {
    type: String,
    required: false,
    default: "",
    description: "The prefix to display before the address",
  } as Props.CGPropExtras<string>,
  label: {
    type: String,
    required: false,
    default: "Address",
    description: "The label to display above the address",
  } as Props.CGPropExtras<string>,
  info: {
    type: String,
    required: false,
    default: "",
    description: "The info to display below the address",

    allowVariable: false,
  } as Props.CGPropExtras<string>,

  stores: {
    type: Array,
    required: false,
    default: (): Array<{ name: BlockClaimAddressStore; type: string }> => [
      { name: "AddressLine1", type: "string" },
      { name: "City", type: "string" },
      { name: "Postcode", type: "string" },
      { name: "Country", type: "string" },
    ],
    description: "The variables stored by this block",
    allowVariable: false,
  } as Props.CGPropExtras<Array<{ name: BlockClaimAddressStore; type: string }>>,

  required: {
    type: Boolean,
    required: false,
    default: false,
    description: "Whether the address is required",
  } as Props.CGPropExtras<boolean>,

  condition: {
    type: Object,
    required: false,
    default: () => {
      return {
        actual: {},
        values: [],
        value: "",
        type: "",
      };
    },
  },

  forceInternationalAddress: {
    type: Boolean,
    required: false,
    default: false,
    description: "Whether to force the international address",
  } as Props.CGPropExtras<boolean>,

  restrictCountry: {
    type: String,
    required: false,
    default: "",
    description: "The country to restrict the address to",
  } as Props.CGPropExtras<string>,
};

export function getProps() {
  return {
    ...BlockClaimAddressProps,
  };
}
