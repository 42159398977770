<script>
// eslint-disable-next-line no-unused-vars
import { Props } from "@/types";
import { themesComputed } from "@/state/helpers";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
/** @type { Props.BlockDropdown} */
const inputPropsValue = inputProps({
  answerType: "string",
  extraProps: ["options", "placeholder", "other"],
  placeholder: "Select an item",
});
export default {
  name: "BlockDropdown",
  components: { BlockInputWrapper },
  props: {
    ...inputPropsValue,
  },
  data() {
    return {
      selected: "",
      newOptions: undefined,
      newAnswer: this.answer,
      otherAnswer: undefined,
    };
  },
  methods: {
    handleInput(input) {
      this.newAnswer = input;
      if (this.newAnswer === "Other" && this.other) {
        if (this.otherAnswer && this.otherAnswer.length > 1) {
          this.$emit("update:answer", this.otherAnswer);
        } else {
          this.$emit("update:answer", null);
        }
      } else {
        this.$emit("update:answer", this.newAnswer);
      }
    },
    handleOtherAnswer() {
      window.console.log("new answer is:", this.otherAnswer);
      this.$emit("update:answer", this.otherAnswer);
    },
    getPlaceholder() {
      return this.$te("blocks.placeholder." + this.placeholder)
        ? this.$t("blocks.placeholder." + this.placeholder)
        : this.placeholder;
    },
  },
  mounted() {
    if (this.answer) {
      var selectionIndex = this.options.findIndex((option) => option === this.newAnswer);
      if (selectionIndex !== -1) {
        this.selected = this.options[selectionIndex];
      } else if (this.answer) {
        this.newAnswer = "Other";
        this.selected = "Other";
        this.otherAnswer = this.answer;
      }
    } else {
      this.$emit("update:answer", null);
      this.newAnswer = null;
    }
  },
  beforeMount() {
    // Work with local state versus prop state
    if (this.options) {
      this.newOptions = this.options;
    }
  },
  computed: {
    ...themesComputed,
  },
};
</script>
<style scoped>
.custom-select {
  /* Themes can't apply sizing with this class
  height: calc(1.5em + 2rem + 2px) !important;
  padding: 1rem 1.25rem !important;
  font-size: 1.015625rem !important;
*/
  border-width: 2px !important;
  box-shadow: none !important;
}
.custom-select:focus {
  border-color: var(--primary) !important;
  transition: 0.25s;
}
.form-control {
  border-radius: 0.25rem !important;
  border-width: 2px !important;
  border-radius: 5px;
  box-shadow: none !important;
}

.form-control:focus {
  border-color: var(--primary) !important;
  transition: 0.25s;
}
.disabled {
  background-color: #e9ecef !important;
}
</style>
<template>
  <div>
    <!--{{ options }}-->
    <BlockInputWrapper
      :labelClass="labelClass"
      :infoClass="infoClass"
      :info="info"
      :padding="padding"
      :label="label"
      :invalidFeedback="invalidFeedback"
      :state="state"
    >
      <b-input-group>
        <b-form-select
          :state="state"
          v-model="newAnswer"
          @change="handleInput($event), $emit('update:state', null)"
          :size="theme.size"
          :class="disabled ? 'disabled' : componentClass"
          :required="required"
          :disabled="disabled"
          :ref="'select'"
          :options="[{ text: getPlaceholder(), value: null, disabled: true }]"
          :data-cy="`dropdown-select-${label}`"
        >
          <slot name="optiontop" />
          <b-form-select-option v-for="(option, index) in newOptions" :key="index + 'DropdownOpt'" :value="option">
            {{
              $te("blocks.dropdown." + option.replace(/\.$/, ""))
                ? $t("blocks.dropdown." + option.replace(/\.$/, ""))
                : option
            }}
          </b-form-select-option>
          <slot name="optionbottom" />
          <b-form-select-option value="Other" v-if="other">Other</b-form-select-option>
        </b-form-select>
      </b-input-group>
      <b-input-group v-if="other && newAnswer === 'Other'" :ref="'other'" class="pt-1">
        <b-form-input
          id="input"
          type="text"
          placeholder="Your answer"
          :size="theme.size"
          :required="required"
          :state="state"
          :disabled="disabled"
          v-model="otherAnswer"
          @update="handleOtherAnswer"
          @change="$emit('update:state', null)"
          aria-describedby="input-live-feedback input-live-help"
          trim
          :class="disabled ? 'disabled' : ''"
          data-cy="dropdown-other-input"
        >
        </b-form-input>
      </b-input-group>
    </BlockInputWrapper>
  </div>
</template>
