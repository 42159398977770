import { DateService } from "@/helpers/ClaimsGate/DateService";
import { VueUtility } from "@/helpers/ClaimsGate/VueUtility";
import { conditionalList } from "./conditionals";

class ConditionalList {
  static instance;
  dateService = new DateService();
  constructor() {}

  /**
   * getInstance
   */
  static getInstance() {
    if (!ConditionalList.instance) {
      ConditionalList.instance = conditionalList;
    }
    return ConditionalList.instance;
  }

  /**
   * Compares an expected and actual to equal one another
   * @param {*} expected
   * @param {*} actual
   * @returns
   */
  typeEquality(expected, actual) {
    if (actual instanceof Date) {
      if (this.dateService.parseDate(expected) instanceof Date) {
        window.console.log("it is an instance of date");
        return false;
      } else {
        window.console.log("parsed date is: ", this.dateService.parseDate(expected));
        return true;
      }
    }
    if (typeof expected !== typeof actual) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checks if an expected and actual value are equal to one another, works for arrays of primitives, strings and numbers
   * TODO: Add type checking for files/objects/array of objects
   * @param {*} expected
   * @param {*} actual
   * @returns
   */
  equals(expected, actual) {
    window.console.log("[execExpected] test!");
    if (typeof actual === "boolean") {
      const parsedExpected = JSON.parse(expected);
      if (typeof parsedExpected === "boolean") {
        return { result: actual === parsedExpected };
      } else {
        return { result: false };
      }
    }

    if (this.typeEquality(expected, actual)) {
      return { result: false };
    }

    let result;

    if (Array.isArray(expected)) {
      // This does not work for array of objects
      return { result: JSON.stringify(expected) == JSON.stringify(actual) };
    }

    if (typeof expected === "number" || typeof expected === "string") {
      // Case insensitive checks for strings
      if (typeof expected === "string") {
        expected = expected.toLowerCase();
        actual = actual.toLowerCase();
      }
      return { result: expected === actual };
    }

    return { result };
  }

  /**
   * Checks if an actual does not equal an expected value
   * @param {*} expected
   * @param {*} actual
   * @returns
   */
  doesNotEqual(expected, actual) {
    const equalityResult = this.equals(expected, actual);

    if (equalityResult.exception) {
      return equalityResult;
    }
    const result = equalityResult.result ? false : true;

    return { result };
  }

  /**
   * Checks if an actual contains an expected value
   * @param {*} expected
   * @param {*} actual
   * @returns
   */
  contains(expected, actual) {
    window.console.log("[contains] Executing start", expected, actual);
    if (Array.isArray(actual) && typeof expected === "string") {
      window.console.log("[contains] Executing array check", expected, actual);
      const hasMatch =
        actual
          .map((element) => {
            return VueUtility.removeSpaces(element.toLowerCase()).includes(
              VueUtility.removeSpaces(expected.toLowerCase())
            );
          })
          .filter((d) => d).length > 0;

      if (hasMatch) {
        return { result: true };
      } else {
        return { result: false };
      }
    }
    if (this.typeEquality(expected, actual)) {
      window.console.log("Early breaking due to type equality");
      return { result: undefined, exception: "Unperformable action" };
    }

    if (Array.isArray(expected) || typeof expected === "string") {
      // Force case insensitive checks for string operations
      if (typeof expected === "string") {
        expected = expected.toLowerCase();
        actual = actual.toLowerCase();
      }

      return { result: actual.includes(expected) };
    }
    return { result: undefined, exception: "" };
  }

  /**
   * Checks if an actual does not contain expected
   * @param {*} expected
   * @param {*} actual
   * @returns
   */
  doesNotContain(expected, actual) {
    const containsResult = this.contains(expected, actual);
    if (containsResult.exception) {
      return containsResult;
    }
    const result = containsResult.result ? false : true;
    return { result };
  }

  /**
   *
   * @param expected
   * @param actual
   */
  exists(expected, actual) {
    const result = actual ? true : false;
    return { result };
  }

  greaterThan(expected, actual) {
    if (this.dateService.parseDate(expected) instanceof Date) {
      window.console.log("before is :", actual);
      const expectedDate = this.dateService.parseDate(expected);
      const actualDate = this.dateService.parseDate(actual);

      console.log("checking greater than date with actual, expected..", actualDate, expectedDate);
      return { result: actualDate > expectedDate };
    }

    if (this.typeEquality(expected, actual)) {
      return { result: false };
    }

    if (typeof expected === "number") {
      return { result: actual > expected };
    }

    return { result: undefined, exception: "" };
  }

  /**
   *
   * @param expected
   * @param actual
   */
  lessThan(expected, actual) {
    const lessThanResult = this.greaterThan(expected, actual);

    if (lessThanResult.exception) {
      return lessThanResult;
    }
    const result = lessThanResult.result ? false : true;
    return { result };
  }

  /**
   *
   * @param expected
   * @param actual
   */
  doesNotExist(expected, actual) {
    const existsResult = this.exists(expected, actual);

    window.console.log("exists result is...", existsResult);
    if (existsResult.exception) {
      return existsResult;
    }
    const result = existsResult.result ? false : true;
    return { result };
  }
}
export { ConditionalList };
