<script lang="ts">
import { ComputedProgressBarSection } from "../ComputedProgressBarSection";
import Vue, { PropOptions } from "vue";
export default Vue.extend({
  name: "ProgressElement",
  props: {
    section: {
      type: Object,
      required: true,
    } as PropOptions<ComputedProgressBarSection>,
    currentPageId: {
      type: String,
      required: true,
    },
    isLastSection: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  computed: {
    titleTextColour(): string {
      return this.isCurrentSection ? "text-primary-700" : "text-grey-700";
    },
    descriptionTextColour(): string {
      return this.isCurrentSection ? "text-primary-600" : "text-grey-600";
    },

    iconColour(): string {
      if (this.section.isComplete) {
        return "text-primary-50";
      } else if (this.isCurrentSection) {
        return "text-primary-50";
      } else {
        return "text-grey-50";
      }
    },
    iconInnerColour(): string {
      if (this.section.isComplete) {
        return "text-primary-600";
      } else if (this.isCurrentSection) {
        return "text-primary-600";
      } else {
        return "text-grey-200";
      }
    },
    textColor(): string {
      return this.isCurrentSection ? "text-primary-500" : "text-grey-700";
    },
    isCurrentSection(): boolean {
      return this.section.isCurrentSection;
    },

    isFirstPageOfSection(): boolean {
      return this.section.pages[0] === this.currentPageId;
    },
  },
});
</script>
<style>
.flex-fill {
  flex: 1 1 0px !important;
}

.z-1 {
  z-index: 3;
  position: relative;
  margin-top: 10px; /* Added to create spacing between the line and the icon */
}

.vertical-bar {
  position: absolute;
  width: 2px;
  bottom: -32px;
  left: 50%; /* center the bar */
  height: 34px;
  transform: translateX(-50%); /* center the bar */
}
</style>
<template>
  <div>
    <template v-if="mode === 'sections'">
      <div :class="!isLastSection ? 'mb-3' : 'mb-0'">
        <div class="d-flex flex-row align-items-start">
          <div class="d-flex flex-column align-items-center position-relative">
            <div class="d-block">
              <b-iconstack font-scale="3" :class="iconColour" shift-v="-1">
                <b-icon stacked icon="circle-fill" scale="0.75" />

                <b-icon
                  stacked
                  icon="circle-fill"
                  scale="0.25"
                  :class="iconInnerColour"
                  v-if="isCurrentSection"
                  animation="throb"
                />
                <b-icon stacked icon="circle-fill" scale="0.25" :class="iconInnerColour" v-if="!section.isComplete" />
                <b-icon stacked icon="check" scale="0.5" :class="iconInnerColour" v-if="section.isComplete" />
              </b-iconstack>
            </div>

            <!-- Vertical line -->
            <template v-if="!isLastSection && section.isComplete">
              <span class="vertical-bar bg-primary-600"></span>
            </template>
            <template v-else-if="!isLastSection && !section.isComplete">
              <span class="vertical-bar bg-grey-200"></span>
            </template>
          </div>

          <!-- Align in the middle of the icon -->
          <div class="ml-2 d-flex flex-column justify-content-center">
            <p :class="['text-sm', 'mb-0', 'mt-1', titleTextColour, 'font-weight-semibold']">{{ section.title }}</p>
            <p :class="['text-sm', 'mb-0', 'mt-0', descriptionTextColour]">{{ section.description }}</p>
          </div>
        </div>
      </div>
    </template>
    <template v-if="mode === 'pages'">
      <div :class="!isLastSection ? 'mb-3' : 'mb-0'">
        <div class="d-flex flex-row align-items-start">
          <div class="d-flex flex-column align-items-center position-relative">
            <div class="d-block">
              <b-iconstack font-scale="3" :class="iconColour" shift-v="-1">
                <b-icon stacked icon="circle-fill" scale="0.75" />

                <b-icon
                  stacked
                  icon="circle-fill"
                  scale="0.25"
                  :class="iconInnerColour"
                  v-if="isCurrentSection"
                  animation="throb"
                />
                <b-icon stacked icon="circle-fill" scale="0.25" :class="iconInnerColour" v-if="!section.isComplete" />
                <b-icon stacked icon="check" scale="0.5" :class="iconInnerColour" v-if="section.isComplete" />
              </b-iconstack>
            </div>

            <!-- Vertical line -->
            <template v-if="!isLastSection && section.isComplete">
              <span class="vertical-bar bg-primary-600"></span>
            </template>
            <template v-else-if="!isLastSection && !section.isComplete">
              <span class="vertical-bar bg-grey-200"></span>
            </template>
          </div>

          <div class="ml-2 d-flex flex-column justify-content-center align-items-center" style="margin-top: 5px">
            <p :class="['text-md', 'mb-0', 'mt-1', titleTextColour, 'font-weight-semibold']">{{ section.title }}</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
