// Vue
import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

import store from "@/state/store";
import routes from "./routes";

// Services
import { WorkspaceService, FunnelsService } from "@claimsgate/core";
import { UserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { getFirebaseBackend } from "@/authUtils.js";

// Functions
import { getClaimsGateDomain, onWorkspaceDomain } from "@/helpers/ClaimsGate/RedirectHelper";
import { fireIndexDomainRedirectEvent } from "./sentry";

Vue.use(VueRouter);
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: "meta",
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

let router;

router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Before each route evaluates...
let hasTriedToResolveCorrectDomain = false;
router.beforeEach(async (routeTo, routeFrom, next) => {
  let localStorageCurrentUser = null;

  try {
    localStorageCurrentUser = JSON.parse(localStorage.getItem("auth.currentUser"));
  } catch {
    // Do nothing
  }

  // ! Emulator specific code
  const authDomain = localStorageCurrentUser?.claims?.aud;
  if (authDomain !== undefined && authDomain !== process.env.VUE_APP_PROJECTId) {
    // If the authDomain in local storage doesn't match the one in process.env, redirect to logout
    console.error("authDomain in local storage doesn't match the one in process.env");
    return redirectToLogout();
  }

  if (!hasTriedToResolveCorrectDomain) {
    try {
      await resolveCorrectDomain(routeTo);
    } catch (e) {
      console.error("Error resolving correct domain in router.js", e);
    }

    hasTriedToResolveCorrectDomain = true;
  }
  // if path contains authToken - log the user out of their current account and log them in using provided token
  if (routeTo?.query?.authToken) {
    await store.dispatch("auth/customTokenLogin", { token: routeTo.query.authToken });

    // remove authToken from then URL
    delete routeTo.query.authToken;

    // remove nocache paramter if it exists
    if (routeTo.query.nocache) {
      delete routeTo.query.nocache;
    }

    // intentionally don't await not to block the thread
    store.dispatch("auth/generateAuthToken");

    // one next() call in order to remove authToken from the URL for security purposes
    return next(routeTo);
  }

  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  if (!authRequired) return next();

  if (!localStorageCurrentUser) {
    console.error("No user in local storage while trying to access a protected route in router.js");
    // ! Page requires authentication, user has no valid local storage, potentially damaged
    // ! so we force a logout
    return redirectToLogout();
  } else {
    if (!authDomain) {
      console.error("authDomain in local storage is undefined while trying to access a protected route???");
      // ! Page requires authentication, user has no valid local storage, potentially damaged
      // ! so we force a logout
      return redirectToLogout();
    }
  }

  // If auth is required and the user is logged in...
  if (store.getters["auth/loggedIn"]) {
    // Validate the local user token...

    let validUser = null;

    try {
      validUser = await store.dispatch("auth/validate");
    } catch (e) {
      console.error("Error validating user in router.js", e);
      return redirectToLogout();
    }

    // Then continue if the token still represents a valid user,
    // otherwise redirect to login.
    console.log("trying to validate token?");
    if (validUser) {
      if (validUser.claims === undefined) {
        // migration code!

        await store.dispatch("auth/refreshClaims");
      }

      const adminRequired = routeTo.meta.adminRequired;

      if (adminRequired === true) {
        window.console.log("adminRequired!");
        if (validUser.claims.admin === true) {
          return next();
        } else if (validUser.claims.admin === false) {
          return redirectToLogout();
        } else if (validUser.claims.admin === undefined) {
          return redirectToLogout();
        }
      } else {
        return next();
      }
    } else {
      return redirectToLogout();
    }
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  return redirectToLogout();

  async function redirectToLogout() {
    // ! We will need to appropriately clear local storage if the user's local storage is potentially damaged
    // ! Otherwise, the user will be stuck in a loop of being redirected to logout
    // ! Due to the `beforeEach` hook being called on every route change

    await store.dispatch("permissions/clearAllPermissionData");
    await store.dispatch("navigation/clearNavigationData");
    await store.dispatch("auth/logOut");
    return next({ name: "logout" });
  }
});

router.afterEach((to, from) => {
  try {
    window.console.log(to, from);
    if (window._tfa) {
      if (window._tfa.TUP) {
        var pageViewAccountIds = window._tfa.TUP.pageViewAccountIds;
        if (pageViewAccountIds && typeof pageViewAccountIds === "object") {
          Object.keys(pageViewAccountIds).forEach((pageViewAccountId) => {
            window._tfa.push({ notify: "event", name: "view_content", id: pageViewAccountId });
          });
        }
      }
    }
  } catch (e) {
    window.console.log(e);
  }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  // If we reach this point, continue resolving the route.
  return next();
});

/** Checks if we are on the correct domain and if not redirects us to it */
async function resolveCorrectDomain(routeTo) {
  // we don not want domain to be changed on production preview channel
  if (isRunningOnPreviewChannelOrLocalHost()) {
    return;
  }

  // if we are on claimsgate domain, we don't need to redirect
  if (window.location.host === getClaimsGateDomain()) {
    return;
  }

  // fetch workspaceId from subdomain
  const workspaceService = new WorkspaceService(getFirebaseBackend().firebase());
  let { data: workspaceId } = await workspaceService.getWorkspaceIdFromSubdomain(window.location.host);

  // if we can't find the workspaceId, we can't do any of the following checks
  if (!workspaceId) {
    return;
  }

  // construct correct path
  const correctDomain = await getCorrectDomain(workspaceService, workspaceId);
  let correctPath = getCorrectPath(routeTo, workspaceId);
  correctPath = await validatePath(correctPath, routeTo, workspaceId);

  // redirect if needed
  if (routeTo.fullPath !== correctPath || window.location.host !== correctDomain) {
    const redirectURL = `https://${correctDomain}${correctPath}`;
    fireIndexDomainRedirectEvent(window.location.href, redirectURL);
    console.log(">>> router.js - redirecting to: ", redirectURL, " from: ", window.location.href);
    window.location.href = redirectURL;
  }

  return;
}

/** Evaluate whether the requested domain is the primarySubdomain specified */
async function getCorrectDomain(workspaceService, workspaceId) {
  const { data: workspaceSubdomain } = await workspaceService.getPrimaryWorkspaceDomain(workspaceId);

  if (workspaceSubdomain && window.location.host !== workspaceSubdomain) {
    return `${workspaceSubdomain}`;
  } else {
    return `${window.location.host}`;
  }
}

/** Evaluate if the page requested is a custom domain page, and if not change it to WorkspaceClaims  */
function getCorrectPath(routeTo, workspaceId) {
  if (!onWorkspaceDomain(routeTo)) {
    if (routeTo.name === "login") {
      return `/login/${workspaceId}`;
    }

    return `/claims/${workspaceId}`;
  } else {
    return `${routeTo.fullPath}`;
  }
}

/** Evaluate whether the requested page is relevant to the workspace
We can have workspaceId/claimId/funnelId in the URL params which can be used for validation */
async function validatePath(currentPath, routeTo, workspaceId) {
  if (routeTo.params.workspaceId && workspaceId !== routeTo.params.workspaceId) {
    return `/claims/${workspaceId}`;
  } else if (routeTo.params.claimId) {
    const userService = new UserHelper();
    const claimData = await userService.getClaimData(routeTo.params.claimId);

    if (claimData && workspaceId !== claimData?.workspacesWithClaimDataAccess?.[0]) {
      return `/claims/${workspaceId}`;
    }
  } else if (routeTo.params.funnelId) {
    const funnelsService = new FunnelsService(getFirebaseBackend().firestore());
    const { data: funnelAuthor } = await funnelsService.getFunnelAuthor(routeTo.params.funnelId);

    if (funnelAuthor && workspaceId !== funnelAuthor) {
      return `/claims/${workspaceId}`;
    }
  }

  return currentPath;
}

function isRunningOnPreviewChannelOrLocalHost() {
  return window.location.host.includes("claimsgate-4cda5--preview") || window.location.host === "localhost";
}

export default router;
