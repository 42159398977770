import { AbstractUserBlockMethods } from "@/types/vue/AbstractUserBlockMethods";
//import { Form } from "./state";
import { FormState } from "./BlockFormInstance";
import { UserBlocks } from "./state";

export async function runUserValidations(state: FormState) {
  try {
    if (state.page.isAutoSubmitPage) {
      return true;
    }
    const blockRefs = state.blockRefs;

    for (const block of blockRefs) {
      const userBlockInstances: Partial<Record<UserBlocks, AbstractUserBlockMethods>> = {};

      const userBlocks: Array<UserBlocks> = [
        "BlockContactDetails",
        "BlockDateOfBirth",
        "BlockCompany",
        "BlockClaimAddress",
      ];

      // Look for a user block ref within the block render ref
      const blockKey = userBlocks.find((blockKey) => {
        return Object.keys(block.$refs).includes(blockKey);
      });

      // Add the user block ref to the record to be processed
      if (block && blockKey) {
        userBlockInstances[blockKey] = block.$refs[blockKey];
      }

      const { BlockContactDetails, BlockDateOfBirth, BlockCompany, BlockClaimAddress, BlockManualIdUpload } =
        userBlockInstances;

      if (BlockContactDetails) {
        if (!(await BlockContactDetails?.validations())) return false;
        if (!(await BlockContactDetails?.computes())) return false;
      }

      if (BlockDateOfBirth) {
        if (!(await BlockDateOfBirth?.validations())) return false;
      }

      if (BlockCompany && state.pageService.isCompanyBlockVisible(state.page)) {
        if (!(await BlockCompany?.validations())) return false;
        if (!(await BlockCompany?.computes())) return false;
      }

      if (BlockClaimAddress) {
        if (!(await BlockClaimAddress?.validations())) return false;
        if (!(await BlockClaimAddress?.computes())) return false;
      }
    }

    return true;
  } catch (exception) {
    console.error("runUserValidations threw", exception);
    return false;
  }
}
