import { eventComputed, formComputed, navigationComputed, themesComputed } from "@/state/helpers";
import { AddressInstance } from "./AddressInstance";
import {
  userIsAddressVerified,
  userIsElectoralRollVerified,
  userIsIdVerified,
} from "@/helpers/ClaimsGate/verification";
import { mapGetters } from "vuex";

export function mappedComputed() {
  return {
    ...(eventComputed as Record<keyof typeof eventComputed, any>),
    ...(formComputed as Record<keyof typeof formComputed, any>),
    ...(themesComputed as Record<keyof typeof themesComputed, any>),
    ...(navigationComputed as Record<keyof typeof navigationComputed, any>),
    ...mapGetters({
      navigationAllowWorkspaceNameChanges: "navigation/getWorkspaceAllowNameChanges",
      navigationAllowAddressChanges: "navigation/getWorkspaceAllowAddressChanges",
    }),
  };
}
export const computed = {
  displayOtherInput,
  themeSize,
  titleAnswer,
  otherAnswer,
  dateOfBirthAnswer,
  userIsVerified,
  loaderState,
};

/**
 * Force display of other input. Used if on mount or after save
 * @this { { allowOtherTitle : boolean, BlockInputs : {title : {answer : string}}  } }
 */
function displayOtherInput(state: AddressInstance): boolean {
  const titles = ["Mr", "Mrs", "Ms", "Miss", "Mx", "Dr", "Professor", "Prefer not to say"];
  if (
    state.allowOtherTitle &&
    !titles.includes(state.BlockInputs.title.answer) &&
    state.BlockInputs.title.answer?.length > 0
  ) {
    return true;
  } else {
    return false;
  }
}

function loaderState(state: AddressInstance): boolean {
  // If the form is processing but we are not return true
  if (state.loaders.next && !state.BlockInputs.nextBlockButton.isProcessing) {
    return true;
  }

  // If we are processing but the form is not return true
  if (state.BlockInputs.nextBlockButton.isProcessing && !state.loaders.next) {
    return true;
  }

  // If we are processing and the form is processing return true
  if (state.BlockInputs.nextBlockButton.isProcessing && state.loaders.next) {
    return true;
  }

  return false;
}

function themeSize(state: AddressInstance): string {
  return state.theme.size;
}

function titleAnswer(state: AddressInstance): string {
  return state.BlockInputs.title.answer;
}
function otherAnswer(state: AddressInstance): string {
  return state.BlockInputs.other.answer;
}
function dateOfBirthAnswer(state: AddressInstance): any {
  return state.BlockInputs.dateOfBirth.answer;
}

function userIsVerified(state: AddressInstance): boolean {
  return (
    userIsElectoralRollVerified(state.userData) ||
    (userIsIdVerified(state.userData) && userIsAddressVerified(state.userData))
  );
}
