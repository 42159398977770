export default {
  me: "عني",
  myClaims: "المطالبات الخاصة بي",
  menu: {
    myDetails: "التفاصيل الخاصة بي",
    settings: "الإعدادات",
    legal: "قانوني",
    logOut: "تسجيل الخروج",
    logIn: "تسجيل الدخول",
  },
};
