import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { PageHelper } from "@/helpers/ClaimsGate/funnels/PageHelper";
import { msg } from "@/helpers/ClaimsGate/ResponseMessageService";

import { flattenPageBlocks, getNextPage, getPageById, prepareNextPage } from "./mounted";
import { FormState } from "./BlockFormInstance";
import { runUserValidations } from "./runUserValidations";
import { buildFunnelParamSlug } from "@/helpers/ClaimsGate/utils/buildFunnelParamSlug";

import {
  createPushIdentityDocumentsToMilbergSalesforceTask,
  createPushToMilbergSalesforceTask,
  englandWalesRejectionConditional,
  pageContainsContactDetailsBlock,
  quantumContactDetailsConditional,
  stopProcessing,
  tlwComputeRejection,
  tlwDelayedPixelInitalisation,
  idTechComputeRejection,
} from "./methods";
import {
  getClaim,
  getClaimsByFunnelId,
  getFunnelMetaData,
  getFunnelVariables,
  Milberg,
  Quantum,
  SlaterGordon,
  Tlw,
} from "@claimsgate/core";
import { Awaited } from "@/types/utils";
import { complete, view } from "@/helpers/vue";
import { Claim, Event, Page, State, Workflow } from "@claimsgate/core-types";
import { handlePageMessages, handleReminderSequences } from "./messages";
import { runBlockTValidations } from "./runBlockTValidations";
import { handleFunnelSpecificLogicOnSubmit } from "./misc/handleFunnelSpecificLogicOnSubmit";
import { getFirebaseBackend } from "@/authUtils";
import { isNil, omitBy } from "lodash";

type NextPromises = [ReturnType<typeof calculateNext>, ReturnType<typeof runPageValidations>];

type AwaitedNextPromises = [CalculateNext: Awaited<NextPromises[0]>, RunPageValidations: Awaited<NextPromises[1]>];

export const JONES_WHYTE_FUNNELS = [
  Quantum.Funnels.JONES_WHYTE_DIESEL_EMISSIONS_ID,
  Quantum.Funnels.JONES_WHYTE_MERCEDES_EMISSIONS_ID,
  "aatXtgkFqt0POA026og1",
];

export async function next(state: FormState) {
  try {
    const methodStart = performance.now();
    await startProcessingNext(state);

    //if (!(await handleRepeatablePage(state))) return false;
    const validations = await Promise.all([handleStandardPage(state), runBlockTValidations(state)]);
    if (!validations[0] || !validations[1]) return false;
    if (!(await runUserValidations(state))) return false;
    const promises: NextPromises = [calculateNext(state), runPageValidations(state)];
    const resolvedPromises: AwaitedNextPromises = await Promise.all(promises);
    const { next: _next } = resolvedPromises[0];
    if (!(await handleCalculateNextPageError(state, resolvedPromises))) return false;

    // Runs page validations
    if (!handlePageValidations(state, resolvedPromises)) {
      return false;
    }

    createTrail(state, _next);
    storeUserAndClaimId(state, _next);

    await tryAuthoriseWorkspaceAccessToUserData(state);
    await handlePageMessages(state);
    await handleReminderSequences(state);
    await handlePageEvents(state);
    await handleSgUkStatementOfTruth(state);

    let nextPage = await calculateNextPage(state, _next);

    // If the user has been assigned a workflow, we will handle the workflow assignment
    nextPage = await handleWorkflowAssignment(state, nextPage);

    // If the user is completing a workflow, we will handle the workflow completion event
    nextPage = await handleWorkflowCompleted(state, nextPage);

    const funnelSpecificLogicResult = await handleFunnelSpecificLogicOnSubmit(state);

    if (funnelSpecificLogicResult.cancelSubmit) {
      return false;
    }

    // Try to transfer the claim to Jones Whyte
    const _nextPage = await tryTransferJonesWhyteClaim(state);

    if (_nextPage) {
      nextPage = _nextPage;
      console.log(">>> Jones Whyte claim transfered", _nextPage);
    }

    await tryCopyClaimToWorkspace(state);

    // If the current page is a review page then we mark the claim as being in review
    // and ignore the next page
    if (state.page?.isReviewPage) {
      state.claimDataService.setArtefact("clientClaimProgress", "review");
      return await handleNoNextPage(state, methodStart);
    }

    if (nextPage) {
      await handleNextPage(state, nextPage);
    } else {
      return await handleNoNextPage(state, methodStart);
    }

    const methodEnd = performance.now();
    window.console.warn(`calculateNext ${methodEnd - methodStart} milliseconds`);
    stopProcessing(state);

    if (state.page.isAutoSubmitPage) {
      // Adding in a delay to allow the page to render before the next page is calculated
      await state.$nextTick();
      await next(state);
    }

    return true;
  } catch (exception) {
    window.console.error("[Form.vue next()]", exception);

    await state.infoModalService.fire(
      "error",
      {
        title: msg.errors.unexpectedError.title,
        text: msg.errors.unexpectedError.text,
        confirmButtonText: "Redirect",
      },
      { route: state.$route }
    );

    // If we can deem the claim identifier to be valid tehn we will redirect the user
    // to the track page
    const { data: isValidClaimId } = await state.userService.validateClaimExists(state.userId, state.claimId);
    if (isValidClaimId) {
      await state.$router.push({ name: "Track", params: { claimId: state.claimId } });
    }
    // If the claim identifier is not vlaid then we will redirect the user
    // to the claims page
    await state.$router.push({ name: "Claims" });

    return false;
  }
}

/** Creates the trail for the claim */
export function createTrail(state: FormState, next: any) {
  if (next && state.claimId) {
    let trail = state.claimDataService.getArtefact("trail");

    const timestamp = new Date();
    // Update the breadcrumb array if this page does not exist in the bread crumb array
    if (trail && !trail.find((previous) => previous.pageId === state.page.id)) {
      // If the page is not already in the trail, lets add it
      trail.push({
        pageId: state.page.id,
        funnelId: state.funnelId,
        date: timestamp,
      });
      state.claimDataService.setArtefact("trail", trail);
    }

    // Set the breadcrumb array
    if (!trail) {
      trail = [{ pageId: state.page.id, funnelId: state.funnelId, date: timestamp }];
      window.console.log("Setting the trail to: ", trail);
      state.claimDataService.setArtefact("trail", trail);
    }
  }
}
async function calculateNextPage(state: FormState, next: any) {
  let nextPage;

  // If there is a next route
  const noneVal = "None"; // Value which states the route should be ignored

  if (next && next.pageId !== noneVal) {
    let pageId;
    let funnelId;
    ({ pageId, funnelId } = next);

    // If the next route is routing the user to a new funnel, find the page we need to route the user to
    if (funnelId && !pageId) {
      const { data: firstRoute } = await state.funnelsService.getFirstPage(funnelId);

      pageId = firstRoute.pageId;
    }

    // If the funnel has not been provided, the next page is on the same funnel
    if (pageId && !funnelId) {
      funnelId = state.funnelId;
    }

    // ! this old code didn't make much sense, replaced it with code below when adding back button feature
    // If the next route is a page route
    //const breadcrumbs = state.router.getBreadCrumbs();
    //if (!breadcrumbs.find((previous) => previous.pageId === pageId)) {
    //  console.log(">>> Accepting new route", pageId, funnelId, breadcrumbs);
    //  state.router.acceptNewRoute({ pageId: pageId, funnelId: funnelId });
    //  nextPage = await getNextPage(state);
    //} else {
    //  nextPage = await getPageById(state, pageId, funnelId);
    //}

    state.router.acceptNewRoute({ pageId: pageId, funnelId: funnelId });
    nextPage = await getNextPage(state);

    if (!nextPage) {
      nextPage = await getPageById(state, pageId, funnelId);
    }
  }
  return nextPage;
}

/** Handles the logic when a next page has been found */
export async function handleNextPage(state: FormState, nextPage: Page) {
  const pageFlow = state.claimDataService.getArtefact("pageFlow");

  const timestamp = new Date();
  // Update the breadcrumb array if this page does not exist in the bread crumb array
  if (pageFlow && !pageFlow.find((previous) => previous.pageId === nextPage.id)) {
    // If the page is not already in the pageFlow, lets add it
    pageFlow.push({
      pageId: nextPage.id,
      funnelId: nextPage.funnelId,
      date: timestamp,
    });
    state.claimDataService.setArtefact("pageFlow", pageFlow);
    //window.console.log("pageFlow", pageFlow);
  }

  // Set the breadcrumb array
  if (!pageFlow) {
    const pageFlow = [
      { pageId: state.page.id, funnelId: state.funnelId, date: timestamp },
      { pageId: nextPage.id, funnelId: nextPage.funnelId, date: timestamp },
    ];
    state.claimDataService.setArtefact("pageFlow", pageFlow);
    //window.console.log("pageFlow", pageFlow);
  }

  // If the next page is a rejection page we mark the claim as rejected and remove them from the reminder sequence
  if (nextPage.isRejectionPage) {
    state.claimDataService.setArtefact("clientClaimProgress", "rejected");
  }

  // Prepare next page
  window.console.log("[nextPage] before", state.claimDataService.getCache());
  await prepareNextPage(state, nextPage);
  window.console.log("[nextPage] after", state.claimDataService.getCache());

  // Update the virtual page url for refresh support
  if (state.claimId) {
    // eslint-disable-next-line prefer-const
    let { pageId, funnelId } = state.router.getNextRoute();
    complete(state);
    state.claimDataService.setArtefact("currentPageId", pageId);

    console.log(">>> setting currentFunelId to", funnelId, state.funnelId);
    state.claimDataService.setArtefact("currentFunnelId", funnelId);

    if (state.funnelId !== funnelId) {
      // If we are transferring a Jones Whyte claim between funnels
      if (JONES_WHYTE_FUNNELS.includes(state.funnelId)) {
        funnelId = state.funnelId;
        state.claimDataService.setArtefact("currentFunnelId", funnelId);
      }

      state.funnelId = funnelId;
    }

    await state.userDataService.update();
    await state.claimDataService.update();

    state.pageId = pageId;

    // If the page was originally accessed with a slug, we want to keep it in the path param for the funnelId

    funnelId = buildFunnelParamSlug(funnelId, state.funnelSlug);
    console.log("pushing to router", { claimId: state.claimId, pageId: pageId, funnelId: funnelId });
    state.$router.push({
      name: "form",
      params: { claimId: state.claimId, pageId: pageId, funnelId: funnelId },
      query: state.$route.query.testClaim === null ? { testClaim: null } : {},
    });
  } else {
    // eslint-disable-next-line prefer-const
    let { pageId, funnelId } = state.router.getNextRoute();
    // If the page was originally accessed with a slug, we want to keep it in the path param for the funnelId
    funnelId = buildFunnelParamSlug(funnelId, state.funnelSlug);

    state.$router.push({
      name: "form",
      params: { pageId: pageId, funnelId: funnelId },
      query: state.$route.query.testClaim === null ? { testClaim: null } : {},
    });

    state.pageId = pageId;
  }

  state.page = flattenPageBlocks(nextPage);

  view(state);
}

/** Handles event when the user has been assigned a workflow */
async function handleWorkflowAssignment(state: FormState, nextPage: Page | undefined): Promise<Page | undefined> {
  // ! Unfortunately, we will need to perform a database read on the claim
  // ! Should a user be assigned a workflow whilst completing the funnel, we will ensure the workflow is handled

  const [claim] = await getClaim(state.db, state.userId, state.claimId);
  const workflow = claim.workflow as Workflow;

  console.log(">>> handleWorkflow", workflow, nextPage);

  // If there is no workflow or it's not pending, just set the previousPageId if nextPage is undefined
  if (!workflow || workflow.status !== "pending") {
    return nextPage; // No workflow to handle, return nextPage as is
  }

  // Begin the workflow
  workflow.status = "inProgress";
  workflow.dateStarted = new Date();

  state.claimDataService.setArtefact("workflow", workflow);

  if (!nextPage) {
    // If the user does not have a next page, we will set the previous page to the current page
    // which was already completed.s
    state.claimDataService.setArtefact("previousPageId", state.page.id);
  } else {
    state.claimDataService.setArtefact("previousPageId", nextPage.id);
  }

  state.router.acceptNewRoute({ pageId: workflow.startPageId, funnelId: state.funnelId });

  nextPage = await getNextPage(state);

  console.log(">>> handleWorkflow", workflow, nextPage);

  return nextPage;
}

/** Handles event when the user has completed a workflow */
async function handleWorkflowCompleted(state: FormState, nextPage: Page | undefined): Promise<Page | undefined> {
  const workflow = state.claimDataService.getArtefact("workflow") as Workflow;

  // If there is no workflow or it's not completed, just return nextPage as is
  if (!workflow || workflow.status !== "inProgress") {
    return nextPage; // No workflow to handle, return nextPage as is
  }

  // If the user has a workflow assigned to them which is completed and nextPage is undefined
  if (!nextPage) {
    // If the user does not have a next page, we will set the nextPage to the previous page
    const previousPageId = state.claimDataService.getArtefact("previousPageId");
    if (previousPageId) {
      state.router.acceptNewRoute({ pageId: previousPageId, funnelId: state.funnelId });
      nextPage = await getNextPage(state);
    }

    // We also want to set the workflow status to complete
    workflow.status = "complete";
    workflow.dateCompleted = new Date();

    state.claimDataService.setArtefact("workflow", workflow);
  }

  console.log(">>> handleWorkflowCompleted", workflow, nextPage);

  return nextPage;
}

/** Handles the logic when the funnel has been completed becuase there are no next pages to complete */
async function handleNoNextPage(state: FormState, methodStart: number) {
  state.claimDataService.setArtefact("currentPageId", state.page.id);
  state.claimDataService.setArtefact("currentFunnelId", state.funnelId);

  // If the next page doesn't exist AND this page is not a rejection page we mark the claim as complete
  if (!state.page.isRejectionPage) {
    const claimStatus = state.page.isReviewPage ? "review" : "completed";
    state.claimDataService.setArtefact("clientClaimProgress", claimStatus);
  }

  complete(state);

  await state.claimDataService.update();
  await state.userDataService.update();

  // The user has completed all of the required steps!
  // We will now redirect them to view how their stuff was done..
  state.$router.push({ name: "Track", params: { claimId: state.claimId } });

  return false;
}

/** Generates a univerally unique claim identifier */
export function createClaimId(state: FormState): string {
  return state.userHelper.createClaimId();
}

/** Returns a boolean indicating if the container answers are valid, or, if refresh is set to false & all answers are empty, will return true, else false*/
async function nextRepeatablePage(state: FormState, refresh: boolean = true) {
  const loaderName = "nextRepeatablePage";
  state.$store.dispatch("form/setLoader", { name: loaderName, value: true });

  /*
   * * Ensure page is set up for repeatable
   */
  if (!state.claimId) {
    state.claimId = createClaimId(state);
  }

  if (!state.claimDataService) {
    state.claimDataService = new ClaimDataService(state.userId, state.claimId);
    state.claimDataService.setArtefact("claimId", state.claimId);
  }

  if (state.page.currentIteration === undefined) {
    state.page.currentIteration = 0;
  }

  const result = await state.repeatableService.nextPage(state.page, state.claimId, state.$store, state, refresh);

  state.page.totalIterations = getPageTotalIterations(state);

  return result;
}

export function getPageTotalIterations(state: FormState): number {
  const repeatableStoreAs = state.claimDataService?.getArtefact(state.page.repeatableStoreAs.value.id) ?? [];
  console.log("returning total iterations", repeatableStoreAs?.length, repeatableStoreAs);
  return repeatableStoreAs?.length;
}

export async function calculateNext(
  state: FormState
): Promise<{ next?: { pageId: string }; result?: boolean; error?: any; exception?: any }> {
  // ! Execut logic specific to TLW Diesel Emissions
  const tlwFunnels: string[] = [Tlw.Funnels.DIESEL_EMISSIONS_FUNNEL_ID];

  if (tlwFunnels.includes(state.funnelId)) {
    const isIdTechRejected = await idTechComputeRejection(state.page, state.funnelId, state);

    const isTlwRejected = await tlwComputeRejection(state.page, state.funnelId, state);

    const tlwPages = {
      contactDetailsPageId: "tXjC5RNSBn6jejRUfcz6",
      qualificationQuestionsPageId: "uvRi56ajYHL8NaoRtjbK",
      rejectionPageId: "tjMjsCOuqADCCRPvkXwM",
    };

    // ! The logic for validation in relation to ID Tech is only shared on the Qualification Questions and Contact Details pages
    if ([tlwPages.contactDetailsPageId, tlwPages.qualificationQuestionsPageId].includes(state.page.id)) {
      if (isIdTechRejected && isTlwRejected) {
        return { result: true, next: { pageId: tlwPages.rejectionPageId } };
      }
    } else {
      if (isTlwRejected) {
        return { result: true, next: { pageId: tlwPages.rejectionPageId } };
      }
    }
  }

  // Check if there are conditionals for evaluation
  const { conditionals, conditionalsEnabled } = JSON.parse(JSON.stringify(state.page));

  console.log(">>", conditionalsEnabled, conditionals);
  if (conditionals?.length > 0 && conditionalsEnabled) {
    for (const conditional of conditionals) {
      let { actual } = conditional;
      const { type } = conditional;

      if (type === "group") {
        await Promise.all(
          conditional.conditionals.map(async (groupConditional, groupConditionalIndex) => {
            const { actual } = groupConditional;
            const { id } = actual;

            conditional.conditionals[groupConditionalIndex].actual = await PageHelper.loadVariable(
              state.page,
              id,
              state.claimId
            );
            return true;
          })
        );
      }

      const { id } = actual;
      actual = await PageHelper.loadVariable(state.page, id, state.claimId);

      const { result, next, exception, error } = await state.conditionalProcessor.execute({
        ...conditional,
        actual,
      });

      window.console.log("Here is the results of my conditional:", result, next, error, exception);

      if (error) {
        return { error: error };
      }
      // If an unhandable exception has occured, we need to stop processing immediately
      if (exception) {
        return { exception: exception };
      }
      // If a next route has been returned from a conditional, return it
      if (next) {
        window.console.log("Conditional passed and returning the next route", next);
        return { result: true, next: next };
      }
    }
  }

  tlwDelayedPixelInitalisation(state);

  // Run a bespoke conditional statement if we are on a Quantum funnel
  const quantumNext = await quantumContactDetailsConditional(state.page, state.funnelId, state.claimDataService);

  if (quantumNext) {
    return { result: true, next: quantumNext };
  }

  const notEnglandOrWalesRejection = await englandWalesRejectionConditional(
    state.page,
    state.funnelId,
    state.claimDataService
  );

  if (notEnglandOrWalesRejection) {
    return { result: true, next: notEnglandOrWalesRejection };
  }

  const { next } = state.page;
  if (next) {
    return { result: true, next };
  }

  // If we still have not broken out - there was no next page
  return { result: true };
}

export async function runPageValidations(state: FormState) {
  if (state.page.validations && state.page.validationsEnabled) {
    const t5 = performance.now();
    const { error: errors, exception: exceptions } = await state.pageValidationService.runValidations(
      state.page,
      state.claimId
    );
    const t6 = performance.now();

    window.console.log("[debug] Page validation service returned: ", errors);
    window.console.warn(`runValidations ${t6 - t5} milliseconds`);
    console.log("Errors found by runValidations", errors);
    if (errors || exceptions) {
      state.isError = true;
      await Promise.all(
        errors.map(async (error) => {
          return state.pageService.setPageError(state.page, error, state.$infoModal);
        })
      );

      state.isError = true;
      // state.$store.dispatch("form/toggleLoader", loaderName);
      return false;
    }
    return true;
  } else {
    return true;
  }
}

/**
 * Handles the logic when the current page being processed is a repeatable page
 * @returns {Promise<boolean>} - Boolean indicating if the current iteration is valid
 */
async function handleRepeatablePage(state: FormState): Promise<boolean> {
  const loaderName = "next";
  if (state.page.isRepeatable) {
    // If a page which is submitted has a repeatable container on iteration 0 then we
    // will need to submit it when the page has been submitted
    const currentIterationIsValid = await nextRepeatablePage(state, false);

    if (!currentIterationIsValid) {
      state.$store.dispatch("form/setLoader", { name: loaderName, value: false });
      return false;
    }
  }

  return true;
}
/** Creates a claim when the page being processed is the start page and no claim currently exists */
function handleIsStartPageAndNoClaimExists(state: FormState) {
  if (!state.claimId && state.page.isStart) {
    state.claimId = createClaimId(state);

    if (!state.claimDataService) {
      state.claimDataService = new ClaimDataService(state.userId, state.claimId);
      window.console.log("[next] setting claimId to", state.claimId);
      state.claimDataService.setArtefact("claimId", state.claimId);
    }
  }
}

/**
 * Stores answers and runs base validations. Stops progress if base page not valid
 * @returns Returns a boolean indicating if the current page is valid
 */
async function handleStandardPage(state: FormState): Promise<boolean> {
  const loaderName = "next";
  if (state.claimId && !state.page.isRepeatable) {
    await state.pageService.storeAnswers(state.userId, state.claimId, state.page);
    const isPageBaseValid = await state.baseValidationService.runBaseValidations(state.page, state);
    console.log("isPageBaseValid", isPageBaseValid);
    if (!isPageBaseValid) {
      state.$store.dispatch("form/setLoader", { name: loaderName, value: false });
      return false;
    }
  }

  return true;
}

async function handlePageEvents(state: FormState) {
  const eventNames = await PageHelper.calculateEventsToFire(state.page, state);

  if (state.funnelId === Milberg.Funnels.JLR_DPF_FUNNEL_ID && state.pageId === "WeddBpBUxe8AE4mjdAPx") {
    console.log(">>> Creating the task to push the claimant to Milberg Salesforce");
    await createPushIdentityDocumentsToMilbergSalesforceTask(state);
  }

  // Determine if this page contains a BlockContactDetails
  if (eventNames) {
    // Fire fall events to pixels

    const eventPromises = [];

    let eventHistory: Array<Event> = state.claimDataService.getArtefact("eventHistory");
    const referrerId = state.claimDataService.getArtefact("referrerId");
    const claimDocumentId = state.claimDataService.getArtefact("documentId");
    let events = state.claimDataService.getArtefact("events");
    const claimStatus = state.claimDataService.getArtefact("claimStatus");

    await Promise.all(
      eventNames.map(async (eventName) => {
        // de-dup the events if user or agreement and they already exist in history
        // This would have happne if the user has gone back and forth between pages or updateClaimStep has been used
        if (eventName === "NewUser" || eventName === "NewAgreement") {
          if (eventHistory && eventHistory.findIndex((e) => e.name === eventName) > -1) {
            return;
          }
        }

        let event: any = {
          name: JSON.parse(JSON.stringify(eventName)),
          date: new Date(),
          funnelId: state.funnelId,
          pageId: state.page.id,
          userId: state.userId,
          workspaceId: state.workspace.id,
          claimId: claimDocumentId ?? state.claimId, // Support backwards compatability of old style claims
          referrerId: "",
          claimStatus,
        };

        // ! Specific extensions pertaining to TLW Diesel Emissions
        if (
          [Tlw.Funnels.DIESEL_EMISSIONS_FUNNEL_ID, Tlw.Funnels.TLW_CLAIM_DETAILS_FUNNEL_ID].includes(state.funnelId)
        ) {
          const [funnelVariables, _getFunnelVariables] = await getFunnelVariables(
            getFirebaseBackend().firestore(),
            state.funnelId
          );

          const statedPurchasePrice = state.claimDataService.getArtefact(
            funnelVariables.find((v) => v.field === "statedPurchasePrice")?.id
          );
          const keeperOwnershipPeriodInMonths = state.claimDataService.getArtefact(
            funnelVariables.find((v) => v.field === "keeperOwnershipPeriodInMonths")?.id
          );
          const tlwExpectedClassificationStatus = state.claimDataService.getArtefact(
            funnelVariables.find((v) => v.field === "tlwExpectedClassificationStatus")?.id
          );
          const tlwExpectedClassificationReason = state.claimDataService.getArtefact(
            funnelVariables.find((v) => v.field === "tlwExpectedClassificationReason")?.id
          );

          if (state.funnelId === Tlw.Funnels.TLW_CLAIM_DETAILS_FUNNEL_ID) {
            // include originalClaimId if available
            const originalClaimId = state.claimDataService.getArtefact("j4i8b27UxtAdlPrvmwjs");
            if (originalClaimId) {
              event.originalClaimId = originalClaimId;
            }
          }

          // Append the statedPurchasePrice to the event
          event.statedPurchasePrice = statedPurchasePrice;
          event.keeperOwnershipPeriodInMonths = keeperOwnershipPeriodInMonths;
          event.tlwExpectedClassificationStatus = tlwExpectedClassificationStatus;
          event.tlwExpectedClassificationReason = tlwExpectedClassificationReason;

          event = omitBy(event, (value) => isNil(value));
        }

        // Add the identifier of the agreement to the event
        if (eventName === "NewAgreement") {
          // If we have been unable to record the agreementId
          if (!state.agreementId) {
            console.log("[agreement]: unable to locate agreementId");
          } else {
            event.agreementId = state.agreementId;
          }

          if (state.funnelId === Milberg.Funnels.JLR_DPF_FUNNEL_ID) {
            console.log(">>> Creating the task to push the claimant to Milberg Salesforce");
            await createPushToMilbergSalesforceTask(state);
          }
        }

        if (referrerId) {
          event.referrerId = referrerId;
        } else {
          event.referrerId = "None";
        }

        if (!eventHistory) {
          eventHistory = [];
          eventHistory.push(event);
        } else if (eventHistory) {
          const eventHistoryHasEvent = eventHistory.filter((eventElement) => eventElement.name === event).length > 0;

          if (!eventHistoryHasEvent) {
            eventHistory.push(event);
          }
        }

        const eventElement = event;

        if (!events) {
          events = [eventElement];
        } else {
          const eventsHasEvent = events.filter((eventElement) => eventElement.name === event).length > 0;
          if (!eventsHasEvent) {
            events.push(eventElement);
          }
        }

        // ! Passing a copy of the event is intentional

        console.log("<LOG>: Firing an event", event);
        eventPromises.push(state.eventsService.fire(event));
        eventPromises.push(state.pixelsService.fire(event));
      })
    );

    await Promise.all(eventPromises);
    state.claimDataService.setArtefact("eventHistory", eventHistory);
    state.claimDataService.setArtefact("events", events);
  }
}

/** Authorises the funnel author to the access the current user's user data if they completed a page containing the Contact Details block */
async function tryAuthoriseWorkspaceAccessToUserData(state: FormState) {
  // ? Workspaces should only be granted user data access after the page submitted contains the ContactDetails block
  // ? Trigger an event 'NewUser'
  const pageHasContactDetailsBlock = await PageHelper.calculatePageContainsContactDetailsBlock(state.page);
  const { authorId } = state.funnelMeta;

  console.log("[pgh]", pageHasContactDetailsBlock);
  if (pageHasContactDetailsBlock) {
    const workspacesWithUserDataAccess = state.userDataService.getArtefact("workspacesWithUserDataAccess") ?? [];

    if (workspacesWithUserDataAccess.indexOf(authorId) === -1) {
      workspacesWithUserDataAccess.push(authorId);
    }

    // Trigger the reminder sequences
    await state.$store.dispatch("events/fire", { name: "sendWelcomeMessage" });

    state.userDataService.setArtefact("workspacesWithUserDataAccess", workspacesWithUserDataAccess);
  }
}

async function tryCopyClaimToWorkspace(state: FormState) {
  const pageContainsCopyClaimBlock = await PageHelper.calculatePageContainsCopyClaimBlock(state.page);
  if (pageContainsCopyClaimBlock) {
    const blockCopyClaim = state.blockRefs.find((block) => block.$refs["BlockCopyClaim"]).$refs["BlockCopyClaim"];
    if (blockCopyClaim) {
      try {
        console.log("[next] Running copyClaim!");
        await blockCopyClaim?.copyClaim();
      } catch (error) {
        console.error("[next] Error copying claim!", error);
      }
    }
  }
}

/** Starts the processing of the current page by toggling the loader state and resetting validation */
async function startProcessingNext(state: FormState) {
  const loaderName = "next";
  // Toggle loader
  state.$store.dispatch("form/setLoader", { name: loaderName, value: true });
  window.console.log("[next], executing next");

  // Reset page validation
  state.isError = false;
  await state.pageService.resetValidation(state.page);
  window.console.log("[next], passed reset validation?");
}

/** Stops processing if the page validations are invalid */
function handlePageValidations(state: FormState, resolvedPromises: any[]) {
  const loaderName = "next";
  const isPageValidationsValid = resolvedPromises[1];

  if (!isPageValidationsValid) {
    window.console.log("[next] normal validation");
    state.$store.dispatch("form/setLoader", { name: loaderName, value: false });
    return false;
  }

  return true;
}

/** Handles the routing and error messages to be displayed when there was an error calculating the next page
 * @returns {Promise<boolean>} Returns a boolean indicating if the calculateNextPage threw an error
 */
async function handleCalculateNextPageError(state: FormState, resolvedPromises: AwaitedNextPromises): Promise<boolean> {
  const loaderName = "next";
  const { result, error: nextError, exception: nextException } = resolvedPromises[0];
  if (nextError) {
    await state.infoModalService.fire("error", {
      title: msg.errors.unexpectedError.title,
      text: msg.errors.unexpectedError.text,
      confirmButtonText: "Redirect",
    });
    await state.$router.push({ name: "Track", params: { claimId: state.claimId } });
    return false;
  }

  // If there was an error which need to be displayed
  if (nextException) {
    window.console.log("[next] Calling setPage error with: ", nextException);
    await state.pageService.setPageError(state.page, nextException, state.$infoModal);
    state.$store.dispatch("form/setLoader", { name: loaderName, value: false });
    return false;
  }

  // If there was an error in running a compute
  if (!result) {
    window.console.log("[next] undefined");
    return false;
  }

  return true;
}
function storeUserAndClaimId(state: FormState, next: any) {
  if (next && state.claimId) {
    // Store a copy of the claimId on the claim document
    if (!state.claimDataService.getArtefact("claimId")) {
      if (state.claimDataService.getArtefact("documentId")) {
        state.claimDataService.setArtefact("claimId", state.claimDataService.getArtefact("documentId"));
      } else {
        state.claimDataService.setArtefact("claimId", state.claimId);
        state.claimDataService.setArtefact("documentId", state.claimId);
      }
    }

    // Store a copy of the userId on the claim document
    if (!state.claimDataService.getArtefact("userId")) {
      state.claimDataService.setArtefact("userId", state.userId);
    }
  }
}
async function tryTransferJonesWhyteClaim(state: FormState) {
  const claim = state.claimDataService.getCache() as Claim;
  const currentFunnelId = state.claimDataService.getArtefact("currentFunnelId");

  console.log(">>> tryTransferJonesWhyteClaim", claim, currentFunnelId);

  // If the funnel is a Jones Whyte funnel
  if (!JONES_WHYTE_FUNNELS.includes(state.funnelId) || !state.page.isStart) {
    return null;
  }

  // If any of the claim keys reference Mercedes
  if (claim["2W0YYhoYvlEFRzladjPE"] && claim["2W0YYhoYvlEFRzladjPE"].toLowerCase().includes("mercedes")) {
    return await transferFunnelId(state, Quantum.Funnels.JONES_WHYTE_MERCEDES_EMISSIONS_ID);
  } else {
    return await transferFunnelId(state, Quantum.Funnels.JONES_WHYTE_DIESEL_EMISSIONS_ID);
  }
}

async function transferFunnelId(state: FormState, funnelId: string) {
  state.claimDataService.setArtefact("currentFunnelId", funnelId);

  const claim = state.claimDataService.getCache() as Claim;
  const _actions = claim.actions.map((action) => {
    return {
      ...action,
      funnelId,
    };
  });

  const _trail = claim.trail.map((trail) => {
    return {
      ...trail,
      funnelId,
    };
  });

  const _eventHistory = claim.eventHistory.map((event) => {
    return {
      ...event,
      funnelId,
    };
  });

  state.claimDataService.setManyArtefacts({
    actions: _actions,
    trail: _trail,
    eventHistory: _eventHistory,
  });

  await state.$router.push({
    name: "form",
    params: { pageId: state.pageId, funnelId: funnelId },
    query: state.$route.query.testClaim === null ? { testClaim: null } : {},
  });

  const [funnelMeta, _getFunnelMeta] = await getFunnelMetaData(state.db, funnelId);
  state.funnelMeta = funnelMeta;

  return await getPageById(state, "LQhXu8RI3DtUuz6stmIW", funnelId);
}
async function handleSgUkStatementOfTruth(state: FormState): Promise<void> {
  console.log(">>> statementOfTruth handleSgUkStatementOfTruth", state.pageId, state.funnelId);
  const sgUkFunnelId = SlaterGordon.Funnels.MERCEDES_FUNNEL_ID;
  const statementOfTruthPageId = SlaterGordon.Funnels.Pages.STATEMENT_OF_TRUTH_PAGE_ID;

  if (state.pageId !== statementOfTruthPageId && state.funnelId !== sgUkFunnelId) return;

  const statementOfTruth = await PageHelper.loadVariable(
    state.page,
    SlaterGordon.Funnels.Variables.statementOfTruth,
    state.claimId
  );

  if (statementOfTruth !== "Accept") return;

  // store date on statementOfTruthDate variable
  const statementOfTruthDate = new Date();
  state.claimDataService.setArtefact(SlaterGordon.Funnels.Variables.statementOfTruthDate, statementOfTruthDate);

  console.log(">>> statementOfTruth", statementOfTruth, statementOfTruthDate);
  return;
}

export async function hasExistingClaimForManufacturerByLawFirm(
  state: FormState,
  pageId: string,
  make: string
): Promise<any> {
  const [claimsForUser] = await getClaimsByFunnelId(getFirebaseBackend().firestore(), state.userId, state.funnelId);

  const agreementSignedClaims = claimsForUser.filter(
    (claim) => claim.actions.find((action) => action.pageId === pageId && action.kind === "completion") !== undefined
  );

  if (agreementSignedClaims) {
    const [funnelVariables, _getFunnelVariables] = await getFunnelVariables(
      getFirebaseBackend().firestore(),
      state.funnelId
    );

    const manufacturerCounts: Record<string, number> = {};

    const makeVariable = funnelVariables.find((variable) => variable.field === "make");

    agreementSignedClaims.forEach((_claim) => {
      const claimMake = _claim[makeVariable.id];
      if (claimMake) {
        manufacturerCounts[claimMake] = (manufacturerCounts[claimMake] || 0) + 1;
      }
    });

    console.log(">>> manufacturerCounts", manufacturerCounts);

    // If the make is in the amountOfManufacturersClaims and the count is greater than 0, return true
    const hasExistingClaimForManufacturerByLawFirm = Object.entries(manufacturerCounts).some(
      ([manufacturer, count]) => manufacturer === make && count > 0
    );

    return hasExistingClaimForManufacturerByLawFirm;
  }

  console.log(">>> hasExistingClaimForManufacturerByLawFirm Client has no existing signed agreements for given firm");
  return false;
}
