export default {
  signInToAccess: "Log masuk untuk mengakses akaun anda dengan",
  signInMethod: "Cara log masuk",
  selectSignInMethod: "Pilih cara log masuk",
  continue: "Teruskan",
  welcomeBack: "Selamat Kembali!",
  emailPlaceholder: "Emel",
  emailLabel: "Emel",
  phonePlaceholder: "Telefon",
  phoneLabel: "Telefon",
  enterYourPassword: "Masukkan kata laluan anda",
  enterPasswordLink: "Masukkan kata laluan yang berkaitan dengan",
  back: "Kembali",
  passwordPlaceholder: "Kata laluan",
  passwordLabel: "Kata laluan",
  forgotPassword: "Lupa kata laluan?",
  signIn: "Log masuk",
  showPassword: "Tunjukkan kata laluan",
  createPasswordHeading: "Cipta Kata Laluan Anda",
  forgotPasswordHeading: "Lupa Kata Laluan",
  createPasswordSupporting: "Sila cipta kata laluan baru untuk akaun anda dengan",
  confirmLabel: "Sahkan",
  confirmPlaceholder: "Sahkan",
  incorrectPassword: "Kata laluan tidak betul. Sila cuba lagi.",
  otpModalHeading: "Sahkan kod",
  otpModalSupporting: "Masukkan kod yang dihantar ke:",
  otpModalInfo:
    "Anda mungkin perlu menyemak folder spam anda. Jika anda mengalami masalah menerima kod, sila kembali dan cuba menggunakan cara log masuk alternatif",
  otpModalCodeInvalid: "Kod yang anda masukkan tidak sah",
};
