import { getFirebaseBackend } from "@/authUtils";
import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
import { MotorSpecsBlockProps, blocks } from "./blocks";
import { FunnelsService, Milberg } from "@claimsgate/core";
import { MotorSpecsPartialVehicleStore } from "./types";

import { InfoModalService } from "../../global/informationModal/InfoModalService";

interface Vehicle {
  vrm: string;
  ownershipDate: string;
  state: boolean | null;
  invalidFeedback: string;
}

export function getData(state: Vue) {
  return {
    vrm: "",
    ownershipDate: "",
    vrmInputState: null as boolean | null,
    userId: null as string,
    claimId: state.$route.params.claimId,
    pageId: state.$route.params.pageId,
    funnelId: state.$route.params.funnelId,
    funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
    variablesService: new VariableService(getFirebaseBackend().firestore()),
    claimDataService: null as ClaimDataService | null,
    Milberg,
    infoModalService: new InfoModalService(state.$infoModal),
    userService: getUserHelper(),
    uiToggles: {
      process: {
        isAutoSearchingWithoutOwnershipDate: false,
      },
      isSearchingByQueryParameters: false,
      isVehicleSearchVisible: true,
      isKeeperSelectVisible: false,
      showVehicleConfirmation: false,
      showKeeperStartQuestion: false,
      showKeeperEndQuestion: false,
      showOwnershipDateQuestion: false,
      showAdditionalVehicleSection: false,
      isLoading: false,
      isSubmitting: false,
    },
    vehicles: [{ vrm: "", ownershipDate: "", state: null, invalidFeedback: "" }] as Vehicle[], // Use the updated type
    showError: false,
    showAdditionalVehiclesQuestion: false, // New property to control visibility
    BlockInputs: {
      ...UserVariableBlocksService.getButtons(blocks.buttons as any),
      ...blocks.inputs,
      ...blocks.texts,
    } as MotorSpecsBlockProps,
    vehicle: null as MotorSpecsPartialVehicleStore,
    vrmInputInvalidFeedback: "",
    uiMessages: {
      provenioOriginalOwner:
        "Unfortunately, you must be the original or second owner of the vehicle to join the claim.",
      provenioPurchasedBetween2013And2019:
        "Unfortunately, your vehicle must be purchased your vehicle between 2013 and 2019 to qualify to join the claim.",
      provenioLandRoverRangeRover:
        "Unfortunately, your vehicle must have been manufactured by Land Rover to join the claim.",

      notDiesel: "Unfortunately, your vehicle must be Diesel to qualify to to make a claim.",
      quantum2009To2019:
        "Unfortunately, your vehicle must be manufactured between 2009 and 2019 to qualify to make a claim.",
      jonesWhyte2008To2018:
        "Unfortunately, your vehicle must be manufactured between 2008 and 2018 to qualify to make a claim.",
      lanierNotPurchasedBefore2020: "To register, you must have purchased the vehicle before 2020.",
      milberg4YearsAfterManufacture:
        "To register, your vehicle must have been manufactured within 4 years of ownership.",
      milbergVehicleNotOwnedIn2017: "To register, you must have purchased the vehicle between July 2017 and 2019.",
      milbergNotVauxhallOrMercedes: "To register, your vehicle must be a Vauxhall or Mercedes.",
      milbergNotDiesel: "To register, the vehicle must have a Diesel engine.",
      vehicleDoesNotQualify: "Unfortunately, your vehicle does not qualify to to make a claim.",
      enterReg: "Please enter a valid registration number",
      noVehicleFound: "Unfortunately, we could not find your vehicle. Please try entering the VRM again.",
      doubleCheckOwnershipDate: "Please double check you owned the vehicle during this date",
      errorFindingVehicle:
        "There was an error finding the data for your vehicle, please try again later or use a different registration number and ownership date.",
      noVehicleSelected: "Please select the vehicle you owned",
      noKeeperSelected: "Please select the date you acquired the vehicle",
      noOwnershipDateSelected: "Please select a date in which you owned the vehicle",
      ownershipDateInFuture: "Please select a date which has already taken place",
      noVehicleConfirmationSelected: "Please confirm whether the above vehicle is your vehicle.",
      duplicateVehicleRegistration: "You have already added this vehicle.",
    },
  };
}
