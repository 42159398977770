import { getClaimDataService } from "@/helpers/vue";
import { getFunnelVariables } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";

import { BlockMotorSpecsInstance } from "./BlockMotorSpecs";
import { resetToSearchVehicleView, searchVehicle } from "./methods";

export async function mounted(state: BlockMotorSpecsInstance) {
  state.userId = state.userService.getUserId();

  getClaimDataService<BlockMotorSpecsInstance>(state);

  resetToSearchVehicleView(state);
  await parseQueryParameters(state);

  if (state.vrm === "") {
    const [funnelVariables, _getFunnelVariables] = await getFunnelVariables(
      getFirebaseBackend().firestore(),
      state.funnelId
    );

    state.vrm =
      state.claimDataService.getArtefact(funnelVariables.find((x) => x.field === "vehicleRegistration")?.id) ?? "";
  }
}

async function parseQueryParameters(state: BlockMotorSpecsInstance): Promise<void> {
  if (Object.keys(state.$route.query)?.length > 0 && state.$route.query?.vehicleRegistration) {
    state.vrm = state.$route.query?.vehicleRegistration?.toString();
    state.ownershipDate = state.$route.query?.ownershipDate?.toString();
  }

  if (state.vrm && state.ownershipDate) {
    state.uiToggles.isSearchingByQueryParameters = true;
    await searchVehicle(state);
  }

  if (state.vrm && !state.ownershipDate) {
    state.uiToggles.isSearchingByQueryParameters = true;
    state.ownershipDate = new Date().toISOString();
    await searchVehicle(state);
  }
}
