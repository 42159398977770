<script lang="ts">
import Vue from "vue";
import { Fragment } from "vue-frag";
import BlockInput from "@/components/shared/blocks/input.vue";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import { BlockProps, Props } from "@/types";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
import { PropOptions } from "vue/types/umd";

const blockEmailInputProps = inputProps({
  answerType: "string",
  extraProps: ["padding", "label"],
  excludeDefaults: true,
}) as Props.BlockSimpleEmail;
export default Vue.extend({
  name: "BlockSimpleEmail",
  components: {
    Fragment: Fragment as any,
    BlockInput,
  },
  props: {
    answer: {
      type: String,
      required: false,
      default: () => "",
    } as PropOptions<string>,

    ...blockEmailInputProps,
    storeAs: {
      type: Object,
    } as PropOptions<object>,

    info: {
      type: String,
      required: false,
      default: () => "",
    } as PropOptions<string>,

    condition: {
      type: Object,
      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },
    required: {
      type: Boolean,
      required: false,
      default: () => true,
    } as PropOptions<boolean>,
  },
  data() {
    return {
      BlockInputs: UserVariableBlocksService.getBlocks(["email", "emailConfirm"]) as Record<
        "email" | "emailConfirm",
        BlockProps.Input<string>
      >,
      claimId: this.$route.params.claimId,
      funnelId: this.$route.params.funnelId,
      pageId: this.$route.params.pageId,
      uiMessages: {
        emailInvalid: "Please enter a valid email address",
      },
    };
  },

  mounted() {
    if (this.answer) {
      this.BlockInputs.email.answer = this.answer;
    }
  },

  watch: {
    label: {
      handler() {
        this.BlockInputs.email.label = this.label;
      },
      immediate: true,
    },
    info: {
      handler() {
        this.BlockInputs.email.info = this.info;
      },
      immediate: true,
    },
  },
  methods: {
    async validations() {
      this.BlockInputs.email.state = null;

      if (!this.required && !this.BlockInputs.email.answer) {
        this.BlockInputs.email.state = true;
        return true;
      }

      if (!this.BlockInputs.email.answer || this.BlockInputs.email.answer.length < 6) {
        this.BlockInputs.email.invalidFeedback = this.uiMessages.emailInvalid;
        this.BlockInputs.email.state = false;
        return false;
      }

      const email = this.BlockInputs.email.answer;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(email);

      if (!isValidEmail) {
        this.BlockInputs.email.invalidFeedback = this.uiMessages.emailInvalid;
        this.BlockInputs.email.state = false;
        return false;
      }
      const { data: result } = await onCallGateway<"validateEmail">({
        functionName: "validateEmail",
        data: { email: email, claimId: this.claimId, funnelId: this.funnelId, pageId: this.pageId },
      });
      const validationAssesment = result?.data;
      if (!validationAssesment?.isValidEmail) {
        this.BlockInputs.email.state = false;
        this.BlockInputs.email.invalidFeedback = this.uiMessages.emailInvalid;
        this.BlockInputs.emailConfirm.state = false;
        this.BlockInputs.emailConfirm.invalidFeedback = this.uiMessages.emailInvalid;
        return false;
      }

      return true;
    },
    async computes() {
      this.BlockInputs.email.state = true;

      const email = this.BlockInputs.email.answer;

      if (!this.required && !this.BlockInputs.email.answer) {
        this.BlockInputs.email.state = true;
        return null;
      }

      return email;
    },
  },
});
</script>
<template>
  <Fragment>
    <BlockInput
      v-bind="BlockInputs.email"
      :padding="padding"
      v-bind.sync="BlockInputs.email"
      :info="info"
      :inputType="'email'"
    ></BlockInput>
  </Fragment>
</template>
