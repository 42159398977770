import { getFirebaseBackend } from "@/authUtils";
import { BlockEmailInstance } from "../BlockEmail";
import * as Sentry from "@sentry/vue";
import { captureMessage } from "@sentry/vue";

export function fireEmailValidationFailedEvent(state: BlockEmailInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("email", state.BlockInputs.email.answer);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("EmailValidationFailed");
  });
}
export function fireEmailValidationPassedEvent(state: BlockEmailInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("email", state.BlockInputs.email.answer);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("EmailValidationPassed");
  });
}
export async function fireEmailValidationStarted(state: BlockEmailInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("email", state.BlockInputs.email.answer);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("EmailValidationStarted");
  });
}
