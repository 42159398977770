var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('BlockCurrencyInput',{attrs:{"info":_vm.info,"padding":_vm.padding,"label":_vm.label,"existingCurrencyValue":_vm.newAnswer.amount,"existingCurrencyCountryCode":_vm.newAnswer.currencyCountryCode,"invalidFeedback":_vm.invalidFeedback,"state":_vm.state,"validCharactersOnly":true,"preferredCountries":[
      'GB',
      'US',
      'CA',
      'AU',
      'DE',
      'FR',
      'IT',
      'ES',
      'NL',
      'BR',
      'MX',
      'ZA',
      'SE',
      'NO',
      'DK',
      'BE',
      'CH',
      'IE',
      'NZ',
    ]},on:{"input":_vm.handleCurrencySelected}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }