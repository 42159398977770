/**
 * Builder is a VueX centralized state manager to help assist sharing state and methods between multiple dynamic components
 * @typedef variable
 * @property {string} id
 * @property {string} field
 * @property {string} group
 * @property {string} type
 *
 */

import { BuilderService } from "../../helpers/ClaimsGate/builder/BuilderService";

import { getFunnelVariables } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";

export const state = {
  userVariables: [],
  claimVariables: [],
  trackingVariables: [],
  identityVariables: [],
  apiKey: "",
  errors: { addVariable: { code: "variable_in_use", isError: false } },
  storeAsMap: {
    BlockFileInput: "file",
    BlockMultiFileInput: "array of files",
    BlockContainer: "Array<T>",
    BlockAgreement: "agreement",
    BlockMultiSelect: "array",
    BlockDatepicker: "date",
    BlockDateOfBirth: "date",
    BlockEmail: "string",
    BlockPhone: "string",
    BlockNumber: "number",
    BlockCurrency: "T",
    BlockCountry: "string",
    BlockDisplayFile: "array of files",
    BlockDsar: "array of files",
    BlockDataArray: "Array<T>",
    BlockSimpleEmail: "string",
    BlockSimplePhone: "string",
    BlockComplexQuestion: "Array<T>",
    BlockNestedMultiSelect: "Array<T>",
    BlockSignature: "string",
  },
};

export const getters = {
  /**
   * Returns the user variables
   * @param {Object} state - VueX State
   * @returns {Array} - List of user variables
   */
  getUserVariables(state) {
    return state.userVariables;
  },

  getStoreAsMap(state) {
    return state.storeAsMap;
  },

  /**
   * Returns the builder errors
   */
  getBuilderErrors(state) {
    return state.errors;
  },

  /**
   * Returns the selected funnel ID
   * @param {*} state - VueX State
   * @returns
   */
  getFunnelId(state) {
    return state.funnelId;
  },

  /**
   * Returns the claim variables
   * @param {*} state - VueX state
   * @returns {Array} - List of claim variables
   */
  getClaimVariables(state) {
    return state.claimVariables;
  },

  /**
   * Returns the API Key
   * @param {*} state - VueX state
   * @returns {String} - API Key associated with requesting user
   */
  getApiKey(state) {
    return state.apiKey;
  },

  /**
   * Returns the tracking variables
   * @param {*} state - VueX state
   * @returns {Array} - List of claim variables
   */
  getTrackingVariables(state) {
    return state.trackingVariables;
  },

  getIdentityVariables(state) {
    return state.identityVariables;
  },

  /**
   * Returns the funnel variables
   * @param {*} state - VueX state
   * @returns {Array} - List of claim variables
   */
  getVariables(state) {
    const { claimVariables, userVariables, trackingVariables, identityVariables } = state;
    return claimVariables
      .concat(userVariables)
      .concat(trackingVariables)
      .concat(identityVariables)
      .concat(claimVariables);
  },

  /**
   * Creates a grouped array of all variables to be used in a dropdown
   * @param {*} state - State object
   * @returns {Array} Array to be used in a dropdown
   */
  getVariablesDropdown(state) {
    const variableDropdown = [];
    const { claimVariables, userVariables, trackingVariables, identityVariables } = state;
    variableDropdown.push({ disabled: true, value: "", text: "Please select a variable" });
    // Add each of the user variables to the dropdown array
    if (userVariables.length > 0) {
      const userVariablesGroup = { label: "User Variables", options: [] };

      userVariables.forEach((variable) => {
        userVariablesGroup.options.push({
          text: `${variable.field} (${variable.type})`,
          value: variable,
        });
      });
      variableDropdown.push(userVariablesGroup);
    }

    // Add each of the claim variables to the dropdown array
    if (claimVariables.length > 0) {
      const claimVariablesGroup = { label: "Claim Variables", options: [] };
      claimVariables.forEach((variable) => {
        if (variable.values) {
          delete variable.values;
        }
        claimVariablesGroup.options.push({
          text: `${variable.field} (${variable.type})`,
          value: variable,
        });
      });
      variableDropdown.push(claimVariablesGroup);
    }

    // Add each of the tracking variables to the dropdown array
    if (trackingVariables.length > 0) {
      const trackingVariablesGroup = { label: "Tracking Variables", options: [] };
      trackingVariables.forEach((variable) => {
        trackingVariablesGroup.options.push({
          text: `${variable.field} (${variable.type})`,
          value: variable,
        });
      });

      variableDropdown.push(trackingVariablesGroup);
    }

    // Add each of the identity variables to the dropdown array
    if (identityVariables.length > 0) {
      const identityVariablesGroup = { label: "Identity Variables", options: [] };
      identityVariables.forEach((variable) => {
        if (variable.values) {
          delete variable.values;
        }
        identityVariablesGroup.options.push({
          text: `${variable.field} (${variable.type})`,
          value: variable,
        });
      });
      variableDropdown.push(identityVariablesGroup);
    }

    return variableDropdown;
  },

  /**
   * Returns the list of actions which can be performed
   */
  getActionDropdown() {
    const dropdown = [];

    dropdown.push({ text: "Please select an action", value: "", disabled: true });
    dropdown.push({ text: "Create a new variable", value: "newVariable" });
    dropdown.push({ text: "Use an existing variable", value: "existingVariable" });

    return dropdown;
  },

  /**
   * Returns the a dropdown containing each of the conditionals which can be performed in Claims Gate
   */
  getConditionalDropdown: function () {
    return (type) => {
      const conditionalDropdown = [];
      // Add place holder
      conditionalDropdown.push({ text: "Please select a condition", value: "", disabled: true });

      // Add standard conditionals which can be used for all types
      conditionalDropdown.push({ text: "exists", value: "exists" });
      conditionalDropdown.push({ text: "does not exist", value: "doesNotExist" });

      if (type === "string") {
        conditionalDropdown.push({ text: "contains", value: "contains" });
        conditionalDropdown.push({ text: "does not contain", value: "doesNotContain" });
        conditionalDropdown.push({ text: "equals", value: "equals" });
        conditionalDropdown.push({ text: "does not equal", value: "doesNotEqual" });
      } else if (type === "number") {
        conditionalDropdown.push({ text: "less than", value: "lessThan" });
        conditionalDropdown.push({ text: "greater than", value: "greaterThan" });
        conditionalDropdown.push({ text: "equals", value: "equals" });
        conditionalDropdown.push({ text: "does not equal", value: "doesNotEqual" });
      } else if (type === "boolean") {
        conditionalDropdown.push({ text: "equals", value: "equals" });
        conditionalDropdown.push({ text: "does not equal", value: "doesNotEqual" });
      } else if (type === "array") {
        conditionalDropdown.push({ text: "contains", value: "contains" });
        conditionalDropdown.push({ text: "does not contain", value: "doesNotContain" });
      } else if (type === "date") {
        conditionalDropdown.push({ text: "less than", value: "lessThan" });
        conditionalDropdown.push({ text: "greater than", value: "greaterThan" });
      }
      return conditionalDropdown;
    };
  },
};

export const mutations = {
  /**
   * Mutates the state to include a given variable
   * @param {*} state - VueX state
   * @param {variable} variable - Variable object to add
   */
  addVariable(state, variable) {
    const claimGroup = "claim";
    const { group } = variable;
    if (group === claimGroup) {
      state.claimVariables.push(variable);
    }
  },

  addProperty(state, data) {
    const { variable, property } = data;
    let claimVariable = state.claimVariables.find((claimVariable) => claimVariable.id === variable.id);

    window.console.log(property, claimVariable);
    /*

    if (!claimVariable.structure) {
      claimVariable.structure = {};
    }

    claimVariable.structure[property.field] = property.type;
    */
  },

  setApiKey(state, apiKey) {
    state.apiKey = apiKey;
  },

  setUserVariables(state, userVariables) {
    state.userVariables = userVariables;
  },

  setClaimVariables(state, claimVariables) {
    state.claimVariables = claimVariables;
  },

  setTrackingVariables(state, trackingVariables) {
    state.trackingVariables = trackingVariables;
  },

  setIdentityVariables(state, identityVariables) {
    state.identityVariables = identityVariables;
  },

  setFunnelId(state, funnelId) {
    state.funnelId = funnelId;
  },

  setError(state, error) {
    const { name, code, isError } = error;
    state.errors[name] = { code: code, isError: isError };
  },
};

export const actions = {
  /**
   * Attempts to add a given variable using the mutator
   * @param {Object} variable - Variable to create
   */
  async addVariable({ commit }, variable) {
    // Reset error tracking state for this action
    commit("setError", { name: "addVariable", code: "variable_in_use", isError: false });

    const builderService = new BuilderService();
    const { field: field, type: type, group: group, id: id, structure } = variable;

    const claimHasVariable =
      state.claimVariables.filter((claimVariable) => {
        return claimVariable.field.toLowerCase() === field.toLowerCase();
      }).length > 0;
    const userHasVariable =
      state.userVariables.filter((userVariable) => userVariable.field.toLowerCase() === field.toLowerCase()).length > 0;

    if (claimHasVariable || userHasVariable) {
      commit("setError", { name: "addVariable", code: "variable_in_use", isError: true });
      return;
    }
    const { data: isVariableCreated } = await builderService.createVariable(
      state.funnelId,
      field,
      type,
      group,
      id,
      structure
    );

    window.console.log(state.funnelId, "created a variable: ", field, " isCreated: ", isVariableCreated);

    if (isVariableCreated) {
      commit("addVariable", variable);
    }
  },

  async resetAddVariableError({ commit }) {
    commit("setError", { name: "addVariable", code: "variable_in_use", isError: false });
  },

  async addProperty({ commit }, data) {
    const { variable, property } = data;
    const builderService = new BuilderService();

    const isPropertyAdded = await builderService.addProperty(state.funnelId, variable.id, property);

    if (isPropertyAdded) {
      commit("addProperty", data);
    }
  },

  /**
   * Initalizes the VueX state of Builder for processing by:
   *  (1) - Loading in each of the available userVariables
   */
  async initalize({ commit }, vars) {
    const { funnelId, isOriginated } = vars;
    console.log("init Payload is", vars);
    commit("setFunnelId", funnelId);
    // Load in the user variables from Firestore

    let userVariables;
    let claimVariables;
    let identityVariables;

    const [funnelVariables] = await getFunnelVariables(getFirebaseBackend().firestore(), funnelId);

    if (!isOriginated) {
      userVariables = funnelVariables.filter((variable) => variable.group === "user");
      claimVariables = funnelVariables.filter((variable) => variable.group === "claim");
      identityVariables = funnelVariables.filter((variable) => variable.group === "identity");
    }
    let trackingVariables = funnelVariables.filter((variable) => variable.group === "tracking");

    if (userVariables && userVariables.length > 0) {
      // Remove structure from all fetched variables to prevent select v-model bug
      userVariables = userVariables.map((userVariables) => {
        const { id, field, type, group, values } = userVariables;
        return {
          id,
          field,
          type,
          group,
          values,
        };
      });
      commit("setUserVariables", userVariables);
    } else {
      commit("setUserVariables", []);
    }

    if (claimVariables && claimVariables.length > 0) {
      // Remove structure from all fetched variables to prevent select v-model bug
      claimVariables = claimVariables.map((claimVariable) => {
        const { id, field, type, group, structure, isObject, nestedOptions, values } = claimVariable;
        const variable = {
          id,
          field,
          type,
          group,
          isObject,
          nestedOptions,
          dropdownValues: values, // ! VueX has an issue with the key values??
        };
        if (structure) {
          variable.structure = structure;
        }
        return variable;
      });
      commit("setClaimVariables", claimVariables);
    } else {
      commit("setClaimVariables", []);
    }

    if (trackingVariables && trackingVariables.length > 0) {
      // Remove structure from all fetched variables to prevent select v-model bug
      trackingVariables = trackingVariables.map((trackingVariables) => {
        const { id, field, type, group, values } = trackingVariables;
        return {
          id,
          field,
          type,
          group,
          values,
        };
      });
      commit("setTrackingVariables", trackingVariables);
    } else {
      commit("setTrackingVariables", []);
    }

    if (identityVariables && identityVariables.length > 0) {
      // Remove structure from all fetched variables to prevent select v-model bug
      identityVariables = identityVariables.map((identityVariables) => {
        const { id, field, type, group, values } = identityVariables;
        return {
          id,
          field,
          type,
          group,
          values,
        };
      });
      commit("setIdentityVariables", identityVariables);
    } else {
      commit("setIdentityVariables", []);
    }
  },
};
