import { Props } from "@/types";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";

const inputPropsValue: Props.BlockDropdownSearch = inputProps({
  answerType: "string",
  extraProps: ["optionsCSV", "placeholder", "other", "state"],
  placeholder: "Type here",
});
export function getProps() {
  return {
    ...inputPropsValue,
  };
}
