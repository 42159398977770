import { reject } from "@/helpers/vue";
import { BlockEmailInstance } from "../BlockEmail";
import { blockIsComplete } from "./computes";

import { fireEmailValidationStarted, fireEmailValidationFailedEvent } from "./fireEmailSentryEvents";
// import * as Sentry from "@sentry/vue";
export async function validations(state: BlockEmailInstance) {
  fireEmailValidationStarted(state);
  if (state.uiToggles.isEmailDefined) return true;

  state.uiToggles.isLoading = true;
  state.BlockInputs.email.state = null;
  state.BlockInputs.emailConfirm.state = null;

  if (blockIsComplete(state)) return true;

  if (!userHasEnteredValue(state)) {
    fireEmailValidationFailedEvent(state);
    return false;
  }

  if (state.requireConfirm && !emailsMatch(state)) return false;

  if (!isEmailFormat(state)) return false;

  return true;
}
/**
 * If assertion fails, email block state gets set, displaying invalid feedback
 * @param state
 * @returns
 */
function emailsMatch(state: BlockEmailInstance): boolean {
  if (state.providedEmail !== state.BlockInputs.emailConfirm.answer) {
    state.BlockInputs.email.state = false;
    state.BlockInputs.emailConfirm.state = false;
    state.BlockInputs.email.invalidFeedback = state.uiMessages.emailsDontMatch;
    state.BlockInputs.emailConfirm.invalidFeedback = state.uiMessages.emailsDontMatch;
    reject(state, state.uiMessages.emailsDontMatch);
    return false;
  }
  return true;
}

/**
 * If assertion fails, email block state gets set
 * @param state
 * @returns
 */
function userHasEnteredValue(state: BlockEmailInstance): boolean {
  if (state.providedEmail?.length < 1) {
    state.BlockInputs.email.state = false;
    state.BlockInputs.email.invalidFeedback = state.uiMessages.emailInvalid;

    reject(state, state.uiMessages.emailInvalid);
    if (state.requireConfirm && state.providedEmail?.length < 1) {
      state.BlockInputs.emailConfirm.state = false;
      state.BlockInputs.emailConfirm.invalidFeedback = state.uiMessages.emailInvalid;
    }
    return false;
  }

  if (state.requireConfirm && state.providedEmail?.length < 1) {
    state.BlockInputs.emailConfirm.state = false;

    return false;
  }

  return true;
}

/** Determines if the given email is in an email format */
export function isEmailFormat(state: BlockEmailInstance) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(state.providedEmail)) {
    state.BlockInputs.email.state = false;
    state.BlockInputs.emailConfirm.state = false;
    state.BlockInputs.email.invalidFeedback = state.uiMessages.emailInvalid;
    state.BlockInputs.emailConfirm.invalidFeedback = state.uiMessages.emailInvalid;

    reject(state, state.uiMessages.emailInvalid);
    return false;
  }
  return true;
}
