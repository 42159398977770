import { getFirebaseBackend } from "@/authUtils";

import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { FinanceAgreementBlockProps, blocks } from "./blocks";

// Services
import { FunnelsService } from "@claimsgate/core";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";

export function getData(state: Vue) {
  return {
    // claim data
    userId: null as string,
    claimId: state.$route.params.claimId,
    pageId: state.$route.params.pageId,
    funnelId: state.$route.params.funnelId,

    // Services
    funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
    variablesService: new VariableService(getFirebaseBackend().firestore()),
    claimDataService: null as ClaimDataService | null,
    userService: getUserHelper(),

    // data passed to child blocks
    BlockInputs: {
      ...UserVariableBlocksService.getButtons(blocks.buttons as any),
      ...blocks.inputs,
      ...blocks.texts,
    } as FinanceAgreementBlockProps,

    // ui state management
    uiToggles: {
      // block toggles
      showBlockSingleSelect: true,
      showBlockMultiFileInput: false,
      showBlockDsarRequest: false,

      // Loading states
      isUploadingDocuments: false,
      isLoading: false,
    },
    uiMessages: {
      noFileUploaded: "Please upload at least one file to proceed.",
      filesTooLarge: "The total size of all files must be less than 36Mb",
    },
  };
}
