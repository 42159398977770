import { User } from "@claimsgate/core-types";

/**
 * Search the users verifications for a passed Identity Upload
 */
export function userIsIdVerified(userData: User): boolean {
  return (
    userData?.verifications?.findIndex(
      (verification) => verification.passed && verification.types.includes("Identity Upload")
    ) > -1
  );
}

/**
 * Search the users verifications for a passed Electoral Roll check
 */
export function userIsElectoralRollVerified(userData: User) {
  return (
    userData?.verifications?.findIndex(
      (verification) => verification.passed && verification.types.includes("Electoral Roll")
    ) > -1
  );
}

/**
 * Search the users verifications for a passed Address Upload
 */
export function userIsAddressVerified(userData: User) {
  return (
    userData?.verifications?.findIndex(
      (verification) => verification.passed && verification.types.includes("Address Verified")
    ) > -1
  );
}

export function userHasCompletedAmlCheck(userData: User) {
  return (
    userData?.verifications?.findIndex((verification) => verification.types.includes("Anti Money Laundering")) > -1
  );
}

export function userIsFaceMatched(userData: User): boolean {
  return (
    userData?.verifications?.findIndex(
      (verification) => verification.passed && verification.types.includes("Identity Facematch")
    ) > -1
  );
}
