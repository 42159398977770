<script lang="ts">
import Vue, { PropOptions } from "vue";

export default Vue.extend({
  name: "Alert",
  props: {
    mode: {
      type: String,
      default: "info",
    } as PropOptions<"info" | "success" | "warning" | "danger">,
    heading: {
      type: String,
      required: true,
    },
    supportingText: {
      type: String,
      required: true,
    },
    actions: {
      type: Array,
      default: () => [],
      required: false,
    } as PropOptions<Array<{ label: string; id: string }>>,
  },

  methods: {
    onActionClick(actionId: string) {
      this.$emit("actionSelected", actionId);
    },
  },

  computed: {
    borderColor(): string {
      return `border-${this.mode}-300`;
    },
    textColor(): string {
      return `text-${this.mode}-700`;
    },
    actionColor(): string {
      return `text-${this.mode}-700`;
    },

    bgColor(): string {
      return `bg-${this.mode}-25`;
    },
    iconColor(): string {
      return `text-${this.mode}-600`;
    },
    icon(): string {
      switch (this.mode) {
        case "info":
          return "info-circle";
        case "warning":
          return "exclamation-circle";
        case "danger":
          return "exclamation-triangle";
        default:
          return "info-circle";
      }
    },
  },
});
</script>
<template>
  <div>
    <b-card :class="['shadow-none border', borderColor, bgColor, 'p-1']">
      <div class="d-flex align-items-center">
        <b-icon :icon="icon" :class="[iconColor, 'align-self-start mt-1 font-weight-semibold d-md-none']" scale="1.3" />
        <b-icon
          :icon="icon"
          :class="[iconColor, 'align-self-start mt-1 font-weight-semibold d-none d-md-block']"
          scale="1.5"
        />

        <div class="d-flex flex-column ml-3">
          <h5 :class="['text-md font-weight-semibold mb-lg-2 mb-2', textColor]">{{ heading }}</h5>
          <p :class="['text-md mb-0', textColor]">{{ supportingText }}</p>

          <div class="d-flex mt-2">
            <div v-for="action in actions" :key="action.id">
              <a
                variant="link"
                role="button"
                :class="['text-md', 'font-weight-semibold', actionColor, 'px-0']"
                @click="onActionClick(action.id)"
              >
                {{ action.label }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
