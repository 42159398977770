import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { UserVariableService } from "@/helpers/ClaimsGate/UserVariableService";
import { BlockProps } from "@/types";

/** Each of the BlockButtonProps used in this component */
interface BlockButtonProps {
  submitButton: Partial<BlockProps.Button>;
  changeIdVerificationMethodButton: Partial<BlockProps.Button>;
}

/** Each of the BlockInputProps used in this component */
interface BlockInputProps {
  dateOfBirth: Partial<BlockProps.DateOfBirth>;
  address: Partial<BlockProps.Address>;
}

/** Each of the BlockTextProps used in this component */
interface BlockTextProps {
  title: Partial<BlockProps.Text>;
  subtext: Partial<BlockProps.Text>;
}

const texts: BlockTextProps = {
  title: {
    text: "Success!",
    align: "center",
    styles: "h3",
  },
  subtext: {
    text: "Your identity has been verified",
    align: "center",
    styles: "h5",
    weight: "normal",
  },
};

const buttons: BlockButtonProps = {
  submitButton: UserVariableService.genBlockButtonProps({ block: false }),
  changeIdVerificationMethodButton: UserVariableBlocksService.genBlockButtonProps({
    block: false,
    text: "Change ID verification method",
  }),
};

const inputs: BlockInputProps = {
  dateOfBirth: UserVariableBlocksService.getBlocks(["dateOfBirth"]).dob as BlockProps.DateOfBirth,
  address: {
    size: "lg",
    isChild: true,
    middleName: true,
    allowOtherTitle: true,
    padding: "20",
    checkElectoralRoll: true,
  } as BlockProps.Address,
};

export interface BlockKycProps extends BlockButtonProps, BlockInputProps, BlockTextProps {}

/** Each of the blocks used by the BlockKYC component */
export const blocks = { buttons, inputs, texts };
