export const state = {
  event: {},
  previousEvent: {},
  // Events which could fire inside of the Vue components
  eventValues: {
    addColumn: "addColumn",
    addContainer: "addContainer",
    addRow: "addRow",
    addBlock: "addBlock",

    deleteComponent: "deleteComponent",
    editComponent: "editComponent",
    editContainer: "editContainer",
    copyComponent: "copyComponent",
    toggleAddBlockModal: "toggleAddBlockModal",
    toggleEditModal: "toggleEditModal",
    toggleManageModal: "toggleManageModal",

    // toggleContainerType: "toggleContainerType",
    toggleRepeatablePage: "toggleRepeatablePage",
    toggleRejectionPage: "toggleRejectionPage",
    next: "next",
    refresh: "refresh",
    previous: "previous",
    updateClaimId: "updateClaimId",
    newAgreement: "newAgreement",
    nextRepeatablePage: "nextRepeatablePage",
    previousRepeatablePage: "previousRepeatablePage",
    deleteCurrentRepeatablePage: "deleteCurrentRepeatablePage",
    refreshPage: "refreshPage",
    nextRepeatableContainer: "nextRepeatableContainer",
    previousRepeatableContainer: "previousRepeatableContainer",
    deleteCurrentIteration: "deleteCurrentIteration",
    refreshContainer: "refreshContainer",
    startReminderSequence: "startReminderSequence",
    sendWelcomeMessage: "sendWelcomeMessage",
  },
  variables: [],
};

export const getters = {
  getPreviousEvent(state) {
    return state.previousEvent;
  },
  getEvent(state) {
    return state.event;
  },
  getEventValues(state) {
    return state.eventValues;
  },
  getVariables(state) {
    return state.variables;
  },
};

export const mutations = {
  fire(state, event) {
    if (state.event) {
      state.previousEvent = state.event;
    }
    state.event = event;
  },
  addVariable(state, variable) {
    state.variables.push(variable);
  },
};

export const actions = {
  fire({ commit }, event) {
    commit("fire", event);
  },

  addVariable({ commit }, variable) {
    commit("addVariable", variable);
  },
};
