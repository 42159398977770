export default {
  lastUpdatedOn: "تم تحديث آخر مطالبة في",
  discoverButton: "البدء",
  discoverHeading: "اكتشف المطالبات بمطالبات ",

  claimCompleted:
    "اكتملت مطالبتك، وهذا يعني عدم الحاجة إلى تفاصيل إضافية في الوقت الحالي.  ستستلم تحديثات منتظمة حول حالة مطالبتك هنا وعبر البريد الإلكتروني.",
  startNew: "بدء مطالبة جديدة",
  status: "الحالة",
  contactUs: "اتصل بنا",
  hereToHelp: "فريقنا موجود هنا لمساعدتك.  انقر هنا لإرسال رسالة إلينا.",
  contact: "اتصل",
  sendUsAMessage: "أرسل إلينا رسالة وسنقوم بالرد عليك بأسرع ما يمكن. ",
  name: "الاسم",
  email: "البريد الإلكتروني",
  messge: "الرسالة",
  send: "إرسال",
  cancel: "إلغاء",
  view: "عرض",
  myClaims: "المطالبات الخاصة بي",
  updatedOn: "تم تحديث آخر",
  finish: "إنهاء",
  notComplete: "لم تكتمل جميع الخطوات المطلوبة لمطالبتك.",
  continueMyClaim: "متابعة مطالبتي",
  files: "الملفات",
  history: "السجل",
  mySettings: "الإعدادات الخاصة بي",

  "Identity Picture 1": "صورة الهوية 1",
  "Identity Picture 2": "صورة الهوية 2",
  "Identity Picture 3": "صورة الهوية 3",
  "Identity Picture 4": "صورة الهوية 4",
  shareLink: "مشاركة الارتباط",
  copyLink: "نسخ ارتباط",
  withdrawInterest: "سحب الاهتمام",
  information: "معلومات",
  clickToGo: "انقر هنا للانتقال إلى",
  homePage: "الصفحة الرئيسية",
  shareThe: "شارك",
  withSomeone: "مع شخص قد يكون مؤهلاً.",
  pageCompleted: "اكتملت الصفحة",
};
