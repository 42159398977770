import { BlockKycInstance } from "./BlockKycInstance";
import { identificationFlow } from "@claimsgate/core";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
import { emitSubmit, getClaimDataService, getUserId } from "@/helpers/vue";
import {
  userIsAddressVerified,
  userIsElectoralRollVerified,
  userIsIdVerified,
} from "@/helpers/ClaimsGate/verification";
export const methods = {
  addressCompleted,
  startYoti: showYoti,
  mounted,
  changeIdVerificationMethod,
  fireIdFailedModal,
};

async function mounted(state: BlockKycInstance) {
  const userId = getUserId();

  state.userId = userId;
  state.userDataService = new UserDataService(state.userService.getUserId());
  await state.userDataService.refresh();
  state.user = state.userDataService.getCache();

  getClaimDataService<BlockKycInstance>(state);

  if (!state.idFlowAllowsElectoralRoll) {
    // Set address to no t2a
    state.BlockInputs.address.checkElectoralRoll = false;
  } else {
    state.BlockInputs.address.checkElectoralRoll = true;
  }

  // If the user is waiting for a response for the Yoti
  if (state.user.identityPending) {
    showYoti(state);
    return;
  }

  // If the flow is Yoti only
  if (state.identificationFlow === identificationFlow.yotiId) {
    if (userIsIdVerified(state.user)) {
      showSubmit(state);
      return;
    } else {
      showYoti(state);
      return;
    }
    // If the flow is Electoral roll only
  } else if (state.identificationFlow === identificationFlow.electoralRoll) {
    if (userIsElectoralRollVerified(state.user)) {
      showSubmit(state);
      return;
    }
    // If the flow is Electoral roll with Yoti fallback
  } else if (
    state.identificationFlow === identificationFlow.electoralRollAndYotiId ||
    state.identificationFlow === identificationFlow.electoralRollWithYotiFallback
  ) {
    if (userIsIdVerified(state.user) || userIsElectoralRollVerified(state.user)) {
      showSubmit(state);
      return;
    }
    // If the flow is Electoral roll with Yoti ID and address fallback
  } else if (state.identificationFlow === identificationFlow.electoralRollWithYotiIdAndAddressFallback) {
    if (userIsIdVerified(state.user) || userIsAddressVerified(state.user)) {
      showSubmit(state);
      return;
    }

    // If the flow is Yoti ID and address
  } else if (state.identificationFlow === identificationFlow.yotiIdAndAddress) {
    if (userIsAddressVerified(state.user)) {
      showSubmit(state);
      return;
    } else {
      showYoti(state);
      return;
    }
    // Final fallback
  } else if (userIsElectoralRollVerified(state.user) || userIsAddressVerified(state.user)) {
    showSubmit(state);
    return;
  }

  state.uiToggles.show.address = true;
  state.uiToggles.show.yoti = false;
  state.blockYotiVueKey++;
  state.uiToggles.show.submitButton = false;
}

async function changeIdVerificationMethod(state: BlockKycInstance) {
  await state.infoModal.fire("warning", {
    cancelButtonText: "Electoral roll check",
    confirmButtonText: "Secure ID upload",
    title: "Identity verification",
    text: "Please select how you would like to verify your identity.",
    onConfirm: async () => {
      showYoti(state);
    },
    onHide: () => {
      showAddress(state);
    },
    noActionOnDismiss: true,
  });
}

/**
 * fire modals wive the user retry options if possible
 */
async function fireIdFailedModal(state: BlockKycInstance, service: "Yoti" | "Electoral Roll") {
  if (service === "Yoti") {
    if (!state.idFlowAllowsElectoralRoll) {
      await fireYotiVerificationFailedModal(state);
      return;
    }
    await state.infoModal.fire("warning", {
      confirmButtonText: "Try again",
      cancelButtonText: "Verify with Electoral roll",
      title: "Identity verification failed",
      text: "We were unable to match your details against the ID provided. Please try again or verify with Electoral roll.",
      onHide: async () => {
        showAddress(state);
      },
      onConfirm: () => {
        showYoti(state);
      },
      noActionOnDismiss: true,
    });
    return;
  }
  if (service === "Electoral Roll") {
    if (!state.idFlowAllowsYoti) {
      await state.infoModal.fire("error", {
        title: "No person found",
        text: "We were unable to match your details against the UK Electoral Register. Please ensure you use your full legal name and current residential address.",
      });
      return;
    }

    if (state.identificationFlow === identificationFlow.electoralRollWithYotiFallback && userIsIdVerified(state.user)) {
      // If user is Identity Upload verified & flow has id fall back auto submit
      emitSubmit(state);
      return;
    }

    if (
      state.identificationFlow === identificationFlow.electoralRollWithYotiIdAndAddressFallback &&
      userIsAddressVerified(state.user) &&
      userIsIdVerified(state.user)
    ) {
      // if user is Identity Upload verified & address verified & flow has id & address fall back auto submit
      emitSubmit(state);
      return;
    }

    await state.infoModal.fire("warning", {
      confirmButtonText: "Try Verification Again",
      cancelButtonText: "Upload Secure ID",
      title: "Identity Check Unsuccessful",
      text: "We were unable to match your details against the UK Electoral Register. Please ensure you use your full legal name and current residential address.",
      onHide: async () => {
        showYoti(state);
      },
      onConfirm: () => {
        showAddress(state);
      },
      noActionOnDismiss: true,
    });
  }
}

/** The user has failed yoti and needs to try again */
async function fireYotiVerificationFailedModal(state: BlockKycInstance) {
  await state.infoModal.fire("error", {
    title: "Identity Verification Unsuccessful",
    text: "Unfortunately, we have been unable to verify your identity. Please ensure your details are accurate and try again.",
  });
}

/**
 * Determine what verification step to take next based on the identificationFlow prop
 */
function addressCompleted(state: BlockKycInstance) {
  if (
    state.identificationFlow === identificationFlow.electoralRoll ||
    state.identificationFlow === identificationFlow.electoralRollWithYotiFallback ||
    state.identificationFlow === identificationFlow.electoralRollWithYotiIdAndAddressFallback ||
    state.identificationFlow === identificationFlow.electoralRollWithYotiIdAndAmlFallback ||
    state.identificationFlow === identificationFlow.none
  ) {
    showSubmit(state);
    console.log(">> KYC submit adress completed");
    dispatchNext(state);
  } else if (
    state.identificationFlow === identificationFlow.yotiId ||
    state.identificationFlow === identificationFlow.yotiAddress ||
    state.identificationFlow === identificationFlow.yotiIdAndAddress ||
    state.identificationFlow === identificationFlow.yotiIdAndAml
  ) {
    showYoti(state);
  } else {
    throw new Error(`Identification flow not yet supported: ${state.identificationFlow}`);
  }
}

/**
 * Render the submit page for the use, pause briefly for them to read it and then submit the page
 */
function showSubmit(state: BlockKycInstance) {
  state.uiToggles.show.address = false;
  state.uiToggles.show.submitButton = true;
}

function showAddress(state: BlockKycInstance) {
  state.uiToggles.show.address = true;
  state.uiToggles.show.yoti = false;
  state.blockYotiVueKey++;
  state.uiToggles.show.submitButton = false;
}

function dispatchNext(state: BlockKycInstance) {
  state.$store.dispatch("form/setLoader", { name: "next", value: true });
  setTimeout(() => {
    emitSubmit(state);
  }, 200);
}

/**
 * Initializes the yoti session and loads in the iFrame
 */
export function showYoti(state: BlockKycInstance): void {
  state.uiToggles.show.address = false;
  state.blockYotiVueKey++;
  state.$nextTick(() => {
    state.uiToggles.show.yoti = true;
  });
  state.uiToggles.show.submitButton = false;
}
