import { BlockPhoneInstance } from "../blockPhone";
import { isValidPhoneNumber } from "libphonenumber-js";
import { BlockProps } from "@/types";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
import {
  firePhoneValidationStartedEvent,
  firePhoneValidationFailedEvent,
  firePhoneValidationPassedEvent,
} from "./firePhoneSentryEvents";
import { reject } from "@/helpers/vue";

export async function validations(state: BlockPhoneInstance): Promise<boolean | string> {
  firePhoneValidationStartedEvent();
  // If we can skip processing then immediately terminate our execution
  if (state.uiToggles.isPhoneNumberDefined) {
    return true;
  }

  const phoneNumber = state.BlockInputs.phone?.answer;

  resetBlockError(state.BlockInputs.phone);

  if (!phoneNumber || phoneNumber.length === 0) {
    setBlockError(
      state,
      state.BlockInputs.phone,
      state.allowInternationalNumbers
        ? state.uiMessages.invalidInternationalPhoneNumber
        : state.uiMessages.invalidPhoneNumber
    );
    firePhoneValidationFailedEvent(state);
    return false;
  }
  if (state.allowInternationalNumbers) {
    if (
      !state.internationalPhoneNumber.number ||
      !state.internationalPhoneNumber.countryCode ||
      !isValidPhoneNumber(phoneNumber, state.internationalPhoneNumber.countryCode as any)
    ) {
      setBlockError(state, state.BlockInputs.phone, state.uiMessages.invalidInternationalPhoneNumber);
      firePhoneValidationFailedEvent(state);
      return false;
    }
  } else if (state.requireUkMobileNumber) {
    console.log("validating if UK mobile number");
    const isValidUkNumber = await onCallGateway<"isUkMobileNumber">({
      functionName: "isUkMobileNumber",
      data: {
        mobileNumber: phoneNumber,
        claimId: state.claimId,
        funnelId: state.funnelId,
        pageId: state.pageId,
        // workspaceId: state.workspaceId,
      },
    });
    if (!isValidUkNumber.data) {
      console.log("not valid number path", isValidUkNumber.data);
      setBlockError(state, state.BlockInputs.phone, state.uiMessages.nonUkMobileNumber);
      firePhoneValidationFailedEvent(state);
      return false;
    }
  } else {
    if (!isValidPhoneNumber(phoneNumber, "GB")) {
      setBlockError(state, state.BlockInputs.phone, state.uiMessages.invalidPhoneNumber);
      firePhoneValidationFailedEvent(state);
      return false;
    }
  }
  console.log("phone number is valid");
  firePhoneValidationPassedEvent(state);
  return true;
}

function resetBlockError(block: BlockProps.Input<string>) {
  block.state = null;
  block.invalidFeedback = "";
}

function setBlockError(state: BlockPhoneInstance, block: BlockProps.Input<string>, errorMessage: string) {
  reject(state, errorMessage);
  block.state = false;
  block.invalidFeedback = errorMessage;
}
