import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { BlockProps } from "@/types";

/** Each of the BlockButtonProps used in this component */
interface BlockButtonProps {
  searchAddressButton?: Partial<BlockProps.Button>;
}

/** Each of the BlockInputProps used in this component */
interface BlockInputProps {
  postcode: Partial<BlockProps.Input<string>>;
  addressSelect: Partial<BlockProps.Dropdown<string>>;
  addressLine1: Partial<BlockProps.Input<string>>;
  addressLine2: Partial<BlockProps.Input<string>>;
  town: Partial<BlockProps.Input<string>>;
  addressPostcode: Partial<BlockProps.Input<string>>;
  internationalPlace: Partial<BlockProps.Input<string>>;
}

export interface BlockClaimAddressProps extends BlockButtonProps, BlockInputProps {}

const buttons: BlockButtonProps = {
  searchAddressButton: { submitButtonDefault: false, text: "Search", block: false, size: "lg" },
};

const inputs: BlockInputProps = {
  postcode: UserVariableBlocksService.genBlockInputProps({ placeholder: "Postcode", label: "Postcode" }),
  addressSelect: UserVariableBlocksService.genBlockDropdownProps({ placeholder: "Please select the address" }),
  addressLine1: UserVariableBlocksService.genBlockInputProps(),
  addressLine2: UserVariableBlocksService.genBlockInputProps(),
  town: UserVariableBlocksService.genBlockInputProps(),
  addressPostcode: UserVariableBlocksService.genBlockInputProps(),
  internationalPlace: UserVariableBlocksService.genBlockInputProps(),
};

/** Each of the blocks used on the BlockClaimAddress component */
export const blocks = { buttons, inputs };
