import { FormState } from "../BlockFormInstance";
import { calculatePerformAtDate } from "./helpers";

/** Handles the transactional messages to be fired on the currently processed page */
export async function handlePageMessages(state: FormState) {
  const { messages } = state.page;
  if (messages) {
    // create task for sendMessage worker for each message to be sent
    await Promise.all(
      messages.map(async (message) => {
        await createTransactionalMessageTask(state, message.id, message.useVariables ?? false);
      })
    );
  }
}

/** Creates a task to send transactional message  */
async function createTransactionalMessageTask(state: FormState, messageId: string, useVariables: boolean = false) {
  try {
    // Data sanity check
    if (!state.userId || !state.claimId || !state.funnelId || !state.page?.id || !messageId) {
      return false;
    }

    const options = {
      userId: state.userId,
      claimId: state.claimId,
      funnelId: state.funnelId,
      pageId: state.page.id,
      messageId: messageId,
      useVariables: useVariables,
      messageType: "transactional",
    };
    const taskRef = state.db.collection("tasks").doc();

    const performAt = calculatePerformAtDate(0, useVariables);

    await taskRef.set({
      performAt: performAt,
      status: "scheduled",
      worker: "sendMessage",
      options: options,
    });

    console.log(">>> [createdTask] transactional message", taskRef.id);

    return true;
  } catch (exception) {
    console.error("!!! createTransactionalMessageTask", exception);
    return false;
  }
}
