import { getFirebaseBackend } from "@/authUtils";
import { getUserId } from "@/helpers/vue/users";
import { Claim, Funnel, Page, Theme } from "@claimsgate/core-types";
import { FunnelsService } from "@claimsgate/core";
import { IncompleteClaim } from "./types";

/**
 * @param workspaceIds - The workspaceIds to filter the claims by
 * @param funnelIds - The funnelIds to filter the claims by
 * @returns - The incomplete claims
 */
export async function getOtherClaims(
  workspaceId?: string,
  funnelIds?: string[],
  currentClaimId?: string
): Promise<Claim[]> {
  const userId = getUserId();

  if (!userId) {
    return [];
  }

  let query = getFirebaseBackend()
    .firestore()
    .collection("users")
    .doc(userId)
    .collection("claims")
    .where("clientClaimProgress", "==", "inProgress");

  if (workspaceId) {
    query = query.where("workspacesWithClaimDataAccess", "array-contains", workspaceId);
  }

  if (funnelIds && funnelIds.length > 0) {
    query = query.where("currentFunnelId", "in", funnelIds);
  }

  const claimsQuerySnapshot = await query.orderBy("createdAt", "desc").limit(20).get();

  const claims = claimsQuerySnapshot.docs.map((doc) => doc.data() as Claim);

  const incompletedClaims = claims.filter(
    (claim) =>
      claim.claimId !== currentClaimId &&
      (claim.actions.find((action) => action.kind === "completion") || claim.createdByApi)
  );

  return incompletedClaims;
}

/**
 * @param claims - The claims to get the funnel details for
 * @returns - The funnel details for the claims
 */
export async function getFunnelDetails(claims: Claim[]): Promise<Funnel[]> {
  const uniqueFunnelIds = [...new Set(claims.map((claim) => claim.currentFunnelId))];

  const savedFunnelDetails: Funnel[] = await Promise.all(
    uniqueFunnelIds.map(async (funnelId) => {
      const funnelDetails = await getFirebaseBackend().firestore().collection("funnels").doc(funnelId).get();
      return funnelDetails.data() as Funnel;
    })
  );

  return savedFunnelDetails;
}

/**
 * @param funnels - The funnels to get the themes for
 * @returns - The themes for the funnels
 */
export async function getWorkspaceTheme(workspaceId: string): Promise<Theme> {
  const workspaceDetails = await getFirebaseBackend()
    .firestore()
    .collection("themes")
    .where("workspaceId", "==", workspaceId)
    .get();

  const workspaceTheme = workspaceDetails.docs[0].data() as Theme;

  return workspaceTheme;
}

/**
 * @param claims - The claims to build the incomplete claims for
 * @param funnelDetails - The funnel details to get the funnel for the claim
 * @param workspaceThemes - The workspace themes to get the theme for the claim
 * @returns - The incomplete claims
 */
export async function buildIncompleteClaim(claim: Claim[], funnelDetails: Funnel[], workspaceTheme: Theme) {
  const incompleteClaims: IncompleteClaim[] = await Promise.all(
    claim.map(async (claim) => {
      const funnel = funnelDetails.find((funnel) => funnel.id === claim.currentFunnelId);
      if (!funnel) return null;

      const currentPage = (
        await getFirebaseBackend()
          .firestore()
          .collection("funnels")
          .doc(claim.currentFunnelId)
          .collection("pages")
          .doc(claim.currentPageId)
          .get()
      ).data() as Page;

      if (!currentPage || !currentPage.next || currentPage.next.pageId === "None") {
        return null;
      }

      return {
        claim: claim,
        funnel: funnel,
        theme: workspaceTheme,
      };
    })
  );

  const filteredIncompleteClaims = incompleteClaims.filter((claim): claim is IncompleteClaim => claim !== null);

  console.log("incompleteClaims", filteredIncompleteClaims);
  return filteredIncompleteClaims;
}

/**
 * @param workspaceId - The workspace ID to get the image for
 * @returns - The workspace image
 */
export async function getWorkspaceImage(workspaceId: string): Promise<string> {
  const workspace = await getFirebaseBackend().firestore().collection("workspaces").doc(workspaceId).get();

  return workspace.data().logo;
}

/**
 * @param workspaceId - The workspace ID to get the public funnels for
 * @param funnelId - The funnel ID to exclude
 * @returns - The public funnels
 */
export async function displayOtherFunnels(workspaceId: string, funnelId: string): Promise<Funnel[]> {
  const funnelsService = new FunnelsService(getFirebaseBackend().firestore());
  const { data: funnels, error } = await funnelsService.getPublicFunnels(workspaceId);

  if (error || !funnels) {
    console.error("Error fetching public funnels:", error);
    return [];
  }

  return funnels;
}
