import { Quantum } from "@claimsgate/core";
import { Dictionary } from "vue-router/types/router";
import { ClaimDataService } from "../ClaimsGate/DataService";

/** Caluclates the termsOfBusinessRate for Thompsons Solicitors Claimants in % */
export function calculateTermsOfBusinessRate(
  claimDataService: ClaimDataService,
  funnelId: string,
  query: Dictionary<string | string[]>
): void {
  if (funnelId === Quantum.Funnels.THOMPSONS_SOLICITORS_DIESEL_EMISSIONS_ID) {
    // If the query parameter "ff" has been passed then we set the Terms of Busines percent to 12
    if (Object.keys(query).includes("ff")) {
      claimDataService.setArtefact("termsOfBusinessRate", 12);
    } else {
      claimDataService.setArtefact("termsOfBusinessRate", 40);
    }
  }

  if (funnelId === Quantum.Funnels.THOMPSONS_SOLICITORS_MOTOR_FINANCE_ID) {
    // Friends and Family - 12%
    if (Object.keys(query).includes("ff")) {
      claimDataService.setArtefact("termsOfBusinessRate", 12);
      return;
    }

    // Union Clients - 25%
    if (Object.keys(query).includes("uc")) {
      claimDataService.setArtefact("termsOfBusinessRate", 25);
      return;
    }

    // Standard Clients - 40%
    claimDataService.setArtefact("termsOfBusinessRate", 40);
    return;
  }
}
