<script lang="ts">
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { eventComputed, formComputed, themesComputed } from "@/state/helpers";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockParagraph from "@/components/shared/blocks/paragraph.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import Vue from "vue";
import { Props } from "@/types";
export default Vue.extend({
  name: "BlockPageButtons",
  props: {
    // * will use this when page buttons release in full
    text: {
      type: String,
      required: false,
      allowVariable: false,
      default: "Continue",
      description: "The text to display on the submit button",
    } as Props.Text,
    block: {
      type: Boolean,
      required: false,
      default: false,
      allowVariable: false,
    } as Props.Bool,
    align: {
      type: String,
      required: false,
      allowVariable: false,
      default: "center",
      valid: ["left", "center", "right"],
      description: "The alignment of text to display",
    } as Props.Align,
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    } as Props.Str,
    currentIteration: {
      description: "The current iteration of a repeatable page",
    } as Props.Num,
    isRepeatable: {
      type: Boolean,
      required: true,
      default: false,
      description: "Determines whether the repeatable page buttons will render",
    } as Props.Bool,
    totalIterations: {
      type: Number,
      required: false,
      default: 1,
      description: "The total number of iterations of the page that currently exist",
    } as Props.Num,
    counterText: {
      type: String,
      required: false,
      default: "Submission",
      description: "The text to be prepended to the submission counter",
    } as Props.Str,
  },
  components: {
    BlockButton,
    BlockSingleSelect,
    BlockPadding,
    BlockParagraph,
  },
  computed: {
    ...eventComputed,
    ...formComputed,
    ...themesComputed,
    nextRepeatableText(): string {
      if (this.addAnotherBlockSelectProps.answer === "Yes") {
        return "Add";
      } else {
        return this.text;
      }
    },
    actualTotalIterations(): number {
      //this.totalIterations is a count of the store as. If user has added a new iteration, total wil be  <= current
      if (this.totalIterations === this.currentIteration) {
        return this.totalIterations + 1;
      } else {
        return this.totalIterations;
      }
    },
  },
  data() {
    return {
      // repeatButtons: {
      //   next: UserVariableBlocksService.genBlockButtonProps({
      //     text: "Next",
      //     submitButtonDefault: true,
      //     block: false,
      //     variant: "secondary"
      //   }),
      //   previous: UserVariableBlocksService.genBlockButtonProps({
      //     outline: true,
      //     text: "Previous",
      //     submitButtonDefault: true,
      //     block: false,
      //     variant: "secondary"
      //   }),
      //   delete: UserVariableBlocksService.genBlockButtonProps({
      //     outline: true,
      //     text: "Delete",
      //     variant: "danger",
      //     submitButtonDefault: true,
      //     block: false,
      //     size: "sm"
      //   })
      // },
      submitButton: UserVariableBlocksService.genBlockButtonProps({
        text: "Continue",
        block: false,
        submitButtonDefault: true,
      }),
      addAnotherBlockSelectProps: UserVariableBlocksService.genBlockSingleSelectProps({
        label: "Add another?",
        options: ["Yes", "No"],
        answer: "No",
      }),
      repeatableText: UserVariableBlocksService.genBlockTextProps(),
    };
  },
  methods: {
    emitEvent(name: string) {
      window.console.log("page buttons called emit event with", name);
      const eventObject = { name: name };
      this.$store.dispatch("events/fire", eventObject);
    },
    submit() {
      if (this.addAnotherBlockSelectProps.answer === "Yes") {
        // move to next iteration
        this.emitEvent(this.eventValues.nextRepeatablePage);
      } else {
        this.emitEvent(this.eventValues.next);
      }
    },
  },
  watch: {
    text: {
      handler() {
        this.submitButton.text = this.text;
      },
      immediate: true,
    },
    block: {
      handler() {
        this.submitButton.block = this.block;
      },
      immediate: true,
    },
    align: {
      handler() {
        this.submitButton.align = this.align;
      },
      immediate: true,
    },
  },
});
</script>
<template>
  <div>
    <BlockPadding :padding="padding" />
    <template v-if="isRepeatable">
      <!-- Previous/delete/next buttons  --- leave this code here incase we want to use this feature 
      <b-row>
        <template v-if="currentIteration !== 0">
          <b-col>
            <BlockButton
              v-bind="repeatButtons.previous"
              @submit="emitEvent(eventValues.previousRepeatablePage)"
              :isProcessing="loaders.previousRepeatablePage"
              class="float-left"
            />
          </b-col>
          <b-col>
            <BlockButton v-bind="repeatButtons.delete" @submit="emitEvent(eventValues.deleteCurrentRepeatablePage)" />
          </b-col>
        </template>
        <b-col>
          <BlockButton
            v-bind="repeatButtons.next"
            @submit="emitEvent(eventValues.nextRepeatablePage)"
            :isProcessing="loaders.nextRepeatablePage"
            class="float-right"
            :text="nextRepeatableText"
          />
        </b-col>
      </b-row> -->
      <template>
        <BlockSingleSelect v-bind="addAnotherBlockSelectProps" v-bind.sync="addAnotherBlockSelectProps" />
      </template>

      <BlockParagraph align="right" :text="''">
        {{ counterText }}: {{ currentIteration + 1 }} / {{ actualTotalIterations }}
      </BlockParagraph>

      <template>
        <BlockButton v-bind="submitButton" @submit="submit" :isProcessing="loaders.next" :text="nextRepeatableText" />
      </template>
    </template>
  </div>
</template>
