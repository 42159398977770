/**
 * Events mixin provides a series of helper methods which can help add events
 * to a page in Claims Gate
 * @param {*} superclass
 * @returns
 */
export const eventsMixin = superclass =>
  class extends superclass {
    /**
     * Adds an empty event to the page event
     */
    addEvent(events) {
      const eventStruct = "";
      events.push(eventStruct);
    }

    /**
     * Removes an event from the page
     */
    deleteEvent(events, eventIndex) {
      if (events && events.length === 1) {
        return;
      } else {
        events.splice(eventIndex, 1);
      }
    }
  };
