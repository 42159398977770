var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column"},[_c('BlockPadding',{attrs:{"padding":_vm.padding},on:{"update:padding":function($event){_vm.padding=$event}}}),(!_vm.isBuilder)?[(!_vm.state.uiToggles.isLoading)?_c('div',[_c('b-card',{staticClass:"w-75 mx-auto p-1 mb-4 border-0 shadow-md d-flex align-items-start",style:([
          _vm.workspaceTheme.fontStyles,
          _vm.workspaceTheme.primaryStyles,
          { backgroundColor: _vm.workspaceTheme.backgroundColour },
        ])},[_c('div',{staticClass:"mr-3 mb-3",staticStyle:{"width":"72px","height":"36px"}},[_c('img',{staticStyle:{"object-fit":"contain","width":"100%","height":"100%"},attrs:{"src":_vm.workspaceImage,"alt":"Workspace Image"}})]),_c('div',{staticClass:"text-left"},[_c('h2',{staticClass:"text-grey-900 text-md font-weight-semibold",style:(_vm.workspaceTheme.fontStyles)},[(_vm.incompleteClaims.length > 0)?[_vm._v(" You have "+_vm._s(_vm.incompleteClaims.length)+" outstanding "),(_vm.incompleteClaims.length === 1)?_c('span',[_vm._v("claim")]):_c('span',[_vm._v("claims")])]:[_vm._v(" Discover additional claims ")]],2),(_vm.incompleteClaims.length > 0)?_c('span',{staticClass:"text-grey-700 text-sm",style:(_vm.workspaceTheme.fontStyles)},[_vm._v(" Please complete the outstanding steps for each claim listed below: ")]):_c('span',{staticClass:"text-grey-700 text-sm",style:(_vm.workspaceTheme.fontStyles)},[_vm._v(" Now that your signup is complete, take a moment to review the additional claims available to you below. ")])])]),(_vm.incompleteClaims.length > 0)?_vm._l((_vm.incompleteClaims),function(incompleteClaim){return _c('div',{key:incompleteClaim.claim.claimId},[_c('b-card',{staticClass:"w-75 mx-auto mb-3 shadow-md px-1",style:([
              incompleteClaim.theme.fontStyles,
              incompleteClaim.theme.primaryStyles,
              { backgroundColor: incompleteClaim.theme.backgroundColour },
            ])},[_c('CardHeader',{attrs:{"heading":_vm.templatedDisplayNames[incompleteClaim.claim.claimId] || incompleteClaim.funnel.displayName,"supportingText":`Complete your claim details for compensation related to your ${incompleteClaim.funnel.title}`,"headingTextClass":`${incompleteClaim.theme.fontStyles['font-family']} text-grey-900`,"headingTextSize":"text-md","supportingTextSize":"text-sm","supportingTextClass":incompleteClaim.theme.fontStyles['font-family']}}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',_vm._b({staticClass:"w-100 mx-auto mt-3",style:([
                  incompleteClaim.theme.fontStyles,
                  incompleteClaim.theme.primaryStyles,
                  { backgroundColor: incompleteClaim.theme.primaryColour },
                ]),attrs:{"data-cy":"otherclaims-complete-claim-button"},on:{"click":function($event){return _vm.formRoute(
                    incompleteClaim.claim.currentFunnelId,
                    incompleteClaim.claim.currentPageId,
                    incompleteClaim.claim.claimId
                  )}}},'b-button',_vm.BlockInputs.incompletedClaimButton,false,true),[_c('span',{style:(incompleteClaim.theme.fontStyles)},[_vm._v(_vm._s(_vm.BlockInputs.incompletedClaimButton.text))])])],1)],1)],1)}):_vm._l((_vm.publicFunnels),function(funnel){return _c('div',{key:funnel.id},[_c('b-card',{staticClass:"w-75 mx-auto mb-3 shadow-md px-1",style:([
              _vm.workspaceTheme.fontStyles,
              _vm.workspaceTheme.primaryStyles,
              { backgroundColor: _vm.workspaceTheme.backgroundColour },
            ])},[_c('CardHeader',{attrs:{"heading":funnel.title ? funnel.title : 'No funnel title',"supportingText":funnel.description ? funnel.description : 'No description',"headingTextClass":`${_vm.workspaceTheme.fontStyles['font-family']} text-grey-900`,"headingTextSize":"text-md","supportingTextSize":"text-sm","supportingTextClass":_vm.workspaceTheme.fontStyles['font-family']}}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',_vm._b({staticClass:"w-100 mx-auto mt-3",style:([
                  _vm.workspaceTheme.fontStyles,
                  _vm.workspaceTheme.primaryStyles,
                  { backgroundColor: _vm.workspaceTheme.primaryColour },
                ]),attrs:{"data-cy":"otherclaims-start-claim-button"},on:{"click":function($event){return _vm.formRoute(funnel.id)}}},'b-button',_vm.BlockInputs.noClaimsButton,false,true),[_c('span',{style:(_vm.workspaceTheme.fontStyles)},[_vm._v(_vm._s(_vm.BlockInputs.noClaimsButton.text))])])],1)],1)],1)})],2):_c('div',[_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('LoadingBar',{attrs:{"loading":_vm.state.uiToggles.isLoading}})],1)])]:[_c('b-card',{staticClass:"w-50 mx-auto p-3 text-center"},[_c('h4',{staticClass:"text-grey-900"},[_vm._v("Make sure a workspaceId has been entered")]),_c('p',{staticClass:"text-grey-700"},[_vm._v("You must enter a workspaceId for this block to work.")])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }