import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { BlockProps } from "@/types";

export interface FinanceAgreementBlockProps {
  financeAgreementsTitle: BlockProps.Text;
  financeAgreementsSubTitle: BlockProps.Text;
  financeAgreementCopyQuestion: BlockProps.SingleSelect;
  fileInput: BlockProps.FileInput;
  dsarSubTitle: BlockProps.Text;
  dsarHtml: BlockProps.HTML;
  fileInputButton: BlockProps.Button;
  deferUpload: BlockProps.SingleSelect;
}

const texts = {
  financeAgreementsTitle: { text: "Finance Agreement" },
  financeAgreementsSubTitle: { text: "" },
  dsarSubTitle: { text: "DSAR Request Service: Information and Consent", align: "left" },
};

const buttons = {
  fileInputButton: { submitButtonDefault: false, text: "Continue", block: false },
};

const inputs = {
  fileInput: UserVariableBlocksService.genBlockFileInputProps(),
  financeAgreementCopyQuestion: UserVariableBlocksService.genBlockSingleSelectProps({
    label: "Do you have a copy of your finance agreement available to upload now?",
    // info: "Please select the vehicle you owned",
    options: ["Yes", "No"],
  }),
  deferUpload: UserVariableBlocksService.genBlockSingleSelectProps({
    label: "Are you able to upload your finance agreement at later date?",
    options: ["Yes", "No"],
  }),
};

export const blocks = { texts, buttons, inputs };
