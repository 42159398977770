import { MessageSendMethod, MessageType, ReminderSequenceMessage } from "@claimsgate/core-types";
import { v4 as uuidv4 } from "uuid";

export type Message = ReminderSequenceMessage;

/**
 * Capture Field Mixin provides a series of helper methods which help connect multiple funnels together in Claims
 * Gate
 * @param {*} superclass
 * @returns
 */
export const messagesMixin = (superclass: any) =>
  class extends superclass {
    /**
     * Returns the default message strucure
     * @returns
     */
    getMessageStructure(messageType: MessageType): Message {
      const structure: Message = {
        id: uuidv4(),
        subject: "",
        body: "Dear {{firstName}}<br /><br /><br />Kind Regards<br />",
        useVariables: false,
        variableSelected: "",
        type: "email",
        rawFiles: [],
      };

      if (messageType === "reminder") {
        structure.delay = 1;
      }

      return structure;
    }
    /**
     * Adds a reminder message
     * @param messages
     * @returns
     */
    addMessage(messages: Array<Message>): void {
      messages.push(this.getMessageStructure("reminder"));
    }

    /**
     * Removes a given reminder message by index
     * @param messages - List of messages
     * @param messageIndex  - Index of message to remove
     */
    deleteMessage(messages: Array<Message>, messageIndex: number): void {
      messages.splice(messageIndex, 1);
    }
  };
