<script lang="ts">
import Vue from "vue";
import { Fragment } from "vue-frag";
import PhoneNumberInput from "@/components/shared/blocks/user/phone/phoneNumberInput/phoneNumberInput.vue";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { BlockProps } from "@/types";
import { Props } from "@/types";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import { PropOptions } from "vue/types/umd";

import { isValidNumber } from "libphonenumber-js";
const blockPhoneInputProps = inputProps({
  answerType: "string",
  extraProps: ["padding", "label"],

  excludeDefaults: true,
}) as Props.BlockSimplePhone;
export default Vue.extend({
  name: "BlockSimplePhone",
  props: {
    answer: {
      type: String,
      required: false,
      default: () => "",
    } as PropOptions<string>,

    info: {
      type: String,
      required: false,
      default: () => "",
    } as PropOptions<string>,

    ...blockPhoneInputProps,
    storeAs: {
      type: Object,
    } as PropOptions<object>,

    condition: {
      type: Object,
      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },
    required: {
      type: Boolean,
      required: false,
      default: () => true,
    } as PropOptions<boolean>,
  },
  components: {
    Fragment: Fragment as any,
    PhoneNumberInput,
  },
  data() {
    return {
      BlockInputs: UserVariableBlocksService.getBlocks(["phone"]) as Record<"phone", BlockProps.Input<string>>,
      formattedNumber: "",

      uiMessages: {
        phoneInvalid: "Please enter a valid phone number",
      },
    };
  },
  mounted() {
    if (this.answer) {
      this.BlockInputs.phone.answer = this.answer;
    }
  },
  watch: {
    label: {
      handler() {
        this.BlockInputs.phone.label = this.label;
      },
      immediate: true,
    },
    info: {
      handler() {
        this.BlockInputs.phone.info = this.info;
      },
      immediate: true,
    },
  },
  methods: {
    validations() {
      this.BlockInputs.phone.state = null;

      if (!this.required && !this.formattedNumber) {
        this.BlockInputs.phone.state = true;

        return true;
      }

      console.log(isValidNumber(this.BlockInputs.phone.answer));

      if (!isValidNumber(this.BlockInputs.phone.answer)) {
        this.BlockInputs.phone.invalidFeedback = this.uiMessages.phoneInvalid;
        this.BlockInputs.phone.state = false;
        return false;
      }

      if (!this.BlockInputs.phone.answer || this.BlockInputs.phone.answer.length < 6) {
        this.BlockInputs.phone.invalidFeedback = this.uiMessages.phoneInvalid;
        this.BlockInputs.phone.state = false;
        return false;
      } else {
        return true;
      }
    },
    computes() {
      this.BlockInputs.phone.state = true;

      if (!this.BlockInputs.phone.answer && !this.required) {
        return null;
      }

      return this.BlockInputs.phone.answer;
    },
    phoneNumberInputChanged(
      phoneNumber: string,
      phoneNumberObject: {
        country: {
          dialCode: string;
          iso2: string;
          name: string;
        };

        countryCallingCode: string;
        countryCode: string;
        formatted: string;
        nationalNumber: string;
        number: string;
        valid: boolean;
      }
    ) {
      console.log(phoneNumberObject);

      if (phoneNumberObject.valid) {
        this.BlockInputs.phone.answer = phoneNumberObject.number;
        this.formattedNumber = phoneNumberObject.formatted;
      } else {
        this.BlockInputs.phone.answer = "";
        this.formattedNumber = phoneNumberObject.formatted;
      }
    },
  },
});
</script>
<template>
  <Fragment>
    <PhoneNumberInput
      @input="phoneNumberInputChanged"
      :preferredCountries="[
        'GB',
        'US',
        'CA',
        'AU',
        'DE',
        'FR',
        'IT',
        'ES',
        'NL',
        'BR',
        'MX',
        'ZA',
        'SE',
        'NO',
        'DK',
        'BE',
        'CH',
        'IE',
        'NZ',
      ]"
      :existingPhoneNumber="BlockInputs.phone.answer"
      :invalidFeedback="BlockInputs.phone.invalidFeedback"
      :state="BlockInputs.phone.state"
      :label="BlockInputs.phone.label"
      :info="BlockInputs.phone.info"
      :padding="padding"
    />
  </Fragment>
</template>
