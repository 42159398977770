import Vue from "vue";
export function getData(state: Vue) {
    return {
      uiToggles: {
        isEntityValidating: false,
      },
      options: [
        "Helsinki (September 2014)",
        "Malaysia (November 2014)",
        "Hong Kong (January 2015)",
        "Dubai (May 2015)",
        "Economist Conference in Bulgaria (October 2015)",
        "London (March 2016)",
        "London (Wembley) (June 2016)",
        "Macau (September 2016)",
        "Bangkok (October 2016)",
        "Lisbon (October 2017)",
        "Bangkok (December 2017)",
        "Melbourne (October 2018)",
        "Other",
      ],
      selected: [],
      selectedOrganised: [],
      dates: [],
      datePickerStates: [],
      answers: [
        //     {
        //     location: "Helsinki (September 2014)",
        //     date: "",
        // }
      ],
      otherText: "",
      otherState: null,
      state: null,
    };
}