export default {
  lastUpdatedOn: "Gugatan terakhir diperbarui pada:",
  discoverButton: "Mula",
  discoverHeading: "Temui tuntutan dengan",

  claimCompleted:
    "Gugatan Anda telah selesai, artinya Anda tidak memerlukan detail lebih lanjut saat ini.  Anda akan menerima pembaruan berkala mengenai status gugatan Anda di sini dan melalui email.",
  startNew: "Mulai gugatan baru",
  status: "Status",
  contactUs: "Hubungi Kami",
  hereToHelp: "Tim kami siap membantu Anda.  Klik di sini untuk mengirimkan pesan kepada kami.",
  contact: "Hubungi",
  sendUsAMessage: "Kirimkan pesan kepada kami, maka kami akan menghubungi Anda secepatnya.",
  name: "Nama",
  email: "Email",
  message: "Pesan",
  send: "Kirim",
  cancel: "Batalkan",
  view: "Lihat",
  files: "Files",

  history: "Riwayat",
  myClaims: "Gugatan Saya",
  updatedOn: "Diperbarui pada",
  finish: "Selesai",
  notComplete: "Anda belum menyelesaikan semua langkah yang diperlukan untuk gugatan Anda.",
  continueMyClaim: "Lanjutkan gugatan saya",
  mySettings: "Pengaturan Saya",

  identityPicture1: "Gambar Identiti 1",
  identityPicture2: "Gambar Identiti 2",
  shareLink: "Kongsi Pautan",
  copyLink: "Salin Pautan",
  withdrawInterest: "Tarik Kepentingan",

  information: "Maklumat",
  clickToGo: "Klik di sini untuk pergi ke",
  homePage: "halaman utama",
  shareThe: "Kongsi",
  withSomeone: "dengan seseorang yang mungkin layak.",
  pageCompleted: "Halaman selesai",
};
