export default {
  me: "Me",
  myClaims: "My Claims",
  menu: {
    myDetails: "My Details",
    settings: "Settings",
    legal: "Legal",
    logOut: "Log Out",
    logIn: "Log In",
  },
};
