import { Route } from "vue-router/types/router";
import { Block, Container } from "@claimsgate/core-types";
export interface BlockAnswer<T> extends Block {
  answer: T;
  state: { [P in keyof T]?: boolean };
  invalidFeedback: { [P in keyof T]?: string };
}

export interface BlockEmailAnswer {
  email: string;
  emailConfirm: string;
}

export interface BlockEmail extends BlockAnswer<BlockEmailAnswer> {}

export interface BlockPhone extends Block {
  answer: string;
  state: boolean;
  invalidFeedback: string;
}

export const userBlocks = ["BlockNationalInsurance", "BlockEmail", "BlockPhone"] as const;
export type UserBlock = typeof userBlocks[number];

export type MutatorFunction<T> = (block: Block | Container) => T;

export interface FormRoute extends Route {
  params: { funnelId: string; pageId?: string; claimId?: string };
}
