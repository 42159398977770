var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Fragment',[_c('RecoverAccount',{attrs:{"userIdentifier":_vm.recover.userIdentifier,"claimId":_vm.$route.params.claimId,"uniqueModalId":"Phone","verifyOnly":false},on:{"authSuccessful":function($event){return _vm.handleAccountRecovered()},"ready":function($event){return _vm.$bvModal.show(`RecoverAccountPhone`)}}}),(_vm.allowInternationalNumbers)?[_c('PhoneNumberInput',{attrs:{"preferredCountries":[
        'GB',
        'US',
        'CA',
        'AU',
        'DE',
        'FR',
        'IT',
        'ES',
        'NL',
        'BR',
        'MX',
        'ZA',
        'SE',
        'NO',
        'DK',
        'BE',
        'CH',
        'IE',
        'NZ',
      ],"existingPhoneNumber":_vm.BlockInputs.phone.answer,"invalidFeedback":_vm.$te('blocks.contactDetails.' + _vm.BlockInputs.phone.invalidFeedback)
          ? _vm.$t('blocks.contactDetails.' + _vm.BlockInputs.phone.invalidFeedback)
          : _vm.BlockInputs.phone.invalidFeedback,"state":_vm.BlockInputs.phone.state,"label":_vm.BlockInputs.phone.label,"info":_vm.BlockInputs.phone.info,"padding":_vm.padding,"disabled":_vm.parentIsProcessing || _vm.BlockInputs.phone.disabled},on:{"input":_vm.phoneNumberInputChanged}})]:[_c('BlockInput',_vm._b({attrs:{"padding":_vm.padding,"inputMode":'numeric',"disabled":_vm.parentIsProcessing || _vm.BlockInputs.phone.disabled}},'BlockInput',_vm.BlockInputs.phone,false,true))]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }