export enum ClaimsGateAppName {
  funnelBuilder = "Funnels",
  crm = "CRM",
  workspace = "Workspace"
}

export enum ClaimsGateAppRoute {
  funnelBuilder = "Funnels",
  crm = "CRM",
  workspace = "Workspace"
}

export interface ClaimsGateApp {
  id: string;
  name: ClaimsGateAppName;
  route: ClaimsGateAppRoute;
}

export namespace ClaimsGateApps {
  export namespace FunnelBuilder {
    export const funnelBuilder: ClaimsGateApp = {
      id: "usFUJpd0jyq0Z30v770h",
      name: ClaimsGateAppName.funnelBuilder,
      route: ClaimsGateAppRoute.funnelBuilder
    };
  }

  export namespace Workspace {
    export const workspace: ClaimsGateApp = {
      id: "SUbG4QDotGADAUtOQJ2s",
      name: ClaimsGateAppName.workspace,
      route: ClaimsGateAppRoute.workspace
    };
  }
}

/** Associates a Claims Gate appId to its associated routing information  */
export const ClaimsGateIdsToAppRoutes = {
  [ClaimsGateApps.FunnelBuilder.funnelBuilder.id]: ClaimsGateApps.FunnelBuilder.funnelBuilder
};
