import { eventComputed, formComputed, navigationComputed } from "@/state/helpers";
import { BlockContactDetailsInstance } from "./blockContactDetails";

export function mappedComputed() {
  return {
    ...(navigationComputed as Record<keyof typeof navigationComputed, any>),
    ...(eventComputed as Record<keyof typeof eventComputed, any>),
    ...(formComputed as Record<keyof typeof formComputed, any>),
  };
}

export const computed = {
  loaderState,
};

function loaderState(state: BlockContactDetailsInstance, formLoader?: boolean): boolean {
  // If the form is processing but we are not return true
  if (formLoader && !state.isProcessing) {
    return true;
  }

  // If we are processing but the form is not return true
  if (state.isProcessing && !formLoader) {
    return true;
  }

  // If we are processing and the form is processing return true
  if (state.isProcessing && formLoader) {
    return true;
  }

  return false;
}
