import { getFirebaseBackend } from "@/authUtils";
import { computes } from "./helpers/computes";
import { saveEmail } from "./helpers/saveEmail";
import { mounted } from "./mounted";
import { validations } from "./helpers/validations";
import { BlockEmailInstance } from "./BlockEmail";

export const methods = {
  computes,
  validations,
  mounted,
  handleAccountRecovered,
  saveEmail,
};

/** Handles the event triggered by the RecoverAccount component when the account has been successfully recovered */
function handleAccountRecovered(state: BlockEmailInstance) {
  // Push to the same route with an added query parameter 'accountRecovered'
  // await state.$router.push({ query: { accountRecovered: "yes", ...state.$route.query } });

  window.location.reload();
}
