export default {
  myDetails: "Detail Saya",
  fullName: "Nama Lengkap",
  noNameFound: "Tidak ada nama yang ditemukan",
  simplifyClaimAddName: "Permudah gugatan Anda di masa mendatang dengan menambahkan nama ke akun Anda. ",
  addName: "Tambahkan Nama",
  contactDetails: "Detail Kontak",
  address: "Alamat",
  cancel: "Batalkan",
  save: "Simpan",
  noAddressFound: "Tidak ada alamat yang ditemukan",
  simplifyClaimAddAddress: "Permudah gugatan Anda di masa mendatang dengan menambahkan alamat ke akun Anda.",
  communicationPreferences: "Preferensi Komunikasi",
  emailReminders: "Pengingat Email",
  receiveEmailReminders: "Terima email pengingat untuk gugatan Anda ",
  smsReminders: "Pengingat SMS",
  receiveSmsReminders: "Terima pengingat SMS untuk gugatan Anda",
  settingsUpdated: "Pengaturan Diperbarui",
  preferencesUpdated: "Preferensi komunikasi Anda telah diperbarui",
  withdrawClaim: {
    title: "Withdraw Claim",
    text: "Are you sure you want to withdraw your claim? This can not be reversed.",
    cancel: "No. Keep it",
    confirm: "Yes. Withdraw",
  },
};
