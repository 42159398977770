export default {
  myDetails: "التفاصيل الخاصة بي",
  fullName: "الاسم بالكامل",
  noNameFound: "لم يتم العثور على اسم",
  simplifyClaimAddName: "اعمل على تبسيط مطالباتك المستقبلية عن طريق إضافة اسم إلى",
  addName: "إضافة اسم",
  contactDetails: "بيانات الاتصال",
  address: "العنوان",
  cancel: "إلغاء",
  save: "حفظ",
  noAddressFound: "لم يتم العثور على عنوان",
  simplifyClaimAddAddress: "اعمل على تبسيط مطالباتك المستقبلية عن طريق إضافة عنوان إلى ",
  communicationPreferences: "تفضيلات التواصل",
  emailReminders: "تذكيرات البريد الإلكتروني",
  receiveEmailReminders: "استلم تذكيرات البريد الإلكتروني الخاصة بك",
  smsReminders: "تذكيرات الرسائل القصيرة",
  receiveSmsReminders: "استلم تذكيرات الرسائل القصيرة الخاصة بك",
  settinsUpdated: "تم تحديث الإعدادات",
  preferencesUpdated: "تم تحديث تفضيلات التواصل الخاصة بك",
  withdrawClaim: {
    title: "سحب المطالبة",
    text: "هل أنت متأكد أنك تريد سحب مطالبتك؟ لا يمكن عكس هذا",
    cancel: "لا. احتفظ به",
    confirm: "نعم. اسحب",
  },
};
