<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { methods, getProps, getData, BlockCarwebInstance } from "./BlockCarweb";
import { Fragment } from "vue-frag";

// Components

import BlockButton from "@/components/shared/blocks/button.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockSubtitle from "@/components/shared/blocks/text/subtitle.vue";
import BlockTitle from "@/components/shared/blocks/text/title.vue";
import BlockHtml from "@/components/shared/blocks/html.vue";
import BlockLoadingBar from "@/components/shared/layout/loadingBar.vue";

import { computed, mappedComputed } from "./computed";
import { mounted } from "./mounted";

export default (Vue as VueConstructor<Vue>).extend({
  name: "BlockCarweb",
  components: {
    Fragment,
    BlockButton,
    BlockSingleSelect,
    BlockPadding,
    BlockSubtitle,
    BlockTitle,
    BlockHtml,
    BlockLoadingBar,
  },
  props: { ...getProps() },

  computed: {
    ...mappedComputed(),
    aComputed(): string {
      return computed.aComputed(this as unknown as BlockCarwebInstance);
    },
  },
  data() {
    return { ...getData(this) };
  },

  watch: {
    padding: {
      handler(padding): void {
        for (const blockInput of Object.values(this.BlockInputs)) {
          blockInput.padding = padding;
        }
      },
      immediate: true,
    },
    invalidFeedback: {
      handler(invalidFeedback): void {
        this.setInvalidationFeedback(invalidFeedback);
      },
    },
  },

  methods: {
    async submitVehicleHistorySelect(): Promise<void> {
      await methods.submitVehicleHistorySelect(this as unknown as BlockCarwebInstance);
    },

    displayHelpModal(): void {
      methods.displayHelpModal(this as unknown as BlockCarwebInstance);
    },
    notMyVehicle(): void {
      methods.notMyVehicle(this as unknown as BlockCarwebInstance);
    },

    setInvalidationFeedback(invalidFeedback: string): void {
      methods.setValidationInvalidFeedback(this as unknown as BlockCarwebInstance, invalidFeedback);
    },
    async searchVehicle(): Promise<void> {
      await methods.searchVehicle(this as unknown as BlockCarwebInstance);
    },

    async submitKeeperSelect(): Promise<void> {
      await methods.submitKeeperSelect(this as unknown as BlockCarwebInstance);
    },
  },
  async mounted(): Promise<void> {
    await mounted(this as unknown as BlockCarwebInstance);
  },
});
</script>

<style scoped>
.error {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #e13f45 !important;
  transition: 0.25s;
}
.selected {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #3452e1 !important;
  transition: 0.25s;
}
.deselected {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #eff2f7 !important;
  transition: 0.25s;
}

.input-group > .input-group-prepend {
  flex: 0 0 15%;
}
.input-group .input-group-text {
  width: 100%;
}
/* Reg Input styling */
.reg-input-selected {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  border-right-width: 0px !important;
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #ced4da !important;
  transition: 0.25s;
}
.reg-input-selected:focus {
  border-color: var(--primary) !important;
}
.input-group-prepend:focus {
  border-color: var(--primary) !important;
}
.btn-glow,
.btn-glow:active,
.btn-glow:focus,
.btn-glow:hover {
  box-shadow: 0px 0px 30px -10px #3452e1 !important;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
  /* Recent browsers */
  text-transform: none;
}
.disabled {
  background-color: #e9ecef !important;
}

.is-invalid {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: var(--danger) !important;
  transition: 0.25s;
}

.form-control {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.prepend-border {
  border: 2px solid var(--primary) !important;
}
</style>
<template>
  <Fragment>
    <BlockPadding :padding="padding" />

    <template v-if="uiToggles.isSearchingByQueryParamaters">
      <BlockLoadingBar
        loadingText="Searching for your vehicle..."
        :loading="uiToggles.isSearchingByQueryParamaters"
        :useTimer="false"
        :alignCenter="true"
      />
    </template>

    <template v-if="uiToggles.isVehicleSearchVisible && !uiToggles.isSearchingByQueryParamaters">
      <template v-if="funnelId === Milberg.Funnels.VAUXHALL_DIESEL_EMISSIONS_FUNNEL_ID">
        <BlockTitle v-bind="BlockInputs.milbergVrmEntryTitle" padding="0" />
        <BlockSubtitle v-bind="BlockInputs.milbergVrmEntrySubtitle" padding="10" />

        <BlockPadding padding="40" />
      </template>

      <h5 class="mb-1">{{ label }}</h5>
      <p class="mb-0"></p>

      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text class="justify-content-center reg-input-selected">
            <img :src="require('@/assets/images/GB.png')" style="width: 30px" alt="GB" />
          </b-input-group-text>
        </b-input-group-prepend>

        <b-form-input
          class="border reg-input-selected"
          v-mask="'XXXXXXXX'"
          size="lg"
          v-model="vrm"
          :state="vrmInputState"
          placeholder="Registration number"
          @update="vrmInputState = null"
        ></b-form-input>
      </b-input-group>

      <template v-if="!vrmInputState">
        <b-form-invalid-feedback :state="vrmInputState" id="input-live-feedback-vrm">
          {{ vrmInputInvalidFeedback }}
        </b-form-invalid-feedback>
      </template>

      <h5 :style="[theme.textColourStyles]" class="mt-1" v-if="vrmInputInvalidFeedback === uiMessages.noVehicleFound">
        For help finding your vehicle, <a @click="displayHelpModal" href="#">click here</a>
      </h5>

      <b-modal id="helpModal" title="Find Your Vehicle" size="md" centered hide-footer>
        <template #modal-header="{ close }">
          <div style="display: block">
            <h4>Find Your Vehicle</h4>
          </div>

          <div style="float: right">
            <b-button size="lg" variant="link" @click="close()" class="p-0 pr-1">
              <i class="fas fa-times mx-1" />
            </b-button>
          </div>
        </template>

        <h5>Current Registrations</h5>
        <p>
          Make sure to search for the Registration Number that is on the vehicle today. If you are not the current
          owner, note that the current owner may have changed the Registration Number.
        </p>

        <h5>Current Mistakes</h5>
        <p>
          If you vehicle uses a standard registration plate, it should be in the format LLNN LLL. L - letter, N -
          number. Common mistakes can be swapping 0 for O, or I for 1.
        </p>
      </b-modal>

      <BlockButton
        @clicked="searchVehicle"
        v-bind="BlockInputs.searchRegButton"
        v-bind.sync="BlockInputs.searchRegButton"
      />

      <template v-if="funnelId === Milberg.Funnels.VAUXHALL_DIESEL_EMISSIONS_FUNNEL_ID">
        <BlockPadding padding="40" />
        <BlockHtml v-bind="BlockInputs.milbergHtml" padding="0" />
      </template>
    </template>

    <template v-if="uiToggles.isVehicleHistorySearchVisible">
      <b-card class="mb-0" no-body style="box-shadow: 0 0rem 0.25rem rgb(0 0 0 / 15%)">
        <b-card-body style="padding-bottom: 0.25rem !important">
          <div
            :style="
              BlockInputs.confirmVehicleText.height === 'auto'
                ? ' '
                : 'position:relative; overflow-y:scroll; height: ' + BlockInputs.confirmVehicleText.height + 'px'
            "
          >
            <h2>Vehicles Found</h2>
            <h4>We found a number of vehicles matching the registration number you entered.</h4>

            <h5>
              <a @click="notMyVehicle" href="JavaScript:void(0);">None are my vehicle? Click here</a>
            </h5>
          </div>
        </b-card-body>
      </b-card>
      <BlockPadding padding="30" />
      <BlockSingleSelect
        v-bind="BlockInputs.vehicleHistorySingleSelect"
        v-bind.sync="BlockInputs.vehicleHistorySingleSelect"
      />
      <BlockButton
        @clicked="submitVehicleHistorySelect"
        v-bind="BlockInputs.submitVehicleHistorySelectButton"
        v-bind.sync="BlockInputs.submitVehicleHistorySelectButton"
      />
    </template>

    <template v-if="uiToggles.isKeeperSelectVisible">
      <b-card class="mb-0" no-body style="box-shadow: 0 0rem 0.25rem rgb(0 0 0 / 15%)">
        <b-card-body style="padding-bottom: 0.25rem !important">
          <div
            :style="
              BlockInputs.confirmVehicleText.height === 'auto'
                ? ' '
                : 'position:relative; overflow-y:scroll; height: ' + BlockInputs.confirmVehicleText.height + 'px'
            "
          >
            <div v-html="BlockInputs.confirmVehicleText.html" />
            <h5>
              <a @click="notMyVehicle" href="JavaScript:void(0);">Not my vehicle? Click here</a>
            </h5>
          </div>
        </b-card-body>
      </b-card>
      <BlockPadding padding="30" />
      <BlockSingleSelect
        v-bind="BlockInputs.keeperSelectSingleSelect"
        v-bind.sync="BlockInputs.keeperSelectSingleSelect"
      />
      <BlockButton
        @clicked="submitKeeperSelect"
        v-bind="BlockInputs.submitKeeperSelectButton"
        v-bind.sync="BlockInputs.submitKeeperSelectButton"
      />
    </template>
  </Fragment>
</template>
