import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import { Variable } from "@claimsgate/core-types";
import { PropOptions } from "vue";
const props: {
  padding: PropOptions<string>;
  condition: PropOptions<Object>;
  required: PropOptions<boolean>;
  label: PropOptions<string>;
  info: PropOptions<string>;
  answer: PropOptions<Array<Record<string, any>>>;
  state: PropOptions<boolean>;
} = inputProps({
  excludeDefaults: true,
  answerType: "array",
  extraProps: ["padding", "required", "condition", "label", "info", "state"],
});
export function getProps() {
  return {
    storeAs: {
      type: Object,
    } as PropOptions<Variable>,

    entityName: {
      type: String,
      required: false,
      default: "Person",
      description: "The name of the entity",
    } as PropOptions<string>,
    ...props,
  };
}
