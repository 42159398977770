import { getClaim, getClaimsByFunnelId, Quantum } from "@claimsgate/core";
import { manualVehicleInstance } from "./manualVehicleInstance";
import { getFirebaseBackend } from "@/authUtils";
import { reject } from "@/helpers/vue";

export const methods = {};
export function changeVehicle(state: manualVehicleInstance) {
  state.currentView = "regInput";
  state.store.make = null;
  state.store.model = null;
  state.store.purchaseDate = null;
  state.store.vrm = null;
  state.store.otherMake = null;
}
export async function storeVehicleData(state: manualVehicleInstance) {
  if (!state.store.make || !state.store.model || !state.store.purchaseDate || !state.store.vrm) {
    return;
  }
  const make = state.store.make === "Other" ? state.store.otherMake : state.store.make;

  const store = {
    make: make,
    model: state.store.model,
    keeperStartDate: state.store.purchaseDate,
    vehicleRegistration: state.store.vrm,
    enteredRegistration: state.store.vrm,
    otherMake: state.store.otherMake !== null ? state.store.otherMake : "",
  };

  const hashedAdditionalStores = await state.variablesService.hashData(store, state.funnelVariables);

  for (const [key, value] of Object.entries(hashedAdditionalStores)) {
    state.claimDataService.setArtefact(key, value);
  }
}

export function validateVRM(state: manualVehicleInstance): boolean {
  if (state.store.vrm) {
    const normalizedVRM = state.store.vrm.trim().toUpperCase().replace(/\s+/g, "");

    if (normalizedVRM.length === 0 || normalizedVRM.length > 7 || normalizedVRM === null) {
      state.uiState.vrm = false;
      state.invalidFeedbacks.vrm = "Please enter a valid registration number.";
      return false;
    } else {
      state.store.vrm = normalizedVRM;
      state.uiState.vrm = true;
      state.invalidFeedbacks.vrm = null;
      return true;
    }
  }
  state.uiState.vrm = false;
  state.invalidFeedbacks.vrm = "Please provide a registration number.";
  return false;
}

async function validateRegInput(state: manualVehicleInstance): Promise<boolean> {
  if (!validateVRM(state)) {
    reject<manualVehicleInstance>(state, "Please provide a valid registration number.");
    return false;
  }

  if (state.pageId !== Quantum.PcpPages.TRANSITION_VEHICLE_PAGE_ID) {
    if (await userHasClaimsForReg(state)) {
      reject<manualVehicleInstance>(state, "You have already registered a claim for this vehicle.");
      return false;
    }
  }

  return true;
}

function validateMakeModel(state: manualVehicleInstance): boolean {
  const results = [];

  ["make", "model", "otherMake"].forEach((field) => {
    state.uiState[field] = null;
    state.invalidFeedbacks[field] = null;
  });

  if (!state.store.make || state.store.make === null) {
    state.uiState.make = false;
    state.invalidFeedbacks.make = "Please select the make of your vehicle.";
    reject<manualVehicleInstance>(state, state.invalidFeedbacks.make);
    results.push(false);
  } else {
    state.uiState.make = true;
    state.invalidFeedbacks.make = null;
    results.push(true);
  }
  if (!state.store.model) {
    state.uiState.model = false;
    state.invalidFeedbacks.model = "Please enter the model of your vehicle";
    reject<manualVehicleInstance>(state, state.invalidFeedbacks.model);
    results.push(false);
  } else {
    state.uiState.model = true;
    state.invalidFeedbacks.model = null;
    results.push(true);
  }

  if (state.store.make === "Other" && !state.store.otherMake) {
    state.uiState.otherMake = false;
    state.invalidFeedbacks.otherMake = "Please enter the make of your vehicle";
    reject<manualVehicleInstance>(state, state.invalidFeedbacks.otherMake);
    results.push(false);
  } else {
    state.uiState.otherMake = true;
    state.invalidFeedbacks.otherMake = null;
    results.push(true);
  }

  return results.includes(false) ? false : true;
}
/**
 * needs to assert the purchase date is before jan 28th 2021.
 * Invalid feedback if not : "Sorry, the vehicle must have been purchased before January 28th 2021 to be eligible"
 */
async function validatePurchaseDate(state: manualVehicleInstance): Promise<boolean> {
  const ownershipDate = state.store.purchaseDate;
  state.uiState.purchaseDate = null;
  state.BlockInputs.purchaseDatePicker.state = null;
  if (!ownershipDate) {
    state.uiState.purchaseDate = false;
    state.BlockInputs.purchaseDatePicker.state = false;
    state.invalidFeedbacks.purchaseDate = "Please select the date when you purchased the vehicle";
    reject<manualVehicleInstance>(state, state.invalidFeedbacks.purchaseDate);
    return false;
  }
  console.log("ownershipDate", ownershipDate);
  if (state.pageId !== Quantum.PcpPages.TRANSITION_VEHICLE_PAGE_ID) {
    if (new Date(ownershipDate) > new Date("2021-01-28")) {
      state.uiState.purchaseDate = false;
      state.invalidFeedbacks.purchaseDate =
        "Sorry, the vehicle must have been purchased before January 28th 2021 to be eligible";

      reject<manualVehicleInstance>(state, state.invalidFeedbacks.purchaseDate);

      state.claimDataService.setArtefact("clientClaimProgress", "rejected");

      await storeVehicleData(state);

      await state.claimDataService.update();

      return false;
    }
  }
  state.claimDataService.setArtefact("clientClaimProgress", "inProgress");
  state.uiState.purchaseDate = true;
  state.BlockInputs.purchaseDatePicker.state = true;
  return true;
}

export async function continueClicked(state: manualVehicleInstance, currentView: manualVehicleInstance["currentView"]) {
  if (currentView === "regInput") {
    if (!(await validateRegInput(state))) {
      return;
    }
    state.currentView = "makeModel";
    return;
  }
  if (currentView === "makeModel") {
    if (!validateMakeModel(state)) return;
    state.currentView = "purchaseDate";
    return;
  }
  if (currentView === "purchaseDate") {
    if (!(await validatePurchaseDate(state))) return;

    // console.log("state of input values", state.store);
    await storeVehicleData(state);

    await state.$store.dispatch("events/fire", { name: state.eventValues.next });
  }
}

export async function userHasClaimsForReg(
  state: manualVehicleInstance,
  options?: { ignoreRejectedClaims?: boolean }
): Promise<boolean> {
  const [claimsForUser, error] = await getClaimsByFunnelId(
    getFirebaseBackend().firestore(),
    state.userId,
    state.funnelId
  );

  if (state.claimDataService.getArtefact("claimStatus") === "testing") {
    return false;
  }

  if (error) {
    console.error("[manualVehicle/methods.ts] Failed to get claims for user", error);
  }

  const funnelVariables = state.funnelVariables;

  const vehicleRegistrationVariable = funnelVariables.find((variable) => variable.field === "vehicleRegistration");
  const enteredRegistrationVariable = funnelVariables.find((variable) => variable.field === "enteredRegistration");

  const existingClaim = claimsForUser.find((claim) => {
    if (claim.currentFunnelId !== state.funnelId) {
      return false;
    }

    if (claim.claimId === state.claimId) {
      return false;
    }

    if (claim.claimStatus === "paused" || claim.claimStatus === "deleted") {
      return false;
    }

    if (claim.clientClaimProgress === "rejected" && options?.ignoreRejectedClaims) {
      return false;
    }

    return (
      claim?.[vehicleRegistrationVariable.id] === state.store.vrm ||
      claim?.[enteredRegistrationVariable.id] === state.store.vrm
    );
  });

  if (!existingClaim) {
    return false;
  }
  console.log("existingClaim - fire modal", existingClaim);
  await state.infoModalService.fire("warning", {
    title: "You have already registered a claim for this vehicle",
    text: "It looks like you already have an active claim for this vehicle. Click resume to go to your existing claim.",
    cancelButtonText: "Cancel",
    confirmButtonText: "Resume",
    onConfirm: () => {
      if (state.navigationHideTrackPageClaimant) {
        getClaim(getFirebaseBackend().firestore(), state.userId, existingClaim.documentId).then(([_existingClaim]) => {
          // We just have to send the user to a specific url
          window.location.href =
            window.location.origin +
            `/form/${state.funnelId}/${_existingClaim.currentPageId}/${existingClaim.documentId}`;
        });
      } else {
        state.$router.push({ name: "Track", params: { claimId: existingClaim.documentId } }).then();
      }
    },
    onHide: () => {
      console.log("Closed");
    },
  });

  return true;
}
