/**
 * VueUtility is a class which provides methods to help create components in Vue
 */
class VueUtility {
  static getSubdomain() {
    return window.location.hostname.split(".")[0];
  }

  static removeSpaces(string: string) {
    return string.replace(/\s+/g, "");
  }

  /**
   * Downloads a file to the requesting users device
   * @param {Blob} blob  - Binary larege object
   * @param {string} fileName - Name of the file to download including the extension
   */
  static downloadBlob(blob: Blob | MediaSource | string, fileName: string) {
    const link = document.createElement("a");
    link.setAttribute("download", fileName);
    link.href = typeof blob !== "string" ? URL.createObjectURL(blob) : blob;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  static camelToTitle(name: string) {
    const caseSplit = name?.split(/(?=[A-Z])/);
    if (caseSplit?.length === name?.length) {
      //Original was all caps, return as is.
      return name;
    }
    const firstWord = `${caseSplit[0]?.slice(0, 1).toUpperCase()}${caseSplit[0]?.slice(1)}`;
    return `${firstWord} ${caseSplit?.slice(1).join(" ")}`;
  }
}

export { VueUtility };
