<script lang="ts">
import Vue from "vue";
import { Fragment } from "vue-frag";
import { PropOptions } from "vue/types/umd";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { StorageService } from "@/helpers/ClaimsGate/StorageService";
import { getFirebaseBackend } from "@/authUtils";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
import { mapDocumentType, parseFiles, uploadToTemp } from "./methods";
import { FileMeta, VerificationLog, YotiVerificationSession } from "@claimsgate/core-types";
import BlockMultiFileInput from "@/components/shared/blocks/multiFileInput.vue";
export default Vue.extend({
  name: "BlockManualIdUpload",
  components: { Fragment, BlockMultiFileInput },
  props: {
    isBuilder: {
      type: Boolean,
      required: false,
      default: false,
    },
    padding: {
      type: String,
      required: false,
      default: "20",
    },
    documentType: {
      type: String,
      required: true,
      valid: ["Drivers License", "Passport"],
      default: "Drivers License",
    } as PropOptions<"Drivers License" | "Passport">,
  },
  data() {
    return {
      blockState: {
        fileInput: null,
      },
      blockAnswer: {
        fileInput: null,
      },
      db: getFirebaseBackend().firestore(),
      userHelper: getUserHelper(),
      storageService: new StorageService(),
      userDataService: undefined as UserDataService,
      claimId: this.$route.params.claimId,
      funnelId: this.$route.params.funnelId,
      userAlreadyHasDocument: false,
    };
  },
  methods: {
    mapDocumentType(type: "Drivers License" | "Passport") {
      return mapDocumentType(type);
    },
    parseFiles(files: File[]) {
      return parseFiles(files);
    },
    async uploadToTemp(files: Array<any>): Promise<FileMeta[]> {
      return uploadToTemp(files, this.userHelper.getUserId(), this.storageService);
    },
    async validations(): Promise<boolean> {
      this.blockState.fileInput = null;
      if (this.userAlreadyHasDocument) {
        return true;
      }
      if (this.blockAnswer.fileInput?.file?.length > 0) {
        return true;
      }
      this.blockState.fileInput = false;
      return false;
    },
    async computes(): Promise<any> {
      if (this.userAlreadyHasDocument) {
        return true;
      }
      const files = this.parseFiles(this.blockAnswer.fileInput.file);
      const tempStorageFileMetas = await this.uploadToTemp(files);

      console.log(tempStorageFileMetas);

      const createManualVerification = getFirebaseBackend()
        .firebaseFunctions()
        .httpsCallable("createManualVerification");

      const response = await createManualVerification({
        userId: this.userHelper.getUserId(),
        claimId: this.claimId,
        funnelId: this.funnelId,
        fileMetas: tempStorageFileMetas,
        documentType: this.mapDocumentType(this.documentType),
      });

      if (response.data?.error) {
        this.blockState.fileInput = false;
        return false;
      }

      await this.userDataService.refresh();
      this.blockState.fileInput = true;
      return true;
    },
  },
  async mounted() {
    if (this.isBuilder) {
      return;
    }
    this.userDataService = new UserDataService(this.userHelper.getUserId());

    const verificationLogSnap = await this.db
      .collection("users")
      .doc(this.userHelper.getUserId())
      .collection("verification_logs")
      .get();

    const existingDocTypes = [];

    verificationLogSnap.docs.forEach((doc) => {
      const verificationLog = doc.data() as VerificationLog<YotiVerificationSession>;

      if (!verificationLog.result.extractedFields) return;

      const documentTypes = verificationLog.result.extractedFields.map((fields) => fields["document_type"]);

      existingDocTypes.push(...documentTypes);
    });

    const yotiDocumentType = this.mapDocumentType(this.documentType);

    if (existingDocTypes.includes(yotiDocumentType)) {
      // hide the block ? show a message that says doc completed
      // mark block complete and skip validation/computes
      this.userAlreadyHasDocument = true;
    }
  },
});
</script>
<template>
  <Fragment>
    <template v-if="!userAlreadyHasDocument">
      <BlockMultiFileInput
        :padding="padding"
        :label="`Please upload a copy of your ${documentType.toLowerCase()}`"
        info="You must ensure that the document is clear and legible in the file uploaded."
        :invalidFeedback="'This field is required'"
        :state="blockState.fileInput"
        :answer.sync="blockAnswer.fileInput"
        :required="true"
        :multiple="true"
      ></BlockMultiFileInput>
    </template>
  </Fragment>
</template>
