import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import { Props } from "@/types";

export function getProps() {
  return {
    iconType: {
      type: String,
      required: true,
    },

    iconName: {
      type: String,
      required: true,
    },

    isSelected: {
      type: Boolean,
      default: false,
    },
  };
}
