import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import Label from "../label.vue";

/** map fields of types to block props to initialise the blocks with */
export const defaultBlockProps = {
  firstName: UserVariableBlocksService.genBlockInputProps({ label: "First Name", placeholder: "First Name" }),
  lastName: UserVariableBlocksService.genBlockInputProps({ label: "Last Name", placeholder: "Last Name" }),
  email: UserVariableBlocksService.genBlockInputProps({ label: "Email", placeholder: "Email" }),
  dateOfBirth: UserVariableBlocksService.genBlockDatePickerProps({
    label: "Date of Birth",
    placeholder: "Date of Birth",
  }),
  bankName: UserVariableBlocksService.genBlockInputProps({
    label: "Bank or Building society name",
    placeholder: "Bank or Building society name",
  }),
  propertyAddress: UserVariableBlocksService.genBlockInputProps({
    label: "What is the property address?",
    placeholder: "What is the property address?",
  }),
  propertyOwnership: UserVariableBlocksService.genBlockSingleSelectProps({
    label: "Who is the property owned by?",
    options: ["Myself", "Joint Ownership", "Unsure"],
  }),
  propertyMortgage: UserVariableBlocksService.genBlockSingleSelectProps({
    label: "Does the property have a mortgage?",
    options: ["Yes", "No", "Unsure"],
  }),
  executorName: UserVariableBlocksService.genBlockInputProps({ label: "Executor Name" }),
  executorEmail: UserVariableBlocksService.genBlockInputProps({ label: "Executor Email" }),
  pensionProvider: UserVariableBlocksService.genBlockInputProps({ label: "Provider Name" }),
  amount: UserVariableBlocksService.genBlockInputProps({ label: "Amount" }),
  currencyCode: UserVariableBlocksService.genBlockInputProps({ label: "Currency Code" }),
  currencyCountryCode: UserVariableBlocksService.genBlockInputProps({ label: "Currency Country Code" }),

  // Mortgage block props
  title: UserVariableBlocksService.genBlockDropdownProps({
    label: "Title",
    placeholder: "Title",
    options: ["Mr", "Mrs", "Ms", "Miss", "Mx", "Dr", "Professor", "Prefer not to say", "Other"],
  }),

  employmentStatus: UserVariableBlocksService.genBlockDropdownProps({
    label: "What was your employment status when you applied for the mortgage?",
    placeholder: "Select employment status",

    options: ["Employed", "Self-Employed", "Unemployed"],
  }),
  incomeVerification: UserVariableBlocksService.genBlockMultiselectProps({
    label: "What income verification details did you provide?",
    options: ["Payslips", "P60s", "Signed accounts", "Management accounts", "Bank statements", "Cannot recall"],
  }),
  address: {
    label: "Address",
    size: "lg",
    inputType: "text",
    required: true,
    disabled: false,
    state: null,
    placeholder: "",
    returnCountry: false,
    countryRestriction: "IE",
  },

  currentAddressLine1: {
    label: "Address Line 1",
    size: "lg",
    inputType: "text",
    required: true,
    disabled: false,
    state: null,
    placeholder: "",
  },

  currentCity: {
    label: "City",
    size: "lg",
    inputType: "text",
    required: true,
    disabled: false,
    state: null,
    placeholder: "",
  },

  currentPostcode: {
    label: "Postcode",
    size: "lg",
    inputType: "text",
    required: true,
    disabled: false,
    state: null,
    placeholder: "",
  },

  mortgageAddress: {
    label: "Mortgage Address",
    size: "lg",
    inputType: "text",
    required: true,
    disabled: false,
    state: null,
    placeholder: "",
    forceInternationalAddress: true,
  },
};
