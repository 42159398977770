import { getFirebaseBackend } from "@/authUtils";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
import { isClaimsGateFunctionReturnedError, isNotClaimsGateFunctionReturnedError } from "@/helpers/vue";
import { getFunnelVariables } from "@claimsgate/core";
import { ClaimsGateFunctionReturnedError, MotorSpecsGetVehicleVinResponse, User } from "@claimsgate/core-types";
import { BlockContactDetailsInstance } from "./blockContactDetails";

export async function computes(state: BlockContactDetailsInstance) {
  try {
    if (state.collectName && !state.userNameDisabled && state.$refs.BlockSimpleName?.computes) {
      const name = (await state.$refs.BlockSimpleName.computes()) as Partial<User>;
      if (!name) {
        state.isProcessing = false;
        return false;
      }
      // store name on user data service?
      state.userDataService?.setManyArtefacts({
        title: name.title,
        firstName: name.firstName,
        lastName: name.lastName,
      });
      await state.userDataService?.update();
    }

    if (!(await state.$refs.BlockEmail.computes())) {
      state.isProcessing = false;
      return false;
    }

    if (!(await state.$refs.BlockPhone.computes())) {
      state.isProcessing = false;
      return false;
    }
    // If BlockConsent.computes does not exist or is not a function
    // then we assume that the consent is not required.
    if (state.$refs.BlockConsent && typeof state.$refs.BlockConsent.computes === "function") {
      if (!(await state.$refs.BlockConsent.computes())) {
        state.isProcessing = false;

        return false;
      }
    } else {
      console.error("BlockConsents was undefined or not a function?", state.$refs);
    }

    if (state.returnEmcodeData) {
      state.isProcessing = true;
      await getEmcodeData(state);
    }

    if (state.allowMarketingOptIn) {
      const [funnelVariables] = await getFunnelVariables(getFirebaseBackend().firestore(), state.funnelId);

      const marketingOptInVariableId = funnelVariables.find(
        (funnelVariable) => funnelVariable.field === "marketingOptIn"
      )?.id;

      const marketingOptInValue = state.marketingOptIn === "" ? "not_accepted" : state.marketingOptIn;
      state.claimDataService.setArtefact(marketingOptInVariableId, marketingOptInValue);
    }

    state.isProcessing = false;
    return true;
  } catch (exception) {
    state.isProcessing = false;
    throw exception;
  }
}

/** Fetches emCode data from MotorSpecs using the existing VIN stored on the claim */
export async function getEmcodeData(state: BlockContactDetailsInstance) {
  const [funnelVariables, _getFunnelVariablesError] = await getFunnelVariables(
    getFirebaseBackend().firestore(),
    state.funnelId
  );

  const claimData = state.claimDataService.getCache();

  // Find the ID of the funnelVariable which has a field equal to 'vin'
  const vinFunnelVariableId = funnelVariables.find((funnelVariable) => funnelVariable.field === "vin")?.id;
  const vin = claimData[vinFunnelVariableId] as string;

  const makeVariableId = funnelVariables.find((funnelVariable) => funnelVariable.field === "make")?.id;
  const make = claimData[makeVariableId] as string;

  const MANUFACTURERS_WITH_EMCODE = [
    "citroen",
    "vauxhall",
    "jaguar",
    "land Rover",
    "landrover",
    "rover",
    "nissan",
    "peugeot",
    "renault",
    "volvo",
  ];

  // We will only be fetching the emCode if it has not already been defined
  const emCodeVariableId = funnelVariables.find((funnelVariable) => funnelVariable.field === "emCode")?.id;
  const emCode = claimData[emCodeVariableId] as number;

  // If emCode is already defined then we will not fetch it.
  if (emCode) {
    return;
  }
  // We will only be fetching emCode for vehicles which have a vehicle make rated by Emissions Analytics
  if (MANUFACTURERS_WITH_EMCODE.map((_make) => _make.toLocaleLowerCase()).includes(make.toLowerCase()) === false) {
    return;
  }

  const response = await getEmcodeDataFromMotorSpecsFunction({
    vin: vin,
    pageId: state.pageId,
    claimId: state.claimId,
    funnelId: state.funnelId,
    invocationReason: "getEmcodeData",
  });

  if (isClaimsGateFunctionReturnedError<MotorSpecsGetVehicleVinResponse>(response)) {
    return;
  } else if (isNotClaimsGateFunctionReturnedError<MotorSpecsGetVehicleVinResponse>(response)) {
    const emCode = response.data.emCode;
    const emCodeFunnelVariableId = funnelVariables.find((funnelVariable) => funnelVariable.field === "emCode")?.id;
    state.claimDataService.setArtefact(emCodeFunnelVariableId, emCode);
  }
}

/** Fetches the emcode for a vehicle by VIN using the backend function */
async function getEmcodeDataFromMotorSpecsFunction(meta: {
  vin: string;
  pageId: string;
  claimId: string;
  funnelId: string;
  invocationReason: string;
}): Promise<{ data: MotorSpecsGetVehicleVinResponse | ClaimsGateFunctionReturnedError }> {
  return (await onCallGateway<"getEmcodeFromMotorSpecs">({
    functionName: "getEmcodeFromMotorSpecs",
    data: {
      vin: meta.vin,
      pageId: meta.pageId,
      claimId: meta.claimId,
      funnelId: meta.funnelId,
      invocationReason: meta.invocationReason,
    },
  })) as { data: MotorSpecsGetVehicleVinResponse | ClaimsGateFunctionReturnedError };
}
