import { navigationComputed, eventComputed, themesComputed } from "@/state/helpers";
import { BlockCaravanInstance } from "./BlockCaravanBlock";

export function mappedComputed() {
  return {
    ...(navigationComputed as Record<keyof typeof navigationComputed, any>),
    ...(eventComputed as Record<keyof typeof eventComputed, any>),
    ...(themesComputed as Record<keyof typeof themesComputed, any>),
  };
}

export const computed = {
  aComputed,
};

function aComputed(state: BlockCaravanInstance): string {
  return "";
}
