//@ts-check
/**
 * A centralized place for UI messages to be generated
 */
class ResponseMessageService {
  /**
   * @type {{
   * wasError : 'There was an error',
   * failedToDelete : 'Failed to delete',
   * failedToSave: 'Failed to save',
   * pageLoadFailed : 'Page failed to load',
   * failedToUpload : 'Failed to upload',
   * resourceDoesNotExist: {
   *  title: "Page not found",
   *  text: "Whoops, your URL refers to a page which does not exist."
   * },
   * unexpectedError: {
   *  title: "Unexpected Error",
   *  text: "Whoops, we're not sure what went wrong. Our team has been notified, please try again later!"
   * }
   * loginLinkExpired: {
   *  title: "Login link expired",
   *  text: "Whoops, this login link is no longer valid. You will need to generate a new sign in link using the login page."
   * },
   * loginSuccessful: {
   *  title: "Login successful",
   *  text: "You will now be redirected.",
   * }
   * }}
   */
  errors;

  /**
   * @typedef {{
   * tryAgain : 'Please Try again',
   * deleteIt : 'Yes, delete it!',
   * redirecting: 'Redirecting you...',
   * okContinue:  'Click ok to continue!'
   * }} Actions
   * */
  /** @type { Actions } */
  actions;

  /**
   * @type {{
   * sure : 'Are you sure?'
   * }}
   */
  questions;

  /**
   * @type {{
   * cannotUndo : 'You can not undo this action',
   * changesLost: 'Unsaved changes will be lost!'
   *  noPermission : 'You do not have permission to access this page'
   * }}
   */
  statements;
  /**
   * @type {{
   *   archived: "This funnel has been archived",
   *   doesNotExist: "This funnel does not exist",
   *   onlyOnce: "This funnel can not be completed more than once",
   *   cannotAccess: "You can not access this funnel.",
   *   noId: "No funnel id provided",
   *   willBeArchived: "This funnel will be archived",
   * }}
   */
  funnels;

  constructor() {
    this.errors = {
      wasError: "There was an error",
      failedToDelete: "Failed to delete",
      failedToSave: "Failed to save",
      pageLoadFailed: "Page failed to load",
      failedToUpload: "Failed to upload",
      resourceDoesNotExist: {
        title: "Page not found",
        text: "Whoops, your URL refers to a page which does not exist.",
      },
      unexpectedError: {
        title: "Unexpected Error",
        text: "Whoops, we're not sure what went wrong. Our team has been notified, please try again later!",
      },
      loginLinkExpired: {
        title: "Login link expired",
        text: "Whoops, this login link is no longer valid. You will need to generate a new sign in link using the login page.",
      },
      loginSuccessful: {
        title: "Login successful",
        text: "You will now be redirected.",
      },
    };

    this.actions = {
      tryAgain: "Please Try again",
      deleteIt: "Yes, delete it!",
      redirecting: "Redirecting you...",
      okContinue: "Click ok to continue!",
    };

    this.text = {
      resourceDoesNotExist: "Whoops, your URL refers to a resource which does not exist.",
    };

    this.questions = {
      sure: "Are you sure?",
    };

    this.statements = {
      cannotUndo: "You can not undo this action",
      changesLost: "Unsaved changes will be lost!",
      noPermission: "You do not have permission to access this page",
    };

    this.funnels = {
      archived: "This funnel has been archived",
      doesNotExist: "This funnel does not exist",
      onlyOnce: "This funnel can not be completed more than once",
      cannotAccess: "You can not access this funnel.",
      noId: "No funnel id provided",
      willBeArchived: "This funnel will be archived",
    };
  }
}

/**
 * An instance of the ResponseMessage service for use
 * @constant { ResponseMessageService } */
const msg = new ResponseMessageService();

export { ResponseMessageService, msg };
