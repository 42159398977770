<script lang="ts">
import Vue, { PropType } from "vue";
import HeadingContent from "@/components/shared/styleWrappers/headingContent/headingContent.vue";
import { GreyTextColour } from "../types";
import { faSquare, IconDefinition } from "@fortawesome/pro-regular-svg-icons";

export default Vue.extend({
  name: "CardHeader",
  components: {
    HeadingContent,
  },
  data() {
    return {
      faSquare,
    };
  },

  props: {
    heading: {
      type: String,
      required: true,
    },
    supportingText: {
      type: String,
      required: true,
    },

    hideSupportingText: {
      type: Boolean,
      default: false,
    },

    supportingTextClass: {
      type: String as PropType<GreyTextColour>,
      default: "text-grey-700" as GreyTextColour,
    },
    supportingBadge: {
      type: String,
      required: false,
    },
    headingTextSize: {
      type: String,
      default: "text-lg",
      required: false,
    },
    supportingTextSize: {
      type: String,
      default: "text-sm",
      required: false,
    },

    supportingBadgeClass: {
      type: String,
      default: "bg-primary-50 text-primary-700",
    },
    headingTextClass: {
      type: String as PropType<GreyTextColour>,
      default: "text-grey-900" as GreyTextColour,
    },
    headingMarginBottom: {
      required: false,
      type: String,
      default: "mb-1",
    },
    closeHandler: {
      required: false,
      type: Function as PropType<() => void>,
    },
    icon: {
      required: false,
      type: [String as PropType<string>, Object as PropType<IconDefinition>] as PropType<string | IconDefinition>,
    },
  },
});
</script>

<template>
  <div class="d-flex align-items-center w-100 mt-2">
    <div v-if="icon" class="mr-3 py-1 px-1 border border-gray-900">
      <span class="fa-stack fa-2x">
        <font-awesome-icon :icon="icon" class="fa-stack-1x fa-inverse text-grey-700" />
      </span>
    </div>
    <HeadingContent
      :heading="heading"
      :supportingText="supportingText"
      :headingTextClass="headingTextClass"
      :supportingTextClass="supportingTextClass"
      :supportingBadge="supportingBadge"
      :supportingBadgeClass="supportingBadgeClass"
      :hideSupportingText="hideSupportingText"
      :headingTextSize="headingTextSize"
      :supportingTextSize="supportingTextSize"
      :headingMarginBottom="headingMarginBottom"
    >
      <slot name="icon" slot="icon"></slot>
      <slot name="extraHeadingContent" slot="extraHeadingContent"></slot>
      <slot name="extraSupportingContent" slot="extraSupportingContent"></slot>
      <slot name="right" slot="right"></slot>
      <slot></slot>
    </HeadingContent>

    <slot name="buttons"></slot>

    <b-button v-if="closeHandler" size="lg" variant="link" @click="closeHandler()" class="p-0 pr-1 text-grey-500">
      <i class="fas fa-times mx-1" />
    </b-button>
  </div>
</template>
