<script lang="ts">
import Vue from "vue";
import { methods, getProps, getData } from "./AltVehicleBlockInstance";

// Components
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import BlockDatePicker from "@/components/shared/blocks/datepicker.vue";
import { mappedComputed } from "./computed";
import { mounted } from "./mounted";
import BlockPadding from "@/components/shared/blocks/padding.vue";

export default Vue.extend({
  name: "BlockAltVehicle",
  components: {
    BlockDatePicker,
    BlockButton,
    BlockInputWrapper,
    BlockPadding,
  },
  props: { ...getProps() },

  computed: {
    ...mappedComputed(),
    vehicleTypeText() {
      if (this.vehicleType === "boat") {
        return "boat";
      } else if (this.vehicleType === "plane") {
        return "aircraft";
      } else {
        return "vehicle";
      }
    },
  },
  data() {
    return { ...getData(this) };
  },

  methods: {
    submit() {
      methods.submit(this);
    },

    notMyVehicle() {
      methods.notMyVehicle(this);
    },
  },
  async mounted(): Promise<void> {
    await mounted(this);
  },
  watch: {
    //padding: {
    //  immediate: true,
    //  handler(newVal) {
    //    Object.keys(this.BlockInputs).forEach((key) => {
    //      this.BlockInputs[key].padding = newVal;
    //    });
    //  },
    //},
  },
});
</script>

<style scoped>
.error {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #e13f45 !important;
  transition: 0.25s;
}
.selected {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #3452e1 !important;
  transition: 0.25s;
}
.deselected {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #eff2f7 !important;
  transition: 0.25s;
}

.input-group > .input-group-prepend {
  flex: 0 0 15%;
}
.input-group .input-group-text {
  width: 100%;
}
/* Reg Input styling */
.reg-input-selected {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  border-right-width: 0px !important;
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #ced4da !important;
  transition: 0.25s;
}
.reg-input-selected:focus {
  border-color: var(--primary) !important;
}
.input-group-prepend:focus {
  border-color: var(--primary) !important;
}
.btn-glow,
.btn-glow:active,
.btn-glow:focus,
.btn-glow:hover {
  box-shadow: 0px 0px 30px -10px #3452e1 !important;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
  /* Recent browsers */
  text-transform: none;
}
.disabled {
  background-color: #e9ecef !important;
}

.is-invalid {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: var(--danger) !important;
  transition: 0.25s;
}

.form-control {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.prepend-border {
  border: 2px solid var(--primary) !important;
}
</style>
<template>
  <div>
    <template v-if="currentView === 'vehicle'">
      <template v-if="vehicleType === 'boat'">
        <!-- boat/ yacht validation [required, mask], fields [win] -->
        <BlockPadding :padding="padding" />
        <b
          ><h5 class="mb-2 font-weight-semibold text-grey-900">
            Please enter the WIN (Watercraft Identification Number)
          </h5></b
        >
        <BlockInputWrapper v-bind="BlockInputs.boatWin">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text class="justify-content-center reg-input-selected">
                <div style="width: 30px" alt="GB" />
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              size="lg"
              v-model="BlockInputs.boatWin.answer"
              :state="BlockInputs.boatWin.state"
              placeholder="Example: AA-AAA-A1234-A-1-12"
              @update="BlockInputs.boatWin.state = null"
            ></b-form-input>
          </b-input-group>
        </BlockInputWrapper>
      </template>
      <template v-else-if="vehicleType === 'plane'">
        <!-- plane validation [required], fields [tailNumber] -->
        <BlockPadding :padding="padding" />
        <h5 class="font-weight-semibold text-grey-9000">Please enter your aircraft tail number</h5>
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text class="justify-content-center reg-input-selected">
              <div style="width: 30px" alt="GB" />
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
            class="border reg-input-selected"
            size="lg"
            v-model="BlockInputs.planeNumber.answer"
            :state="BlockInputs.planeNumber.state"
            placeholder="Example: U123AB"
            @update="BlockInputs.planeNumber.state = null"
          ></b-form-input>
        </b-input-group>
        <span v-if="BlockInputs.planeNumber.state === false" class="text-sm text-danger">
          {{ BlockInputs.planeNumber.invalidFeedback }}
        </span>
      </template>
      <template v-else>
        <BlockPadding :padding="padding" />
        <h5 class="font-weight-semibold text-grey-900">Please enter the make of your vehicle</h5>

        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text class="justify-content-center reg-input-selected">
              <div style="width: 30px" alt="GB" />
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
            class="border reg-input-selected"
            size="lg"
            v-model="BlockInputs.generalMake.answer"
            :state="BlockInputs.generalMake.state"
            @update="BlockInputs.generalMake.state = null"
          ></b-form-input>
        </b-input-group>

        <template v-if="BlockInputs.generalMake.state === false">
          <span class="text-sm text-danger">
            {{ BlockInputs.generalMake.invalidFeedback }}
          </span>
        </template>

        <BlockPadding :padding="padding" />
        <h5 class="font-weight-semibold text-grey-900">Please enter the model of your vehicle (optional)</h5>
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text class="justify-content-center reg-input-selected">
              <div style="width: 30px" alt="GB" />
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
            class="border reg-input-selected"
            size="lg"
            v-model="BlockInputs.generalModel.answer"
            :state="BlockInputs.generalModel.state"
            @update="BlockInputs.generalModel.state = null"
          ></b-form-input>
        </b-input-group>
      </template>
    </template>
    <template v-else>
      <BlockPadding :padding="padding" />
      <b-card no-body style="box-shadow: 0 0rem 0.25rem rgb(0 0 0 / 15%)">
        <b-card-body style="padding-bottom: 0.25rem !important">
          <h4 class="text-grey-7000 font-weight-semibold">
            {{ vehicleTypeText.charAt(0).toUpperCase() + vehicleTypeText.slice(1) }} Found
          </h4>
          <h4 class="text-grey-900">
            We found your {{ vehicleTypeText }}:
            <b
              >{{
                BlockInputs.generalMake.answer.toUpperCase() ||
                BlockInputs.planeNumber.answer.toUpperCase() ||
                BlockInputs.boatWin.answer.toUpperCase()
              }}
              {{ BlockInputs.generalModel.answer ? ` - ${BlockInputs.generalModel.answer.toUpperCase()}` : "" }}</b
            >
          </h4>

          <h5 class="font-weight-semibold" role="button" @click="notMyVehicle">
            <span>Click here to change your vehicle</span>
          </h5>
        </b-card-body>
      </b-card>

      <h5 class="mb-0 font-weight-semibold text-grey-900">Purchase Date</h5>
      <p class="mb-1 text-grey-700">Please enter the date you purchased the vehicle</p>
      <BlockDatePicker
        v-bind="BlockInputs.ownershipDatePicker"
        v-bind.sync="BlockInputs.ownershipDatePicker"
        :answer.sync="BlockInputs.ownershipDatePicker.answer"
      />
    </template>
    <BlockButton
      @clicked="submit()"
      v-bind="BlockInputs.submit"
      v-bind.sync="BlockInputs.submit"
      :padding="padding"
      :isProcessing="loaders.next"
    />
  </div>
</template>
