import { ClaimDataService, UserDataService } from "@/helpers/ClaimsGate/DataService";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { User } from "@claimsgate/core-types";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { BlockProps } from "@/types";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
export function getData(state: Vue) {
  return {
    user: {} as User,
    //userData: {} as User,
    userService: getUserHelper(),
    userDataService: undefined as UserDataService,
    claimDataService: undefined as ClaimDataService,
    infoModalService: new InfoModalService(state.$infoModal),
    claimId: state.$route.params.claimId as string,
    pageId: state.$route.params.pageId as string,
    funnelId: state.$route.params.funnelId as string,
    userId: null as string,
    uiToggles: {
      isLoading: false,
      /** Flag to indicate if the user has already got a email set up correctly on their user */
      isEmailDefined: false,
    },

    BlockInputs: UserVariableBlocksService.getBlocks(["email", "emailConfirm"]) as Record<
      "email" | "emailConfirm",
      BlockProps.Input<string>
    >,
    uiMessages: {
      emailInvalid: "Please enter a valid email address",
      emailsDontMatch: "The email addresses entered do not match",
      emailAssociatedOtherAccount: "This email is associated with another account",
      userDifferentEmail: "Please use a different email",
    },
    recoverAccountIsProcessing: false,
    recover: {
      userIdentifier: {
        email: undefined,
      },
      verifyUserIdentifier: {
        email: undefined,
      },
    },
  };
}
