import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { FunnelLogMetadata, Log } from "@claimsgate/core-types";
import { getFirebaseBackend } from "@/authUtils.js";
import { DateTime } from "luxon";
import { isNil, omitBy } from "lodash";
import { getWorkspaceIdByFunnelId } from "@claimsgate/core";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { onCallGateway } from "./onCallGateway";

// This class is responsible for handling and correctly logging all of the axios calls that need to be perfromed from the front-end
class AxiosService {
  /** Performs a web request using the given config and stores a log containing meta data in Firestore */
  static async request<T>(data: AxiosRequestConfig, metadata: FunnelLogMetadata): Promise<AxiosResponse<T>> {
    try {
      // // ? This callback ensures everything in JS is finished before continueing with the code.
      // // ? This is needed to solve 'potentially open handles' thrown when running Jest tests
      // await process.nextTick(() => {
      //   // do nothing
      // });

      const response = await axios.request<T>(data);

      await this.writeLogToFirestore({ data, response }, metadata);
      return response;
    } catch (exception) {
      await this.writeLogToFirestore({ data, exception }, metadata);
      throw exception;
    }
  }

  static async get<T>(endpoint: string, metadata: FunnelLogMetadata): Promise<AxiosResponse<T>> {
    try {
      const response = await axios.get<T>(endpoint);

      await this.writeLogToFirestore({ endpoint, response }, metadata);

      return response;
    } catch (exception) {
      await this.writeLogToFirestore({ endpoint, exception }, metadata);
      throw exception;
    }
  }

  static async writeLogToFirestore<T>(
    options: { data?: AxiosRequestConfig; endpoint?: string; response?: AxiosResponse<T>; exception?: Error },
    metadata: FunnelLogMetadata
  ): Promise<string> {
    try {
      const db = getFirebaseBackend().firestore();
      const UserService = getUserHelper();
      const logRequestRef = db.collection("requestLogs").doc();

      let log: Partial<Log> = {
        date: DateTime.now().setZone("utc").toJSDate(),

        requestLogId: logRequestRef.id,

        invokedBy: "claimsgate",
        invocationFunction: metadata.invocationFunction ?? "notSpecified",
        invocationReason: metadata.invocationReason ?? "notSpecified",

        userId: UserService.getUserId(),
        claimId: metadata.claimId ?? "",
        funnelId: metadata.funnelId ?? "",
        pageId: metadata.pageId ?? "",
        workspaceId: metadata.workspaceId ?? "",

        isTest: metadata?.invocationReason?.endsWith(".spec.ts") ? true : false,
      };

      // Try to get user's IP address
      const { data: userIPAddress } = await onCallGateway<"getUserIPAddress">({ functionName: "getUserIPAddress" });
      if (userIPAddress) {
        log.requestorIPAddress = userIPAddress;
      } else {
        log.requestorIPAddress = "context.rawRequst.ip is not available";
      }

      if (options.data) {
        log = {
          ...log,
          request: {
            method: options.data.method,
            url: options.data.url,
            headers: options.data.headers,
            body: options.data.data,
            query: options.data.params,
          },
        };
      }

      if (options.endpoint) {
        log = {
          ...log,
          endpoint: options.endpoint,
        };
      }

      // check if funnel author can be derived
      let workspaceIdFromFunnelId, workspaceIdFromFunnelIdError;
      if (metadata.funnelId) {
        [workspaceIdFromFunnelId, workspaceIdFromFunnelIdError] = await getWorkspaceIdByFunnelId(db, metadata.funnelId);

        if (!workspaceIdFromFunnelId || workspaceIdFromFunnelIdError) {
          log.securityNote = `WorkspaceId could not be derived from funnelId: ${metadata.funnelId}`;
        }
      }

      // check whether the derived funnel author matches provided workspaceId. If not leave a note in the log
      if (!metadata.workspaceId && workspaceIdFromFunnelId) {
        log.workspaceId = workspaceIdFromFunnelId;
      } else if (metadata.workspaceId && workspaceIdFromFunnelId) {
        if (workspaceIdFromFunnelId !== metadata.workspaceId) {
          log.securityNote = `Passed workspaceId is ${metadata.workspaceId}. WorkspaceId from funnelId is ${workspaceIdFromFunnelId}`;
        }
      }

      // If we received a response from the server, add it to the log
      if (options.response) {
        log.response = {
          statusText: options.response.statusText,
          status: options.response.status,
          data: options.response.data,
          headers: options.response.headers,
        };

        log.status = "success";
      }

      // If we received an exception, add it to the log
      if (options.exception) {
        log.error = {
          message: options.exception.message,
          stack: options.exception.stack,
        };
        log.status = "error";
      }

      // Perform a omitBy to remove any null or undefined values in a deeply nested object
      log.response = omitBy(log.response, isNil) as any;
      log.error = omitBy(log.error, isNil) as any;
      log.request = omitBy(log.request, isNil) as any;

      const compactedLog = omitBy(log, isNil) as Partial<Log>;

      await logRequestRef.set(compactedLog);

      return logRequestRef.id;
    } catch (exception) {
      console.log("AxiosService, writeLogToFirestore", exception);
      return "";
    }
  }
}

// eslint-disable-next-line no-undef
export { AxiosService };
