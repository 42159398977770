import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import { Props } from "@/types";
const blockConsentProps: Props.BlockConsent = inputProps({
  answerType: "none",
  extraProps: [
    "workspaceTermsAndConditionsLink",
    "workspacePrivacyPolicyLink",
    "padding",
    "privacyPolicyText",
    "smsCheckBox",
    "privacyPolicyLink",
  ],
  excludeDefaults: true,
});

export function getProps() {
  return {
    ...blockConsentProps,
  };
}
