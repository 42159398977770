var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Fragment',[_c('PhoneNumberInput',{attrs:{"preferredCountries":[
      'GB',
      'US',
      'CA',
      'AU',
      'DE',
      'FR',
      'IT',
      'ES',
      'NL',
      'BR',
      'MX',
      'ZA',
      'SE',
      'NO',
      'DK',
      'BE',
      'CH',
      'IE',
      'NZ',
    ],"existingPhoneNumber":_vm.BlockInputs.phone.answer,"invalidFeedback":_vm.BlockInputs.phone.invalidFeedback,"state":_vm.BlockInputs.phone.state,"label":_vm.BlockInputs.phone.label,"info":_vm.BlockInputs.phone.info,"padding":_vm.padding},on:{"input":_vm.phoneNumberInputChanged}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }