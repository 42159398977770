import { ComplexQuestionInstance } from "../ComplexQuestionInstance";
import { Question } from "./ComplexQuestionTypes";

export function validateSection(
  state: ComplexQuestionInstance,
  currentEntityIndex: number,
  currentSectionIndex: number
): boolean {
  const { answers, form } = state;

  resetFormState(state);

  return form.sections[currentSectionIndex].questions
    .map((question) => {
      if (question.condition) {
        // if condition not met, return true as the question is not rendered
        if (answers[currentEntityIndex][question.condition.field] !== question.condition.value) {
          return true;
        }
      }

      const questionIsValid = validateQuestion(question, answers, currentEntityIndex);

      return questionIsValid;
    })
    .every((questionIsValid) => questionIsValid);
}

function validateQuestion(question: Question, answers: any[], currentEntityIndex: number) {
  const questionIsValid = assertQuestionValid(question, answers, currentEntityIndex);

  if (!questionIsValid) setStateFailed(question);
  else question.props.state = question.required ? questionIsValid : null;

  return questionIsValid;
}

function assertQuestionValid(question: Question, answers: any[], currentEntityIndex: number) {
  if (!question.required) return true;

  console.log("assertQuestionValid", {
    answer: answers[currentEntityIndex][question.field],
    questionField: question.field,
  });

  if (typeof answers[currentEntityIndex][question.field] === "object") {
    const object = answers[currentEntityIndex][question.field];
    return Object.values(object).every((value) => !!value);
  }

  return (
    !!answers[currentEntityIndex][question.field] ||
    question.defaultAnswer !== answers[currentEntityIndex][question.field]
  );
}

function setStateFailed(question: Question) {
  question.props.state = false;
  question.props.invalidFeedback = "This field is required";
  return false;
}

export function resetFormState(state: ComplexQuestionInstance): void {
  state.form.sections.forEach((section) => {
    section.questions.forEach((question) => {
      question.props.state = null;
    });
  });
}
