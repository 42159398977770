import { BlockPhoneInstance } from "../blockPhone";

import parsePhoneNumber from "libphonenumber-js";
import { isPhoneNumberAvailable } from "./isPhoneNumberAvailable";
import { savePhoneNumber } from "./savePhoneNumber";
import { reject } from "lodash";
import { getFirebaseBackend } from "@/authUtils";

export async function computes(state: BlockPhoneInstance) {
  // If we can skip processing then immediately terminate our execution
  if (state.uiToggles.isPhoneNumberDefined) {
    return true;
  }

  const phoneNumber = state.BlockInputs.phone.answer;

  let parsedPhoneNumber = parsePhoneNumber(phoneNumber, "GB").number;

  if (state.allowInternationalNumbers) {
    parsedPhoneNumber = parsePhoneNumber(phoneNumber, state.internationalPhoneNumber.countryCode as any).number;
  }

  if (!(await isPhoneNumberAvailable(state, parsedPhoneNumber))) {
    // If a user already exists with a given phone number
    state.BlockInputs.phone.invalidFeedback = state.uiMessages.phoneAssociatedOtherAccount;

    reject(state, state.uiMessages.phoneAssociatedOtherAccount);
    state.BlockInputs.phone.state = false;
    return false;
  }

  // If we failed to save the phone number
  if (!(await savePhoneNumber(state, parsedPhoneNumber))) return false;

  await getFirebaseBackend().firebaseAuth()?.currentUser.reload();

  state.BlockInputs.phone.state = true;

  return true;
}

export interface UserExistsResult {
  status: boolean;
  user?: { phoneNumber: boolean; email: boolean };
}
