<script lang="ts">
//@ts-check
import { BuilderUtility } from "@/helpers/ClaimsGate/builder/BuilderUtility";
// eslint-disable-next-line no-unused-vars
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import { Props } from "@/types";
import { themesComputed } from "@/state/helpers";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import Vue from "vue";
const inputPropsValue = inputProps({
  answerType: "string",
  extraProps: ["text", "linkText", "linkTarget"],
});

delete inputPropsValue.label;
delete inputPropsValue.info;

export default Vue.extend({
  name: "BlockCheckbox",
  components: { BlockInputWrapper },
  props: { ...(inputPropsValue as Props.BlockCheckbox) },
  data() {
    return {
      linkTargetCopy: undefined,
      BuilderUtility: BuilderUtility,
    };
  },

  methods: {
    setAnswerUndefined() {
      this.$emit("update:answer", "");
    },
  },
  mounted() {
    if (this.linkTarget) {
      this.linkTargetCopy = BuilderUtility.parseUrl(this.linkTarget);
    }
  },

  watch: {
    linkTarget: {
      handler(newLink) {
        this.linkTargetCopy = BuilderUtility.parseUrl(newLink);
      },
    },
    answer: function (newVal) {
      if (newVal === "not_accepted") {
        this.setAnswerUndefined();
      }
    },
  },
  computed: {
    ...themesComputed,
    dataCyLabel(): string {
      return `checkbox-${this.text.toLowerCase().replace(/\s/g, "")}`;
    },
  },
});
</script>

<template>
  <BlockInputWrapper
    :padding="padding"
    :invalidFeedback="invalidFeedback"
    :state="state"
    :info="undefined"
    :label="undefined"
  >
    <div style="margin-top: 0.25rem">
      <b-form-checkbox
        @change="
          $emit('update:answer', $event);
          $emit('update:state', null);
        "
        :checked="answer"
        :state="state"
        :require="required"
        :disabled="disabled"
        value="accepted"
        unchecked-value="not_accepted"
        :size="theme.size"
        class="x126"
        :ref="'b-checkbox'"
        v-bind:style="[$route.params.workspaceId && theme.primaryStyles ? theme.primaryStyles : {}]"
        :data-cy="`checkbox-${dataCyLabel}`"
      >
        <span class="font-weight-normal" :style="theme.textColourStyles">
          {{ $te("blocks.contactDetails." + text) ? $t("blocks.contactDetails." + text) : text }}
          <a :href="linkTargetCopy" target="_blank"
            >{{ $te("blocks.contactDetails." + linkText) ? $t("blocks.contactDetails." + linkText) : linkText }}
          </a>
        </span>
      </b-form-checkbox>
    </div>
  </BlockInputWrapper>
</template>
