import * as Sentry from "@sentry/vue";
import { captureMessage } from "@sentry/vue";

/** Fires a proactive Sentry log including before and after URL during domain changes in index.js */
export function fireIndexDomainRedirectEvent(beforeURL, afterURL) {
  Sentry.withScope((scope) => {
    scope.setTag("beforeURL", beforeURL);
    scope.setTag("afterURL", afterURL);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("IndexDomainRedirect");
  });
}
