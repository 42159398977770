<script lang="ts">
import Vue from "vue";
import BlockText from "@/components/shared/blocks/text/text.vue";
import { subTitleProps } from "@/helpers/ClaimsGate/blocks/textProps";
export default Vue.extend({
  name: "BlockSubTitle",
  components: { BlockText },
  props: {
    ...subTitleProps(),
    condition: {
      type: Object,
      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },
  },
});
</script>
<template>
  <BlockText
    :text="text"
    :padding="padding"
    :styles="styles"
    :weight="weight"
    :align="align"
    :textColour="textColour"
    :useTheme="useTheme"
  >
    <slot> </slot>
  </BlockText>
</template>
