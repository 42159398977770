<script lang="ts">
import Vue from "vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import { props } from "@/helpers/ClaimsGate/blocks/progressProps";
export default Vue.extend({
  name: "BlockProgressBar",
  components: { BlockPadding },
  props: { ...props },
  computed: {
    cols() {
      if (this.width === "sm") {
        return "col-6 col-lg-4";
      }
      if (this.width === "md") {
        return "col-12 col-lg-6";
      }
      return "col-12";
    },
    justify() {
      if (this.align === "center") {
        return "justify-content-center";
      }
      if (this.align === "right") {
        return "justify-content-end";
      }
      return "";
    },
  },
});
</script>
<style scoped>
div .progress-bar-striped {
  /* background-size: 2rem 2rem !important; */
  background-repeat: repeat;
}
</style>
<template>
  <div>
    <BlockPadding :padding="padding" />
    <div class="row" :class="[justify]">
      <div :class="[cols]">
        <b-progress max="100" :height="height + 'px'" :variant="variant" :animated="animated">
          <b-progress-bar :value="value">
            <template v-if="showProgressValue">
              <span>{{ value }}%</span>
            </template>
          </b-progress-bar>
        </b-progress>
      </div>
    </div>
  </div>
</template>
