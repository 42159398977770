<script lang="ts">
// Import Claims Gate classes
import Vue from "vue";
import { getProps, getData, methods, mappedComputed, BlockYotiInstance, computed } from "./BlockYotiInstance";
import LoadingBar from "@/components/shared/layout/loadingBar.vue";
import BlockFaq from "@/components/shared/blocks/faq/faq.vue";

import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockLink from "@/components/shared/blocks/link.vue";
import BlockManualIdUpload from "@/components/shared/blocks/manualIdUpload/yotiManualIdUpload.vue";
import Alert from "@/components/claimant/track/alert/alert.vue";
import ContactForm from "@/components/claimant/track/contactForm/contactForm.vue";
import { Fragment } from "vue-frag";
import CardHeader from "@/components/shared/styleWrappers/cardHeader/cardHeader.vue";

export default Vue.extend({
  name: "BlockYoti",
  components: {
    LoadingBar,
    BlockPadding,
    Fragment,
    BlockButton,
    Alert,
    BlockFaq,
    ContactForm,
    CardHeader,
    BlockLink,
    BlockManualIdUpload,
  },
  props: {
    ...getProps(),
  },
  data() {
    return {
      ...getData(this),
    };
  },
  methods: {
    reloadPage() {
      return methods.reloadPage();
    },
    emitSubmit() {
      return methods.emitSubmit(this as BlockYotiInstance);
    },
  },
  computed: {
    ...mappedComputed(),
    isVerified(): boolean {
      return computed.isVerified(this as BlockYotiInstance);
    },
    showContactFaq(): boolean {
      return computed.showContactFaq(this as BlockYotiInstance);
    },
  },
  async mounted(): Promise<void> {
    methods.beforeDestroy(this as BlockYotiInstance);
    await methods.mounted(this as BlockYotiInstance);
  },
  beforeDestroy() {
    methods.beforeDestroy(this as BlockYotiInstance);
  },
});
</script>
<template>
  <Fragment>
    <BlockPadding :padding="padding"></BlockPadding>
    <template v-if="isBuilder">
      <div class="row justify-content-center text-center">
        <div class="col-12">
          <img :src="require('@/assets/images/yoti-placeholder.png')" />
        </div>
      </div>
    </template>
    <template v-else>
      <b-modal hide-footer size="lg" id="yotiCameraError">
        <template #modal-header>
          <CardHeader
            :heading="`Camera Permissions Required`"
            :supportingText="'Follow the instructions below to enable camera permissions for your browser:'"
          ></CardHeader>
        </template>

        <div class="mt-0">
          <h4 class="font-weight-semibold text-md text-grey-900">iPhone Users</h4>
          <p class="text-sm mb-0">
            <span class="text-grey-600 font-weight-semibold">For Safari:</span> Go to Settings > Safari > Camera and
            select 'Allow'.
          </p>
          <p class="text-sm mt-2">
            <span class="text-grey-600 font-weight-semibold">For Other Browsers:</span> Go to Settings > [Your Browser]
            > Camera and select 'Allow'.
          </p>
        </div>
        <!-- Android Section -->
        <div class="mt-4">
          <h4 class="font-weight-bold text-md text-grey-900">Android Users</h4>
          <p class="text-sm mb-0">A popup should appear asking for permissions when you try to use the camera.</p>
          <p class="text-sm mt-2">
            If it doesn't, go to Settings > Apps > [Your Browser] > Permissions and enable Camera.
          </p>
        </div>

        <!-- Desktop/Laptop Section -->
        <div class="mt-4">
          <h4 class="font-weight-bold text-md text-grey-900">Desktop/Laptop Users</h4>

          <p class="text-sm mb-0 mt-2">
            <span class="font-weight-semibold text-grey-600">Google Chrome:</span>
            A camera icon will appear in the address bar. Click it and choose 'Allow'.
          </p>

          <p class="text-sm mb-0 mt-2">
            <span class="font-weight-semibold text-grey-600">Mozilla Firefox:</span>
            A popup will appear near the address bar. Click 'Allow'.
          </p>

          <p class="text-sm mb-0 mt-2">
            <span class="font-weight-semibold text-grey-600">Microsoft Edge:</span>
            A popup will appear at the bottom of the browser. Click 'Allow'.
          </p>

          <p class="text-sm mb-0 mt-2">
            <span class="font-weight-semibold text-grey-600">Safari:</span>
            A prompt will appear asking for your permission. Click 'Allow'.
          </p>
        </div>

        <b-form-checkbox v-model="isChecked" class="mt-5" size="lg" v-if="!isChecked">
          I have attempted to enable camera permissions for my browser.
        </b-form-checkbox>

        <b-button size="lg" variant="primary" class="mt-5" @click="reloadPage" v-if="isChecked" block>
          Refresh Page
        </b-button>
      </b-modal>

      <!-- If the user does not have their identity pending and has not already been verified -->
      <template v-if="!identityPending && !isVerified">
        <template v-if="loadingYotiSrc">
          <div class="row justify-content-center">
            <LoadingBar :loading="loadingYotiSrc" />
          </div>
        </template>

        <template v-else>
          <template v-if="!showManual">
            <div class="d-flex justify-content-center text-center">
              <div class="row">
                <div class="col-12 d-none d-lg-block">
                  <iframe :src="yotiSrc" style="height: 60vh; width: 100vw; border: none" allow="camera"></iframe>
                </div>

                <div class="col-12 d-lg-none d-block">
                  <iframe :src="yotiSrc" style="height: 70vh; width: 100%; border: none" allow="camera"></iframe>
                </div>

                <!-- <div class="col-12">
                <BlockPadding :padding="'20'"></BlockPadding>
                <slot></slot>
              </div> -->
              </div>
            </div>
            <div class="row">
              <div class="d-flex justify-content-center text-center" v-if="allowManualUploadFallback">
                <BlockPadding :padding="'20'"></BlockPadding>
                <BlockLink
                  :emitClick="true"
                  :text="$t('blocks.yoti.clickManualUpload')"
                  @clicked="showManual = true"
                ></BlockLink>
              </div>
            </div>
          </template>
          <template v-else>
            <BlockManualIdUpload />
            <div class="mt-2">
              <BlockLink
                :emitClick="true"
                :text="$t('blocks.yoti.backToYoti')"
                @clicked="showManual = false"
              ></BlockLink>
            </div>
          </template>
        </template>
      </template>

      <!-- If the user is awaiting verification from Yoti -->

      <template v-else-if="identityPending && !isVerified">
        <template v-if="!showManual">
          <Alert
            :heading="$t('blocks.yoti.verificationInProgress')"
            :supportingText="$t('blocks.yoti.inProgressInfo')"
            :mode="'primary'"
            :actions="[{ label: 'Refresh Status', id: 'refresh' }]"
            @actionSelected="reloadPage"
          />
          <BlockPadding :padding="'20'"></BlockPadding>
          <div v-if="allowManualUploadFallback">
            <BlockPadding :padding="'20'"></BlockPadding>
            <BlockLink
              :emitClick="true"
              :text="$t('blocks.yoti.clickManualUpload')"
              @clicked="showManual = true"
            ></BlockLink>
          </div>
        </template>
        <template v-else>
          <BlockManualIdUpload />
          <BlockLink :emitClick="true" :text="$t('blocks.yoti.backToYoti')" @clicked="showManual = false"></BlockLink>
        </template>
      </template>

      <!-- v-else-if event.eventType === ERROR show reload button ? -->

      <template v-else-if="!identityPending && isVerified && yotiSrc">
        <Alert
          :heading="'Identity Verification Completed'"
          :supportingText="'Congratulations! Your identity has been successfully verified. You can now proceed by clicking the button below.'"
          :mode="'success'"
        />

        <div class="row justify-content-center">
          <div class="col-12">
            <BlockButton
              v-bind="BlockInputs.submitButton"
              v-bind.sync="BlockInputs.submitButton"
              :isProcessing="loaders.next"
              @clicked="emitSubmit"
            />
          </div>
        </div>
      </template>
      <template v-else-if="!identityPending && isVerified">
        <Alert
          :heading="'Identity Already Verified'"
          :supportingText="'It appears you have already verified your identity. There\'s no need to resubmit your details. Please click the button below to continue.'"
          :mode="'primary'"
        />

        <div class="row justify-content-center">
          <div class="col-12">
            <BlockButton
              v-bind="BlockInputs.submitButton"
              v-bind.sync="BlockInputs.submitButton"
              :isProcessing="loaders.next"
              @clicked="emitSubmit"
            />
          </div>
        </div>
      </template>
    </template>
    <template>
      <div>
        <BlockPadding :padding="'30'"></BlockPadding>

        <div class="d-flex flex-column align-items-left">
          <p class="text-lg text-grey-900 font-weight-semibold text-left">
            {{ $t("blocks.yoti.faqs.struggleToConfirm") }}
          </p>
        </div>

        <BlockPadding :padding="'0'"></BlockPadding>
        <template v-for="(q, i) of faqContent">
          <BlockFaq v-bind="q" :padding="'0'" :key="i + 'question'" />
        </template>
        <BlockFaq
          :useSlot="true"
          faqQuestion="What can I do if I cannot complete the identity verification"
          padding="0"
          v-if="showContactFaq"
        >
          <p class="card-text" @click="$bvModal.show('contactModal')">
            {{ $t("blocks.yoti.faqs.contact") }}
            <b-link @click.prevent="$bvModal.show('contactModal')"> {{ $t("blocks.yoti.faqs.clickHere") }}</b-link>
          </p>

          <ContactForm :showTrackCard="false" :claimId="claimId" :user="userData" :userId="userData.id" />
        </BlockFaq>
      </div>
    </template>
  </Fragment>
</template>
