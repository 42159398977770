<script lang="ts">
// Types
// eslint-disable-next-line no-unused-vars
import { Props, UserBlockProps, BlockProps, Claim } from "@/types";
import { Agreement, Page } from "@claimsgate/core-types";
import Vue from "vue";
import firebase from "firebase/compat/app";
// Components
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockDraw from "@/components/shared/blocks/draw.vue";
import BlockLoadingBar from "@/components/shared/layout/loadingBar.vue";
//import BlockCheckbox from "@/components/shared/blocks/checkbox.vue";
//import ModalBodyWrapper from "@/components/shared/wrappers/modalBodyWrapper.vue";
import { englandWalesRejectionConditional } from "@/components/claimant/form/methods";
//import BlockHtml from "@/components/shared/blocks/html.vue";
// Services
import {
  FunnelsService,
  getClaimsByFunnelId,
  getFunnelVariables,
  Milberg,
  Quantum,
  Tlw,
  updateClaim,
} from "@claimsgate/core";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
import { StorageService } from "@/helpers/ClaimsGate/StorageService";
import { ClaimDataService, UserDataService } from "@/helpers/ClaimsGate/DataService";
import { getFirebaseBackend } from "@/authUtils";
// eslint-disable-next-line no-unused-vars
import { getUserHelper, UserHelper } from "@/helpers/ClaimsGate/UserHelper";

import { eventComputed, formComputed, themesComputed } from "@/state/helpers";
import { msg } from "@/helpers/ClaimsGate/ResponseMessageService";
import { UserVariableService } from "@/helpers/ClaimsGate/UserVariableService";
import { getClaimDataService, getUserId, isClaimsGateFunctionReturnedError } from "@/helpers/vue";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";

export default Vue.extend({
  name: "BlockAgreement",

  components: {
    BlockPadding,
    BlockDraw,
    BlockLoadingBar,
    //BlockCheckbox,
    //ModalBodyWrapper,
    //BlockHtml,
    // BlockText,
    // BlockLink,
  },
  props: {
    condition: {
      type: Object,
      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },
    signatureRequired: {
      type: Boolean,
      required: false,
      default: true,
      description: "Require the user to draw a signature to associate with their claim.",
    } as Props.Bool,
    duration: {
      type: String,
      // required: true,
      default: "indefinite",
      valid: ["indefinite", "6 months", "1 year", "2 years", "3 years", "4 years", "5 years", "6 years"],
      description: "Duration of agreement in years.",
    } as Props.Str,
    file: {
      type: [Object, String],
      required: false,
      // default: () => {
      //   return { type: "file", path: "a file path", id: "90usiglfg" };
      // },
      description:
        "This file will be templated and attached to the user's claim for their records. To template the document with variables from the claim or from the user wrap the variable in {{ }}. For example {{email}} would become test@email.com",
    } as Props.File,
    buttonText: {
      type: String,
      required: false,
      default: "Continue",
      description:
        "The text that will be displayed on the submit button. Once pressed a templated pdf of the agreement will be generated. This may take up to 10 minutes.",
    } as Props.Str,
    answer: {
      required: false,
      allowVariable: false,
      default: "",
      description: "The text in the input",
    } as Props.Answer<string>,
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    } as Props.Str,
    storeAs: {
      required: false,
    } as Props.VueProps<{ id: string }>,
    //summaryDocument: {
    //  type: String,
    //  required: false,
    //  allowVariable: true,
    //  default: "<h1>Hello world!</h1>",
    //  description: "A summary of the agreement that the user will be able to view before signing.",
    //} as Props.Str,
    //acceptText: {
    //  type: String,
    //  required: false,
    //  default: "I have read and accept the agreement",
    //  description: "The text to show next to the accept checkbox",
    //} as Props.Str,
  },

  data() {
    return {
      demoPath: "",
      examplePath: "",
      userService: getUserHelper(),
      claimDataService: undefined as ClaimDataService,
      userDataService: undefined as UserDataService,
      /** @type { ClaimDataService} */
      infoModalService: new InfoModalService(this.$infoModal), //? this is wrong, need to create type
      funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
      storageService: new StorageService(),
      firebaseFunctions: getFirebaseBackend().firebaseFunctions(),
      db: getFirebaseBackend().firestore(),
      FieldValue: firebase.firestore.FieldValue,
      BlockInputs: {
        ...UserVariableBlocksService.getBlocks(["draw", "text"]),
        acceptCheckbox: UserVariableService.genBlockCheckboxProps({
          linkText: "",
          linkTarget: "",
          text: "Do you accept the agreement",
        }),
      } as {
        draw: BlockProps.Draw;
        text: BlockProps.Text;
        acceptCheckbox: BlockProps.Checkbox<"" | "accepted">;
      },

      uiToggles: {
        isValidating: false,
        isLoading: false,
      },
      uiMessages: {
        failedToMerge: "Failed to complete agreement",
        fileMissing: "Agreement is set up incorrectly",
      },
      blockPreviouslyCompleted: false,
      linksCopy: [],
      userId: undefined as string,
      claimId: this.$route.params.claimId,
      agreementId: undefined as string,
      templateFileId: undefined as string,
      claimData: undefined as Claim,
      fileDownloadLink: "",
      pageId: this.$route.params.pageId,
      funnelId: this.$route.params.funnelId,
    };
  },
  computed: {
    ...eventComputed,
    ...formComputed,
    ...themesComputed,

    loaderState(): boolean {
      console.log("Loader state", this.loaders.next, this.uiToggles.isValidating);

      // If the form is processing but we are not return true
      if (this.loaders.next && !this.uiToggles.isValidating) {
        return true;
      }

      // If we are processing but the form is not return true
      if (this.uiToggles.isValidating && !this.loaders.next) {
        return true;
      }

      // If we are processing and the form is processing return true
      if (this.uiToggles.isValidating && this.loaders.next) {
        return true;
      }

      return false;
    },
  },
  methods: {
    async submit(): Promise<void> {
      this.uiToggles.isValidating = true;
      this.BlockInputs.acceptCheckbox.state = null;

      if (this.claimData.currentFunnelId === Quantum.Funnels.THOMPSONS_SOLICITORS_DIESEL_EMISSIONS_ID) {
        if (await this.userHasClaimsForVin()) {
          this.uiToggles.isValidating = false;
          return;
        }
      }

      if (this.claimData.currentFunnelId === Milberg.Funnels.JLR_DPF_FUNNEL_ID) {
        if (await this.isNotEnglandWales()) {
          // Next logic will run the check again and push them to rejection page
          await this.$store.dispatch("events/fire", { name: this.eventValues.next });
          this.uiToggles.isValidating = false;
          return;
        }
        if (await this.userHasClaimsForVin()) {
          this.uiToggles.isValidating = false;
          return;
        }
      }

      const variableId = this.storeAs?.id;
      // Used if the accept check box is implemented
      //if (this.BlockInputs.acceptCheckbox.answer !== "accepted" && this.acceptText?.length > 0) {
      //  this.BlockInputs.acceptCheckbox.state = false;
      //  this.uiToggles.isValidating = false;
      //  this.BlockInputs.draw.state = !!this.BlockInputs.draw.answer;
      //  return;
      //}
      if (this.blockPreviouslyCompleted && this.claimData?.[variableId]?.merged === true) {
        this.BlockInputs.draw.state = true;
        await this.$store.dispatch("events/fire", { name: this.eventValues.next });
        this.uiToggles.isValidating = false;
        return;
      } else if (this.blockPreviouslyCompleted && this.claimData?.[variableId]?.merged === false) {
        this.BlockInputs.draw.state = true;
        await this.templateClaimAgreement();
        return;
      } else {
        const saveResult = await this.saveUserAgreement();
        if (saveResult) {
          await this.templateClaimAgreement();
        }
        this.uiToggles.isValidating = false;
      }
    },
    async isNotEnglandWales(): Promise<boolean> {
      const rejection = await englandWalesRejectionConditional(
        { id: this.pageId } as Page,
        this.funnelId,
        this.claimDataService
      );
      if (!rejection) {
        return false;
      }
      return true;
    },

    async userHasClaimsForVin(): Promise<boolean> {
      // const claimStatus = this.claimData.claimStatus;
      // // comment out this if you want testing to be included
      // // if (claimStatus === "testing") {
      // //   return false;
      // // }
      //Check that vin is not existing in this users claims
      const [claimsForUser, _error] = await getClaimsByFunnelId(
        getFirebaseBackend().firestore(),
        this.userId,
        this.claimData.currentFunnelId
      );

      if (_error) {
        console.error("[milbergDPF] There was an error gettingClaimsByFunnelId in agreement.vue", _error);
      }

      const [funnelVariables] = await getFunnelVariables(
        getFirebaseBackend().firestore(),
        this.claimData.currentFunnelId
      );

      const vinVariable = funnelVariables.find((variable) => variable.field === "vin");
      const agreementVariable = funnelVariables.find((variable) => variable.field === "agreement");

      const existingClaim = claimsForUser.find((claim) => {
        console.log("hasClaimsForUser", claim, this.claimData);
        // If the currentFunnelId is not the same as the current funnel then this is not an existing claim
        if (claim.currentFunnelId !== this.claimData.currentFunnelId) {
          return false;
        }

        // If the claim is paused or deleted then this is not an existing claim
        if (claim.claimStatus === "paused" || claim.claimStatus === "deleted") {
          return false;
        }

        // If the claim is the same as the current claim then this is not an existing claim
        if (claim.claimId === this.claimData.claimId) {
          return false;
        }

        // If the claim has an agreement then this is an existing claim
        if (claim?.[agreementVariable.id] && claim?.[vinVariable.id] === this.claimData?.[vinVariable.id]) {
          return true;
        }

        return false;
      });

      if (!existingClaim) {
        return false;
      }

      await this.infoModalService.fire("warning", {
        title: "You have already registered a claim for this vehicle",
        text: "It looks like you already have an active claim for this vehicle. Click resume to go to your existing claim.",
        cancelButtonText: "Cancel",
        confirmButtonText: "Resume",
        onConfirm: () => {
          // Mark this claim as paused
          updateClaim(getFirebaseBackend().firestore(), this.userId, this.claimId, {
            claimStatus: "paused",
            wasDuplicate: true,
            dateDuplicateIdentified: new Date(),
          }).then(() => {
            this.$router.push({ name: "Track", params: { claimId: existingClaim.documentId } });
          });
        },
        onHide: () => {
          console.log("Closed");
        },
      });

      return true;
    },

    async templateClaimAgreement(): Promise<boolean> {
      // Trigger function templateClaimAgreement with userId,  claimId, agreementId
      try {
        if (!this.userId || !this.claimId || !this.$route.params?.funnelId) {
          // Some data is missing to render agreement
          await this.infoModalService.fire("error", { text: this.uiMessages.failedToMerge });
          this.uiToggles.isValidating = false;
          return false;
        }

        if (!this.storeAs?.id) {
          await this.infoModalService.fire("error");
          this.uiToggles.isValidating = false;
          throw new Error("Store as has not been defined for agreement!");
        }

        const funnelId = this.$route.params?.funnelId;

        const options = {
          userId: this.userId,
          claimId: this.claimId,
          funnelId,
          variableId: this.storeAs.id,
        };

        if (funnelId === Tlw.Funnels.DIESEL_EMISSIONS_FUNNEL_ID) {
          // copy the claim to TLW Workspace Funnel.
          const response = await onCallGateway<"tlwCopyClaim">({
            functionName: "tlwCopyClaim",
            data: {
              userId: this.userId,
              claimId: this.claimId,
              // TODO: add workspacesWithUserDataAccess, ... if needed
            },
          });

          if (isClaimsGateFunctionReturnedError(response)) {
            console.error("Error from tlwCopyClaim", response);
            return false;
          }

          if (response?.data?.["success"] && response?.data?.["copiedClaimId"]) {
            console.log("TLW Copy Claim", response);
            const copiedClaimId = response.data["copiedClaimId"];
            const copiedFunnelId = response.data["copiedFunnelId"];
            const copiedWorkspaceId = response.data["copiedWorkspaceId"];
            //const copiedPageId = response.data["copiedPageId"];

            options.claimId = copiedClaimId;
            options.funnelId = copiedFunnelId;
            await this.db.collection("tasks").doc().set({
              performAt: this.FieldValue.serverTimestamp(),
              status: "scheduled",
              worker: "templateClaimAgreement",
              options: options,
            });
            options.claimId = this.claimId;
            options.funnelId = funnelId;

            const [funnelVariables] = await getFunnelVariables(getFirebaseBackend().firestore(), funnelId);
            const copiedClaimVariableId = funnelVariables.find((variable) => variable.field === "copiedClaimId")?.id;
            this.claimDataService.setArtefact(copiedClaimVariableId, copiedClaimId);

            const workspacesWithUserDataAccess = this.userDataService.getArtefact("workspacesWithUserDataAccess") ?? [];
            if (workspacesWithUserDataAccess.indexOf(copiedWorkspaceId) === -1) {
              workspacesWithUserDataAccess.push(copiedWorkspaceId);
              this.userDataService.setArtefact("workspacesWithUserDataAccess", workspacesWithUserDataAccess);
            }

            // await this.db.collection("tasks").doc().set({

            //   performAt: this.FieldValue.serverTimestamp(),
            //   status: "scheduled",
            //   worker: "templateClaimAgreement",
            //   options: options,
            // });
            //console.log("redirecting to new claim", copiedClaimId, copiedFunnelId, copiedPageId);
            //window.location.pathname = `/form/${copiedFunnelId}/${copiedPageId}/${copiedClaimId}`;
            //return true;
          } else {
            console.error("[TLW] Error copying claim", response);
          }
        }

        await this.db.collection("tasks").doc().set({
          performAt: this.FieldValue.serverTimestamp(),
          status: "scheduled",
          worker: "templateClaimAgreement",
          options: options,
        });

        console.log("Agreement fireing next");
        await this.$store.dispatch("events/fire", { name: this.eventValues.next });

        this.uiToggles.isValidating = false;

        return true;
      } catch (exception) {
        console.log("Task save e", exception);
        await this.infoModalService.fire("error", { text: this.uiMessages.failedToMerge });
        this.uiToggles.isValidating = false;
        return false;
      }
    },
    async saveUserAgreement(maintainLoader: boolean = true): Promise<boolean> {
      try {
        if (this.signatureRequired) {
          // Set State on signature block, break if answer undefined.
          if (this.BlockInputs.draw.answer) {
            this.BlockInputs.draw.state = true;
          } else {
            this.BlockInputs.draw.state = false;
            this.BlockInputs.draw.invalidFeedback = "Please sign your signature in the box above";
            this.uiToggles.isValidating = false;
            return false;
          }
        }

        const userAgreement: Agreement = {
          signature: this.BlockInputs.draw.answer,
          dateAgreed: this.FieldValue.serverTimestamp(),
          status: "accepted",
          duration: this.durationNumber(),
          type: "agreement",
          funnelId: this.$route.params.funnelId,
        };

        if (!this.file?.path) {
          // Funnel author hasn't uploaded a file for templating
          await this.infoModalService.fire("error", { text: this.uiMessages.failedToMerge });
          this.uiToggles.isValidating = false;
          return false;
        }

        userAgreement.templateFileId = this.file.fileId;

        if (this.file.templateVersion !== undefined) {
          userAgreement.templateVersion = this.file.templateVersion;
        }
        // Save as variable id
        if (this.storeAs.id) {
          this.claimDataService.setArtefact(this.storeAs.id, userAgreement);
          this.claimDataService.setArtefact("agreementAccepted", true);
          const result = await this.claimDataService.update();

          this.agreementId = this.claimDataService.getArtefact(this.storeAs.id).id;
          this.$store.dispatch("events/fire", { name: this.eventValues.newAgreement, agreementId: this.agreementId });
          this.templateFileId = this.claimDataService.getArtefact(this.storeAs.id).templateFileId;

          if (!maintainLoader) {
            this.uiToggles.isValidating = false;
          }

          return result.data;
        } else {
          this.uiToggles.isValidating = false;
          throw new Error("Store as is not defined!");
        }
      } catch {
        await this.infoModalService.fire("error", {
          title: msg.errors.failedToSave,
          text: msg.actions.tryAgain,
        });
        this.uiToggles.isValidating = false;

        return false;
      }
    },
    /**
     * Convert the duration option selected into a floating point integer.
     */
    durationNumber(): number {
      if (this.duration === "indefinite") {
        return 0;
      } else if (this.duration.includes("months")) {
        return 0.5;
      } else {
        return parseFloat(this.duration);
      }
    },
    async openFile(path: string): Promise<void> {
      const url = await this.storageService.getDownloadLink(path);
      window.open(url);
    },
  },
  watch: {
    text: function (newVal) {
      this.BlockInputs.text.text = newVal;
    },
    signatureRequired: function (newVal) {
      this.BlockInputs.draw.required = newVal;
    },
    loaders: {
      handler() {
        if (!this.loaders.next) {
          this.uiToggles.isValidating = false;
        }
      },
      deep: true,
    },
    // Needed when checkbox is implemented
    //acceptText() {
    //  this.BlockInputs.acceptCheckbox.text = this.acceptText;
    //},
  },
  async mounted() {
    try {
      this.uiToggles.isLoading = true;

      const userId = getUserId();

      this.userId = userId;

      if (!this.userDataService) {
        this.userDataService = new UserDataService(this.userId);
      }

      getClaimDataService(this);

      this.claimData = this.claimDataService.getCache();

      // Set up Block
      // this.BlockInputs.text.text = this.text;
      this.BlockInputs.draw.required = this.signatureRequired;

      console.log("CLAIM DATA IS", this.claimData);
      let variableId = this.storeAs?.id;

      if (this.claimData?.[variableId]?.status === "accepted") {
        this.BlockInputs.draw.answer = this.claimData?.[variableId]?.signature;
        this.BlockInputs.draw.disabled = true;
        this.blockPreviouslyCompleted = true;
      }

      // if (this.file?.path) {
      //   this.fileDownloadLink = await this.storageService.getDownloadLink(this.file.path);
      // }
      this.uiToggles.isLoading = false;
    } catch (exception) {
      console.log(exception);
      await this.infoModalService.fire("error", { text: msg.errors.pageLoadFailed });
      this.uiToggles.isLoading = false;
    }
  },
});
</script>
<style scoped></style>
<template>
  <div>
    <template v-if="uiToggles.isLoading">
      here is claim {{ claimData }}
      <BlockLoadingBar :text="'Loading'" />
    </template>
    <template v-else>
      <!-- Code for summary, linkw view and checkbox -->
      <!--<BlockHtml :html="summaryDocument" />-->
      <!--<BlockPadding :padding="padding" />-->
      <!--<template v-if="file && file.templateExamplePath">
        <b-link @click.prevent="openFile(file.templateExamplePath)">View Agreement</b-link>
      </template>-->
      <!--<template v-if="acceptText && acceptText.length > 0">
        <BlockCheckbox v-bind="BlockInputs.acceptCheckbox" v-bind.sync="BlockInputs.acceptCheckbox" />
      </template>-->
      <BlockPadding :padding="padding" />
      <template v-if="signatureRequired">
        <BlockDraw v-bind="BlockInputs.draw" v-bind.sync="BlockInputs.draw" />
      </template>
      <BlockPadding :padding="padding" />
      <template>
        <b-row class="no-gutters">
          <b-col class="text-center">
            <b-button
              @click="submit()"
              variant="primary"
              class="text-white"
              :size="theme.size"
              :squared="theme.squared"
              :block="false"
              style="margin-top: 10px"
              :disabled="loaderState"
              :data-cy="`agreement-button-submit`"
            >
              <template v-if="loaderState">
                <b-spinner class="spinner-border-sm m-1" role="status"></b-spinner>
              </template>
              <template v-else>
                {{ buttonText }}
              </template>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>
