<script>
//@ts-check

// Types
// eslint-disable-next-line no-unused-vars
import { Props, UserBlockProps } from "@/types";

// Components
import BlockInput from "@/components/shared/blocks/input.vue";
import BlockDropdown from "@/components/shared/blocks/dropdown.vue";
import BlockLoadingBar from "@/components/shared/layout/loadingBar.vue";

// services
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { UserVariableService } from "@/helpers/ClaimsGate/UserVariableService";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
// eslint-disable-next-line no-unused-vars
import { getUserHelper, UserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { eventComputed, formComputed } from "@/state/helpers";

/**
 * @type { ['title', 'firstName','middleName', 'lastName', 'other']}
 */
const blockNameInputs = ["title", "firstName", "middleName", "lastName", "other"];

export default {
  name: "BlockName",
  components: {
    BlockInput,
    BlockDropdown,
    BlockLoadingBar,
  },
  props: {
    /**@type { Props.Size } */
    size: {
      type: String,
      required: true,
      default: "lg",
      valid: ["lg", "md", "sm"],
      description: "The size of the input",
      allowVariable: false,
    },
    /**@type { Props.Disabled } */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** @type { Props.State } */
    state: {
      type: Boolean,
      default: false,
    },
    /**@type { Props.Bool } */
    title: {
      type: Boolean,
      default: true,
    },
    /**@type { Props.Bool } */
    lastName: {
      type: Boolean,
      default: true,
    },
    /**@type { Props.Bool } */
    middleName: {
      type: Boolean,
      default: true,
    },
    /**@type { Props.Bool } */
    firstName: {
      type: Boolean,
      default: true,
    },
    /**@type { Props.Bool } */
    allowOtherTitle: {
      type: Boolean,
      default: true,
      description: "Allow  other to be custom",
    },
    /** @type { Props.Str} */
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    },
  },
  computed: {
    ...eventComputed,
    ...formComputed,
  },
  data() {
    return {
      /**@type { UserBlockProps.Name } */
      BlockInputs: UserVariableBlocksService.getBlocks(blockNameInputs),
      infoModalService: new InfoModalService(this.$infoModal),
      /**@type { UserHelper } */
      userService: getUserHelper(),
      /**@type { UserDataService | undefined } */
      userDataService: undefined,
      /** @type { Array<'firstName' | 'lastName' | 'title'> } */
      requiredFields: ["firstName", "lastName", "title"],
      /**@type { { isValidating : boolean, pageLoading: boolean}} */
      uiToggles: {
        isValidating: false,
        pageLoading: false,
      },
      /**@type {{  pleaseEnterYour: 'Please enter your ', pageLoadFailed: 'This page has failed to load. Please refresh and try again'}} */
      uiMessages: {
        pleaseEnterYour: "Please enter your ",
        pageLoadFailed: "This page has failed to load. Please refresh and try again",
      },
      /** @type {{ firstName : string | undefined, lastName : string | undefined, middleName : string | undefined, title: string | undefined}} */
      user: {
        firstName: undefined,
        lastName: undefined,
        middleName: undefined,
        title: undefined,
      },
    };
  },
  methods: {
    /**
     * Emits an event to form.vue indicating the page should be submitted
     * @type { () => Promise<void> }
     */
    async emitPageSubmit() {
      // this.userDataService = new UserDataService(this.userService.getUserId());
      await this.userDataService?.refresh();

      this.$store.dispatch("events/fire", { name: this.eventValues.next });
    },
    /** @type {{ ():Promise<void>}} */
    async submit() {
      this.uiToggles.isValidating = true;

      if (!this.BlockInputs.title.answer) {
        this.BlockInputs.title.state = false;
        this.BlockInputs.title.invalidFeedback = this.invaldidMessage("title");
        this.uiToggles.isValidating = false;
        return;
      }

      if (this.validateFields()) {
        const title =
          this.BlockInputs.title.answer === "Other" ? this.BlockInputs.other.answer : this.BlockInputs.title.answer;

        this.userDataService?.setManyArtefacts({
          title: title,
          firstName: this.BlockInputs.firstName.answer,
          middleName: this.BlockInputs.middleName.answer,
          lastName: this.BlockInputs.lastName.answer,
        });
        await this.userDataService?.update();

        await this.emitPageSubmit();
      } else {
        // ? THROW SWAL ??
        this.uiToggles.isValidating = false;
      }
    },
    /** @type {{ (): Boolean}} */
    validateFields() {
      // Set state and feedback for fields
      let result = this.requiredFields.map((field) => {
        if (this[field] && !UserVariableService.isValidName(this.BlockInputs[field].answer)) {
          this.BlockInputs[field].state = false;
          if (field !== "title") {
            this.BlockInputs[field].invalidFeedback = this.invaldidMessage(field);
          }
          return false;
        } else {
          // If title block === "Other" need to validate BlockInpputs.other
          if (field === "title" && this.BlockInputs.title.answer === "Other") {
            if (!UserVariableService.isValidName(this.BlockInputs.other.answer)) {
              this.BlockInputs.other.state = false;
              this.BlockInputs.other.invalidFeedback = this.invaldidMessage("title");
              return false;
            } else {
              this.BlockInputs.other.state = true;
            }
          }

          this.BlockInputs[field].state = true;
          return true;
        }
      });

      if (this.BlockInputs.middleName.answer?.length > 1) {
        this.BlockInputs.middleName.state = true;
      }

      return !result.some((field) => !field);
    },
    /** Loads values into input blocks and disables those with values
     * @type {{ ():void}}
     */
    disableCompletedFields() {
      this.requiredFields.map((/**@type { 'firstName' | 'lastName' | 'title'} */ field) => {
        if (this.user[field] && field !== "title") {
          this.BlockInputs[field].answer = this.user[field] ?? "";
          this.BlockInputs[field].disabled = true;
        } else {
          const titleOptions = this.BlockInputs.title.options;
          if (this.user.title) {
            if (titleOptions.includes(this.user.title)) {
              this.BlockInputs[field].answer = this.user[field] ?? "";
              this.BlockInputs[field].disabled = true;
            } else {
              this.BlockInputs.title.answer = "Other";
              this.BlockInputs.title.disabled = true;
              this.BlockInputs.other.answer = this.user.title;
              this.BlockInputs.other.disabled = true;
            }
          }
        }
      });
      if (this.user.middleName) {
        this.BlockInputs.middleName.answer = this.user.middleName;
        this.BlockInputs.middleName.disabled = true;
      }
    },
    /** @type {{ (field: string): String}} */
    invaldidMessage(field) {
      // convert camel to normal words
      const fieldString = field
        .split(/(?=[A-Z])/)
        .join(" ")
        .toLowerCase();
      return this.uiMessages.pleaseEnterYour + fieldString;
    },
  },
  async mounted() {
    this.uiToggles.pageLoading = true;

    try {
      this.userDataService = new UserDataService(this.userService.getUserId());
      await this.userDataService.refresh();
      const userData = this.userDataService.getCache();

      this.user = {
        title: userData.title ?? undefined,
        firstName: userData.firstName ?? undefined,
        middleName: userData.middleName ?? undefined,
        lastName: userData.lastName ?? undefined,
      };

      this.disableCompletedFields();
      this.uiToggles.pageLoading = false;
    } catch (e) {
      console.log(e);
      this.uiToggles.pageLoading = false;
      await this.infoModalService.fire("error", { text: this.uiMessages.pageLoadFailed });
    }
  },
  watch: {
    size: function (newVal) {
      blockNameInputs.forEach((input) => {
        this.BlockInputs[input].size = newVal;
      });
    },
    padding: {
      handler: function (newPadding) {
        blockNameInputs.forEach((input) => {
          this.BlockInputs[input].padding = newPadding;
        });
      },
      immediate: true,
    },
  },
};
</script>
<template>
  <div>
    <template v-if="uiToggles.pageLoading">
      <BlockLoadingBar text="Loading..." />
    </template>
    <template v-else>
      <template v-if="title">
        <BlockDropdown v-bind="BlockInputs.title" v-bind.sync="BlockInputs.title" />
        <template v-if="BlockInputs.title.answer === 'Other' && allowOtherTitle">
          <BlockInput v-bind="BlockInputs.other" v-bind.sync="BlockInputs.other" />
        </template>
      </template>

      <template v-if="firstName">
        <BlockInput v-bind="BlockInputs.firstName" v-bind.sync="BlockInputs.firstName" />
      </template>
      <template v-if="middleName">
        <BlockInput v-bind="BlockInputs.middleName" v-bind.sync="BlockInputs.middleName" />
      </template>
      <template v-if="lastName">
        <BlockInput v-bind="BlockInputs.lastName" v-bind.sync="BlockInputs.lastName" />
      </template>
      <template>
        <b-row class="no-gutters">
          <b-col class="text-center">
            <b-button
              @click="submit()"
              variant="primary"
              class="text-white"
              :size="size"
              :style="'margin-top: ' + padding + 'px'"
            >
              <template v-if="uiToggles.isValidating">
                <b-spinner class="spinner-border-sm m-1" role="status"></b-spinner>
              </template>
              <template v-else> Continue </template>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>
