export default {
  lastUpdatedOn: "Last claim updated on",
  discoverButton: "Start",
  discoverHeading: "Discover claims with",

  claimCompleted:
    "Your claim is completed, meaning no further details are required from you at this time.  You will receive regular updates on your claim status here and via email.",
  startNew: "Start a new claim",
  status: "Status",
  contactUs: "Contact Us",
  hereToHelp: "Our team is here to help you.  Click here to send us a message.",
  contact: "Contact",
  sendUsAMessage: "Send us a message and we will get back to you as soon as possible. ",
  name: "Name",
  email: "Email",
  message: "Message",
  send: "Send",
  cancel: "Cancel",
  view: "View",
  files: "Files",

  history: "History",
  myClaims: "My Claims",
  updatedOn: "Updated on",
  finish: "Finish",
  notComplete: "You have not completed all of the required steps for your claim.",
  continueMyClaim: "Continue my claim",
  mySettings: "My Settings",

  identityPicture1: "Identity Picture 1",
  identityPicture2: "Identity Picture 2",
  shareLink: "Share Link",
  copyLink: "Copy Link",
  withdrawInterest: "Withdraw Interest",

  information: "Information",
  clickToGo: "Click here to go to the",
  homePage: "home page",
  shareThe: "Share the",
  withSomeone: "with someone who might be eligible.",
  pageCompleted: "Page completed",
};
