import { User } from "@claimsgate/core-types";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { BlockKycProps, blocks } from "./blocks";
import { ClaimDataService, UserDataService } from "@/helpers/ClaimsGate/DataService";

export function getData(state: Vue) {
  return {
    uiToggles: {
      show: {
        address: false,
        yoti: false,
        submitButton: false,
      },
    },
    BlockInputs: { ...blocks.buttons, ...blocks.inputs, ...blocks.texts } as BlockKycProps,
    user: {} as User,
    infoModal: new InfoModalService(state.$infoModal),
    startYotiSession: false,
    userService: getUserHelper(),
    userDataService: undefined as UserDataService,
    userId: undefined as string,
    claimId: state.$route.params.claimId as string,
    pageId: state.$route.params.pageId,
    funnelId: state.$route.params.funnelId,
    claimDataService: undefined as ClaimDataService,
    blockYotiVueKey: 0,
  };
}
