//@ts-check
// eslint-disable-next-line no-unused-vars
import { StandardAsyncResult } from "../../types";
// eslint-disable-next-line no-unused-vars
import { ClaimsGateErrors, ClaimsGateServiceError } from "@claimsgate/core";

class AsyncHelper {
  constructor() {}
  /**
   * @param {*} dataObject
   * @returns { StandardAsyncResult}
   */
  static onCompleted(dataObject) {
    return { data: dataObject };
  }

  /**
   *
   * @param {typeof ClaimsGateServiceError | Object} ErrorClass See {@link ClaimsGateErrors}
   * @param {Object} errorArguments
   * @returns { StandardAsyncResult}
   */
  static onError(ErrorClass, errorArguments = undefined) {
    if (typeof ErrorClass == "function") {
      let argumentsArray = [];
      if (errorArguments) {
        argumentsArray = Object.values(errorArguments).map((errorArgument) => errorArgument);
      }

      const errorClass = new ErrorClass(...argumentsArray);

      return {
        error: {
          message: errorClass.getMessage(),
          type: errorClass.getType(),
          id: errorClass.getId(),
        },
      };
    } else {
      return { error: ErrorClass };
    }
  }
  /**
   * @param {*} exception
   * @returns { StandardAsyncResult}
   */
  static onException(exception) {
    window.console.error(exception);
    return { exception: exception };
  }
}

export { AsyncHelper };
