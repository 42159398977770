import axios from "axios";
import { ComputeUtils } from "@/helpers/ClaimsGate/computes/ComputeUtil";
import { AsyncHelper } from "@/helpers/ClaimsGate/AsyncHelper";
import { VehicleUtility } from "@/helpers/ClaimsGate/vehicles/VehicleUtility";
import { StandardAsyncResult } from "@/types";
import { VueUtility } from "../VueUtility";
import { getFirebaseBackend } from "@/authUtils";
import { ClaimsGateErrors } from "@claimsgate/core";

export interface GetVehicleResult {
  vehicleStatus: "none" | "single" | "multiple";
  vehicle?: Record<string, any>;
  vrmAssignmentHistory?: Array<Record<string, any>>;
}
export type VehicleApi = "UKVD" | "CarWeb";
const noVehicleResult: GetVehicleResult = {
  vehicleStatus: "none",
  vehicle: null,
};

class VehicleService {
  vueUtitlity: VueUtility;
  constructor() {
    this.vueUtitlity = new VueUtility();
  }
  /**
   * Fetches a vehicle from UK Vehicle Data
   */
  async getVehicle(
    vid: string,
    api: VehicleApi,
    recallData: boolean,
    apiKey?: string
  ): Promise<StandardAsyncResult<GetVehicleResult, ClaimsGateErrors.ApiError | ClaimsGateErrors.DataMissing, any>> {
    let response: Record<string, any>, error: any, exception: any;
    let url: string;

    const vrmMaxLength = 8;
    const useVin = vid.length > vrmMaxLength;

    if (!useVin) vid = vid.toUpperCase().replace(/\s/g, "");
    try {
      // Get Vehicle Data
      if (api === "UKVD") {
        if (!apiKey) {
          return AsyncHelper.onError(ClaimsGateErrors.ApiKeyMissing);
        }

        ({ url } = this.createUkvdApiUrl(vid, apiKey, useVin));
        ({ data: response, error, exception } = await this.callUkvdApi(url, api));
      } else {
        ({ data: response, error } = await this.callCarWebApi(vid, useVin, recallData));
      }

      if (error) {
        return AsyncHelper.onError(error);
      } else if (exception || !response) {
        return AsyncHelper.onException(exception);
      }

      // console.log("API RESPONSE IS", response, error, exception);

      // Parse Vehicle Data
      let vehicle: Record<string, any>;

      if (api === "UKVD") {
        const flattenedVehicle = ComputeUtils.flatten(response.data);
        vehicle = VehicleUtility.mapUkVehicleData(flattenedVehicle);
      } else {
        vehicle = VehicleUtility.mapCarWebVehicleData(response);
      }
      // console.log("Parsed Vehicle is", vehicle);

      let vrmAssignmentHistory: GetVehicleResult["vrmAssignmentHistory"];
      let result: GetVehicleResult;

      // If the vehicle is associated with multiple vehicles then fetch the reverse cherished transfer history
      if (!useVin && vehicle?.plateChangeCount > 0 && api !== "CarWeb") {
        ({ data: vrmAssignmentHistory } = await this.fetchVrmAssignmentHistory(vid, apiKey));

        result = {
          vehicleStatus: "multiple",
          vehicle: null,
          vrmAssignmentHistory,
        };

        // If the vehicle is associated with a single vehicle
      } else if (vehicle.plateChangeCount === 0 || useVin || api === "CarWeb") {
        // Calculate engine family based on known vehicle engine codes
        if (api === "UKVD" && vehicle?.engineModelCode) {
          const engineFamily = this.calculateEngineFamily(vehicle.engineModelCode);
          vehicle.engineFamily = vehicle.engineFamily.concat(engineFamily);
          vehicle.engineFamily = vehicle.engineFamily.filter((engineFamilyElement) => engineFamilyElement);
        }

        if (api === "CarWeb" && vehicle.engineModelCode) {
          vehicle.engineModelCode = [vehicle.engineModelCode];
        }

        // Include the date first registered in the keeper change history
        const formattedKeeperChangeHistory = this.formatKeeperChangeHistory(
          vehicle.keeperChangeHistory,
          vehicle.dateFirstRegistered
        );
        vehicle.keeperChangeHistory = formattedKeeperChangeHistory;

        for (const [key, value] of Object.entries(vehicle)) {
          if (value === undefined || value === null) {
            delete vehicle[key];
          }
        }

        result = {
          vehicleStatus: "single",
          vehicle,
          vrmAssignmentHistory: null,
        };
        // Otherwise no vehicle was found
      } else {
        result = noVehicleResult;
      }

      return AsyncHelper.onCompleted(result);
    } catch (getVehicleException) {
      return AsyncHelper.onException(getVehicleException);
    }
  }

  /**
   * generat the URL to call the API IF ukvd
   * @param vid
   * @param api
   * @param apiKey
   */
  createUkvdApiUrl(vid: string, apiKey: string, useVin): { url?: string } {
    let queryParameters: string;

    const endpoint = "https://uk1.ukvehicledata.co.uk/api/datapackage/VehicleData";

    if (useVin) {
      window.console.log("Querying by VIN", vid);
      queryParameters = `?v=2&api_nullitems=1&auth_apikey=${apiKey}&key_VIN=${vid}`;
    } else {
      queryParameters = `?v=2&api_nullitems=1&auth_apikey=${apiKey}&key_VRM=${vid}`;
    }
    return { url: `${endpoint}${queryParameters}` };
  }

  /**
   * Calls the given endpoint, parsing the response if xml into json
   * @param url
   * @param api
   * @returns
   */
  async callUkvdApi(url: string, api: VehicleApi): Promise<StandardAsyncResult<Record<string, any>, void, any>> {
    try {
      const response = await axios.get(url, {
        headers: { Accept: "text/html,application/xhtml+xml" },
        withCredentials: false,
        responseType: "application/xml",
      } as any);
      console.log("API REPSONSE IS", response);
      return AsyncHelper.onCompleted(response);
    } catch (exception) {
      window.console.error("Vehicle API Threw", url, exception);
      return AsyncHelper.onException(exception);
    }
  }

  async callCarWebApi(
    vid: string,
    useVin: boolean,
    recallData: boolean
  ): Promise<StandardAsyncResult<Record<string, any>, ClaimsGateErrors.ApiError | string, void>> {
    const carWeb = getFirebaseBackend().firebaseFunctions().httpsCallable("carWeb");

    const { data } = await carWeb({ vid, useVin, recallData });
    const { data: response, error, exception } = data;

    if (!data || error || exception) {
      return AsyncHelper.onError(ClaimsGateErrors.ApiError);
    }

    if (response.errorcode) {
      return AsyncHelper.onError(data.errordescription);
    }

    return AsyncHelper.onCompleted(response);
  }

  /**
   * Fetches the vehicle assignment history for vehicles with reverse cherished transfer history
   * @param {String} vrm - Registration number of the vehicle to lookup
   */
  async fetchVrmAssignmentHistory(vrm: string, apiKey: string): Promise<StandardAsyncResult<Array<any>, void, void>> {
    const endpoint = "https://uk1.ukvehicledata.co.uk/api/datapackage/VrmAssignmentHistoryData";
    const parameters = `?v=2&api_nullitems=1&auth_apikey=${apiKey}&key_VRM=${vrm}`;

    const response = await axios.get(endpoint + parameters);
    const flattenedResult = ComputeUtils.flatten(response.data);

    // Parse out the vrmAssignmentHistory object
    const vrmAssignmentHistoryList = JSON.parse(
      `${flattenedResult["Response.DataItems.VehicleHistory.VrmAssignmentHistoryList."]}`
    );

    const filteredVrmAssignmentHistoryList = this.filterVrmAssignmentHistory(vrmAssignmentHistoryList);
    return AsyncHelper.onCompleted(filteredVrmAssignmentHistoryList);
  }

  /**
   * Calculate the engine family of a vehicle given an engine code
   * @param {Array} engineCode - Engine code of a vehicle
   * @returns {Array} engineFamily List of engine families matchhing engine code
   */
  calculateEngineFamily(engineCodeList) {
    window.console.log("[calculatingEngineFamily]", engineCodeList);
    const engineCodes = [
      {
        EngineCode_DWID: 1,
        EngineCode: "CAAA",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 2,
        EngineCode: "CAAB",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 3,
        EngineCode: "CAAC",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 4,
        EngineCode: "CAAD",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 5,
        EngineCode: "CAGA",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 6,
        EngineCode: "CAGB",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 7,
        EngineCode: "CAGC",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 8,
        EngineCode: "CAHA",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 9,
        EngineCode: "CAYA",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 10,
        EngineCode: "CAYB",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 11,
        EngineCode: "CAYC",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 12,
        EngineCode: "CAYD",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 13,
        EngineCode: "CAYE",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 14,
        EngineCode: "CBAA",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 15,
        EngineCode: "CBAB",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 16,
        EngineCode: "CBBB",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 17,
        EngineCode: "CBDB",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 18,
        EngineCode: "CBDC",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 19,
        EngineCode: "CCHA",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 125,
        EngineCode: "CCWA",
        EngineFamily: "EA897",
      },
      {
        EngineCode_DWID: 20,
        EngineCode: "CDBA",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 21,
        EngineCode: "CDCA",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 126,
        EngineCode: "CDTA",
        EngineFamily: "EA897",
      },
      {
        EngineCode_DWID: 127,
        EngineCode: "CDUC",
        EngineFamily: "EA897",
      },
      {
        EngineCode_DWID: 128,
        EngineCode: "CDUD",
        EngineFamily: "EA897",
      },
      {
        EngineCode_DWID: 22,
        EngineCode: "CEGA",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 129,
        EngineCode: "CEXA",
        EngineFamily: "EA897",
      },
      {
        EngineCode_DWID: 130,
        EngineCode: "CEXB",
        EngineFamily: "EA897",
      },
      {
        EngineCode_DWID: 23,
        EngineCode: "CFCA",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 24,
        EngineCode: "CFFA",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 25,
        EngineCode: "CFFB",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 26,
        EngineCode: "CFFD",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 27,
        EngineCode: "CFFE",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 28,
        EngineCode: "CFGB",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 29,
        EngineCode: "CFGC",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 30,
        EngineCode: "CFHA",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 31,
        EngineCode: "CFHC",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 32,
        EngineCode: "CFHD",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 33,
        EngineCode: "CFHF",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 34,
        EngineCode: "CFJA",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 35,
        EngineCode: "CFJB",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 36,
        EngineCode: "CFWA",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 37,
        EngineCode: "CGLB",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 38,
        EngineCode: "CGLC",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 39,
        EngineCode: "CGLD",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 131,
        EngineCode: "CGQB",
        EngineFamily: "EA897",
      },
      {
        EngineCode_DWID: 40,
        EngineCode: "CJCA",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 41,
        EngineCode: "CJCB",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 42,
        EngineCode: "CJCD",
        EngineFamily: "EA189",
      },
      {
        EngineCode_DWID: 132,
        EngineCode: "CJMA",
        EngineFamily: "EA897",
      },
      {
        EngineCode_DWID: 43,
        EngineCode: "CKFC",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 44,
        EngineCode: "CKTB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 45,
        EngineCode: "CKTC",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 46,
        EngineCode: "CKUB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 47,
        EngineCode: "CKUC",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 133,
        EngineCode: "CKVC",
        EngineFamily: "EA897",
      },
      {
        EngineCode_DWID: 134,
        EngineCode: "CLAA",
        EngineFamily: "EA897",
      },
      {
        EngineCode_DWID: 135,
        EngineCode: "CLAB",
        EngineFamily: "EA897",
      },
      {
        EngineCode_DWID: 48,
        EngineCode: "CLHA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 136,
        EngineCode: "CLZB",
        EngineFamily: "EA897",
      },
      {
        EngineCode_DWID: 49,
        EngineCode: "CNEA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 50,
        EngineCode: "CNFA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 51,
        EngineCode: "CNFB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 52,
        EngineCode: "CNHA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 53,
        EngineCode: "CNHC",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 54,
        EngineCode: "CRBC",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 137,
        EngineCode: "CRCA",
        EngineFamily: "EA897",
      },
      {
        EngineCode_DWID: 55,
        EngineCode: "CRKA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 56,
        EngineCode: "CRKB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 57,
        EngineCode: "CRLB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 58,
        EngineCode: "CRMB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 59,
        EngineCode: "CSHA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 60,
        EngineCode: "CSLA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 61,
        EngineCode: "CSLB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 62,
        EngineCode: "CSLC",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 63,
        EngineCode: "CSNA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 64,
        EngineCode: "CSUA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 65,
        EngineCode: "CSUB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 138,
        EngineCode: "CTBA",
        EngineFamily: "EA897",
      },
      {
        EngineCode_DWID: 66,
        EngineCode: "CUAA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 67,
        EngineCode: "CUNA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 68,
        EngineCode: "CUPA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 69,
        EngineCode: "CUSB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 70,
        EngineCode: "CUTA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 71,
        EngineCode: "CUUA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 72,
        EngineCode: "CUUB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 73,
        EngineCode: "CUVA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 74,
        EngineCode: "CUVC",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 75,
        EngineCode: "CUVE",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 76,
        EngineCode: "CUWA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 77,
        EngineCode: "CXEB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 78,
        EngineCode: "CXEC",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 79,
        EngineCode: "CXFA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 80,
        EngineCode: "CXGB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 81,
        EngineCode: "CXHA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 82,
        EngineCode: "CXHC",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 83,
        EngineCode: "CXMA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 84,
        EngineCode: "CXXA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 85,
        EngineCode: "CXXB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 86,
        EngineCode: "DASA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 87,
        EngineCode: "DASB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 88,
        EngineCode: "DAUA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 89,
        EngineCode: "DAUB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 90,
        EngineCode: "DAVA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 91,
        EngineCode: "DAWA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 92,
        EngineCode: "DBKA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 93,
        EngineCode: "DCXA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 94,
        EngineCode: "DCYA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 95,
        EngineCode: "DCZA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 96,
        EngineCode: "DDAA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 97,
        EngineCode: "DDDA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 98,
        EngineCode: "DDYA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 99,
        EngineCode: "DDYB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 100,
        EngineCode: "DETA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 101,
        EngineCode: "DEUA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 102,
        EngineCode: "DEZE",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 103,
        EngineCode: "DFCA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 104,
        EngineCode: "DFEA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 105,
        EngineCode: "DFFA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 106,
        EngineCode: "DFGA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 107,
        EngineCode: "DFGC",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 108,
        EngineCode: "DFHA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 109,
        EngineCode: "DFLA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 110,
        EngineCode: "DFLD",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 111,
        EngineCode: "DFMA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 112,
        EngineCode: "DFSA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 113,
        EngineCode: "DFSB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 114,
        EngineCode: "DFSD",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 115,
        EngineCode: "DFTA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 116,
        EngineCode: "DFUA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 117,
        EngineCode: "DGCA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 118,
        EngineCode: "DGDA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 119,
        EngineCode: "DGDB",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 120,
        EngineCode: "DGTA",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 121,
        EngineCode: "DGTC",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 122,
        EngineCode: "DGTD",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 123,
        EngineCode: "DGTE",
        EngineFamily: "EA288",
      },
      {
        EngineCode_DWID: 124,
        EngineCode: "DJGA",
        EngineFamily: "EA288",
      },
    ];

    const engineFamily = [];

    engineCodeList?.forEach((engineCode) => {
      engineCodes?.forEach((engineElement) => {
        if (engineCode === engineElement.EngineCode) {
          engineFamily.push(engineElement.EngineFamily);
        }
      });
    });

    window.console.log("[calculatingEngineFamily]", engineFamily);

    return engineFamily;
  }

  formatKeeperChangeHistory(keeperChangeHistory: Array<string>, dateFirstRegistered: string) {
    // If we know when the vehicle was first registered and this date is not included
    // in the keeperChangeHistory we can append it to the start of the array

    if (!keeperChangeHistory) {
      keeperChangeHistory = [];
    }
    if (dateFirstRegistered && !keeperChangeHistory.includes(dateFirstRegistered)) {
      keeperChangeHistory.unshift(dateFirstRegistered);
    }

    window.console.log("[formatKeeperChangeHistory.sort] before: ", keeperChangeHistory);
    keeperChangeHistory = keeperChangeHistory.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    window.console.log("[formatKeeperChangeHistory.sort] after: ", keeperChangeHistory);

    return keeperChangeHistory;
  }

  /**
   * Filters the VRM Assignment history to only include vehicles which are eligible for an
   * emissions claim
   * @param {Array} vrmAssignmentHistoryList
   */
  filterVrmAssignmentHistory(vrmAssignmentHistoryList: Array<any>): any[] {
    // Parse the vrmAssingmentHistoryList

    if (vrmAssignmentHistoryList) {
      vrmAssignmentHistoryList = vrmAssignmentHistoryList
        .map((historyElement) => {
          const vehicle = historyElement.Vehicle;
          window.console.log("historyElement is", historyElement);
          if ((vehicle.Make && vehicle.Make.length > 0) || (vehicle.Model && vehicle.Model.length > 0)) {
            return {
              vin: vehicle.Vin,
              make: vehicle.Make,
              model: vehicle.Model,
              yearOfManufacture: vehicle.Year,
              colour: vehicle.Colour,
            };
          } else {
            return undefined;
          }
        })
        .filter((d) => d);
    }
    // Eligibility criteria

    /*
    const eligibleMakeList = ["MERCEDES", "VOLKSWAGEN", "SEAT", "AUDI", "SKODA", "VAUXHALL"];
    const manufactureYearStart = 2005;
    const manufactureYearEnd = 2019;

    window.console.log("VrmAssignmentHistoryList is: ", vrmAssignmentHistoryList);

    const filteredVrmAssignmentHistoryList = vrmAssignmentHistoryList.filter(
      vrmAssignmentHistory => {
        const { yearOfManufacture, make } = vrmAssignmentHistory;

        // If the vehicle was produced in the eligible time period
        if (yearOfManufacture <= manufactureYearEnd && yearOfManufacture >= manufactureYearStart) {
          let makeMatch = false;

          // Determine if the vehicle was produced by an eligible manufacturer
          for (const eligibleMake of eligibleMakeList) {
            if (make.includes(eligibleMake)) {
              makeMatch = true;
            }
          }

          // If the vehicle was produced by an eligible manufacturer
          if (makeMatch) {
            return vrmAssignmentHistory;
          }
        }
        return false;
      }
      
    );
    */
    // Remove the 'false' values from the filtered list
    //return filteredVrmAssignmentHistoryList.filter(vrmAssignmentHistory => vrmAssignmentHistory);

    return vrmAssignmentHistoryList;
  }
}

export { VehicleService };
