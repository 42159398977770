import { eventComputed, formComputed, navigationComputed, themesComputed } from "@/state/helpers";
import { DsarInstance } from "./DsarInstance";

export function mappedComputed() {
  return {
    ...eventComputed,
    ...formComputed,
    ...themesComputed,
    ...navigationComputed,
  };
}
export const computed = {
  loaHtml,
  loaTemplateValues,
  loaDisclaimerHtml,
};
function loaDisclaimerHtml(state: DsarInstance): string {
  return `
  <h4><strong>Letter of Authority</strong></h4>
  <p>For a DSAR to be completed on your behalf, we need to provide your finance lender with a letter of authority (LOA).</p>
  <p>This letter is intended for you to state that ${state.navigationWorkspaceName} has permission to obtain your finance agreement.</p>
  <p>We have prepared this letter of authority for you below. Please review it, and enter your signature in the pad at the bottom.</p>
  <p>This signed letter will be sent to your finance lender to state the you give permission for ${state.navigationWorkspaceName} to enact a DSAR on your behalf.</p>
        `;
}
function loaTemplateValues(state: DsarInstance) {
  return {
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    addressLine1: state.user.addressLine1,
    city: state.user.city,
    postcode: state.user.postcode,
    email: state.user.email,
    workspaceName: state.navigationWorkspaceName,
    date: new Date().toLocaleDateString(),
  };
}
function loaHtml(state: DsarInstance): string {
  return `
<p>${state.loaTemplateValues.firstName} ${state.loaTemplateValues.lastName}<br/>

${state.loaTemplateValues.addressLine1}<br/>

${state.loaTemplateValues.city}<br/>

${state.loaTemplateValues.postcode}<br/>

${state.loaTemplateValues.date}<br/> </p>
<p>To whom it may concern,</p>

<p>I, ${state.loaTemplateValues.firstName} ${state.loaTemplateValues.lastName}, provide this letter as proof of my authorisation for ${state.navigationWorkspaceName} to act as a legal representative on my behalf in collecting the finance agreement that exists between myself and your company.</p>

<p>This letter and the attached copy of my photo identification are intended to satisfy any evidence required to prove that I have granted ${state.loaTemplateValues.workspaceName} explicit authority to act on my behalf.</p>

<p>My details are as follows:</p>

<p>${state.loaTemplateValues.firstName} ${state.loaTemplateValues.lastName}</br>

${state.loaTemplateValues.addressLine1}</br>

${state.loaTemplateValues.city}</br>

${state.loaTemplateValues.postcode}</br>
</br>

${state.loaTemplateValues.email}</p>

<p>I expect that your company will respond to the request made by ${state.loaTemplateValues.workspaceName} to provide them with my finance agreement.</p>

<p>Regards, </p>
<br/>
<br/>
<p>__________________</p>
<p>${state.loaTemplateValues.firstName} ${state.loaTemplateValues.lastName}<br/>
${state.loaTemplateValues.date}</p>`;
}
