var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-19"},[_c('BlockInputWrapper',_vm._b({},'BlockInputWrapper',{
      info: _vm.info ? _vm.info : '',
      padding: _vm.padding,
      label: _vm.label,
      invalidFeedback: _vm.invalidFeedback,
      state: _vm.dropdownState,
    },false),[_c('DropdownSearchV3',{attrs:{"placeholder":_vm.placeholder,"options":_vm.options,"state":_vm.dropdownState,"isDisabled":_vm.disabled,"fixedOptions":_vm.fixedOptions},on:{"update:state":function($event){_vm.dropdownState=$event},"input":function($event){_vm.dropdownAnswer = $event}}})],1),(_vm.showOther)?_c('div',[_c('BlockInput',{attrs:{"inputType":"text","placeholder":'Type your answer here',"required":true,"answer":_vm.otherAnswer,"state":_vm.otherState,"invalidFeedback":'This field is required'},on:{"update:answer":function($event){_vm.otherAnswer=$event}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }