import { MutatorFunction } from "@/types";
import { Block, Compute, Container, Page, Variable } from "@claimsgate/core-types";
import { PageUtility } from ".";
import { ClaimDataService, UserDataService } from "../DataService";
import { InfoModalService } from "../../../components/shared/global/informationModal/InfoModalService";
import { VariableService } from "../VariableService";
import { UserBlockComputeService } from "./computes";
import { UserBlockValidatorsService } from "./validations";
import { ComputeProcessor, getComputeProcessor } from "@/helpers/ClaimsGate/computes/ComputeProcessor";
import firebase from "firebase/compat/app";
import { FirebaseAuthBackend, getFirebaseBackend } from "@/authUtils";
import Vue from "vue";

export class PageService {
  claimDataService: ClaimDataService;
  userDataService: UserDataService;
  variableService: VariableService;
  userBlockComputeService: UserBlockComputeService;
  computeProcessor: ComputeProcessor;
  db: firebase.firestore.Firestore;
  constructor(db?: firebase.firestore.Firestore) {
    this.variableService = new VariableService();
    this.userBlockComputeService = new UserBlockComputeService();
    this.computeProcessor = getComputeProcessor();
    if (db) {
      this.db = db;
    } else {
      this.db = getFirebaseBackend().firestore();
    }
  }

  /**
   * Stores each of the answers given on the current page in a cache
   * @param {*} page
   * @param {*} claimId
   */
  async storeAnswers(userId: string, claimId: string, page: Page): Promise<boolean> {
    console.log("Store answers", page);

    this.claimDataService = ClaimDataService.getInstance(claimId);
    this.userDataService = UserDataService.getInstance(userId);

    // For each of the blocks in the page
    const { blocks } = page;

    if (!blocks) {
      return undefined;
    }

    const mutator: MutatorFunction<void> = (block: Block) => {
      if (block.storeAs) {
        const { storeAs, answer } = block;

        if (storeAs?.id && block.isVisible) {
          console.log(">>> Calling store answer with", storeAs, answer);
          this.storeAnswer(storeAs, answer);
        }
      }
    };

    PageUtility.traverseBlocksSync(mutator, page, "cols");

    return true;
  }

  /**
   * Stores a type guarded answer in a given variable in Firestore
   */
  storeAnswer(variable: Variable, answer: unknown) {
    const { id, group, field, type } = variable;
    console.log(">>> Running store answer with", variable, answer);
    if (id) {
      const parsedAnswer = this.variableService.parseValue(answer, type);
      console.log(">>> Parsing a value", answer, type);

      // Validate if the given answer is of the correct type
      const isValidValue = this.variableService.validate(parsedAnswer, type);

      // If the value is of the correct type, we will set it to be stored
      // in Firebase
      if (isValidValue) {
        if (group === "user") {
          console.log(">>> Saving an answer on the user", id, field, parsedAnswer);
          this.userDataService.setArtefact(field, parsedAnswer);
        }
        this.claimDataService.setArtefact(id, parsedAnswer);
      }
    }
  }
  /**
   * @deprecated
   */
  pageContainsVehicleBlock(page: Page): boolean {
    console.log("[debug] page contains vehicle block");
    let result = false;

    const mutator: MutatorFunction<void> = (block: Block) => {
      if (block.type == "BlockVehicle") {
        console.log("[debug] block is: ", block);
        result = true;
      }
    };

    PageUtility.traverseBlocksSync(mutator, page, "container");

    return result;
  }

  isCompanyBlockVisible(page: Page): boolean {
    console.log("[debug] page contains vehicle block");
    let result = false;

    const mutator: MutatorFunction<void> = (block: Block) => {
      if (block.type == "BlockCompany" && block.isVisible) {
        result = true;
      }
    };

    PageUtility.traverseBlocksSync(mutator, page, "blocks");

    return result;
  }

  pageContainsUserBlocks(page: Page): boolean {
    console.log("[debug] page contains vehicle block");
    let result = false;

    const mutator: MutatorFunction<void> = (block: Block) => {
      if (
        block.type == "BlockVehicle" ||
        block.type === "BlockContactDetails" ||
        block.type === "BlockAddress" ||
        block.type === "BlockAgreement" ||
        block.type === "BlockKYC"
      ) {
        result = true;
      }
    };

    PageUtility.traverseBlocksSync(mutator, page, "blocks");

    return result;
  }

  /** ? If there is a need for InfoModal to be displayed it needs to be passed from the vue component*/
  async setPageError(page: Page, error: { field: string; message: string }, infoModal: Vue["$infoModal"]) {
    const { field, message } = error;
    console.log("[setPageError]", error);
    // If a field has been defined, we can try to put the error in a better spot
    let isErrorSet;
    if (field) {
      const mutator: MutatorFunction<boolean> = (block: Block) => {
        const { storeAs, state, invalidFeedback } = block;
        if (storeAs && state !== undefined && invalidFeedback !== undefined) {
          console.log("Setting state on block", block);
          if (UserBlockValidatorsService.isUserBlock(block.type)) {
            // error.message, error?.field
            if (!field) {
              //general error message for block
              block.state = false;
              block.invalidFeedback = message;
              return true;
            } else {
              // field specific error message
              block.state = { [`${field}`]: false };
              block.invalidFeedback = { [`${field}`]: message };
              console.log("BLOCK STATE AND INVALID", block.state, block.invalidFeedback);
              return true;
            }
          } else if (storeAs.field === field || (Array.isArray(field) && field.includes(storeAs.field))) {
            block.state = false;
            block.invalidFeedback = message;
            return true;
          } else {
            return false;
          }
          // ! The below is bespoke logic to handle the setting of errors which contain
          // ! a Block Vehicle.
          // ! This code will need to be deprecated in the future.
        } else if (
          block.type === "BlockVehicle" ||
          block.type === "BlockMotorSpecs" ||
          block.type === "BlockCarweb" ||
          block.type === "BlockClaimsGateVehicle"
        ) {
          console.log("Setting invalid feedback on block vehicle.");
          window.vm.$set(block, "invalidFeedback", "");
          window.vm.$nextTick(() => {
            window.vm.$set(block, "invalidFeedback", message);
          });

          return true;
        } else {
          return false;
        }
      };

      isErrorSet = PageUtility.traverseBlocksSync(mutator, page);
    }

    // If the mutator did not set the variable

    if (!isErrorSet) {
      if (error.message) {
        if (infoModal) {
          const infoModalService = new InfoModalService(infoModal);
          await infoModalService.fire("error", {
            text: error.message,
          });
        }
      }
    }

    return true;
  }

  /**
   * Resets the currently displayed error message
   */
  async resetValidation(page) {
    const mutator = (block: Block) => {
      window.console.log("runing mutator?");
      const { state } = block;

      if (state !== undefined) {
        if (Object(state) !== state) {
          // State is a primitive boolean
          block.state = null;
          window.console.log("Block is now: ", block);
        } else {
          // state is a key value object
          Object.keys(state).forEach((field) => {
            state[field] = null;
          });
        }
      }
    };

    PageUtility.traverseBlocksSync(mutator, page);

    return true;
  }

  async runComputes(page, claimId, userId, computesInProgress) {
    const { computes } = page;
    // computes: [webhook1, webhook2, catchHook3];
    // webhook1 and webhook2 to run first
    // computes: [webhook4];
    // webhook1 and webhook2 need to run first
    // then catchHook3
    // then webhook

    // computes: [webhook1, webhook2, catchHook3];

    const computesToExecute: Array<Compute> = JSON.parse(JSON.stringify(computes));

    computesInProgress = null;
    window.console.log("[computes]: Computes to execute is: ", computesToExecute);

    let result;
    let next;
    let error;
    let exception;

    while (computesToExecute.length > 0) {
      // if (this.computesInProgress) {
      //   computesToExecute = this.computesInProgress;
      // }

      ({ result, next, error, exception } = await this.computeProcessor.runComputes(page, computesToExecute, claimId));

      if (result?.isCatchHook) {
        computesInProgress = result.computes;
        await this.waitForCatchHook(result.webhookId, claimId, userId);

        window.console.log("[catchHook] before", this.claimDataService.getCache());
        await this.claimDataService.refresh();
        window.console.log("[catchHook] after", this.claimDataService.getCache());
      }
    }

    return { result, next, error, exception, computesInProgress };
  }

  /**
   * Updates the user interface to reflect we are waiting for a response from an external third party
   */
  waitForCatchHook(webhookId, claimId, userId) {
    window.console.log(`Beginning to wait for catch hook for userId ${userId} claimId ${claimId}`, webhookId);
    return new Promise((resolve) => {
      const timeWaited = 0;

      // ? Since catch responses will contain the date they were recieved we may want to enforce an expiration date
      // ? An expiration date would describe the total length of time before a catch hook response becomes invalid
      const userRef = this.db.collection("users").doc(userId);
      const claimRef = userRef.collection("claims").doc(claimId);

      // Listen for updates to the claim in Firestore so we can detect when the catch hook response
      // has been recieved

      const startDate = getFirebaseBackend().firestoreClass().Timestamp.now().toDate();
      window.console.log("[catchHook] startDate is: ", startDate);

      const unsubscribe = claimRef.onSnapshot((claimSnapshot) => {
        const claim = claimSnapshot.data();
        window.console.log("Recieved an update!", claim, claimId);
        if (claim && claim.catchResponses) {
          const hasCatchResponse =
            // ? Catch hooks will only work if the client waits on the page for the response of
            // ? the hook. This means, we record the date we start listening and the date the catch hook arrives
            // ? and only allow the user to pass if the catch hook response is fresh.
            // ? This will allow for multiple submissions of the same page with different data.
            claim.catchResponses.filter((catchResponse) => {
              if (catchResponse.webhookId === webhookId) {
                window.console.log("[catchHook] date recieved", catchResponse.date.toDate());
              }

              return catchResponse.webhookId === webhookId && catchResponse.date.toDate() > startDate;
            }).length > 0;

          if (hasCatchResponse) {
            window.console.log("[catchHook] got the expected response!");

            unsubscribe();
            return resolve(true);
          }
        }
      });
    });
  }
}
