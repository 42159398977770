import { blocks } from "./blocks";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { UserDataService, ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { FunnelsService } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";
import { BusinessListEntity } from "@claimsgate/core-types";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
export function getData(state: Vue) {
  return {
    uiToggles: {
      show: {
        companySearch: false,
        companySelect: false,
        submitButton: false,
        manualEntryOption: false,
        manualEntry: false,
        lookupOption: false,
      },
      loaders: {
        isSearchingForCompany: false,
      },
    },
    uiMessages: {
      companyNotSearched: "Please search and select your company before continuing.",
      companyNameMissing: "Please enter company name.",
      companyAddressMissing: "Please enter the address of the company to the best of your knowledge.",
      companyNameTooShort: "The company name you entered is too short. Please enter a name with at least 4 characters.",
      companyNotFound:
        "Sorry, we couldn't find a company matching the name you entered. Please check the name and try again.",
      companyNotSelected: "Please select your company",
    },
    companies: null as Array<BusinessListEntity> | null,
    funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
    variablesService: new VariableService(),
    BlockInputs: {
      ...blocks.buttons,
      ...blocks.inputs,
    },
    userService: getUserHelper(),
    userDataService: undefined as UserDataService,
    userId: undefined as string,
    claimId: state.$route.params.claimId as string,
    pageId: state.$route.params.pageId as string,
    funnelId: state.$route.params.funnelId as string,
    claimDataService: undefined as ClaimDataService,
  };
}
