export default {
  myDetails: "Butiran Saya",
  fullName: "Nama Penuh",
  noNameFound: "Nama tidak ditemukan",
  simplifyClaimAddName: "Permudahkan tuntutan masa depan dengan menambahkan nama ke dalam akaun anda.",
  addName: "Tambah Nama",
  contactDetails: "Butiran Hubungan",
  address: "Alamat",
  cancel: "Batal",
  save: "Simpan",
  noAddressFound: "Tiada Alamat Ditemui",
  simplifyClaimAddAddress: "Permudahkan tuntutan masa depan dengan menambahkan alamat ke dalam akaun anda.",
  communicationPreferences: "Pilihan Komunikasi",
  emailReminders: "Peringatan Emel",
  receiveEmailReminders: "Terima peringatan e-mel untuk tuntutan anda",
  smsReminders: "Peringatan SMS",
  receiveSmsReminders: "Terima peringatan SMS untuk tuntutan anda",
  settinsUpdated: "Tetapan Dikemas Kini",
  preferencesUpdated: "Pilihan komunikasi anda telah dikemas kini",
  withdrawClaim: {
    title: "Tarik Balik Tuntutan",
    text: "Adakah anda pasti mahu menarik balik tuntutan anda? Tindakan ini tidak boleh dibatalkan.",
    cancel: "Tidak. Sila simpan",
    confirm: "Ya. Sila tarik balik",
  },
};
