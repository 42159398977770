import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import { Variable } from "@claimsgate/core-types";
import { PropOptions } from "vue";

const props: {
    padding: PropOptions<string>;
    condition: PropOptions<Object>;
    required: PropOptions<boolean>;
    label: PropOptions<string>;
    info: PropOptions<string>;
    answer: PropOptions<Array<Record<string, any>>>;
    invalidFeedback: PropOptions<string>
} = inputProps({
    excludeDefaults: true,
    answerType: "array",
    extraProps: ["padding", "required", "condition", "label", "info", "invalidFeedback"],
});

export function getProps() {
    return {
        storeAs: {
            type: Object,
        } as PropOptions<Variable>,
        ...props,
    };
}