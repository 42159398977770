/* eslint-disable no-unused-vars */
import { Props, BlockProps, UserBlockProps } from "../../types";

import { i18n } from "@/i18n";

class UserVariableBlocksService {
  /**
   * * Generate an object that can be passed into a Block to declare its props
   * @param { any  } [overrides] - Overide the default configuration, or add props to the default by declaring key/vals
   * @returns { any }
   */
  static genBlock(overrides) {
    const block = {
      size: "lg",
      inputType: "text",
      required: true,
      disabled: false,
      state: null,
      invalidFeedback: "",
      answer: "",
      placeholder: "",
    };

    if (overrides && Object.keys(overrides).length > 0) {
      Object.keys(overrides).map((key) => {
        block[key] = overrides[key];
      });
    }
    return block;
  }

  /**
   * @param {  Partial<BlockProps.Input>} [overrides]
   * @returns { BlockProps.Input }
   */
  static genBlockInputProps(overrides) {
    if (overrides && !overrides.inputType) {
      overrides.inputType = "text";
    }
    return UserVariableBlocksService.genBlock(overrides);
  }

  /**
   *
   * @param { Partial<BlockProps.DatePicker>} overrides
   * @returns { BlockProps.DatePicker}
   */
  static genBlockDatePickerProps(overrides) {
    if (overrides && !overrides.inputType) {
      overrides.inputType = "text";
    }
    return UserVariableBlocksService.genBlock(overrides);
  }

  /**
   * @param { Partial<BlockProps.Dropdown> } [overrides]
   * @returns { BlockProps.Dropdown }
   */
  static genBlockDropdownProps(overrides) {
    if (overrides && !overrides.options) {
      overrides.options = [];
    }
    return UserVariableBlocksService.genBlock(overrides);
  }
  /**
   * @param { Partial<BlockProps.DropdownSearch> } [overrides]
   * @returns { BlockProps.DropdownSearch }
   */
  static genBlockDropdownSearchProps(overrides) {
    if (overrides && !overrides.options) {
      overrides.options = [];
    }
    return UserVariableBlocksService.genBlock(overrides);
  }
  /**
   * @param { Partial<BlockProps.Multiselect> } [overrides]
   * @returns { Partial<BlockProps.Multiselect> }
   */
  static genBlockMultiselectProps(overrides) {
    if (overrides && !overrides.options) {
      overrides.options = [];
    }
    return UserVariableBlocksService.genBlock(overrides);
  }
  /**
   * @param { Partial<BlockProps.SingleSelect> } [overrides]
   * @returns { BlockProps.SingleSelect }
   */
  static genBlockSingleSelectProps(overrides) {
    if (overrides && !overrides.options) {
      overrides.options = [];
    }
    return UserVariableBlocksService.genBlock(overrides);
  }

  /**
   *
   * @param { BlockProps.Checkbox } [overrides]
   * @returns { BlockProps.Checkbox }
   */
  static genBlockCheckboxProps(overrides) {
    if (overrides && !overrides.linkTarget) {
      overrides.linkTarget = "";
    }
    if (overrides && !overrides.linkText) {
      overrides.linkText = "";
    }
    return UserVariableBlocksService.genBlock(overrides);
  }

  /**
   *
   * @param { Partial<BlockProps.Text> } [overrides]
   * @returns { BlockProps.Text }
   */
  static genBlockTextProps(overrides) {
    if (overrides && !overrides.text) {
      overrides.text = "";
    }

    return UserVariableBlocksService.genBlock(overrides);
  }

  /**
   * @param { Partial<BlockProps.FileInput>} [overrides]
   * @returns { BlockProps.FileInput }
   */
  static genBlockFileInputProps(overrides) {
    /**@constant @type {BlockFileInputProps} */
    const defaults = {
      size: "md",
      placeholder: "Browse or drop a docx file",
      multiple: false,
      accept: ".docx",
    };
    if (overrides) {
      // Overide and or add to the defaults
      Object.keys(overrides).map((key) => {
        defaults[key] = overrides[key];
      });
    }
    return UserVariableBlocksService.genBlock(defaults);
  }

  /**
   *
   * @param { Partial<BlockProps.Button> } [overrides]
   * @returns { BlockProps.Button }
   */
  static genBlockButtonProps(overrides) {
    const defaults = {
      text: "Continue",
      block: true,
      submitButtonDefault: false,
      align: "center",
      pill: false,
      squared: false,
      outline: false,
      variant: "primary",
      isProcessing: false,
    };
    if (overrides) {
      // Overide and or add to the defaults
      Object.keys(overrides).map((key) => {
        defaults[key] = overrides[key];
      });
    }

    return UserVariableBlocksService.genBlock(defaults);
  }

  static getWorkspaceBlocks() {
    const output = {
      headingText: UserVariableBlocksService.genBlockTextProps({
        color: "dark",
        styles: "h3",
        align: "left",
        weight: "normal",
        text: "Create your workspace",
      }),

      workspaceName: UserVariableBlocksService.genBlockInputProps({
        answer: "",
        label: "Workspace name",
        invalidFeedback: "No workspace given",

        inputType: "text",
        required: true,
        state: null,
        placeholder: "Please enter your workspace name",
        size: "lg",
        disabled: false,
      }),

      termsAndConditionsAccepted: UserVariableBlocksService.genBlockCheckboxProps({
        linkTarget: window.location.origin + "/legal",
        text: "Do you agree with the",
        linkText: "Privacy Policy",
      }),

      createWorkspace: UserVariableBlocksService.genBlockButtonProps({
        text: "Continue",
        block: true,
        submitButtonDefault: false,
        align: "center",
        pill: false,
        squared: false,
        outline: false,
        variant: "primary",
        isProcessing: false,
      }),

      // ? Member Invite Blocks - Not needed atm - Might be used in the future
      // memberInviteHeading: UserVariableBlocksService.genBlockTextProps({
      //   color: "dark",
      //   styles: "h3",
      //   align: "left",
      //   weight: "normal",
      //   text: "Invite up to 3 members"
      // }),

      // memberToAdd1: UserVariableBlocksService.genBlockInputProps({
      //   answer: "",
      //   label: "Member 1",
      //   invalidFeedback: "No member email given",
      //   text: "Please enter an email",
      //   inputType: "text",
      //   required: true,
      //   state: null,
      //   placeholder: "Email",
      //   size: "lg",
      //   disabled: false
      // }),

      // memberToAdd2: UserVariableBlocksService.genBlockInputProps({
      //   answer: "",
      //   label: "Member 2",
      //   invalidFeedback: "No member email given",
      //   text: "Please enter an email",
      //   inputType: "text",
      //   required: true,
      //   state: null,
      //   placeholder: "Email",
      //   size: "lg",
      //   disabled: false
      // }),

      // memberToAdd3: UserVariableBlocksService.genBlockInputProps({
      //   answer: "",
      //   label: "Member 3",
      //   invalidFeedback: "No member email given",
      //   text: "Please enter an email",
      //   inputType: "text",
      //   required: true,
      //   state: null,
      //   placeholder: "Email",
      //   size: "lg",
      //   disabled: false
      // }),

      // inviteMember: UserVariableBlocksService.genBlockButtonProps({
      //   text: "Continue",
      //   block: true,
      //   submitButtonDefault: false,
      //   align: "center",
      //   pill: false,
      //   squared: false,
      //   outline: false,
      //   variant: "primary",
      //   isProcessing: false
      // }),

      // inviteMembers: UserVariableBlocksService.genBlockButtonProps({
      //   text: "Continue",
      //   block: true,
      //   submitButtonDefault: false,
      //   align: "center",
      //   pill: false,
      //   squared: false,
      //   outline: false,
      //   variant: "primary",
      //   isProcessing: false
      // })
    };

    return output;
  }

  /**@typedef {  UserBlockProps.Name | UserBlockProps.Email | UserBlockProps.DateOfBirth | UserBlockProps.Postcode | UserBlockProps.Agreement | UserBlockProps.Phone } GetBlocksRepsonse */
  /**
   * Returns the specified config objects to be passed into Blocks
   * @param { Array<UserBlockProps.Fields> } blockKeys
   * @returns { GetBlocksRepsonse | any }
   */
  static getBlocks(blockKeys) {
    const blockInputs = {
      addressLine1: UserVariableBlocksService.genBlockInputProps({
        label: i18n.t("blocks.address.addressLine1"),
        placeholder: i18n.t("blocks.address.addressLine1"),
        disabled: true,
      }),
      city: UserVariableBlocksService.genBlockInputProps({
        label: i18n.t("blocks.address.city"),
        placeholder: i18n.t("blocks.address.city"),
        disabled: true,
      }),
      postcode: UserVariableBlocksService.genBlockInputProps({
        label: i18n.t("blocks.address.postcode"),
        disabled: true,
        placeholder: i18n.t("blocks.address.postcode"),
      }),
      firstName: UserVariableBlocksService.genBlockInputProps({
        label: i18n.t("blocks.address.firstName"),
        placeholder: i18n.t("blocks.address.firstName"),
      }),
      middleName: UserVariableBlocksService.genBlockInputProps({
        label: i18n.t("blocks.address.middleName"),
        placeholder: i18n.t("blocks.address.middleName"),
      }),
      lastName: UserVariableBlocksService.genBlockInputProps({
        label: i18n.t("blocks.address.lastName"),
        placeholder: i18n.t("blocks.address.lastName"),
      }),
      other: UserVariableBlocksService.genBlockInputProps({
        label: "Other Title",
        placeholder: "Other Title",
      }),
      title: UserVariableBlocksService.genBlockDropdownProps({
        options: ["Mr", "Mrs", "Ms", "Miss", "Mx", "Dr", "Professor", "Prefer not to say", "Other"],
        label: i18n.t("blocks.address.title"),
        size: "lg",
        placeholder: i18n.t("blocks.address.title"),
      }),
      phone: UserVariableBlocksService.genBlockInputProps({
        label: i18n.t("blocks.phone.label"),
        inputType: "text",
        placeholder: i18n.t("blocks.phone.label"),
      }),
      email: UserVariableBlocksService.genBlockInputProps({
        label: i18n.t("blocks.email.label"),

        inputType: "text",
        placeholder: i18n.t("blocks.email.label"),
        invalidFeedback: i18n.t("blocks.email.invalidFeedback"),
      }),
      emailConfirm: UserVariableBlocksService.genBlockInputProps({
        label: "Confirm Email Address",
        inputType: "text",
        placeholder: "Confirm Email Address",
        invalidFeedback: "This field is required",
      }),
      privacyConsent: UserVariableBlocksService.genBlockCheckboxProps({
        linkTarget: window.location.origin + "/legal/privacy",
        linkText: "Privacy Policy",
        invalidFeedback: "This field is required",
      }),
      dateOfBirth: UserVariableBlocksService.genBlock({
        label: i18n.t("blocks.address.dateOfBirth"),
        required: true,
        inputType: "string",
      }),
      draw: UserVariableBlocksService.genBlock({
        height: "auto",
      }),
      text: UserVariableBlocksService.genBlockTextProps({
        color: "dark",
        styles: "h5",
        align: "left",
        weight: "normal",
      }),
      cherishedPlateVehicleSelect: UserVariableBlocksService.genBlockSingleSelectProps({
        label: "Select your vehicle",
      }),
      keeperSelect: UserVariableBlocksService.genBlockSingleSelectProps({
        label: "Select the date you acquired the vehicle",
      }),
      button: UserVariableBlocksService.genBlockButtonProps({
        text: "Continue",
        block: true,
        submitButtonDefault: false,
        align: "center",
        pill: false,
        squared: false,
        outline: false,
        variant: "primary",
        isProcessing: false,
      }),
      postcodeSearchBlock: UserVariableBlocksService.genBlockInputProps({
        answer: "",
        label: i18n.t("blocks.address.postcode"),
        invalidFeedback: "Your postcode was invalid, please try again.",
        text: "Search for your address using a UK Postcode",
        inputType: "text",
        required: true,
        state: null,
        placeholder: "Postcode",
        size: "lg",
        disabled: false,
      }),
      addressSelectBlockDropdown: UserVariableBlocksService.genBlockInputProps({
        label: "Select your address",
        options: [],
        required: false,
      }),
      nextBlockButton: UserVariableBlocksService.genBlockButtonProps({
        text: i18n.t("blocks.button.continue"),
        variant: "primary",
        outline: false,
        pill: false,
        squared: false,
        block: false,
        size: "lg",
        align: "center",
      }),
    };

    const output = {};

    blockKeys.map((key) => {
      output[key] = blockInputs[key];
    });
    return output;
  }

  /**
   *
   * @param { { [key: string]: BlockProps.Button | Partial<BlockProps.Button> }} buttons
   * @returns { { [key: string]: BlockProps.Button | Partial<BlockProps.Button>}}
   */
  static getButtons(buttons) {
    Object.keys(buttons).map((key) => {
      buttons[key] = UserVariableBlocksService.genBlockButtonProps(buttons[key]);
    });

    return buttons;
  }
}

export { UserVariableBlocksService };
