import { v4 as uuidv4 } from "uuid";
import { getFirebaseBackend } from "@/authUtils.js";
/**
 * WebhookModule is a class which provides helpful methods to add Webhook compute's
 * to a page in Claims Gate
 */
export const webhookMixin = superclass =>
  class extends superclass {
    /**
     * Adds a webhook to the current page
     */
    async addWebhook(computes, funnelId) {
      const webhookStruct = {
        id: uuidv4(),
        name: "",
        field: "",
        params: { url: "", params: [], headers: [], body: [], stores: [] },
        testResponse: ""
      };
      computes.push(webhookStruct);
      const db = getFirebaseBackend().firestore();
      await db
        .collection("funnels")
        .doc(funnelId)
        .collection("webhooks")
        .doc(webhookStruct.id)
        .set(webhookStruct, { merge: true });
    }

    /**
     * Deletes a webhook from the current page
     */
    deleteWebhook(computes, computeIndex) {
      computes.splice(computeIndex, 1);
    }

    /**
     * Adds a body to the given webhook
     */
    addBody(computes, computeIndex) {
      const bodyStruct = { key: "", value: "", type: "" };
      computes[computeIndex].params.body.push(bodyStruct);
    }

    removeBody(computes, computeIndex, bodyIndex) {
      computes[computeIndex].params.body.splice(bodyIndex, 1);
    }

    /**
     * Adds a header to a given webhook
     */
    addHeader(computes, computeIndex) {
      const headerStruct = { key: "", value: "", type: "" };
      computes[computeIndex].params.headers.push(headerStruct);
    }

    /**
     * Removes a header from a given webhook
     */
    removeHeader(computes, computeIndex, headerIndex) {
      computes[computeIndex].params.headers.splice(headerIndex, 1);
    }

    /**
     * Adds a query parameter to a given webhook
     */
    addQueryParameter(computes, computeIndex) {
      const queryParmeterStruct = { key: "", value: "", type: "" };
      computes[computeIndex].params.params.push(queryParmeterStruct);
    }

    /**
     * Removes a given query parameter from a webhook
     */
    removeQueryParameter(computes, computeIndex, paramIndex) {
      computes[computeIndex].params.params.splice(paramIndex, 1);
    }

    /**
     * Adds an element to store from a webhook response
     */
    addStore(computes, index, store) {
      let storeStruct = { field: "", as: "", selectedAction: "" };
      if (store) {
        storeStruct = store;
      }

      computes[index].params.stores.push(storeStruct);
    }

    /**
     * Removes a element to store from webhook response
     */
    removeStore(computes, computeIndex, storeIndex) {
      computes[computeIndex].params.stores.splice(storeIndex, 1);
    }

    /**
     * Returns the available webhook request types
     */
    getRequestTypes() {
      return ["GET", "POST", "PUT", "DELETE", "CATCH"];
    }
  };

class WebhookUtility {
  /**
   * Parses a webhook arguments such as 'headers' or 'body' to JSON from their original array of object states
   * @param {*} webhookArgument
   * @returns
   */
  static parseArgumentsToJson(webhookArgument) {
    const parsedArguments = {};
    Object.keys(webhookArgument).map(index => {
      let value;
      if (typeof webhookArgument[index].value === "object") {
        try {
          value = JSON.parse(webhookArgument[index].testValue);
        } catch (e) {
          value = webhookArgument[index].testValue;
        }
      } else {
        value = webhookArgument[index].value;
      }
      const key = webhookArgument[index].key;
      parsedArguments[key] = value;
    });
    return parsedArguments;
  }
  /**
   * Creates an array to be used in the dropdown for creating a variable
   * @param { Object } response Flattened response from a webhook
   * @returns { Array<{text, key}> } dropdown
   */
  static convertWebhookResponse(response) {
    const out = [];
    try {
      const placeholder = { text: "Please select a response variable", value: "", disabled: true };
      out.push(placeholder);
      for (const [key, value] of Object.entries(response)) {
        out.push({
          text: `${key} : ${value} (${WebhookUtility.getTypeOf(value)})`,
          value: `${key}`
        });
      }
      return out;
    } catch {
      return out;
    }
  }

  static isIsoString(str) {
    if (
      /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(str) ||
      /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}z/.test(str)
    ) {
      var d = new Date(str);
      return d.toISOString();
    } else {
      return false;
    }
  }

  static getTypeOf(value) {
    let copy;

    try {
      copy = JSON.parse(value);
    } catch (e) {
      copy = value;
    }

    let parsedDate;

    try {
      if (this.isIsoString(copy)) {
        console.log("is iso");
        parsedDate = this.isIsoString(copy);
      }
    } catch (e) {
      parsedDate = null;
    }

    if (parsedDate) {
      return "date";
    } else if (Array.isArray(copy)) {
      return "array";
    } else {
      return typeof copy;
    }
  }
}

export { WebhookUtility };
