import { getFirebaseBackend } from "@/authUtils";
import { BlockEmailInstance } from "./BlockEmail";

import { User } from "@claimsgate/core-types";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
import { getClaimDataService, getUserId } from "@/helpers/vue";

export async function mounted(state: BlockEmailInstance) {
  const userId = getUserId();

  if (!userId) {
    return;
  }

  state.userId = getUserId();

  state.userDataService = new UserDataService(state.userId);

  getClaimDataService<BlockEmailInstance>(state);

  //await state.userDataService.refresh();

  calculateIsPhoneNumberAlreadyDefined(state);
}

function calculateIsPhoneNumberAlreadyDefined(state: BlockEmailInstance) {
  const user: User = state.userDataService.getCache();
  const authUser = getFirebaseBackend().firebaseAuth()?.currentUser;

  state.uiToggles.isEmailDefined =
    user.email && authUser.email && user.email?.toLowerCase() === authUser.email?.toLowerCase();

  state.user = user;

  if (state.uiToggles.isEmailDefined) {
    state.BlockInputs.email.disabled = true;
    state.BlockInputs.email.answer = user.email;

    if (state.requireConfirm) {
      state.BlockInputs.emailConfirm.disabled = true;
      state.BlockInputs.emailConfirm.answer = user.email;
    }
  }
}
