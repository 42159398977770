export default {
  me: "Saya",
  myClaims: "Gugatan Saya",
  menu: {
    myDetails: "Detail Saya",
    settings: "Pengaturan",
    legal: "Legal",
    logOut: "Keluar",
    logIn: "Masuk",
  },
};
