import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import { Props } from "@/types";
const blockEmailInputProps = inputProps({
  answerType: "none",
  extraProps: ["requireConfirm", /*"enforceValidation", */ "enforceVerification", "padding"],
  excludeDefaults: true,
}) as Props.BlockEmail;

export function getProps() {
  return {
    ...blockEmailInputProps,
    parentIsProcessing: { type: Boolean, default: false },
  };
}
