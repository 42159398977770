<script>
import { BuilderUtility } from "@/helpers/ClaimsGate/builder/BuilderUtility";
import BlockPadding from "@/components/shared/blocks/padding.vue";
export default {
  name: "BlockLink",
  components: { BlockPadding },
  props: {
    text: {
      type: String,
      required: false,
      allowVariable: true,
      default: "This is a link",
      description: "The text to display before the link",
    },
    target: {
      type: String,
      required: false,
      allowVariable: false,
      default: "_blank",
      description: "Sets the 'target' attribute on the rendered link",
      valid: ["_blank", "_self"],
    },
    link: {
      type: String,
      required: false,
      allowVariable: true,
      default: "https://portal.claimsgate.co.uk/",
      description: "Denotes the target URL of the link for standard a links",
    },
    // Deprecated. Can't use this and link. :to overrides :link
    // to: {
    //   type: String,
    //   required: false,
    //   allowVariable: true,
    //   default: "",
    //   description:
    //     "<router-link> prop: Denotes the target route of the link. When clicked, the value of the to prop will be passed to `router.push()` internally, so the value can be either a string or a Location descriptor object"
    // },
    emitClick: {
      type: Boolean,
      required: false,
      default: false,
      description:
        "If true, component will emeit event 'clicked' for use by parent. If false (default) component will follow href or to action.",
    },
    align: {
      type: String,
      required: false,
      allowVariable: false,
      default: "center",
      valid: ["left", "center", "right"],
      description: "The alignment of text to display",
    },
    /** @type { Props.Str} */
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    },
    bold: {
      type: Boolean,
      required: false,
      default: true,
      description: "If true, the text will be bold",
    },
    textSize: {
      type: String,
      required: false,
      default: "sm",
      valid: ["sm", "md", "lg"],
      description: "The size of the text",
    },
  },
  data() {
    return {
      BuilderUtility: BuilderUtility,
    };
  },
  methods: {},
  computed: {
    textClass() {
      return `text-${this.textSize ?? "sm"}`;
    },
  },
};
</script>
<style scoped></style>
<template>
  <div>
    <BlockPadding :padding="padding" />

    <div :class="'text-' + align">
      <div @click.capture.prevent @click="$emit('clicked')" v-if="emitClick">
        <!-- <slot></slot> -->
        <b-link
          :href="link"
          :target="target"
          :class="[bold ? 'font-weight-semibold' : '', textClass]"
          data-cy="link-link"
        >
          {{ text }}
        </b-link>
      </div>
      <div v-else>
        <slot></slot>
        <b-link
          :href="link"
          :target="target"
          :class="[bold ? 'font-weight-semibold' : '', textClass]"
          data-cy="link-link"
        >
          {{ text }}
        </b-link>
      </div>
    </div>
  </div>
</template>
