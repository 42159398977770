import Vue from "vue";
import { methods } from "./methods";
import { getProps } from "./props";
import { getData } from "./data";
import { computed, mappedComputed } from "./computed";
import { identificationFlow } from "@claimsgate/core";

export { methods, getProps, getData, computed, mappedComputed };

export interface BlockYotiProps {
  identificationFlow: identificationFlow;
  padding: string;
  //requireAge?: string;
  isBuilder?: boolean;
  isChild?: boolean;
  faceMatch?: boolean;
  livenessCheck?: boolean;
}

export type Methods = keyof typeof methods;
export type Data = ReturnType<typeof getData>;

export type Computed = { [K in keyof typeof computed]: ReturnType<typeof computed[K]> } & ReturnType<
  typeof mappedComputed
>;

export type BlockYotiInstance = Vue & Data & Computed & BlockYotiProps;
