<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      required: true,
      default: "10",
      valid: ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark", "white", "none"],
      allowVariable: false
    },
    size: {
      type: String,
      required: true,
      default: "xl",
      valid: ["xl", "lg", "md", "sm", "xs"],
      allowVariable: false
    },
    isRepeatable: {
      type: Boolean,
      required: true,
      default: false,
      allowVariable: false
    },
    answer: {
      type: String,
      required: false,
      allowVariable: false,
      default: "",
      description: "The text in the input"
    }
  }
};
</script>
