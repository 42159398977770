var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Fragment',[_c('BlockInputWrapper',_vm._b({},'BlockInputWrapper',{ info: _vm.info, padding: _vm.padding, label: _vm.label, state: _vm.state, invalidFeedback: _vm.invalidFeedback },false),[_c('b-form-group',{staticClass:"mb-0"},[_c('b-form-checkbox-group',{ref:'b-multiselect',attrs:{"id":"checkbox-group-0","name":"checkbox-group-0"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('b-row',{staticClass:"justify-content-center no-gutters"},[[_vm._l((_vm.options),function(option){return [_c('b-col',{key:option,staticClass:"mb-0",attrs:{"cols":"12"}},[_c('b-card',{staticClass:"border mb-0 p-1",class:{
                    selected: _vm.isSelected(option),
                    deselected: !_vm.isSelected(option),
                    'invalid-border': _vm.state === false,
                    'valid-border': _vm.state === true,
                  },attrs:{"no-body":""},on:{"click":function($event){$event.preventDefault();return _vm.toggleSelected(option)}}},[_c('div',{class:`custom-checkbox b-custom-control-${_vm.theme.size} ${
                      _vm.theme.size === 'lg' ? 'p-3 pl-4' : _vm.theme.size === 'md' ? 'p-2 pl-3' : 'px-1'
                    }`},[_c('b-form-checkbox',{staticClass:"mr-2 d-inline-block",attrs:{"value":option}}),_c('span',{staticClass:"font-weight-normal m-0 d-inline-block"},[_vm._v(" "+_vm._s(option)+" ")])],1)])],1)]})]],2)],1)],1)],1),_vm._l((_vm.selectedOrganised),function(option,index){return [_c('b-col',{key:`${option}-${index}`,staticClass:"px-0",attrs:{"cols":"12"}},[(_vm.isSelected(option) && option === 'Other')?[_c('BlockTextArea',_vm._b({},'BlockTextArea',{
            info: '',
            padding: _vm.padding,
            label: `Please provide as much detail as you can about other event(s) you watched or listened to (eg the type of event, the approximate date it was held and which OneCoin representatives attended.`,
            invalidFeedback: 'Cannot be empty',
            required: true,
            textRows: `5`,
            state: _vm.otherState,
            answer: _vm.otherText,
            disabled: false,
            placeholder: `Please add detail here.`,
          },false))]:_vm._e(),(_vm.isSelected(option) && option !== 'None' && option !== 'Other')?_c('BlockDatePicker',_vm._b({attrs:{"answer":_vm.dates[_vm.getIndex(option)]},on:{"update:answer":function($event){_vm.$set(_vm.dates, _vm.getIndex(option), $event)}}},'BlockDatePicker',{
          state: _vm.datePickerStates[_vm.getIndex(option)],
          label: `Please select an approximate date for when you watched or listened to the ${option} event`,
          info: '',
          invalidFeedback: 'Please select a date',
          displayDays: true,
          yearsStart: 2014,
          yearsEnd: 2024,
          disabled: false,
          required: true,
          padding: _vm.padding,
        },false)):_vm._e()],2)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }