import { Props } from "@/types";

export function getProps() {
  return {
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    } as Props.CGPropExtras<string>,

    amountOfClaims: {
      type: Number,
      required: true,
      description: "The amount of claims you want to display",
      default: 5,
    } as Props.CGPropExtras<number>,

    workspaceId: {
      type: String,
      required: true,
      description: "The workspace ID you want to limit the claims to",
      default: "7ilt0GpkiRi5mqDAAchE",
    } as Props.CGPropExtras<string>,

    funnelIds: {
      type: Array,
      required: true,
      description: "The funnels you want to limit the claims to",
      default: () => [],
    } as Props.CGPropExtras<string[]>,

    isBuilder: {
      type: Boolean,
      required: true,
      description: "Whether the component is in builder mode",
    } as Props.CGPropExtras<boolean>,
  };
}
