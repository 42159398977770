import { render, staticRenderFns } from "./dsar.vue?vue&type=template&id=d960a36a&scoped=true"
import script from "./dsar.vue?vue&type=script&lang=ts"
export * from "./dsar.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d960a36a",
  null
  
)

export default component.exports