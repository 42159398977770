import { UserDataService } from "@/helpers/ClaimsGate/DataService";
import { BlockYotiInstance } from "./BlockYotiInstance";
import { getFirebaseBackend } from "@/authUtils";
import { createYotiSession, emitSubmit, saveSessionError } from "./helpers";
import { createClaimDataService } from "@/helpers/vue";
import { User, Verification } from "@claimsgate/core-types";
import { identificationFlow } from "@claimsgate/core";

export const methods = {
  mounted,
  beforeDestroy,

  reloadPage,
  createYotiSession,
  emitSubmit,
};

async function mounted(state: BlockYotiInstance) {
  try {
    state.claimId = state.$route.params.claimId;

    if (!state.isChild) {
      createClaimDataService<BlockYotiInstance>(state);
    }

    state.userData = (await state.userService.getUserData()) ?? {};

    state.userDataService = new UserDataService(state.userService.getUserId());

    // Preload the user data on page load
    const { identityPending } = state.userData ?? {
      identityPending: undefined,
    };

    if (identityPending !== undefined) {
      state.identityPending = identityPending;
    }

    if (!state.isVerified && !state.identityPending) {
      state.userHadAttemptedVerificationBeforeMount = checkIfUserAttemptedVerificationBeforeMount(state);
    }

    if (state.isVerified) {
      await emitSubmit(state);
      return;
    }

    if (state.identityPending) {
      return;
    }

    await createYotiSession(state);
  } catch (exception) {
    console.error("Yoti.mounted() threw", exception);
    state.infoModal.fire("error", {});
  }
}

function beforeDestroy(state: BlockYotiInstance) {
  window.removeEventListener("message", (event) => {
    yotiEventHandler(state, event);
  });
  state.isDestroyed = true;
  if (state.unsubscribeFromUserSnap) {
    state.unsubscribeFromUserSnap();
  }
}
/**
 * Listens for changes on the users doc, to set relevant identityPending/Verified values
 * @param state
 */
export function subscriptionHandler(state: BlockYotiInstance) {
  // Start a subscription to receive updates when the requesting user's
  // data changes in Firestore
  const db = getFirebaseBackend().firestore();
  const userId = state.userService.getUserId();
  const userRef = db.collection("users").doc(userId);

  // Begin subscription, allowing for self-invocation to unsubscribe

  state.unsubscribeFromUserSnap = userRef.onSnapshot(async (documentSnapshot) => {
    // If the current and previous sessions are not the same, skip this update
    if (state.currentYotiSessionId !== state.previousYotiSessionId && state.previousYotiSessionId) {
      state.previousYotiSessionId = state.currentYotiSessionId;

      return;
    }

    await processSubscriptionSnapshot(documentSnapshot, state);
  });
}

/**
 * Takes the user doc and update the component state accordingly
 */
export async function processSubscriptionSnapshot(
  documentSnapshot: firebase.default.firestore.DocumentSnapshot,
  state: BlockYotiInstance
) {
  if (documentSnapshot.exists) {
    const userData = documentSnapshot.data() as User;
    const { identityPending } = userData;

    state.identityPending = identityPending;

    state.userData = userData;

    console.log("subHandle", {
      stateUserData: state.userData,
      isVerified: state.isVerified,
      isPending: state.identityPending,
    });

    state.userDataService.setManyArtefacts(userData);

    // this is a computed value based on wether the user has the correct verifications
    if (state.isVerified) {
      //setTimeout(async () => {
      //  await emitSubmit(state);
      //}, 4000); // 2000 milliseconds = 2 seconds
      // Allow user to submit the form
      return;
    }

    if (state.userHadAttemptedVerificationBeforeMount) {
      // break here to stop failed modal popping up on mount.
      // Need to set to false so code can get passed here when yoti sesssion is completed
      state.userHadAttemptedVerificationBeforeMount = false;
      return;
    }

    /**
     * Local state will only have a sessionId if it is currently in progress,
     * so we only wan't to create a new session if no local session running,
     * yoti has failed, and it is not pending.
     */
    if (
      !state.isVerified &&
      !state.identityPending &&
      state.userData.currentYotiSessionId !== userData.currentYotiSessionId
    ) {
      await createYotiSession(state);
      return;
    }
    //console.log("Users has faield ver", userHasFailedVerification(userData.verifications, state.identificationFlow));
    if (
      userHasFailedVerification(userData.verifications, state.identificationFlow) &&
      !state.identityPending &&
      !state.showManual
    ) {
      if (state.isChild) {
        // Current session failed, let the parent decide what to do
        state.$emit("yotiFailed");
        state.userData = userData;

        if (!state.loadingYotiSrc) await createYotiSession(state);
      } else {
        // current session failed, use needs to try again.
        await fireVerificationFailedModal(state);

        state.userData = userData;

        if (!state.loadingYotiSrc) {
          state.userHadAttemptedVerificationBeforeMount = true;
          await createYotiSession(state);
        }
      }
    }
  }
}

/** The user has failed yoti and needs to try again */
async function fireVerificationFailedModal(state: BlockYotiInstance) {
  await state.infoModal.fire("error", {
    title: "We couldn't verify your identity",
    text: "Please try again.",
  });
}

function reloadPage() {
  window.location.reload();
}

/**
 * Takes message events from the yoti iframe an processes them accordingly. Affects the UI states of the block
 * @param state
 * @param event
 * @returns
 */
export async function yotiEventHandler(state: BlockYotiInstance, event) {
  const yotiOrigin = "https://api.yoti.com";
  const yotiEventValues = {
    success: "SUCCESS",
    error: "ERROR",
  };

  if (state.isVerified) {
    if (state.isDestroyed) {
      return;
    }
    await emitSubmit(state);
    return;
  }
  //console.log("yoti event is", event);
  // Extract data and origin object for autheticity check
  const { data, origin } = event;

  if (data && origin === yotiOrigin) {
    // Extract the message received from Yoti
    const { eventType, eventCode } = data;
    console.log({ eventType, eventCode });
    if (eventType === yotiEventValues.success) {
      // Prevent plausible race condition which can occur
      // if Yoti instantly verifies a user

      if (!state.isVerified) {
        const store = { identityPending: true, identitySubmitted: new Date() };

        // The local session is finished, but we want to leave the sessionId sotre in the database
        state.userData.currentYotiSessionId = undefined;

        console.log("Setting user data with store", store);

        await state.userService.setUserData(store);
      }
    } else if (eventType === yotiEventValues.error) {
      if (eventCode >= 4000 && eventCode <= 7000) {
        state.$bvModal.show("yotiCameraError");

        console.error("KYC - Yoti Camera Error", eventCode);
      }

      if (state.isChild) {
        // If the event was a camera related issue, we want to show the user a different error message
        if (eventCode < 4000 || eventCode > 7000) {
          state.$emit("yotiFailed");
        }
      } else {
        // https://developers.yoti.com/identity-verification/render-the-user-view#error-codes
        if (eventCode === "2002" || (eventCode as number)?.toString() === "2002") {
          await saveSessionError(state, {
            name: `Error ${eventCode}: Session expired`,
            message: "The user failed to complete the Yoti session in the given time",
            time: new Date(),
          });
        } else {
          await saveSessionError(state, {
            name: `Error ${eventCode}: Yoti iFrame Error`,
            message: `Yoti iFrame returned an error with code ${eventCode}`,
            time: new Date(),
          });
        }
      }

      await createYotiSession(state);
      //window.location.reload();
    }
  }
}

/**
 * Checks if the user has attempted verification previously. Must be an attempt that matches the verification type requirements of the funnel
 */
function checkIfUserAttemptedVerificationBeforeMount(state: BlockYotiInstance) {
  if (
    state.identificationFlow === identificationFlow.yotiId ||
    state.identificationFlow === identificationFlow.electoralRollWithYotiFallback
  ) {
    return (
      state.userData?.verifications?.findIndex((verification) => verification.types.includes("Identity Upload")) > -1
    );
  }
  if (
    state.identificationFlow === identificationFlow.yotiIdAndAddress ||
    state.identificationFlow === identificationFlow.electoralRollWithYotiIdAndAddressFallback
  ) {
    return (
      state.userData?.verifications?.findIndex((verification) => verification.types.includes("Identity Upload")) > -1 &&
      state.userData?.verifications?.findIndex((verification) => verification.types.includes("Address Verified")) > -1
    );
  }
  if (state.identificationFlow === identificationFlow.yotiAddress) {
    return (
      state.userData?.verifications?.findIndex((verification) => verification.types.includes("Address Verified")) > -1
    );
  }
  if (state.identificationFlow === identificationFlow.yotiIdAndAml) {
    return (
      state.userData?.verifications?.findIndex((verification) => verification.types.includes("Identity Upload")) > -1 &&
      state.userData?.verifications?.findIndex((verification) => verification.types.includes("Anti Money Laundering")) >
      -1
    );
  }
  return false;
}
/**
 * Looks for existing verifications that have failed.
 * Returns true if the user has failed a verification that is required by the funnel identificationFlow
 */
function userHasFailedVerification(verifications: Verification[], funnelIdFlow: identificationFlow): boolean {
  if (!verifications || verifications.length < 0) return false;

  const userFailedAddress = !!verifications.find(
    (verification) => verification.types.includes("Address Verified") && !verification.passed
  );

  const userFailedId = !!verifications.find(
    (verification) => verification.types.includes("Identity Upload") && !verification.passed
  );

  if (funnelIdFlow === identificationFlow.yotiAddress) {
    return userFailedAddress;
  }

  if (
    funnelIdFlow === identificationFlow.yotiId ||
    funnelIdFlow === identificationFlow.electoralRollAndYotiId ||
    funnelIdFlow === identificationFlow.electoralRollWithYotiFallback
  ) {
    return userFailedId;
  }

  if (
    funnelIdFlow === identificationFlow.yotiIdAndAddress ||
    funnelIdFlow === identificationFlow.electoralRollWithYotiIdAndAddressFallback
  ) {
    return userFailedId || userFailedAddress;
  }

  return false;
}
