import { BlockPhoneInstance } from "../blockPhone";
import { UserExistsResult } from "./computes";

export function askUserToLogin(state: BlockPhoneInstance, result: UserExistsResult) {
  window.console.log("[result]", result);
  if (result.user.email) {
    state.blockState(state, false);
  }

  // trigger loading of the recover modal
  state.recover.userIdentifier = { phoneNumber: state.BlockInputs.phone.answer };
}
