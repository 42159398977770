import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { BlockProps } from "@/types";

/** Each of the BlockTextProps used in this component */
interface BlockTextProps {
  confirmVehicleText?: Partial<BlockProps.HTML>;
}

/** Each of the BlockButtonProps used in this component */
interface BlockButtonProps {
  searchVinButton?: Partial<BlockProps.Button>;
  submitVehicleConfirmationButton?: Partial<BlockProps.Button>;
  submitKeeperSelectButton?: Partial<BlockProps.Button>;
  submitOwnershipDateButton?: Partial<BlockProps.Button>;
  notMyVehicleButton?: Partial<BlockProps.Button>;
}

/** Each of the BlockInputProps used in this component */
interface BlockInputProps {
  caravanVin: Partial<BlockProps.Input<string>> & { mask: string };
  ownershipDatePicker: Partial<BlockProps.DatePicker>;
  vehicleConfirmationSingleSelect: Partial<BlockProps.SingleSelect>;
  keeperSelectSingleSelect: Partial<BlockProps.SingleSelect>;
  keeperStartDatePicker: Partial<BlockProps.DatePicker>;
  keeperEndDatePicker: Partial<BlockProps.DatePicker>;
  currentOwnerSingleSelect: Partial<BlockProps.SingleSelect>;
}

export interface CaravanBlockProps extends BlockButtonProps, BlockInputProps, BlockTextProps {}

const texts: BlockTextProps = {
  confirmVehicleText: { html: "<a onclick='test'>Not my vehicle?</a>", height: "auto" },
};

const buttons: BlockButtonProps = {
  searchVinButton: { submitButtonDefault: false, text: "Continue", block: false },
  submitVehicleConfirmationButton: { submitButtonDefault: false, text: "Continue", block: false },
  submitKeeperSelectButton: { submitButtonDefault: false, text: "Continue", block: false },
  submitOwnershipDateButton: { submitButtonDefault: false, text: "Continue", block: false },
  notMyVehicleButton: { submitButtonDefault: false, text: "Not my vehicle?", block: false, variant: "link" },
};

const inputs: BlockInputProps = {
  ownershipDatePicker: UserVariableBlocksService.genBlockDatePickerProps({
    displayDays: true,
    yearsStart: 2000,
    yearsEnd: new Date().getFullYear(),
    answer: "" as any,
  }),
  keeperStartDatePicker: UserVariableBlocksService.genBlockDatePickerProps({
    displayDays: false,
    yearsStart: 2008,
    yearsEnd: new Date().getFullYear(),
    answer: "" as any,
  }),
  keeperEndDatePicker: UserVariableBlocksService.genBlockDatePickerProps({
    displayDays: false,
    yearsStart: 2008,
    yearsEnd: new Date().getFullYear(),
    answer: "" as any,
  }),
  caravanVin: {
    ...UserVariableBlocksService.genBlockInputProps({
      answer: "" as any,
      label: "Please enter the VIN of your caravan",
      placeholder: "",
      required: true,
    }),
    mask: "NNNNNNNNNNNNNNNNN",
  },

  keeperSelectSingleSelect: UserVariableBlocksService.genBlockSingleSelectProps({}),
  vehicleConfirmationSingleSelect: UserVariableBlocksService.genBlockSingleSelectProps({
    options: ["Yes", "No"],
  }),
  currentOwnerSingleSelect: UserVariableBlocksService.genBlockSingleSelectProps({
    options: ["Yes", "No"],
  }),
};

/** Each of the blocks used on the BlockMotorSpecs component */
export const blocks = { texts, buttons, inputs };
