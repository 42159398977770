import { navigationComputed } from "@/state/helpers";
import { BlockCompanyInstance } from "./BlockCompany";

export function mappedComputed() {
  return {
    ...(navigationComputed as Record<keyof typeof navigationComputed, any>),
  };
}

export const computed = {
  fake,
};

function fake(state: BlockCompanyInstance): string {
  return "";
}
