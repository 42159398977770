<script lang="ts">
import Vue from "vue";
import { PropOptions } from "vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
export default Vue.extend({
  name: "Carousel",
  components: {
    BlockPadding,
  },
  props: {
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    } as PropOptions<string>,
  },
  data() {
    return {
      slide: 0,
      slideHeight: 730,
      texts: [
        {
          main: '"Very happy with the updates regarding the Milberg diesel emissions claim... Kept up to date at regular intervals regarding the progress of the claim..."',
          sub: "Patrick",
        },
        {
          main: '"Having uses Milberg for my diesel claim. They have made the claim very easy by requesting clearly what they need from me & regular updated."',
          sub: "Amar",
        },
        {
          main: "\"Seem very proffesional in my mercedes diesel claim have now sent documents for my claim so I'm hoping for a quick payment as feel dissatisfied that I bought a Bluetec mercedes running on ad blue and I've been lied too on emmissions.\"",
          sub: "Michael",
        },
        {
          main: '"Sara Ahmed the advisor I spoke to was really helpful and brilliant, I couldn\'t ask for a better experience. Thank you Milberg, you stand heads over and above all the others I have spoken to on the matter of emissions claims for my Mercedes Sprinter."',
          sub: "Victoria",
        },
        {
          main: '"Delighted with the progress and updates I’m receiving from Milberg London LLP regarding my emission claim against Mercedes Benz."',
          sub: "Roddy",
        },
      ],
    };
  },
  methods: {
    getSlideHeights(): Array<any> {
      const refKey = "trustSlide";
      const result = [];
      this.texts.forEach((item, index) => {
        result.push(this.$refs[`${refKey}${index}`][0]?.clientHeight);
      });
      return result;
    },
    onSlideEnd() {
      this.slideHeight = this.getSlideHeights()[this.slide] + 400;
    },
  },
});
</script>

<style scoped>
p {
  color: black;
}
.carousel-inner {
  width: 100%;
  /*max-height: 200px !important;*/
}
.carousel-caption {
  top: 10px !important;
  /*transform: translateY(-5%);
  bottom: initial;*/
}
</style>

<template>
  <div>
    <BlockPadding :padding="padding" />

    <b-row>
      <b-col cols="12" class="d-flex alignt-items-center justify-content-center">
        <img
          :src="require('@/assets/images/trustpilotMilberg.png')"
          height="42"
          alt="Trustpilot Score"
          class="mx-4 mb-3"
        />
      </b-col>

      <b-col cols="12" class="d-flex alignt-items-center justify-content-center">
        <p>Reviews 231• Excellent</p>
      </b-col>

      <b-col cols="12" class="d-flex alignt-items-center justify-content-center">
        <b-carousel
          id="carousel-1"
          v-model="slide"
          controls
          background="#FAFAFA"
          :img-height="slideHeight"
          img-width="1080"
          :interval="10000"
          @sliding-end="onSlideEnd"
          class="carousel-dark"
        >
          <b-carousel-slide img-blank img-blank-color="#FAFAFA" v-for="(r, index) in texts" :key="index + 'trustCar'">
            <div :ref="'trustSlide' + index">
              <p>
                {{ r.main }}
              </p>
              <p>{{ r.sub }}</p>
            </div>
          </b-carousel-slide>
        </b-carousel>
      </b-col>
    </b-row>
  </div>
</template>
