<script lang="ts">
import Vue from "vue";
import { PropOptions } from "vue/types/umd";
import { Fragment } from "vue-frag";
export default Vue.extend({
  name: "ComplexQuestionCards",
  components: {
    Fragment,
  },
  props: {
    answers: {
      type: Array,
      required: true,
    } as PropOptions<Object[]>,
    entityName: {
      type: String,
      required: true,
    } as PropOptions<string>,
    entityNames: {
      type: Array,
      required: true,
    } as PropOptions<string[]>,
    entityValidationStates: {
      type: Array,
      required: true,
    } as PropOptions<boolean[]>,
  },
  computed: {
    addText(): string {
      return `Add a ${this.entityName}`;
    },
  },
  methods: {
    getRoundingClass(index: number): string {
      if (this.answers?.length === 1) {
        return "";
      }
      if (index === 0) {
        return "rounded-top square-bottom";
      }
      if (index === this.answers?.length - 1) {
        return "square-top rounded-bottom";
      }
      return "rounded-0";
    },
    getValidationClass(index: number): string {
      const state = this.entityValidationStates?.[index];
      if (state === true) {
        return "border-success border-thick";
      }
      if (state === false) {
        return "border-danger border-thick";
      }
      return "";
    },
  },
});
</script>
<template>
  <Fragment>
    <template v-for="(entity, index) of answers">
      <b-card :key="'existingElement' + index" :class="['p-0', 'm-0', getRoundingClass, getValidationClass(index)]">
        <div class="d-flex align-items-center">
          <div class="grow w-100">
            <h5 class="mb-0">
              <span class="font-weight-semibold"> {{ entityNames[index] }} </span>
            </h5>
          </div>
          <div v-if="index > 0">
            <b-button size="md" variant="outline-light text-grey-600" @click="$emit('remove', index)" class="mr-1">
              Remove
            </b-button>
          </div>
          <div>
            <b-button
              size="md"
              class="bg-primary-50 text-primary-700 border-primary-50 bg-hover-primary-100 font-weight-semibold"
              @click="$emit('edit', index)"
            >
              Edit
            </b-button>
          </div>
        </div>
      </b-card>
    </template>
    <b-button size="lg" variant="link" @click="$emit('add')">
      <b-icon icon="plus"></b-icon>
      {{ addText }}
    </b-button>
  </Fragment>
</template>
