import { getFirebaseBackend } from "@/authUtils";
import * as Sentry from "@sentry/vue";
import { captureMessage } from "@sentry/vue";
import { BlockClaimsGateVehicleInstance } from "./BlockClaimsGateVehicle";

/** Fires a proactive Sentry log including the VRM called 'ClaimsGateVehicleApiError' */
export function fireClaimsGateVehicleApiError(state: BlockClaimsGateVehicleInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", state.vrm);
    scope.setTag("funnelId", state.funnelId);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("ClaimsGateVehicleApiError");
  });
}
