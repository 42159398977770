// we need our modal component
import InformationModal from "./informationModal.vue";

const InfoModal = {
  // every plugin for Vue.js needs install method
  // this method will run after Vue.use(<your-plugin-here>) is executed
  install(Vue) {
    // We must create new Eventbus
    // which is just another Vue instance that will be listening for and emiting events from our main instance
    // this EventBus will be available as Modal.EventBus
    this.EventBus = new Vue();

    // making our modal component global
    Vue.component("InfoModal", InformationModal);

    // exposing global $modal object with method show()
    Vue.prototype.$infoModal = {
      show(params, resolve, reject) {
        InfoModal.EventBus.$emit("show", params, resolve, reject);
      },
    };
  },
};

export default InfoModal;
