/**
 * This class is responsible for maintaing a [k,v] storage for Claims Gate compute results.
 * Results are non-persistent and will not be retained throughout a page refresh
 */
class ComputeResults {
  results;

  constructor() {
    this.results = {};
  }

  /**
   * Sets a new result on the compute results object
   * @param {*} key
   * @param {*} value
   */
  setResult(key, value) {
    window.console.log("Setting result k,v: ", key, value);
    this.results[key] = value;
  }

  getValues() {
    return Object.values(this.results);
  }

  /**
   * Returns a result from the compute results object
   * @param {*} key
   * @returns
   */
  getResult(key) {
    window.console.log("Attempted to fetch results: ", this.results);
    return this.results[key];
  }
}

export { ComputeResults };
