import { BlockYotiInstance } from "../BlockYotiInstance";
import { subscriptionHandler, yotiEventHandler } from "../methods";
import { computeIdFlow } from "./computeIdFlow";
/**
 * Creates a Yoti Session
 */
export async function createYotiSession(state: BlockYotiInstance) {
  try {
    if (state.isBuilder) {
      return;
    }
    state.loadingYotiSrc = true;

    /**
     * The user could be missing some or all of the verifications they need to complete the funnel.
     * Need to calculate the right flow for them
     */
    const computedIdFlow = computeIdFlow(state);
    state.$emit("update:identificationFlow", computedIdFlow);
    console.log("creating session with id flow", computedIdFlow, state.claimId, state.funnelId);
    const { data: session } = await state.yotiService.createSession({
      identificationFlow: computedIdFlow,
      theme: {
        primary: state.theme["primaryColour"],
        secondary: state.theme["secondaryColour"],
        text: state.theme["textColour"],
      },
      faceMatch: state.faceMatch,
      livenessCheck: state.livenessCheck,
      claimId: state.claimId,
      funnelId: state.funnelId,
    });

    console.log("session is", session);
    let sessionId;
    let clientSessionToken;

    if (session.exception) throw session.exception;

    if (session) {
      sessionId = session.sessionId;
      clientSessionToken = session.clientSessionToken;

      const yotiSrc = state.yotiService.generateSrc(sessionId, clientSessionToken);
      state.yotiSrc = yotiSrc;

      state.userData.currentYotiSessionId = sessionId;

      if (state.currentYotiSessionId) {
        state.previousYotiSessionId = state.currentYotiSessionId;
        state.currentYotiSessionId = sessionId;
      } else {
        state.currentYotiSessionId = sessionId;
      }

      // Set up a verificationSession trail
      if (!state.userData.yotiVerificationSessions) {
        state.userData.yotiVerificationSessions = [];
      }

      let existingTrailForThisSession = state.userData.yotiVerificationSessions.find(
        (session) => session.sessionId === sessionId
      );

      if (!existingTrailForThisSession) {
        // Create a new trail object
        existingTrailForThisSession = {
          sessionId: sessionId,
          clientErrors: [],
          sessionStartDate: new Date(),
          supplementaryDocCheck: {},
        };
        state.userData.yotiVerificationSessions.push(existingTrailForThisSession);
      }

      state.userDataService.setManyArtefacts({
        currentYotiSessionId: sessionId,
        yotiVerificationSessions: state.userData.yotiVerificationSessions,
      });
      console.log("Stting", state.userDataService.getCache());
      await state.userDataService.update();

      if (state.unsubscribeFromUserSnap === undefined) {
        subscriptionHandler(state);

        window.addEventListener("message", (event) => {
          yotiEventHandler(state, event);
        });
      }
    } else {
      throw new Error("No session returned from Yoti");
    }
    state.loadingYotiSrc = false;
  } catch (exception) {
    console.error("Creating a yoti session threw", exception);
    state.loadingYotiSrc = false;
    await state.infoModal.fire("error", {}, { preventFire: true });
  }
}
