<script lang="ts">
import Vue from "vue";

import { getProps } from "./props";
import { getData } from "./data";
import { computed } from "./computed";
import * as methods from "./methods";
//Components
import { Fragment } from "vue-frag";
import BlockCurrency from "@/components/shared/blocks/currency/currency.vue";
import BlockDatepicker from "@/components/shared/blocks/datepicker.vue";
import BlockInput from "@/components/shared/blocks/input.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockCountry from "@/components/shared/blocks/country/country.vue";
import BlockMultiSelect from "@/components/shared/blocks/multiselect.vue";
import BlockTextArea from "@/components/shared/blocks/text/textarea.vue";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import ComplexQuestionCards from "./complexQuestionCards/complexQuestionCards.vue";
import ComplexQuestionModal from "./complexQuestionModal/complexQuestionModal.vue";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";

export default Vue.extend({
  name: "BlockComplexQuestion",
  props: {
    ...getProps(),
  },
  components: {
    ComplexQuestionCards,
    ComplexQuestionModal,
    Fragment,
    BlockCurrency,
    BlockDatepicker,
    BlockInput,
    BlockSingleSelect,
    BlockPadding,
    BlockCountry,
    BlockMultiSelect,
    BlockTextArea,
    BlockInputWrapper,
  },
  data() {
    return {
      ...getData(this),
    };
  },
  computed: {
    sections(): number {
      return computed.sections(this);
    },
    entityNames(): string[] {
      return computed.entityNames(this);
    },
    conditionallyRenderQuestions(): Record<string, boolean> {
      return computed.conditionallyRenderQuestions(this);
    },
  },
  methods: {
    editEntity(index: number): void {
      methods.editEntity(index, this);
    },
    removeEntity(index: number): void {
      methods.removeEntity(index, this);
    },
    addEntity(): void {
      methods.addEntity(this);
    },
    closeModal(): void {
      this.$bvModal.hide("complexQuestionModal");
    },
    saveEntity(): void {
      methods.saveEntity(this);
    },
    nextSection(): void {
      methods.nextSection(this);
    },
    async validations(): Promise<boolean> {
      return methods.validations(this);
    },
    async computes(): Promise<Array<any>> {
      return methods.computes(this);
    },
    reloadAnswers(existingAnswers: Array<any>): Array<any> {
      return methods.reloadAnswers(existingAnswers, this);
    },
  },
  mounted() {
    const userService = getUserHelper();
    if (!this.userId) {
      this.userId = userService.getUserId();
    }
    this.claimDataService = new ClaimDataService(this.userId, this.claimId);

    if (this.answer && Array.isArray(this.answer) && this.answer.length > 0) {
      this.answers = this.reloadAnswers(this.answer);
    } else {
      this.addEntity();
    }
  },
});
</script>

<template>
  <Fragment>
    <BlockInputWrapper v-bind="{ padding, label }">
      <ComplexQuestionCards
        :entityName="entityName"
        :entityNames="entityNames"
        :entityValidationStates="entityValidationStates"
        :answers="answers"
        @remove="removeEntity($event)"
        @edit="editEntity($event)"
        @add="addEntity()"
      />

      <ComplexQuestionModal
        :title="title"
        :subTitle="subTitle"
        :currentSectionIndex="currentSectionIndex"
        :sections="sections"
        :isProcessing="uiToggles.isProcessing"
        @closeModal="closeModal"
        @previous="currentSectionIndex--"
        @next="nextSection()"
        @save="saveEntity()"
      >
        <template #body v-if="form.sections[currentSectionIndex]">
          <template v-for="(q, index) of form.sections[currentSectionIndex].questions">
            <component
              v-if="
                conditionallyRenderQuestions[q.field] &&
                answers[currentEntityIndex] &&
                answers[currentEntityIndex][q.field] !== undefined
              "
              :is="q.component"
              :key="'question' + q.field + index"
              v-bind="q.props"
              :answer.sync="answers[currentEntityIndex][q.field]"
            />
          </template>
        </template>
        <template v-if="uiToggles.isSaveError">
          <p class="mb-0 text-danger text-sm">There was an error saving the information</p>
        </template>
      </ComplexQuestionModal>
    </BlockInputWrapper>
  </Fragment>
</template>
