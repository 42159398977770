<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { methods, getProps, getData, computed, mappedComputed, BlockEmailRefs, BlockEmailInstance } from "./BlockEmail";
import { Fragment } from "vue-frag";
import BlockInput from "@/components/shared/blocks/input.vue";
import RecoverAccount from "@/components/shared/auth/RecoverAccount.vue";

export default (Vue as VueConstructor<Vue & BlockEmailRefs>).extend({
  name: "BlockEmail",
  components: { Fragment, BlockInput, RecoverAccount },
  props: { ...getProps() },
  computed: {
    ...mappedComputed(),
    blockState: {
      get(): boolean {
        return computed.blockState(this as BlockEmailInstance);
      },
      set(blockState: boolean): void {
        computed.blockState(this as BlockEmailInstance, blockState);
      },
    },
    blockDisabled: {
      get(): boolean {
        return computed.blockDisabled(this as BlockEmailInstance);
      },
      set(blockDisabled: boolean) {
        computed.blockState(this as BlockEmailInstance, blockDisabled);
      },
    },
    providedEmail: {
      get(): string {
        return computed.providedEmail(this as BlockEmailInstance);
      },
      set(answer: string) {
        computed.providedEmail(this as BlockEmailInstance, answer);
      },
    },
    verifyEmailAttempted(): boolean {
      return computed.verifyEmailAttempted(this as BlockEmailInstance);
    },
  },
  data() {
    return { ...getData(this) };
  },
  async mounted() {
    await methods.mounted(this as BlockEmailInstance);
  },
  methods: {
    async validations() {
      return methods.validations(this as BlockEmailInstance);
    },
    async computes() {
      return methods.computes(this as BlockEmailInstance);
    },
    async handleAccountRecovered() {
      return methods.handleAccountRecovered(this as BlockEmailInstance);
    },
    async saveEmail() {
      try {
        //this.$bvModal.hide("RecoverAccountVerify");
        console.log("Recover account triggered save email", { verifyEmailAttempted: this.verifyEmailAttempted });
        await methods.saveEmail(this as BlockEmailInstance);
      } catch (exception) {
        this.infoModalService.fire("error");
      }
    },
  },
});
</script>
<template>
  <Fragment>
    <!-- Recover Modal -->
    <RecoverAccount
      :userIdentifier="recover.userIdentifier"
      :claimId="$route.params.claimId"
      @authSuccessful="handleAccountRecovered()"
      uniqueModalId="1"
      @ready="$bvModal.show(`RecoverAccount1`)"
      :verifyOnly="false"
      ref="recoverAccount"
    />
    <RecoverAccount
      :userIdentifier="recover.verifyUserIdentifier"
      @authSuccessful="saveEmail"
      :verifyOnly="true"
      uniqueModalId="Verify"
      @ready="$bvModal.show(`RecoverAccountVerify`)"
      :claimId="undefined"
    />
    <!--  -->
    <BlockInput
      v-bind="BlockInputs.email"
      :padding="this.padding"
      v-bind.sync="BlockInputs.email"
      :inputType="'email'"
      :inputMode="'email'"
      :disabled="parentIsProcessing || BlockInputs.email.disabled"
    ></BlockInput>
    <template v-if="requireConfirm && !BlockInputs.email.disabled">
      <BlockInput
        :padding="this.padding"
        v-bind="BlockInputs.emailConfirm"
        v-bind.sync="BlockInputs.emailConfirm"
        :inputType="'email'"
        :disabled="parentIsProcessing || BlockInputs.emailConfirm.disabled"
      ></BlockInput>
    </template>
  </Fragment>
</template>
