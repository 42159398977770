<script lang="ts">
// General
import Vue from "vue";

export default Vue.extend({
  name: "TrackSection",
  components: {},
  props: {
    title: {
      type: String,
    },
  },
  computed: {},
  data() {
    return {};
  },
  async mounted() {},
  methods: {},
});
</script>

<template>
  <section class="mb-5 mt-3">
    <h5 class="font-weight-bold text-grey-600 mb-2" v-if="title">
      {{ title }}
    </h5>
    <slot></slot>
  </section>
</template>
