import { SlaterGordon, getFunnelVariables } from "@claimsgate/core";
import { AltVehicleBlockInstance } from "./AltVehicleBlockInstance";
import { reject } from "@/helpers/vue";
import { getFirebaseBackend } from "@/authUtils";

export const methods = {
  submit,
  notMyVehicle,
};

async function submit(state: AltVehicleBlockInstance) {
  if (state.currentView === "vehicle") {
    if (!validateVehicle(state)) {
      return;
    }
    await state.$nextTick();
    state.currentView = "date";
    await state.$nextTick();
  } else if (state.currentView === "date") {
    if (!validateDate(state)) {
      return;
    }
    await storeData(state);
    await state.$store.dispatch("events/fire", { name: state.eventValues.next });
  }
}

function validateVehicle(state: AltVehicleBlockInstance) {
  const validations = [];
  if (state.vehicleType === "boat") {
    state.BlockInputs.boatWin.answer = state.BlockInputs.boatWin.answer.replace(/[-\s]/g, "");
    if (!state.BlockInputs.boatWin.answer || state.BlockInputs.boatWin.answer.length <= 4) {
      state.BlockInputs.boatWin.state = false;
      state.BlockInputs.boatWin.invalidFeedback =
        "The WIN you have entered is invalid, please ensure it follows the format (AA-AAA-A1234-A-1-12)";
      validations.push(false);
    }
  }
  if (state.vehicleType === "plane") {
    state.BlockInputs.planeNumber.answer = state.BlockInputs.planeNumber.answer.replace(/[-\s]/g, "");
    if (!state.BlockInputs.planeNumber.answer) {
      state.BlockInputs.planeNumber.state = false;
      state.BlockInputs.planeNumber.invalidFeedback =
        "The plane number you have entered appears to be invalid. For example, N12345";
      validations.push(false);
    }
  }
  if (state.vehicleType === "general") {
    state.BlockInputs.generalMake.answer = state.BlockInputs.generalMake.answer.replace(/[-]/g, "");
    if (!state.BlockInputs.generalMake.answer || state.BlockInputs.generalMake.answer.length <= 2) {
      state.BlockInputs.generalMake.state = false;
      state.BlockInputs.generalMake.invalidFeedback =
        "The make of your vehicle must be greater than 2 characters in length.";

      validations.push(false);
    }
    console.log("Validations: ", state.BlockInputs.generalMake);
  }

  return validations.every((validation) => validation) || validations.length === 0;
}

function validateDate(state: AltVehicleBlockInstance) {
  const validations = [];
  if (!state.BlockInputs.ownershipDatePicker.answer) {
    state.BlockInputs.ownershipDatePicker.state = false;
    state.BlockInputs.ownershipDatePicker.invalidFeedback =
      "Please enter a valid ownership date, this must be before the current date";
    validations.push(false);
  }

  if (
    SlaterGordon.Funnels.MISSOLD_CAR_FINANCE_FUNNEL_ID === state.funnelId ||
    state.funnelId == "umkfPtVEbYgMUP5R4DMR"
  ) {
    const ownershipDate = state.BlockInputs.ownershipDatePicker.answer;
    if (new Date(ownershipDate) > new Date("2021-01-01")) {
      // if date is greater than January 1st 2021, reject with error
      state.BlockInputs.ownershipDatePicker.state = false;
      state.BlockInputs.ownershipDatePicker.invalidFeedback =
        "The vehicle must have been purchased before January 1st 2021";
      validations.push(false);
    }
  }

  return validations.every((validation) => validation) || validations.length === 0;
}

async function storeData(state: AltVehicleBlockInstance) {
  const [funnelVariables] = await getFunnelVariables(getFirebaseBackend().firestore(), state.funnelId);

  const store: { [key: string]: string | Date } = {};

  if (state.vehicleType === "boat") {
    store.win = state.BlockInputs.boatWin.answer.toUpperCase();
  } else if (state.vehicleType === "plane") {
    store.tailNumber = state.BlockInputs.planeNumber.answer.toUpperCase();
  } else if (state.vehicleType === "general") {
    store.make = state.BlockInputs.generalMake.answer.toUpperCase();
    store.model = state.BlockInputs.generalModel.answer.toUpperCase();
  }

  store.keeperStartDate = state.BlockInputs.ownershipDatePicker.answer;

  const hashedVehicle = await state.variableService.hashData(store, funnelVariables);

  for (const [key, value] of Object.entries(hashedVehicle)) {
    state.claimDataService.setArtefact(key, value);
  }
  Object.keys(state.BlockInputs).forEach((key) => {
    state.BlockInputs[key].state = true;
  });
}

export function notMyVehicle(state: AltVehicleBlockInstance) {
  resetToSearchVehicleView(state);
}

function resetToSearchVehicleView(state: AltVehicleBlockInstance) {
  // Set view back to vehicle
  state.currentView = "vehicle";

  // Clear answers
  state.BlockInputs.generalMake.answer = "";
  state.BlockInputs.generalModel.answer = "";
  state.BlockInputs.planeNumber.answer = "";
  state.BlockInputs.boatWin.answer = "";

  // Reset ownership date picker state
  state.BlockInputs.ownershipDatePicker.state = null;
}
