<script lang="ts">
import Vue from "vue";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import BlockInput from "@/components/shared/blocks/input.vue";
import DropdownSearchV3 from "./vue-search-select.vue";
import { getProps } from "./props";
export default Vue.extend({
  components: {
    DropdownSearchV3,
    BlockInputWrapper,
    BlockInput,
  },
  props: {
    ...getProps(),
  },
  computed: {
    fixedOptions(): Array<any> {
      if (this.other) {
        return [
          { text: "Other", value: "Other" },
          { text: "Please refine your search for more accurate results", value: "", disabled: true },
        ];
      } else {
        return [{ text: "Please refine your search for more accurate results", value: "", disabled: true }];
      }
    },
    options(): Array<any> {
      return this.optionsCSV.map((o) => ({ text: o, value: o }));
    },
    localState: {
      get(): boolean {
        return (this as any).state;
      },
      set(val: boolean): void {
        this.$emit("update:state", val);
      },
    },
    dropdownAnswer: {
      get(): string {
        return (this as any).answer;
      },
      set(val: string): void {
        console.log("dropdownAnswer", val);
        if (val === "Other") {
          this.showOther = true;
          this.$emit("update:answer", undefined);
        } else {
          this.showOther = false;
          this.$emit("update:answer", val);
        }
      },
    },
    dropdownState: {
      get(): boolean {
        if (this.showOther) return null;
        return this.localState;
      },
      set(val: boolean): void {
        this.localState = val;
      },
    },
    otherState: {
      get(): boolean {
        if (this.showOther) return this.localState;
        return null;
      },
      set(val: boolean): void {
        this.localState = val;
      },
    },
  },
  data() {
    return {
      otherAnswer: "",
      showOther: false,
    };
  },
  watch: {
    otherAnswer(val: string) {
      if (val) {
        this.$emit("update:answer", val);
        this.otherState = null;
      } else {
        this.$emit("update:answer", undefined);
      }
    },
    localState(val: boolean) {
      if (this.showOther) {
        this.otherState = val;
      }
    },
  },
});
</script>
<style scoped>
.mb-19 {
  margin-bottom: 19px;
}
</style>
<template>
  <div class="mb-19">
    <BlockInputWrapper
      v-bind="{
        info: info ? info : '',
        padding,
        label,
        invalidFeedback,
        state: dropdownState,
      }"
    >
      <DropdownSearchV3
        :placeholder="placeholder"
        :options="options"
        :state.sync="dropdownState"
        :isDisabled="disabled"
        @input="dropdownAnswer = $event"
        :fixedOptions="fixedOptions"
      ></DropdownSearchV3>
    </BlockInputWrapper>
    <div v-if="showOther">
      <BlockInput
        inputType="text"
        :placeholder="'Type your answer here'"
        :required="true"
        :answer.sync="otherAnswer"
        :state="otherState"
        :invalidFeedback="'This field is required'"
      />
    </div>
  </div>
</template>
