import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { BlockProps } from "@/types";

/** Each of the BlockButtonProps used in this component */
interface BlockButtonProps {
  searchCompanyButton?: Partial<BlockProps.Button>;
}

/** Each of the BlockInputProps used in this component */
interface BlockInputProps {
  companyName: Partial<BlockProps.Input<string>>;
  companyNumber: Partial<BlockProps.Input<string>>;
  companyAddressLine1: Partial<BlockProps.Input<string>>;
  companyPostcode: Partial<BlockProps.Input<string>>;
  companySelect: Partial<BlockProps.Dropdown<string>>;
}

export interface BlockCompanyProps extends BlockButtonProps, BlockInputProps {}

const buttons: BlockButtonProps = {
  searchCompanyButton: { submitButtonDefault: false, text: "Search", block: false, size: "lg" },
};

const inputs: BlockInputProps = {
  companyName: UserVariableBlocksService.genBlockInputProps({ placeholder: "Company Name", label: "Company Name" }),
  companySelect: UserVariableBlocksService.genBlockDropdownProps({ placeholder: "Please select a company" }),
  companyAddressLine1: UserVariableBlocksService.genBlockInputProps({
    placeholder: "Company Address",
    label: "Company Address",
  }),
  companyPostcode: UserVariableBlocksService.genBlockInputProps(),
  companyNumber: UserVariableBlocksService.genBlockInputProps(),
};

/** Each of the blocks used on the BlockCompany component */
export const blocks = { buttons, inputs };
