import { navigationComputed } from "@/state/helpers";
import { BlockPhoneInstance } from "./blockPhone";

export function mappedComputed() {
  return {
    ...(navigationComputed as Record<keyof typeof navigationComputed, any>),
  };
}
export const computed = {
  blockState,
};

/**
 * Combines the state boolean of email AND emailConfirm input block
 */
function blockState(state: BlockPhoneInstance, blockState?: boolean): boolean {
  if (blockState !== undefined) {
    state.BlockInputs.phone.state = blockState;
  }
  return state.BlockInputs.phone.state;
}
