import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { CaravanBlockProps, blocks } from "./blocks";
import { CaravanPartialVehicleStore } from "./types";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";

import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
import { InfoModalService } from "../../global/informationModal/InfoModalService";

export function getData(state: Vue) {
  return {
    vin: "",
    ownershipDate: "",
    vinInputState: null as boolean | null,
    uiToggles: {
      process: {
        isAutoSearchingWithoutOwnershipDate: false,
      },
      isSearchingByQueryParameters: false,
      isVehicleSearchVisible: true,
      isKeeperSelectVisible: false,
      showVehicleConfirmation: false,
      showKeeperStartQuestion: false,
      showKeeperEndQuestion: false,
      showOwnershipDateQuestion: false,

      isLoading: false,
    },
    userId: null as string,
    userService: getUserHelper(),
    claimId: state.$route.params.claimId,
    pageId: state.$route.params.pageId,
    funnelId: state.$route.params.funnelId,
    claimDataService: null as ClaimDataService | null,
    infoModalService: new InfoModalService(state.$infoModal),
    variableService: new VariableService(),
    vinInputInvalidFeedback: "",
    BlockInputs: {
      ...UserVariableBlocksService.getButtons(blocks.buttons as any),
      ...blocks.inputs,
      ...blocks.texts,
    } as CaravanBlockProps,
    vehicle: null as CaravanPartialVehicleStore,
    uiMessages: {
      noOwnershipDateSelected: "Please select a date in which you owned the vehicle",
      ownershipDateInFuture: "Please select a date which has already taken place",
      noVehicleConfirmationSelected: "Please confirm whether the above vehicle is your vehicle.",
      vinNot17Characters:
        "The VIN you have entered appears to be invalid. It should be 17 characters long, for example 1234567CU81234567.",
      vinContainsInvalidCharacters:
        "The VIN you have entered appears to be invalid. A VIN will only contain numbers and uppercase letters, for example 1234567CU81234567.",
      vinNotMatched:
        "The VIN you have entered appears to be invalid. A correctly formatted VIN will look like this 1234567CU81234567.",
    },
  };
}
