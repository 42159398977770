<script lang="ts">
// Vue
import Vue, { VueConstructor } from "vue";

// Component
import { methods, getProps, getData, BlockFinanceAgreementInstance } from "./BlockFinanceAgreement";
import { mappedComputed } from "./computed";
import { mounted } from "./mounted";

// Blocks
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockMultiFileInput from "@/components/shared/blocks/multiFileInput.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockDsar from "@/components/shared/blocks/dsar/dsar.vue";
export default (Vue as VueConstructor<Vue>).extend({
  name: "BlockFinanceAgreement",
  components: {
    BlockButton,

    BlockSingleSelect,
    BlockMultiFileInput,
    BlockPadding,
    BlockDsar,
  },
  props: { ...getProps() },

  computed: {
    ...mappedComputed(),
  },
  data() {
    return { ...getData(this) };
  },
  watch: {
    padding: {
      handler(padding): void {
        for (const blockInput of Object.values(this.BlockInputs)) {
          blockInput.padding = padding;
        }
      },
      immediate: true,
    },
    // invalidFeedback: {
    //   handler(invalidFeedback): void {
    //     this.setInvalidationFeedback(invalidFeedback);
    //   },
    // },
  },
  methods: {
    async submitFinanceAgreements(): Promise<void> {
      await methods.submitFinanceAgreements(this as unknown as BlockFinanceAgreementInstance);
    },
    async submitDeferUpload(): Promise<void> {
      await methods.submitDeferUpload(this as unknown as BlockFinanceAgreementInstance);
    },
  },
  async mounted(): Promise<void> {
    await mounted(this as unknown as BlockFinanceAgreementInstance);
  },
});
</script>
<template>
  <div>
    <BlockPadding :padding="padding" />

    <!-- Question -->
    <template>
      <BlockPadding padding="20" />
      <BlockSingleSelect
        v-bind="BlockInputs.financeAgreementCopyQuestion"
        v-bind.sync="BlockInputs.financeAgreementCopyQuestion"
      />

      <BlockPadding padding="20" />
    </template>
    <template v-if="BlockInputs.financeAgreementCopyQuestion.answer === 'No'">
      <BlockPadding padding="20" />
      <BlockSingleSelect v-bind="BlockInputs.deferUpload" v-bind.sync="BlockInputs.deferUpload" />

      <BlockPadding padding="20" />
      <BlockButton
        @clicked="submitDeferUpload"
        v-bind="BlockInputs.fileInputButton"
        v-bind.sync="BlockInputs.fileInputButton"
        v-if="BlockInputs.deferUpload.answer === 'Yes'"
      />
    </template>
    <!-- Upload Agreement Documents -->
    <template v-if="BlockInputs.financeAgreementCopyQuestion.answer === 'Yes'">
      <BlockMultiFileInput
        ref="BlockFileInput"
        v-bind="BlockInputs.fileInput"
        v-bind.sync="BlockInputs.fileInput"
        :accept="null"
        :multiple="true"
        :size="'lg'"
        :label="'Agreement documents'"
        :info="'Please upload all relevant documents.'"
        :placeholder="'Choose files'"
      ></BlockMultiFileInput>

      <BlockPadding padding="20" />
      <BlockButton
        @clicked="submitFinanceAgreements"
        v-bind="BlockInputs.fileInputButton"
        v-bind.sync="BlockInputs.fileInputButton"
      />
    </template>

    <!-- DSAR Request -->
    <template v-else-if="BlockInputs.deferUpload.answer === 'No'">
      <BlockDsar :padding="padding" :storeAs="{ field: 'financeAgreement' }" :consentText="consentText" />
    </template>

    <!-- Help Modal -->
  </div>
</template>
