const _defaults = {
  text: {
    type: String,
    required: false,
    allowVariable: true,
    default: "This is some text...",
    description: "The text to display",
  },
  /** @type { Props.Str} */
  padding: {
    type: String,
    required: false,
    default: "10",
    description: "The padding above the block",
  },
  styles: {
    type: String,
    required: false,
    allowVariable: false,
    default: "h2",
    valid: ["h1", "h2", "h3", "h4", "h5", "h6", "paragraph"],
    description: "The style of text to display",
  },
  weight: {
    type: String,
    required: false,
    allowVariable: false,
    default: "medium",
    valid: ["bold", "medium", "normal", "light"],
    description: "The font weight of text to display",
  },
  align: {
    type: String,
    required: false,
    allowVariable: false,
    default: "center",
    valid: ["left", "center", "right"],
    description: "The alignment of text to display",
  },
  textColour: {
    type: String,
    required: false,
    allowVariable: false,
    default: "text-body",
    valid: [
      "text-primary",
      "text-secondary",
      "text-success",
      "text-danger",
      "text-warning",
      "text-info",
      "text-light",
      "text-dark",
      "text-muted",
      "text-white",
      "text-body",
    ],
    description: "The colour of the text to display",
  },
  useTheme: {
    type: Boolean,
    required: false,
    allowVariable: false,
    default: true,
    valid: [true, false],
    description: "Boolean that defines whether currently chose theme is applied",
  },
};

export function titleProps() {
  const props = { ..._defaults };
  props.text = { ...props.text, default: "Title" };
  props.styles = { ...props.styles, valid: ["h1", "h2", "h3"], default: "h1" };
  props.weight = { ...props.weight, default: "medium" };

  return props;
}

export function subTitleProps() {
  const props = { ..._defaults };
  props.text = { ...props.text, default: "Sub-Title" };
  props.styles = { ...props.styles, valid: ["h4", "h5", "h6"], default: "h4" };
  props.weight = { ...props.weight, default: "normal", valid: ["bolder", "bold", "medium", "normal", "light"] };
  return props;
}

export function paragraphProps() {
  const props = { ..._defaults };
  delete props.styles;
  props.text = { ...props.text, default: "This is some text..." };
  props.weight = { ...props.weight, default: "normal", valid: ["bold", "medium", "normal", "light"] };
  return props;
}

export function textProps() {
  return _defaults;
}

export const claimsGateTextPropValues = {
  heading: {
    styles: "h1",
    weight: "medium",
    align: "center",
    text: "",
  },
  title: {
    styles: "h3",
    weight: "medium",
    align: "center",
    text: "",
  },
  subTitle: {
    styles: "h4",
    weight: "normal",
    align: "left",
    text: "",
    padding: "0",
  },
  paragraph: {
    weight: "normal",
    align: "left",
    text: "",
    padding: "0",
  },
};
