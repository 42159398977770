// Services
import { AsyncHelper } from "@/helpers/ClaimsGate/AsyncHelper";
import { UserVariableBlocksService } from "./UserVariableBlocksService";
// eslint-disable-next-line no-unused-vars
import { AsyncResult } from "../../types";
import { onCallGateway } from "./onCallGateway";

/**
 * Provides methods for the 'UserVariable' components
 */
class UserVariableService extends UserVariableBlocksService {
  constructor() {
    super();
  }

  /**
   * Checks firbase Auth to confirm if there is a user registered with this email or phone number
   * @param {string} email
   * @param { import("libphonenumber-js").E164Number} phoneNumber
   * @returns { Promise<import("@claimsgate/core-types").StandardAsyncResult<{status: boolean, user : {phoneNumber: boolean, email: boolean}}>> }
   */
  static async checkIfUserExists(email, phoneNumber) {
    try {
      const before = performance.now();
      const { data: result } = await onCallGateway({
        functionName: "confirmUserExists",
        data: {
          email,
          phoneNumber,
        },
      });
      const after = performance.now();
      console.warn(`confirmUserExists Function: ${after - before}`);
      console.log("Endpoint response is", result);

      return AsyncHelper.onCompleted(result);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Calls Firebase auth, setting the current account with given email and phoneNumber
   * @param { import("libphonenumber-js").E164Number} phoneNumber
   * @returns { Promise<AsyncResult<{status:boolean, message:string}, never, boolean>> }
   */
  static async setUserContactDetails(phoneNumber) {
    try {
      const before = performance.now();
      const { data: result } = await onCallGateway({
        functionName: "setUserContactDetails",
        data: {
          phoneNumber,
        },
      });
      const after = performance.now();
      console.warn(`setUserContactDetails function: ${after - before}`);
      console.log("Set user contact result, ", result);
      return AsyncHelper.onCompleted(result);
    } catch {
      return AsyncHelper.onException(true);
    }
  }

  /**
   * Checks is a name is more than one letter, and does not contain numbers
   * @param { String } name
   * @returns { Boolean } True if name is valid
   */
  static isValidName(name) {
    if (name.length < 2) {
      return false;
    } else {
      const exp = RegExp(/[0-9]/);

      if (exp.test(name)) {
        return false;
      } else {
        return true;
      }
    }
  }
}

export { UserVariableService };
