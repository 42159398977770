<script lang="ts">
import { getFirebaseBackend } from "@/authUtils";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
import { authComputed } from "@/state/helpers";
import { getClaim, WorkspaceService } from "@claimsgate/core";
import Vue from "vue";
import BlockHtml from "@/components/shared/blocks/html.vue";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import { Props } from "@/types";
import { PropOptions } from "vue";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
import { navigationComputed, eventComputed, themesComputed } from "@/state/helpers";

import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";

import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { FunnelsService } from "@claimsgate/core";
import { Fragment } from "vue-frag";
import { Variable } from "@claimsgate/core-types";
const props: {
  padding: PropOptions<string>;
  condition: PropOptions<any>;
} = inputProps({
  excludeDefaults: true,

  extraProps: ["padding", "condition"],
});
export default Vue.extend({
  name: "TransferWorkspace",
  components: {
    BlockHtml,
    Fragment,
  },
  props: {
    ...props,
    workspaceId: {
      type: String,
      default: "",
    },

    isBuilder: {
      type: Boolean,
    },

    text: {
      type: String,
      required: false,
      allowVariable: true,
      default: "Click Here",
      description: "Provide text",
    } as Props.CGPropExtras<string>,
    automaticallySubmit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...authComputed,
    ...navigationComputed,
    ...eventComputed,
    ...themesComputed,
    linkHtml(): string {
      return `<h4><a href="${this.link}">${this.text}</a></h4>`;
    },
  },
  data() {
    return {
      workspaceService: new WorkspaceService(getFirebaseBackend().firebase()),
      userService: getUserHelper(),
      funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
      userId: null,
      claimId: this.$route.params.claimId,
      funnelId: this.$route.params.funnelId,
      destinationClaimId: "",
      claimDataService: null as ClaimDataService,
      isLoading: false,
      link: "",
      infoModalService: new InfoModalService(this.$infoModal),
      funnelVariables: null as Array<Variable>,
    };
  },
  async mounted() {
    try {
      this.isLoading = true;
      this.userId = this.userService.getUserId();

      this.claimDataService = new ClaimDataService(this.userId, this.claimId);

      const { data: funnelVariables } = await this.funnelsService.getFunnelVariables(this.funnelId);
      this.funnelVariables = funnelVariables;

      const copyClaimVariableId = this.funnelVariables.find((variable) => variable.field === "copiedClaimId")?.id;
      if (copyClaimVariableId) {
        this.destinationClaimId = this.claimDataService.getArtefact(copyClaimVariableId);

        console.log(">>> DESTINATION CLAIM ID", this.destinationClaimId);
      }

      if (this.claimDataService.getArtefact("copiedClaimId")) {
        this.destinationClaimId = this.claimDataService.getArtefact("copiedClaimId");
        console.log(">>> DESTINATION CLAIM ID", this.destinationClaimId);
      }

      const redirectUrl = await this.getWorkspaceLink();

      if (!redirectUrl) {
        throw new Error(`Failed to get workspace link for workspaceId: ${this.workspaceId}, claimId: ${this.claimId}`);
      }

      this.link = redirectUrl;

      if (this.automaticallySubmit && !this.isBuilder) {
        window.location.href = redirectUrl;
      }

      this.isLoading = false;
    } catch (exception) {
      console.error(
        "[TLW Solicitors - Urgent Issue] Claimants are not being redirected to the workspace following signing",
        exception
      );
      this.isLoading = false;
      await this.infoModalService.fire("error", {
        title: "Unexpected Error",
        text: "Whoops, we're not sure what went wrong. Our team has been notified, please try again later!",
        dismissButtonText: "Understood",
      });
    }
  },
  methods: {
    async checkAuthToken(): Promise<string> {
      // check if the authToken is defined and whether it is still valid (it gets invalidated after 3600 seconds)
      if (!this.authToken?.token || this.authToken?.createdAt + 3300 <= Math.round(new Date().getTime() / 1000)) {
        const authToken = await this.generateAuthToken();
        return authToken;
      }

      return this.authToken.token;
    },
    async generateAuthToken(): Promise<string> {
      // generate auth token
      const { data: authToken } = await onCallGateway<"generateCustomToken">({ functionName: "generateCustomToken" });

      if (authToken) {
        await this.$store.dispatch("auth/setAuthToken", {
          token: authToken,
          createdAt: Math.round(new Date().getTime() / 1000),
        });
        return authToken;
      }

      return "";
    },

    async getWorkspaceLink() {
      console.log(">>> TRANSFER: ", this.workspaceId);
      let { data: primaryWorkspaceDomain } = await this.workspaceService.getPrimaryWorkspaceDomain(this.workspaceId);
      if (!primaryWorkspaceDomain) {
        primaryWorkspaceDomain = "claimsgate.co.uk";
      }
      const authToken = await this.checkAuthToken();

      let redirectURL: string;
      let queryString = "";

      if (this.$route.query) {
        queryString = Object.keys(this.$route.query)
          .map((key) => `${key}=${this.$route.query[key]}`)
          .join("&");
      }

      if (this.destinationClaimId) {
        const [destinationClaim] = await getClaim(
          getFirebaseBackend().firestore(),
          this.userId,
          this.destinationClaimId
        );

        if (queryString) {
          redirectURL = `https://${primaryWorkspaceDomain}/form/${destinationClaim.currentFunnelId}/${destinationClaim.currentPageId}/${this.destinationClaimId}?${queryString}&authToken=${authToken}`;
        } else {
          redirectURL = `https://${primaryWorkspaceDomain}/form/${destinationClaim.currentFunnelId}/${destinationClaim.currentPageId}/${this.destinationClaimId}?authToken=${authToken}`;
        }
      } else {
        if (queryString) {
          redirectURL = `https://${primaryWorkspaceDomain}/claims/${this.workspaceId}?${queryString}&authToken=${authToken}`;
        } else {
          redirectURL = `https://${primaryWorkspaceDomain}/claims/${this.workspaceId}?authToken=${authToken}`;
        }
      }

      console.log(">>> TRANSFER", redirectURL, " from: ", window.location.href);
      return redirectURL;
    },
  },
});
</script>
<template>
  <Fragment>
    <template v-if="isLoading">
      <b-spinner class="spinner-border-sm" role="status"></b-spinner>
    </template>
    <BlockHtml v-else v-bind="{ padding, html: linkHtml, storeAs: {} }"></BlockHtml>
  </Fragment>
</template>
