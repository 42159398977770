<script lang="ts">
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import Vue from "vue";
const inputs: any[] = ["title", "firstName", "middleName", "lastName", "other"];
import BlockInput from "@/components/shared/blocks/input.vue";
import BlockDropdown from "@/components/shared/blocks/dropdown.vue";

import { Fragment } from "vue-frag";
export default Vue.extend({
  name: "SimpleName",
  props: {
    title: {
      type: Boolean,
      default: true,
    },
    firstName: {
      type: Boolean,
      default: true,
    },
    middleName: {
      type: Boolean,
      default: true,
    },
    lastName: {
      type: Boolean,
      default: true,
    },
    allowOtherTitle: {
      type: Boolean,
      default: true,
    },
    answer: {
      type: Object,
      default: () => {
        return {
          title: "",
          firstName: "",
          middleName: "",
          lastName: "",
        };
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: "10",
    },
  },
  components: {
    BlockInput,
    BlockDropdown,
    Fragment,
  },
  data() {
    return {
      BlockInputs: UserVariableBlocksService.getBlocks(inputs),
      uiMessages: {
        pleaseEnterYour: "Please enter your",
      },
    };
  },
  computed: {
    titleOptions(): Array<string> {
      const options = this.BlockInputs.title.options;
      if (!this.allowOtherTitle) {
        return options.filter((option: string) => option !== "Other");
      }
      return options;
    },
  },
  methods: {
    validations(): boolean {
      this.resetInputs();
      return inputs
        .map((input) => {
          if (!this[input]) return true;

          return input == "title" ? this.validateTitle() : this.validateName(input);
        })
        .every(Boolean);
    },
    computes() {
      const answer = {};
      inputs.forEach((input) => {
        answer[input] = this.BlockInputs[input].answer;
        this.BlockInputs[input].state = true;
      });
      return answer;
    },
    invalidMessage(field: string) {
      const fieldString = field
        .split(/(?=[A-Z])/)
        .join(" ")
        .toLowerCase();
      return `${this.uiMessages.pleaseEnterYour} ${fieldString}`;
    },
    isValidName(name: string): boolean {
      return name?.length > 1;
    },
    setError(input: string, message: string): boolean {
      this.BlockInputs[input].invalidFeedback = message;
      this.BlockInputs[input].state = false;
      return false;
    },
    resetInputs() {
      inputs.forEach((input) => {
        this.BlockInputs[input].state = null;
        this.BlockInputs[input].invalidFeedback = "";
      });
    },
    validateName(input: string) {
      if (!this.isValidName(this.BlockInputs[input].answer)) {
        return this.setError(input, this.invalidMessage(input));
      } else {
        return true;
      }
    },
    validateTitle() {
      if (this.BlockInputs.title.answer === "Other" && this.allowOtherTitle) {
        if (this.BlockInputs.other.answer?.length > 0) {
          return true;
        } else {
          this.BlockInputs.other.state = false;
          this.BlockInputs.other.invalidFeedback = this.invalidMessage("title");
          return false;
        }
      }
      if (this.BlockInputs.title.answer?.length > 0) {
        // this.BlockInputs.title.state = true;
        return true;
      } else {
        this.BlockInputs.title.state = false;
        this.BlockInputs.title.invalidFeedback = this.invalidMessage("title");
        return false;
      }
    },
  },
  watch: {
    answer: {
      handler: function (newVal) {
        inputs.forEach((input) => {
          if (input === "title" && !this.BlockInputs.title.options.includes(newVal[input])) {
            this.BlockInputs.other.answer = newVal[input];
            this.BlockInputs.title.answer = "Other";
          }
          this.BlockInputs[input].answer = newVal[input];
        });
      },
      deep: true,
      immediate: true,
    },
    disabled: {
      handler: function (newVal) {
        inputs.forEach((input) => {
          this.BlockInputs[input].disabled = newVal;
        });
      },
      immediate: true,
    },
    padding: {
      handler: function (newVal) {
        inputs.forEach((input) => {
          this.BlockInputs[input].padding = newVal;
        });
      },
      immediate: true,
    },
  },
});
</script>
<template>
  <Fragment>
    <template v-if="title">
      <div class="d-flex">
        <BlockDropdown
          v-bind="BlockInputs.title"
          :options="titleOptions"
          v-bind.sync="BlockInputs.title"
          class="w-50"
        />

        <template v-if="firstName">
          <BlockInput v-bind="BlockInputs.firstName" v-bind.sync="BlockInputs.firstName" class="w-50 ml-2" />
        </template>
      </div>

      <template v-if="BlockInputs.title.answer === 'Other' && allowOtherTitle">
        <BlockInput v-bind="BlockInputs.other" v-bind.sync="BlockInputs.other" />
      </template>
    </template>

    <template v-if="firstName && !title">
      <BlockInput v-bind="BlockInputs.firstName" v-bind.sync="BlockInputs.firstName" />
    </template>
    <template v-if="middleName">
      <BlockInput v-bind="BlockInputs.middleName" v-bind.sync="BlockInputs.middleName" />
    </template>
    <template v-if="lastName">
      <BlockInput v-bind="BlockInputs.lastName" v-bind.sync="BlockInputs.lastName" />
    </template>
  </Fragment>
</template>
