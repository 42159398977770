<script lang="ts">
import Vue, { VueConstructor } from "vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import { Props } from "@/types";
import { mapGetters } from "vuex";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { templateText } from "@/helpers/vue";
import { ClaimDataService, UserDataService } from "@/helpers/ClaimsGate/DataService";
import { FunnelsService } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";
import BlockInvalidFeedback from "@/components/shared/blocks/invalidfeedback.vue";

import { Variable } from "@claimsgate/core-types";
export default (Vue as VueConstructor<Vue>).extend({
  components: { BlockPadding, BlockInvalidFeedback },
  data() {
    return {
      userService: getUserHelper(),
      funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
      userId: null as string,
      claimId: this.$route.params.claimId,
      funnelId: this.$route.params.funnelId,
      userDataService: null as UserDataService,
      claimDataService: null as ClaimDataService,
      funnelVariables: null as Array<Variable>,
      hasReadDocument: false as boolean,
      hasClickedLink: false as boolean,
      state: null as boolean,
      invalidFeedback: "This field is required",
    };
  },

  props: {
    storeAs: {
      type: [Object, String],
    } as Props.CGPropExtras<string>,
    answer: {
      type: [String],
      required: false,
      default: "",
      description: "If the block has been read",
    } as Props.CGPropExtras<string | number | boolean | Array<any> | Object>,
    required: {
      type: Boolean,
      required: false,
      default: false,
      description: "Is this block required?",
    } as Props.CGPropExtras<boolean>,
    condition: {
      type: Object,
      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },

    hideCard: {
      type: Boolean,
      required: false,
      default: false,
      description: "Hide the card",
    } as Props.CGPropExtras<boolean>,

    height: {
      type: String,
      required: false,
      allowVariable: false,
      default: "auto",
      valid: [
        "auto",
        "50",
        "100",
        "150",
        "200",
        "250",
        "300",
        "350",
        "400",
        "450",
        "500",
        "600",
        "700",
        "800",
        "900",
        "1000",
      ],
      description: "Padding in pixels",
    } as Props.CGPropExtras<string>,
    html: {
      type: String,
      required: false,
      allowVariable: true,
      default: "<h1>Hello world!</h1>",
      description: "Render HTML",
    } as Props.CGPropExtras<string>,

    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    } as Props.CGPropExtras<string>,

    isBuilder: {
      type: Boolean,
      required: false,
      default: false,
      description: "Is this block being used in the builder?",
    } as Props.CGPropExtras<boolean>,
    forceRead: {
      type: Boolean,
      required: false,
      default: false,
      description: "Force the user to read the document",
    } as Props.CGPropExtras<boolean>,
    forceClick: {
      type: Boolean,
      required: false,
      default: false,
      description: "Force the user to click a link",
    } as Props.CGPropExtras<boolean>,
  },
  computed: {
    templated(): string {
      return templateText(this.html, this);
    },
    ...mapGetters({
      workspaceName: "navigation/getWorkspaceName",
      funnel: "form/getFunnelMeta",
    }),
    funnelTitle(): string {
      return this.funnel?.title;
    },
  },
  async mounted(): Promise<void> {
    this.userId = this.userService.getUserId();
    this.userDataService = new UserDataService(this.userId);
    this.claimDataService = new ClaimDataService(this.userId, this.claimId);
    const { data: funnelVariables } = await this.funnelsService.getFunnelVariables(this.funnelId);
    this.funnelVariables = funnelVariables;
  },
  methods: {
    handleClick($event: Event) {
      const target = $event.target as HTMLElement;
      if (target.tagName === "A") {
        this.$emit("update:answer", "Yes");
        this.hasClickedLink = true;
      }
    },

    handleScroll($event: Event) {
      this.state = null;
      const container = $event.target as HTMLElement;

      const { scrollTop, scrollHeight, clientHeight } = container;

      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 50;

      if (isAtBottom) {
        this.$emit("update:answer", "Yes");
        this.hasReadDocument = true;
      }
    },
    validations(): boolean {
      this.state = null;
      if (this.forceRead) {
        if (this.hasReadDocument) {
          return true;
        } else {
          this.invalidFeedback = "Please read the entire document before continuing.";

          this.state = false;
          return false;
        }
      }

      if (this.forceClick) {
        if (this.hasClickedLink) {
          return true;
        } else {
          this.invalidFeedback = "Please ensure you have clicked and read the link before continuing.";

          this.state = false;
          return false;
        }
      }

      return true;
    },
  },
});
</script>

<template>
  <div>
    <BlockPadding :padding="padding" />
    <!-- Remove the shadow from the card -->

    <template v-if="!hideCard">
      <b-card
        class="mb-0"
        no-body
        style="box-shadow: 0 0rem 0.25rem rgb(0 0 0 / 15%)"
        :style="hideCard ? 'border: none' : ''"
      >
        <b-card-body
          style="padding-bottom: 0.25rem !important"
          :class="[state === false ? 'border border-danger border-thick border-rounded' : '']"
        >
          <div
            :ref="'scrollContainer'"
            @scroll="handleScroll($event)"
            :style="height === 'auto' ? ' ' : 'position:relative; overflow-y:scroll; height: ' + height + 'px'"
          >
            <div v-html="templated" @click="handleClick" />
          </div>
        </b-card-body>
      </b-card>
      <div v-if="forceRead && state === false" class="text-danger mt-2 text-sm">
        Please read the entire document before continuing
      </div>
    </template>
    <template v-else>
      <div :style="height === 'auto' ? ' ' : 'position:relative; overflow-y:scroll; height: ' + height + 'px'">
        <div v-html="templated" />
      </div>
    </template>
    <BlockInvalidFeedback :state="state" v-if="state === false" :invalidFeedback="invalidFeedback" />
  </div>
</template>
