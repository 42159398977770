import { ClaimsGateFunctionReturnedError } from "@claimsgate/core";
import { VariableType } from "@claimsgate/core-types";

/** @return Returns a boolean indicating whether or not the provided variable type can be edited */
export function isEditableVariableType(type: VariableType): boolean {
  const EDITABLE_VARIABLE_TYPES: Array<VariableType> = [
    "boolean",
    "date",
    "number",
    "file",
    "array of files",
    "string",
    "T",
    "Array<T>",
  ];

  return EDITABLE_VARIABLE_TYPES.includes(type);
}

/** Type guard to check if a returned function response is a Claims Gate function returned error */
export function isClaimsGateFunctionReturnedError<T>(response: {
  data?: ClaimsGateFunctionReturnedError | T;
}): response is { data: ClaimsGateFunctionReturnedError } {
  return (
    typeof response.data === "object" &&
    !Array.isArray(response) &&
    (response.data as ClaimsGateFunctionReturnedError).error !== undefined
  );
}

/** Type guard to check if a given value is not a ClaimsgateFunctionReturnedError */
export function isNotClaimsGateFunctionReturnedError<T>(response: {
  data: ClaimsGateFunctionReturnedError | T;
}): response is { data: T } {
  return !isClaimsGateFunctionReturnedError(response);
}

export function preprocessFilters(filters: any[]) {
  return filters.map((filter) => {
    const _filter: any = {
      variable: filter.variable.id,
      type: filter.variable.type,
      condition: filter.condition,
      value: filter.value,
    };

    if (filter?.nestedVariable?.field) {
      _filter.variable += `.${filter.nestedVariable.field}`;
    }

    if (filter?.variable?.not === true) _filter.not = true;
    if (filter?.variable?.exists === true) _filter.exists = true;

    return _filter;
  });
}
