import { BlockConsentInstance } from "./BlockConsent";
import { AsyncHelper, ClaimsGateErrors } from "@claimsgate/core";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";

export async function mounted(state: BlockConsentInstance) {
  try {
    if (!state.userService.getUserId()) {
      return AsyncHelper.onError(ClaimsGateErrors.UserIdMissing);
    }
    state.userDataService = new UserDataService(state.userService.getUserId());

    //await state.userDataService.refresh();

    state.user = state.userDataService.getCache();

    setExistingConsents(state);

    return AsyncHelper.onCompleted(true);
  } catch (exception) {
    console.error(exception);
    return AsyncHelper.onException(exception);
  }
}

/**
 * Checks for existing CGPrivacy consent on the user,
 * and if the users consents array includes a consent for the current workspace
 * will set and disable respective check box
 * @param state
 */
function setExistingConsents(state: BlockConsentInstance) {
  const { latestPrivacyConsent, latestTsAndCsConsent } = getExistingConsentsForThisWorkspace(state);

  state.existingConsentsForThisWorkspace = { latestPrivacyConsent, latestTsAndCsConsent };

  state.checkboxes.workspacePrivacy.answer = latestPrivacyConsent?.status ?? "";
  state.checkboxes.workspacePrivacy.disabled = latestPrivacyConsent?.status === "accepted";

  if (state.user.privacyConsent === "accepted") {
    state.checkboxes.cgPrivacy.answer = state.user.privacyConsent;
    state.checkboxes.cgPrivacy.disabled = true;
    state.checkboxes.sms.answer = "accepted";
    state.checkboxes.sms.disabled = true;
  }
}

function getExistingConsentsForThisWorkspace(state: BlockConsentInstance) {
  const userConsents = state.user.consents ?? [];
  console.log("All consents are", userConsents);

  const existingConsents = userConsents.filter((consent) => consent.workspaceId === state.navigationWorkspaceId);
  console.log("Existing consents are", existingConsents);

  const latestPrivacyConsent = existingConsents
    .filter((consent) => consent.type === "PrivacyPolicy")
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .shift();

  const latestTsAndCsConsent = existingConsents
    .filter((consent) => consent.type === "TermsAndConditions")
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .shift();

  console.log("Existing latest consents are", latestPrivacyConsent, latestTsAndCsConsent);

  return { latestPrivacyConsent, latestTsAndCsConsent };
}
