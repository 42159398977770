import { FileMeta } from "@claimsgate/core-types";
import { generateFirestoreId } from "@claimsgate/core";
import { StorageService } from "@/helpers/ClaimsGate/StorageService";
import mime from "mime-types";

/**
 * Convert the user friendly document type to the names used by yoti for consistency
 */
export function mapDocumentType(
  type: "Drivers License" | "Passport" | "Anti Money Laundering" | "Electoral Roll"
): "DRIVING_LICENCE" | "PASSPORT" | "ANTI_MONEY_LAUNDERING" | "ELECTORAL_ROLL" {
  switch (type) {
    case "Drivers License":
      return "DRIVING_LICENCE";
    case "Passport":
      return "PASSPORT";
    case "Anti Money Laundering":
      return "ANTI_MONEY_LAUNDERING";
    case "Electoral Roll":
      return "ELECTORAL_ROLL";
    default:
      return "DRIVING_LICENCE";
  }
}

/**
 * Convert the yoti document type to the user friendly names for consistency
 */
export function reverseMapDocumentType(
  type: "DRIVING_LICENCE" | "PASSPORT" | "ANTI_MONEY_LAUNDERING" | "ELECTORAL_ROLL"
): "Drivers License" | "Passport" | "Anti Money Laundering" | "Electoral Roll" {
  switch (type) {
    case "DRIVING_LICENCE":
      return "Drivers License";
    case "PASSPORT":
      return "Passport";
    case "ANTI_MONEY_LAUNDERING":
      return "Anti Money Laundering";
    case "ELECTORAL_ROLL":
      return "Electoral Roll";
    default:
      return "Drivers License";
  }
}

/**
 * Parse the files into the format expected by the API
 */
export function parseFiles(files: File[]) {
  return files.map((file, index) => {
    return {
      name: `identityPicture${index + 1}`,
      file,
      type: file.type,
      extension: file.name.split(".").pop(),
    };
  });
}

/**
 * Store the given files in a temp location, so they can be moved to the correct location by the backend
 * @returns Meta of the stored files
 */
export async function uploadToTemp(
  files: Array<any>,
  userId: string,
  storageService: StorageService
): Promise<FileMeta[]> {
  const userFilePath = `temp/${userId}`;
  const fileMetas = await Promise.all(
    files.map(async (fileData) => {
      const fileId = generateFirestoreId();

      const filePath = `${userFilePath}/${fileId}`;
      const { data } = await storageService.uploadFile(filePath, fileData.file);

      if (!data) {
        throw new Error("Error uploading file");
      }

      const fileMeta: FileMeta = {
        name: fileData.name,
        type: fileData.type,
        extension: fileData.extension,
        path: filePath,
        fileId: fileId,
        id: fileId,
        mime: mime.extension(fileData.type) || fileData.extension,
        createdAt: new Date(),
      };

      return fileMeta;
    })
  );
  return fileMetas;
}
