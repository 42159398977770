<script lang="ts">
// eslint-disable-next-line no-unused-vars
import { Props } from "@/types";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import Vue, { PropType } from "vue";
import { themesComputed } from "@/state/helpers";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
const inputPropsValue: Props.BlockInput = inputProps({
  extraProps: ["elementId", "placeholder"],
});
export default Vue.extend({
  name: "BlockNumber",
  components: { BlockInputWrapper },
  props: {
    ...inputPropsValue,
    answer: {
      type: [Number, String] as PropType<number>,
      required: false,
    },
    isCurrency: {
      type: Boolean,
      required: false,
      description: "If true, the input is formatted as a currency",
      default: false,
    } as Props.CGPropExtras<boolean>,
    step: {
      type: Number as PropType<number>,
      required: false,
      description: "The step value for the number input",
      default: 1,
    } as Props.CGPropExtras<number>,
  },
  data() {
    return {
      selected: false,
      newAnswer: 0 as number,
      hover: false,
    };
  },
  watch: {
    /**
     * Allow for the parent to communicate prop changes to value
     * @param  {string} answer
     * @return {void}
     */
    answer: {
      handler: function (val) {
        if (val) {
          this.newAnswer = val;
        }
      },
    },
  },
  methods: {
    handleInput(input: number): void {
      this.newAnswer = input;
      this.$emit("update:answer", this.newAnswer);
      this.$emit("update:state", null);
    },
  },
  mounted() {
    this.newAnswer = this.answer ?? 0;
  },
  computed: {
    ...themesComputed,

    /** Calculates the primary styles to be applied to the input */
    primaryStyles(): Record<string, string | number> {
      return this.theme?.primaryStyles ?? {};
    },
    /**
     * If theme from vuex has not been intitalized, default to large
     */
    size(): string {
      if (this.theme?.size?.length > 0) {
        return this.theme.size;
      } else {
        return "lg";
      }
    },
    /**
     * 'phoneNumber' is not a supported input type for b-form-input, but is useful for browser autocompletes
     *  Convert it to number for b-form-input
     */
    type(): string {
      return this.inputType === "phoneNumber" ? "text" : this.inputType;
    },
  },
});
</script>
<style scoped>
button.btn.btn-grey {
  font-size: 1.015625rem;
  border-width: 2px;
  border-color: lightgrey;
  border-radius: 2px 0px 0px 2px;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
input.form-control.prepended {
  border-radius: 0px 2px 2px 0px !important;
}
input.form-control {
  height: calc(1.5em + 2rem + 4px) !important;
  font-size: 1.015625rem;
}
@media screen and (min-width: 1200px) {
  button.btn.btn-grey {
    padding-left: 2.85rem;
    padding-right: 2.85rem;
  }
}
@media screen and (min-width: 1600px) {
  button.btn.btn-grey {
    padding-left: 3.4rem;
    padding-right: 3.4rem;
  }
}
</style>
<template>
  <BlockInputWrapper :info="info" :padding="padding" :label="label" :invalidFeedback="invalidFeedback" :state="state">
    <b-form :novalidate="true">
      <b-input-group>
        <b-input-group-prepend v-if="isCurrency">
          <b-button variant="grey" :disabled="true"> £ </b-button>
        </b-input-group-prepend>
        <b-form-input
          :id="elementId ? elementId : 'input'"
          :type="'number'"
          :placeholder="placeholder"
          :size="size"
          :required="required"
          :state="state"
          :squared="theme.squared"
          :disabled="disabled"
          v-model="newAnswer"
          @update="handleInput"
          aria-describedby="input-live-feedback input-live-help"
          trim
          :style="primaryStyles"
          class="mb-0"
          :class="{ disabled: disabled ? 'disabled' : '', prepended: isCurrency }"
          :name="'number'"
          numeric
          :step="step"
          inputmode="numeric"
        >
        </b-form-input>
        <slot name="form-input" />
      </b-input-group>
    </b-form>
  </BlockInputWrapper>
</template>
