export default {
  myDetails: "मेरे विवरण",
  fullName: "पूरा नाम",
  noNameFound: "कोई नाम नहीं मिला",
  simplifyClaimAddName: "अपने खाते में एक नाम जोड़ते हुए आपके भविष्य के दावों को सरल बनाएं।",
  addName: "नाम जोड़ें",
  contactDetails: "संपर्क विवरण",
  address: "पता",
  cancel: "रद्द करें",
  save: "सहेजें",
  noAddressFound: "कोई पता नहीं मिला",
  simplifyClaimAddAddress: "अपने खाते में एक पता जोड़ते हुए आपके भविष्य के दावों को सरल बनाएं।",
  communicationPreferences: "मेरी सेटिंग्स ",
  emailReminders: "ईमेल रिमाइंडर्स",
  receiveEmailReminders: "आपके क्लेम्स के लिए ईमेल रिमाइंडर्स प्राप्त करें",
  smsReminders: "SMS रिमाइंडर्स",
  receiveSmsReminders: "आपके क्लेम्स के लिए SMS रिमाइंडर्स प्राप्त करें",
  settingsUpdated: "सेटिंग्स अपडेट हो गईं",
  preferencesUpdated: "आपकी संचार प्राथमिकताएं अपडेट कर दी गई हैं।",
  withdrawClaim: {
    title: "दावा वापस लें",
    text: "क्या आप वाकई अपना दावा वापस लेना चाहते हैं? इसे पलटाया नहीं जा सकता।",
    cancel: "नहीं। इसे रखें",
    confirm: "हां। वापस लें",
  },
};
