import { UserHelper } from "../ClaimsGate/UserHelper";
import { Claim, Variable } from "@claimsgate/core-types";
import { ClaimDataService, UserDataService } from "@/helpers/ClaimsGate/DataService";
import { getFunnelVariables, resolveVariable } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";

/** Templates a given piece of text using the claim data and user data available in the provided component's state */
export function templateText<
  T extends {
    claimId: string;
    userId: string;
    claimDataService: ClaimDataService;
    userService: UserHelper;
    userDataService: UserDataService;
    isBuilder: boolean;
    funnelVariables: Array<Variable>;
    funnelTitle: string;
    workspaceName: string;
  } & Vue
>(text: string, state: T): string {
  // If we are on the builder just return the original text
  if (state.isBuilder) {
    return text;
  }

  if (!state.userDataService || !state.claimDataService || !state.funnelVariables) {
    return "";
  }

  let templatedText = text;

  const userData = state.userDataService.getCache();
  const claimData = state.claimDataService.getCache();

  // Match both single variables, OR-chained variables, and lists
  const matches = templatedText.match(/(\[.*?\])|{{.*?}}(\|\|{{.*?}})*/g);

  if (matches) {
    for (const match of matches) {
      if (match.startsWith("[") && match.endsWith("]")) {
        // Handle list
        const listItems = match.slice(1, -1).split(",");
        const resolvedItems = listItems
          .map((item) =>
            resolveVariable(
              item.trim().split("||"),
              state.funnelVariables,
              claimData,
              userData,
              state.funnelTitle,
              state.workspaceName,
              true
            )
          )
          .filter((item) => item !== null && item !== undefined && item !== "_");
        const replacement = resolvedItems.join(", ");
        templatedText = templatedText.replace(match, replacement || "_");
      } else {
        // Handle single variable or OR-chained variables
        const variableNames = match.split("||").map((v) => v.trim().replace(/^{{|}}$/g, ""));
        const replacement = resolveVariable(
          variableNames,
          state.funnelVariables,
          claimData,
          userData,
          state.funnelTitle,
          state.workspaceName,
          true
        );
        templatedText = templatedText.replace(match, replacement);
      }
    }
  }

  return templatedText;
}

/** Statelessly templates a given piece of text using the claim data with OR chaining and list support **/
export async function statelessTemplateClaimText(text: string, claimData: Claim): Promise<string> {
  let templatedText = text;

  const [funnelVariables, _getFunnelVariablesError] = await getFunnelVariables(
    getFirebaseBackend().firestore(),
    claimData.currentFunnelId
  );

  // Match both single variables, OR-chained variables, and lists
  const matches = templatedText.match(/(\[.*?\])|{{.*?}}(\|\|{{.*?}})*/g);

  if (matches) {
    for (const match of matches) {
      if (match.startsWith("[") && match.endsWith("]")) {
        // Handle list
        const listItems = match.slice(1, -1).split(",");
        const resolvedItems = listItems
          .map((item) => resolveVariable(item.trim().split("||"), funnelVariables, claimData))
          .filter((item) => item !== null && item !== undefined && item !== "_");
        const replacement = resolvedItems.join(", ");
        templatedText = templatedText.replace(match, replacement || "_");
      } else {
        // Handle single variable or OR-chained variables
        const variableNames = match.split("||").map((v) => v.trim().replace(/^{{|}}$/g, ""));
        const replacement = resolveVariable(variableNames, funnelVariables, claimData);
        templatedText = templatedText.replace(match, replacement);
      }
    }
  }

  return templatedText;
}
