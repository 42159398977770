export default {
  signInToAccess: "قم بتسجيل الدخول للوصول إلى حسابك بمطالبات ",
  signInMethod: "طريقة تسجيل الدخول",
  selectSignInMethod: "حدد طريقة تسجيل الدخول",
  continue: "متابعة ",
  welcomeBack: "مرحبًا بعودتك!",
  emailPlaceholder: "البريد الإلكتروني",
  emailLabel: "البريد الإلكتروني",
  phonePlaceholder: "الهاتف",
  phoneLabel: "الهاتف",
  enterYourPassword: "الرجاء إدخال كلمة المرور الخاصة بك",
  enterPasswordLink: "أدخل كلمة المرور المرتبطة بـ",
  back: "العودة",
  passwordPlaceholder: "كلمة المرور",
  passwordLabel: "كلمة المرور",
  forgotPassword: "هل نسيت كلمة المرور؟",
  signIn: "تسجيل الدخول",
  showPassword: "إظهار كلمة المرور",
  createPasswordHeading: "إنشاء كلمة المرور الخاصة بك",
  forgotPasswordHeading: "هل نسيت كلمة المرور؟",
  createPasswordSupporting: "يُرجى إنشاء كلمة مرور جديدة لحسابك في مطالبات ",
  confirmLabel: "تأكيد",
  confirmPlaceholder: "تأكيد",
  incorrectPassword: "كلمة مرور غير صحيحة.  يُرجى المحاولة مرة أخرى.",
  otpModalHeading: "تأكيد الرمز",
  otpModalSupporting: "أدخل الرمز الذي تم إرساله إلى:",
  otpModalInfo:
    "قد تحتاج إلى التحقق من مجلد الرسائل غير المرغوب فيها If you are having issues receiving the code, please go back and try using an alternative login method",
  otpModalCodeInvalid: "الرمز غير صحيح.",
};
